import React from 'react';
import { useStatus } from 'utils/status';
import { useTranslation, Trans } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { executeAction } from 'utils/redux';
import { getAssessmentInfo } from 'store/slices/testtakerSlice';
import { useProgress } from 'utils/progress';
import { useResize } from 'utils/hooks';

const Intro = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { uid, aid, email, firstName, lastName, corporation, logoURL } =
    useStatus();
  const location = useLocation();
  const { userPlan } = location?.state;
  const [assessment, setAssessment] = React.useState(null);
  const { setIsEntireLoading } = useProgress();
  // const [timeLimit, setTimeLimit] = React.useState(0);
  // const [questionBoolean, setQuestionBoolean] = React.useState(Boolean);
  const windowSize = useResize();

  const widthPercentage =
    windowSize?.height < 900
      ? `${100 - (900 - windowSize?.height) / 5}%`
      : '100%';

  React.useEffect(() => {
    if (!Boolean(uid) || !Boolean(aid)) return;

    setIsEntireLoading(true);
    executeAction(
      getAssessmentInfo({
        uid,
        aid,
      })
    )
      .unwrap()
      .then(({ doc }) => {
        setIsEntireLoading(false);
        setAssessment(doc);
      })
      .catch((e) => {
        setIsEntireLoading(false);
        setAssessment(null);
      });
  }, [uid, aid]);

  const onSubmitHandler = () => {
    if (assessment?.groupTests && assessment?.groupTests?.testcnt > 0) {
      navigate('/testtaker/progress/selecttest', {
        state: { logoURL },
        replace: true,
      });
    } else {
      userPlan === 100
        ? navigate('/testtaker/progress/store-survey', {
            state: { logoURL, userPlan },
            replace: true,
          })
        : navigate('/testtaker/evaluation', {
            state: { logoURL },
            replace: true,
          });
    }
  };

  const introVideo = window.document.getElementById('intro-video');
  introVideo?.addEventListener('loadeddata', function () {
    introVideo.style.display = 'inline-block';
  });

  return (
    <main className="pages-public-intro">
      <div className="container">
        <div className="container-inner">
          <section className="video-box">
            <video
              id="intro-video"
              src={`${process.env.REACT_APP_IMATE_URL}/${assessment?.introVideoUrl}`}
              controls={true}
              style={{ width: widthPercentage }}
              autoPlay
              playsInline
            ></video>
          </section>
          <button
            className="submit demo common-button black active"
            onClick={onSubmitHandler}
          >
            {t('btn.skip')}
          </button>
        </div>
      </div>
    </main>
  );
};

export default Intro;
