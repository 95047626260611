import React from 'react';
import { useTranslation } from 'react-i18next';
import { useResult } from 'utils/result';
import { useCallbackPrompt } from 'utils/hooks';
import ExitModal from 'components/modal/ExitModal';
import { executeAction } from 'utils/redux';
import {
  getAttributes,
  getCultureAttributes,
} from 'store/slices/testtakerSlice';
import { useProgress } from 'utils/progress';
import { useStatus } from 'utils/status';

const CultureFit = ({ test, setCurrentState }) => {
  const { t } = useTranslation();
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(true);
  const { recordResult } = useResult();
  const [canDone, setCanDone] = React.useState(false);
  const [selecteds, setSelecteds] = React.useState([]);
  const [cultureSelecteds, setCultureSelecteds] = React.useState([]);
  const [curStep, setCurStep] = React.useState(1);
  const { setIsEntireLoading } = useProgress();
  const [attributes, setAttributes] = React.useState([]);
  const { getTestInfo, setTestInfo } = useStatus();
  const { duration, doneCnt, maxDoneCnt } = getTestInfo();
  const lang = t('lang');

  React.useEffect(() => {
    const skillEndEventListener = (event) => {
      onSubmitHandler();
    };

    window.scrollTo(0, 0);

    window.document.addEventListener('skill_end', skillEndEventListener);
    return () =>
      window.document.removeEventListener('skill_end', skillEndEventListener);
  }, []);

  React.useEffect(() => {
    if (curStep === 1) {
      setIsEntireLoading(true);
      executeAction(
        getAttributes({
          sid: test?.id,
          lang,
        })
      )
        .unwrap()
        .then(({ docs }) => {
          setAttributes(docs || []);
          setIsEntireLoading(false);
        })
        .catch((e) => {
          console.dir(e);
          setAttributes([]);
          setIsEntireLoading(false);
        });
    } else if (curStep === 2) {
      setIsEntireLoading(true);
      executeAction(
        getCultureAttributes({
          sid: test?.id,
          lang,
        })
      )
        .unwrap()
        .then(({ docs }) => {
          setAttributes(docs || []);
          setIsEntireLoading(false);
        })
        .catch((e) => {
          console.dir(e);
          setAttributes([]);
          setIsEntireLoading(false);
        });
    }
  }, [curStep]);

  React.useEffect(() => {
    if (curStep === 1) {
      if ((selecteds || []).length >= 5) {
        return setCanDone(true);
      }
      return setCanDone(false);
    }
    if (curStep === 2) {
      if ((cultureSelecteds || []).length >= 5) {
        return setCanDone(true);
      }
      return setCanDone(false);
    }
    setCanDone(false);
  }, [curStep, selecteds, cultureSelecteds]);

  const onAttributeClickHandler = (attribute) => {
    if (curStep === 1) {
      const idx = selecteds.findIndex((x) => x.id === attribute.id);
      if (idx >= 0) {
        setSelecteds([
          ...selecteds.slice(0, idx),
          ...selecteds.slice(idx + 1, selecteds.length),
        ]);
      } else if (selecteds.length < 5) setSelecteds([...selecteds, attribute]);
    } else if (curStep === 2) {
      const idx = cultureSelecteds.findIndex((x) => x.id === attribute.id);
      if (idx >= 0) {
        setCultureSelecteds([
          ...cultureSelecteds.slice(0, idx),
          ...cultureSelecteds.slice(idx + 1, cultureSelecteds.length),
        ]);
      } else if (cultureSelecteds.length < 5)
        setCultureSelecteds([...cultureSelecteds, attribute]);
    }
  };

  const onNextHandler = () => {
    setCurStep(curStep + 1);
    setTestInfo({
      duration,
      doneCnt: doneCnt + 1,
      maxDoneCnt,
      canSubmit: true,
    });
  };

  const onSubmitHandler = () => {
    recordResult({
      sid: test?.uuid,
      attributes: (selecteds || []).map((x) => x.id),
      cultureAttributes: (cultureSelecteds || []).map((x) => x.id),
    });
    setCurrentState('end');
    setTestInfo({
      duration: null,
      doneCnt: null,
      maxDoneCnt: null,
      canSubmit: false,
    });
  };

  return (
    <main className="pages-protected-evaluation-objectives-culture-fit">
      <ExitModal
        showPrompt={showPrompt}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
      />
      <div className="container">
        <div className="container-inner">
          <h1 className="title fs20">{t('title.culture-fit')}</h1>
          <div className="attributes">
            <ul className="attributes-list">
              {(attributes || []).map((attribute, idx) => (
                <li
                  key={idx}
                  className={`attributes-list-item ${
                    curStep === 1
                      ? selecteds.findIndex((x) => x.id === attribute.id) >=
                          0 && 'selected'
                      : cultureSelecteds.findIndex(
                          (x) => x.id === attribute.id
                        ) >= 0 && 'selected'
                  } ${
                    curStep === 1
                      ? selecteds.length >= 5 && 'disabled'
                      : cultureSelecteds.length >= 5 && 'disabled'
                  }`}
                >
                  <div
                    className="container"
                    onClick={() => onAttributeClickHandler(attribute)}
                  >
                    {t('lang') === 'ko' ? (
                      <>
                        <span>{attribute?.ko}</span>
                        <span>{attribute?.en}</span>
                      </>
                    ) : (
                      <span>{attribute?.en}</span>
                    )}
                  </div>
                </li>
              ))}
            </ul>
          </div>
          {/* {curStep === 1 ? (
            <button
              className={`next common-button fsbtn16 primary ${canDone && "active"}`}
              onClick={canDone ? onNextHandler : null}
            >
              {t("btn.next")}
            </button>
          ) : (
            <button
              className={`submit common-button fsbtn16 primary ${canDone && "active"}`}
              onClick={canDone ? onSubmitHandler : null}
            >
              {t("btn.submit")}
            </button>
          )} */}
        </div>
      </div>
    </main>
  );
};

export default CultureFit;
