import React from 'react';
import { useTranslation } from 'react-i18next';
import DemoVoiceTestLeftContents from './DemoVoiceTestLeftContents';
import DemoVoiceTestRightContents from './DemoVoiceTestRightContents';

function DemoVoiceTestLayout({ uid, aid, cid, userPlan }) {
    const { t } = useTranslation();
    const [loadingVoiceTest, setLoadingVoiceTest] = React.useState(false);
    const [recordState, setRecordState] = React.useState('stop');
    const [startFunctionState, setStartFunctionState] = React.useState(false);

  return (
    <main className="pages-demo-voice-test">
      <div className="container">
          <div className="setting-title">{t('voicetest.check_setting')}</div>
        <div className="container-wrapper">
          <DemoVoiceTestLeftContents
              uid={uid}
              aid={aid}
              cid={cid}
              userPlan={userPlan}
              setLoadingVoiceTest={setLoadingVoiceTest}
              recordState={recordState}
              setRecordState={setRecordState}
              startFunctionState={startFunctionState}
              setStartFunctionState={setStartFunctionState}
          />
          <DemoVoiceTestRightContents
              loadingVoiceTest={loadingVoiceTest}
              recordState={recordState}
              setStartFunctionState={setStartFunctionState}
          />
        </div>
      </div>
    </main>
  );
}

export default DemoVoiceTestLayout;
