import React from 'react';

function PositionContents({ title, desc, list, onClick, btnLabel }) {
  return (
    <section className="infomation-job-contents">
      <article className="infomation-job">
        <h3 className="fs20">{title && title}</h3>
        <p className="fs16">{desc && desc}</p>
      </article>

      <article className="infomation-detail-list">
        <ul>
          {list &&
            (list || []).map((li, idx) => (
              <li key={`list_${idx}`} className="fs16">
                {li}
              </li>
            ))}
        </ul>
      </article>

      <article className="information-button">
        <button
          className="submit common-button fsbtn16 primary active"
          onClick={onClick}
        >
          {btnLabel && btnLabel}
        </button>
      </article>
    </section>
  );
}

export default PositionContents;
