import { nextQuestion } from './question';

export async function koreanListeningQuestion(previous) {
  const baseUrl = `${process.env.REACT_APP_IMATE_URL}/images/audio/koreanListening`;
  /** ==========
   * 문제
  ============== */
  const questionDifficulty = {
    0: [
      '마포까지 어떻게 가실 예정이세요?',
      5,
      {
        audio: [
          [
            `${baseUrl}/Level-3/Level-3-Desc-1~2.mp3`,
            `${baseUrl}/Common-Voice/Korean-문제.mp3`,
            `${baseUrl}/Level-3/Level-3-Q-1.mp3`,
            `${baseUrl}/Common-Voice/Korean-1.mp3`,
            `${baseUrl}/Level-3/Level-3-Q-1-1.mp3`,
            `${baseUrl}/Common-Voice/Korean-2.mp3`,
            `${baseUrl}/Level-3/Level-3-Q-1-2.mp3`,
            `${baseUrl}/Common-Voice/Korean-3.mp3`,
            `${baseUrl}/Level-3/Level-3-Q-1-3.mp3`,
            `${baseUrl}/Common-Voice/Korean-4.mp3`,
            `${baseUrl}/Level-3/Level-3-Q-1-4.mp3`,
          ],
        ],
      },
    ],
    1: [
      '여름 휴가는 어디로 갈 예정이세요?',
      5,
      {
        audio: [
          [
            `${baseUrl}/Level-3/Level-3-Desc-1~2.mp3`,
            `${baseUrl}/Common-Voice/Korean-문제.mp3`,
            `${baseUrl}/Level-3/Level-3-Q-2.mp3`,
            `${baseUrl}/Common-Voice/Korean-1.mp3`,
            `${baseUrl}/Level-3/Level-3-Q-2-1.mp3`,
            `${baseUrl}/Common-Voice/Korean-2.mp3`,
            `${baseUrl}/Level-3/Level-3-Q-2-2.mp3`,
            `${baseUrl}/Common-Voice/Korean-3.mp3`,
            `${baseUrl}/Level-3/Level-3-Q-2-3.mp3`,
            `${baseUrl}/Common-Voice/Korean-4.mp3`,
            `${baseUrl}/Level-3/Level-3-Q-2-4.mp3`,
          ],
        ],
      },
    ],
    2: [
      '두 사람은 주말에 무엇을 할 예정인가요?',
      10,
      {
        audio: [
          [
            `${baseUrl}/Level-4/Level-4-Desc-1.mp3`,
            `${baseUrl}/Level-4/Level-4-Dialog-1.mp3`,
            `${baseUrl}/Common-Voice/Korean-문제.mp3`,
            `${baseUrl}/Level-4/Level-4-Q-1.mp3`,
            `${baseUrl}/Common-Voice/Korean-1.mp3`,
            `${baseUrl}/Level-4/Level-4-Q-1-1.mp3`,
            `${baseUrl}/Common-Voice/Korean-2.mp3`,
            `${baseUrl}/Level-4/Level-4-Q-1-2.mp3`,
            `${baseUrl}/Common-Voice/Korean-3.mp3`,
            `${baseUrl}/Level-4/Level-4-Q-1-3.mp3`,
            `${baseUrl}/Common-Voice/Korean-4.mp3`,
            `${baseUrl}/Level-4/Level-4-Q-1-4.mp3`,
          ],
        ],
      },
    ],
    3: [
      '다음 중 30일 수도권의 날씨는?',
      10,
      {
        audio: [
          [
            `${baseUrl}/Level-4/Level-4-Desc-2.mp3`,
            `${baseUrl}/Level-4/Level-4-Dialog-2.mp3`,
            `${baseUrl}/Common-Voice/Korean-문제.mp3`,
            `${baseUrl}/Level-4/Level-4-Q-2.mp3`,
            `${baseUrl}/Common-Voice/Korean-1.mp3`,
            `${baseUrl}/Level-4/Level-4-Q-2-1.mp3`,
            `${baseUrl}/Common-Voice/Korean-2.mp3`,
            `${baseUrl}/Level-4/Level-4-Q-2-2.mp3`,
            `${baseUrl}/Common-Voice/Korean-3.mp3`,
            `${baseUrl}/Level-4/Level-4-Q-2-3.mp3`,
            `${baseUrl}/Common-Voice/Korean-4.mp3`,
            `${baseUrl}/Level-4/Level-4-Q-2-4.mp3`,
          ],
        ],
      },
    ],
    4: [
      '다음 중 늦은 이유는?',
      15,
      {
        audio: [
          [
            `${baseUrl}/Level-5/Level-5-Desc-1.mp3`,
            `${baseUrl}/Level-5/Level-5-Dialog-1.mp3`,
            `${baseUrl}/Common-Voice/Korean-문제.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-1.mp3`,
            `${baseUrl}/Common-Voice/Korean-1.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-1-1.mp3`,
            `${baseUrl}/Common-Voice/Korean-2.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-1-2.mp3`,
            `${baseUrl}/Common-Voice/Korean-3.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-1-3.mp3`,
            `${baseUrl}/Common-Voice/Korean-4.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-1-4.mp3`,
          ],
        ],
      },
    ],
    5: [
      '화자는 다음 중 무엇을 하는 것으로 하루 일과를 시작합니까?',
      20,
      {
        audio: [
          [
            `${baseUrl}/Level-6/Level-6-Desc-1.mp3`,
            `${baseUrl}/Level-6/Level-6-Dialog-1.mp3`,
            `${baseUrl}/Common-Voice/Korean-문제.mp3`,
            `${baseUrl}/Level-6/Level-6-Q-1.mp3`,
            `${baseUrl}/Common-Voice/Korean-1.mp3`,
            `${baseUrl}/Level-6/Level-6-Q-1-1.mp3`,
            `${baseUrl}/Common-Voice/Korean-2.mp3`,
            `${baseUrl}/Level-6/Level-6-Q-1-2.mp3`,
            `${baseUrl}/Common-Voice/Korean-3.mp3`,
            `${baseUrl}/Level-6/Level-6-Q-1-3.mp3`,
            `${baseUrl}/Common-Voice/Korean-4.mp3`,
            `${baseUrl}/Level-6/Level-6-Q-1-4.mp3`,
          ],
        ],
      },
    ],
    6: [
      '다음 중 맞는 말은?',
      25,
      {
        audio: [
          [
            `${baseUrl}/Level-7/Level-7-Desc-1.mp3`,
            `${baseUrl}/Level-7/Level-7-Dialog-1.mp3`,
            `${baseUrl}/Common-Voice/Korean-문제.mp3`,
            `${baseUrl}/Level-7/Level-7-Q-1.mp3`,
            `${baseUrl}/Common-Voice/Korean-1.mp3`,
            `${baseUrl}/Level-7/Level-7-Q-1-1.mp3`,
            `${baseUrl}/Common-Voice/Korean-2.mp3`,
            `${baseUrl}/Level-7/Level-7-Q-1-2.mp3`,
            `${baseUrl}/Common-Voice/Korean-3.mp3`,
            `${baseUrl}/Level-7/Level-7-Q-1-3.mp3`,
            `${baseUrl}/Common-Voice/Korean-4.mp3`,
            `${baseUrl}/Level-7/Level-7-Q-1-4.mp3`,
          ],
        ],
      },
    ],
    7: [
      '',
      25,
      {
        audio: [
          [
            `${baseUrl}/Level-7/Level-8-Desc-1.mp3`,
            `${baseUrl}/Level-7/Level-8-Dialog-1.mp3`,
            `${baseUrl}/Common-Voice/Korean-1.mp3`,
            `${baseUrl}/Level-7/Level-8-Q-1-1.mp3`,
            `${baseUrl}/Common-Voice/Korean-2.mp3`,
            `${baseUrl}/Level-7/Level-8-Q-1-2.mp3`,
            `${baseUrl}/Common-Voice/Korean-3.mp3`,
            `${baseUrl}/Level-7/Level-8-Q-1-3.mp3`,
            `${baseUrl}/Common-Voice/Korean-4.mp3`,
            `${baseUrl}/Level-7/Level-8-Q-1-4.mp3`,
          ],
        ],
      },
    ],
  };

  /** ==========
   * 문제 선택지
  ============== */
  const choices = {
    0: [
      ['늦지 않아서 다행이네요.', 0],
      ['지하철 2호선을 타고 가세요.', 0],
      ['택시를 타고 가려고요.', 1],
      ['도착해서 연락드릴게요.', 0],
    ],
    1: [
      ['제주도로 가려고요.', 1],
      ['자전거를 탈 계획입니다.', 0],
      ['회사에 늦겠어요.', 0],
      ['책 읽는 것을 가장 좋아합니다.', 0],
    ],
    2: [
      ['수영', 0],
      ['꽃구경', 1],
      ['맛집 탐방', 0],
      ['자전거 타기', 0],
    ],
    3: [
      ['맑음', 0],
      ['비', 1],
      ['태풍', 0],
      ['흐림', 0],
    ],
    4: [
      ['다리를 다쳐서', 0],
      ['딸을 데려다 주느라', 0],
      ['아침식사를 하느라', 0],
      ['길이 막혀서', 1],
    ],
    5: [
      ['샤워를 하는 것', 0],
      ['운동 하는 것', 0],
      ['이메일을 확인하는 것', 0],
      ['물을 마시는 것', 1],
    ],
    6: [
      ['해당 세일 행사는 기성복을 최대 40%까지 할인해서 판매한다.', 0],
      ['20만원 이상 구매 고객께는 선물을 증정한다.', 0],
      ['사은 선물 수령은 영수증 지참 후 지하 1층 안내데스크에서 가능하다.', 1],
      ['해당 세일 행사는 연중무휴 제공한다.', 0],
    ],
    7: [
      [
        '강원세계산림엑스포는 관광객 유치 목표를 초과 달성하였으며, 각종 전국 단위 행사와 학술 행사등의 유치를 통해 강원도의 지역경제를 활성화 하는데에 기여했다.',
        1,
      ],
      [
        '주 행사장이 위치한 고성의 방문객은 저조했으나, 이 외 지역은 관광객이 초과 유치되었다.',
        0,
      ],
      ['50% 이상의 관관객이 단체로, 학교, 지역 단체 등으로 이루어졌다.', 0],
      [
        '강원도는 2023강원세계산림엑스포의 흥행을 이어가기위해 2024년 다시금 엑스포 유치를 기획하고 있다. ',
        0,
      ],
    ],
  };

  /** ==========
   * 난이도
  ============== */
  const difficultyLevels = {
    5: 'Beginner',
    10: 'Intermediate',
    15: 'Intermediate',
    20: 'Advanced',
    25: 'Advanced',
  };

  /** ==========
   * 지문 그룹
  ============== */
  const passageQuestions = {
    0: [
      ['다음의 대화를 듣고 이어질 수 있는 말로 가장 적절한 답을 고르시오.'],
      [0],
    ],
    1: [
      ['다음의 대화를 듣고 이어질 수 있는 말로 가장 적절한 답을 고르시오.'],
      [1],
    ],
    2: [
      ['다음 대화를 듣고 질문에 대한 답변으로 가장 적절한 것을 고르시오.'],
      [2],
    ],
    3: [['다음 지문을 듣고 가장 옳은 내용을 고르시오.'], [3]],
    4: [['다음 지문을 듣고 가장 옳은 내용을 고르시오.'], [4]],
    5: [['다음 지문을 듣고 가장 옳은 내용을 고르시오.'], [5]],
    6: [['다음 지문을 듣고 가장 옳은 내용을 고르시오.'], [6]],
    7: [['다음 지문을 듣고 가장 옳은 내용을 고르시오.'], [7]],
  };

  return nextQuestion(
    previous,
    questionDifficulty,
    choices,
    difficultyLevels,
    passageQuestions
  );
}
