import React from 'react';

function PieChart({ graghTime, time }) {
  return (
    <div className="score-graph">
      <div className="single-chart">
        <svg viewBox="0 0 36 36" className="circular-chart">
          <path
            className="circle-bg"
            d="M18 2.0845
              a 15.9155 15.9155 0 0 1 0 31.831
              a 15.9155 15.9155 0 0 1 0 -31.831"
          />
          <path
            className="circle"
            strokeDasharray={`${graghTime && graghTime} , 100`}
            d="M18 2.0845
              a 15.9155 15.9155 0 0 1 0 31.831
              a 15.9155 15.9155 0 0 1 0 -31.831"
          />
        </svg>
      </div>
      <div className="percentage">
        <span className="number fs28">{time && time}</span>
      </div>
    </div>
  );
}

export default PieChart;
