import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as TriangleIcon } from 'assets/images/icon/testtaker/triangle-icon.svg';
import { useCallbackPrompt } from 'utils/hooks';
import ExitModal from 'components/modal/ExitModal';
import { useResult } from 'utils/result';
import { divideSkillScope, divideSkillSection, useStatus } from 'utils/status';
import HtmlEditor from 'components/frontCodeEditor/HtmlEditor';
import CssEditor from 'components/frontCodeEditor/CssEditor';
import JavascriptEditor from 'components/frontCodeEditor/JavascriptEditor';
import { convertHtmlForDisplay } from 'utils/string';

function FrontCode({ test, setCurrentState, assessment, objectiveTests }) {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(0);

  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(true);
  const { recordResult } = useResult();
  const { setTestInfo, getTestInfo } = useStatus();
  const [cansubmit, SetCanSubmit] = useState(false);
  const { duration, doneCnt, maxDoneCnt } = getTestInfo();
  const [htmlCode, setHtmlCode] = useState('');
  const [cssCode, setCssCode] = useState('');
  const [javascriptCode, setJavascriptCode] = useState('');
  const [iFrameKey, setIFrameKey] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const iFrameRef = useRef(null);

  const __TAB_LIST__ = [
    {
      id: 0,
      value: 'HTML',
    },
    {
      id: 1,
      value: 'CSS',
    },
    {
      id: 2,
      value: 'JavaScript',
    },
  ];

  const __TAB_CONTENTS__ = [
    {
      id: 0,
      key: 'html',
    },
    {
      id: 1,
      key: 'css',
    },
    {
      id: 2,
      key: 'javascript',
    },
  ];

  /** ====================
   * @function sendMessage : iframe으로 html코드를 전송하는 함수
   * postMessage(전송할 메세지, 발송한 도메인 주소(보안을 위한 절차))
   * @returns 
  ======================= */
  const sendMessage = (htmlCode, cssCode, javascriptCode) => {
    SetCanSubmit(true);
    setIsLoading(true);
    if (!iFrameRef.current) return;

    setIFrameKey((prevKey) => prevKey + 1);

    setTimeout(() => {
      iFrameRef.current.contentWindow.postMessage(
        { htmlCode, cssCode, javascriptCode, render: 'render' },
        '*'
      );
      setIsLoading(false);
    }, 3000);
  };

  /** =========================
   * @function tabClickHandler : tab버튼 클릭 시 해당 index 값을 저장하는 함수
   * @param {*} index(number) : tab버튼의 index 값 
  ============================ */
  const tabClickHandler = (index) => {
    setActiveIndex(index);
  };

  /** ==================
   * @function onChange : htmlEditor, CssEditor, JavascriptEditor 컴포넌트에서 반환하는 action 값에 따라 각각에 해당하는 State에 저장 함수
   * @param {*} action(string) : 각각의 컴포넌에서 반환하는 타입 값
   * @param {*} data(string) : 각각의 코드 에디터에 작성하는 코드 값
  ===================== */
  const onChange = (action, data) => {
    switch (action) {
      case 'htmlCode': {
        setHtmlCode(data);
        break;
      }
      case 'cssCode': {
        setCssCode(data);
        break;
      }
      case 'javascriptCode': {
        setJavascriptCode(data);
        break;
      }
      default: {
        console.warn('case not handled!', action, data);
      }
    }
  };

  /** ========================
   * @function onSubmitHandler : 작성한 데이터 저장
   * type: string,
   * section: string,
   * sid: string,(사용자가 직접 만든 문제는 uuid가 존재하고 테스트에 기본으로 제공하는 문제는 id가 존재함)
   * @param {*} htmlCode(string) : html 코드 
   * @param {*} cssCode(string) : css 코드
   * @param {*} javascriptCode(string) : javascript 코드 
  =========================== */
  const onSubmitHandler = (htmlCode, cssCode, javascriptCode) => {
    recordResult({
      type: divideSkillScope(test),
      section: divideSkillSection(test),
      sid: test?.uuid || test?.id,
      data: {
        htmlCode,
        cssCode,
        javascriptCode,
        // sourceCode: code,
        // result: outputResult,
        // codeLanguage: languageID,
      },
    });
    tabClickHandler(0);
    setHtmlCode('');
    setCssCode('');
    setJavascriptCode('');
    sendMessage('', '', '');
    setIFrameKey((prevKey) => prevKey + 1);
    SetCanSubmit(false);
  };
  useEffect(() => {
    const skillEndEventListener = (event) => {
      onSubmitHandler(htmlCode, cssCode, javascriptCode);
    };

    window.scrollTo(0, 0);

    window.document.addEventListener('skill_end', skillEndEventListener);
    return () =>
      window.document.removeEventListener('skill_end', skillEndEventListener);
  }, []);

  useEffect(() => {
    const testDoneEventListener = (event) => {
      onSubmitHandler(htmlCode, cssCode, javascriptCode);
    };

    let btnName = '';
    if (doneCnt === maxDoneCnt) {
      btnName = t('btn.submit');
    } else {
      btnName = t('btn.next');
    }

    setTestInfo({
      duration,
      doneCnt,
      maxDoneCnt,
      canSubmit: cansubmit,
      buttonName: btnName,
    });

    window.document.addEventListener('test_done', testDoneEventListener);
    return () => {
      window.document.removeEventListener('test_done', testDoneEventListener);
    };
  }, [cansubmit, htmlCode, cssCode, javascriptCode]);

  /** ==========================
   * @function convertComponents : __TAB_CONTENTS__의 key값에 따라 다른 에디터(컴포넌트)를 호출하는 함수
   * @param {*} content(object) : id값과 key값을 가지고 있는 객체
   * @returns 
  ============================= */
  const convertComponents = (content) => {
    switch (content?.key) {
      case 'html':
        return <HtmlEditor htmlCode={htmlCode} onChange={onChange} />;
      case 'css':
        return <CssEditor cssCode={cssCode} onChange={onChange} />;
      case 'javascript':
        return (
          <JavascriptEditor
            javascriptCode={javascriptCode}
            onChange={onChange}
          />
        );
    }
  };

  return (
    <main className="pages-protected-evaluation-subjectives-code">
      <ExitModal
        showPrompt={showPrompt}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
      />
      <div className="container">
        <div className="spliter">
          <section className="spliter-left">
            <div className="question">
              <pre
                className="fs18"
                dangerouslySetInnerHTML={{
                  __html: convertHtmlForDisplay(test?.question),
                }}
              />
              {test?.url?.length > 0 && (
                <a
                  href={`${process.env.REACT_APP_IMATE_URL}/${test?.url}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    alt=""
                    src={`${process.env.REACT_APP_IMATE_URL}/${test?.url}`}
                    style={{ width: '100%', height: 'auto' }}
                  />
                </a>
              )}
            </div>
          </section>

          <section className="spliter-right">
            <nav className="tab">
              <ul>
                {(__TAB_LIST__ || []).map((tab, idx) => (
                  <li
                    key={idx}
                    className={`fs16 ${activeIndex === idx ? 'active' : ''}`}
                    onClick={() => tabClickHandler(idx)}
                  >
                    {tab?.value}
                  </li>
                ))}
              </ul>
            </nav>
            {(__TAB_CONTENTS__ || []).map(
              (content, idx) =>
                activeIndex === idx && (
                  <React.Fragment key={idx}>
                    {convertComponents(content)}
                  </React.Fragment>
                )
            )}
            <aside className="editor-result-button-box">
              <span className="fs16">{t('code.result-title')}</span>
              <button
                className="common-button fsbtn16 primary active"
                disabled={!!isLoading}
                onClick={() => sendMessage(htmlCode, cssCode, javascriptCode)}
              >
                {!isLoading ? t('btn.code-run') : t('btn.code-runing')}
                <TriangleIcon />
              </button>
            </aside>

            <aside className="editor-output">
              <iframe
                ref={iFrameRef}
                key={iFrameKey}
                id="iframe-preview"
                name="front preview"
                sandbox="allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
                src="/front-iframe"
                loading="lazy"
              />
            </aside>
          </section>
        </div>
      </div>
    </main>
  );
}

export default FrontCode;
