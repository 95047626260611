import React from 'react';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';
import { useResult } from 'utils/result';

function DemoVoiceTestRightContents({
  loadingVoiceTest,
  recordState,
  setStartFunctionState,
}) {
  const { stream } = useResult();
  const onRecordStartHandler = () => {
    setStartFunctionState(true);
  };
  const { t } = useTranslation();
  
  return (
    <div className="container-right">
        <header className="header">{t('voicetest.test_guide_helper')}</header>
      <section className="read-sentence">
        <div className="read-sentence-wrapper">
          <p className="fs28">{t('voicetest.test_guide')}</p>
        </div>
      </section>
      <div className="button-wrapper">
        {!loadingVoiceTest ? (
          <button
            className={`form-submit common-button fsbtn16 black active submit ${
              recordState === 'start' || !stream && 'disabled'
            }`}
            disabled={recordState === 'start' || !stream}
            onClick={onRecordStartHandler}
          >
            {t('voicetest.btn_start')}
          </button>
        ) : (
          <>
            <button
              className="form-submit common-button fsbtn16 grey submit loading"
              disabled
            >
              <CircularProgress color="grey" size={32} />
            </button>
            <div className="helper">
              <p>{t('desc.please-wait-a-moment')}</p>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default DemoVoiceTestRightContents;
