import React from 'react';

function VideoInfoHeader({ title, desc }) {
  return (
    <header className="header">
      <h1 className="header-title fs28to24">{title && title}</h1>
      <p className="header-description">{desc && desc}</p>
    </header>
  );
}

export default VideoInfoHeader;
