import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import {
  _getCandidateInAssessment,
  _updateCandidateInfo,
  _getUserInfo,
  _updateCandidate,
  _updateCandidatePhoneNumber,
  _getEditText,
  _setCandidateDoc,
  _setCandidateDocWithoutPhoneNumber,
  _getAssessmentsOfCurrentUser,
  _getCandidatesOfAssessment,
  _leaveUpdateCandidate,
  _updateCandidateWithoutPhoneNumber,
} from 'utils/firebase/auth';
import { displayName } from 'utils/string';
import { serializeError } from 'serialize-error';
import axios from 'axios';

const initialState = {
  uid: '',
  aid: '',
  email: '',
  firstName: '',
  lastName: '',
  corporation: '',
  logoURL: '',
};

export const getAssessmentsOfCurrentUser = createAsyncThunk(
  'auth/getAssessmentsOfCurrentUser',
  async (payload, { getState, rejectWithValue }) => {
    const { uid, aid } = payload;
    try {
      // const uid = getState().auth.user?.uid;

      const docs = await _getAssessmentsOfCurrentUser({
        uid,
      });

      return {
        docs,
      };
    } catch (e) {
      Sentry.captureException(e);
      return rejectWithValue(serializeError(e));
    }
  }
);
export const getCandidatesOfAssessment = createAsyncThunk(
  'auth/getCandidatesOfAssessment',
  async (payload = {}, { rejectWithValue, getState }) => {
    const { aid, uid } = payload;
    try {
      // const uid = getState().auth.user?.uid;
      const docs = await _getCandidatesOfAssessment({ uid, aid });
      return {
        docs,
      };
    } catch (e) {
      Sentry.captureException(e);
      return rejectWithValue(serializeError(e));
    }
  }
);

export const getCandidatesOfAssessments = createAsyncThunk(
  'auth/getCandidatesOfAssessments',
  async (payload = {}, { rejectWithValue, getState, dispatch }) => {
    const { assessments, uid } = payload;
    try {
      const tasks = new Array();
      for (const assessment of assessments) {
        const aid = assessment?.id;
        tasks.push(dispatch(getCandidatesOfAssessment({ aid, uid })).unwrap());
      }
      const tasksResult = await Promise.all(tasks);
      const candidates = (tasksResult || []).map((it) => it.docs);
      return {
        candidates,
      };
    } catch (e) {
      Sentry.captureException(e);
      return rejectWithValue(serializeError(e));
    }
  }
);

export const getCandidateInAssessment = createAsyncThunk(
  'auth/getCandidateInAssessment',
  async (payload = {}, { rejectWithValue }) => {
    const { uid, aid, email } = payload;
    try {
      const doc = await _getCandidateInAssessment({ uid, aid, email });
      return {
        doc,
      };
    } catch (e) {
      Sentry.captureException(e);
      return rejectWithValue(serializeError(e));
    }
  }
);

export const updateCandidateInfo = createAsyncThunk(
  'auth/updateCandidateInfo',
  async (payload = {}, { rejectWithValue }) => {
    const { uid, aid, email, firstName, lastName, birth, testNumber } = payload;
    try {
      await _updateCandidateInfo({
        uid,
        aid,
        email,
        firstName,
        lastName,
        birth,
        testNumber
      });
      await _setCandidateDocWithoutPhoneNumber({
        uid,
        aid,
        email,
        firstName,
        lastName,
        birth,
        testNumber
      });
      return {
        success: true,
      };
    } catch (e) {
      Sentry.captureException(e);
      return rejectWithValue(serializeError(e));
    }
  }
);

export const getUserInfo = createAsyncThunk(
  'auth/getUserInfo',
  async (payload = {}, { rejectWithValue }) => {
    const { uid } = payload;
    try {
      const doc = await _getUserInfo({ uid });
      return {
        doc,
      };
    } catch (e) {
      Sentry.captureException(e);
      return rejectWithValue(serializeError(e));
    }
  }
);

export const sendEmailToCandidate = createAsyncThunk(
  'assessments/sendEmailToCandidate',
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { uid, aid, email, firstName, phoneNumber, countryCode } = payload;
      const firstName1 = '';
      const lastName = '';
      const { logoURL, corporation } = await _getUserInfo({
        uid,
      });

      await _updateCandidate({
        uid,
        aid,
        cid: email,
        firstName: firstName1,
        lastName,
        phoneNumber,
        countryCode,
      });

      await _setCandidateDoc({
        uid,
        aid,
        firstName: '',
        lastName: '',
        email,
        phoneNumber,
        countryCode,
      });

      let editText = await _getEditText({
        uid,
        aid,
        key: 'edit_invite_email',
        lang: window.localStorage.getItem('i18nextLng')?.slice?.(0, 2) || 'en',
      });
      editText = editText.replaceAll(
        '{candidate_name}',
        displayName(
          firstName,
          lastName,
          window.localStorage.getItem('i18nextLng')?.slice?.(0, 2) || 'en'
        )
      );
      editText = editText.replaceAll('{company_name}', corporation);

      // await axios.post(
      //   `${process.env.REACT_APP_SERVER_PUBLIC_PATH}/assessment/invite/email`,
      //   {
      //     email,
      //     logoURL,
      //     companyName: corporation,
      //     editText,
      //     uid,
      //     aid,
      //     firstName : firstName1,
      //     lastName,
      //   },
      //   {
      //     headers: {
      //       "Publish-Type": process.env.REACT_APP_NODE_ENV,
      //       "User-Lang": window.localStorage.getItem("i18nextLng").slice(0, 2),
      //     },
      //   }
      // );

      return {
        success: true,
      };
    } catch (e) {
      Sentry.captureException(e);
      return rejectWithValue(serializeError(e));
    }
  }
);

export const sendEmailToCandidateWithoutPhoneNumber = createAsyncThunk(
  'assessments/sendEmailToCandidateWithoutPhoneNumber',
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { uid, aid, email, firstName } = payload;
      const firstName1 = '';
      const lastName = '';
      const { corporation } = await _getUserInfo({
        uid,
      });

      await _updateCandidateWithoutPhoneNumber({
        uid,
        aid,
        cid: email,
      });

      await _setCandidateDocWithoutPhoneNumber({
        uid,
        aid,
        firstName: '',
        lastName: '',
        email,
      });

      let editText = await _getEditText({
        uid,
        aid,
        key: 'edit_invite_email',
        lang: window.localStorage.getItem('i18nextLng')?.slice?.(0, 2) || 'en',
      });
      editText = editText.replaceAll(
        '{candidate_name}',
        displayName(
          firstName,
          lastName,
          window.localStorage.getItem('i18nextLng')?.slice?.(0, 2) || 'en'
        )
      );
      editText = editText.replaceAll('{company_name}', corporation);

      return {
        success: true,
      };
    } catch (e) {
      Sentry.captureException(e);
      return rejectWithValue(serializeError(e));
    }
  }
);

export const leaveDetection = createAsyncThunk(
  'assessments/leaveDetection',
  async (payload = {}, { rejectWithValue }) => {
    const { uid, aid, email, data } = payload;
    try {
      _leaveUpdateCandidate({
        uid: uid || '',
        aid: aid || '',
        cid: email,
        data: {
          progress: 0,
        },
      });

      return {
        success: true,
      };
    } catch (e) {
      Sentry.captureException(e);
      return rejectWithValue(serializeError(e));
    }
  }
);

export const updatePhoneNumber = createAsyncThunk(
  'assessments/updatePhoneNumber',
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { uid, aid, email, phoneNumber, countryCode } = payload;

      await _updateCandidatePhoneNumber({
        uid,
        aid,
        cid: email,
        phoneNumber,
        countryCode,
      });

      await _setCandidateDoc({
        uid,
        aid,
        email,
        phoneNumber,
        countryCode,
      });

      return {
        success: true,
      };
    } catch (e) {
      Sentry.captureException(e);
      return rejectWithValue(serializeError(e));
    }
  }
);

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: {
    [getUserInfo.pending]: (state) => {},
    [getUserInfo.fulfilled]: (state, action) => {
      state.corporation = action.payload.doc?.corporation || '';
      state.logoURL = action.payload.doc?.logoURL || '';
    },
    [getUserInfo.rejected]: (state, action) => {
      state.corporation = '';
      state.logoURL = '';
    },
  },
});

export const {} = authSlice.actions;

export default authSlice.reducer;
