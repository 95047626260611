import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CheckStep1 } from 'assets/images/illustration/mic-camera-check-step-1.svg';
import { ReactComponent as CheckStep2 } from 'assets/images/illustration/mic-camera-check-step-2.svg';
import { ReactComponent as CheckStep3 } from 'assets/images/illustration/mic-camera-check-step-3.svg';
import VideoInfoContents from './VideoInfoContents';
import VideoInfoHeader from './VideoInfoHeader';
import {
  VideoInfoButtonStep1,
  VideoInfoButtonStep2,
  VideoInfoButtonStep3,
} from './VideoInfoButton';

function VideoInfoLayout() {
  const { t } = useTranslation();
  const [stepCount, setStepCount] = React.useState(0);
  const [stepLeave, setStepLeave] = React.useState(-1);
  const location = useLocation();
  const { assessment, logoURL, userPlan } = location.state;

  return (
    <main className="pages-public-video-info">
      <div className="container">
        <div className="container-inner">
          <VideoInfoHeader
            title={t('title.camera-mic-guide')}
            desc={t('desc.camera-mic-guide')}
          />
          {stepCount === 0 && (
            <>
              <VideoInfoContents
                number={stepCount + 1}
                desc={t('desc.mic-camera-check-step-1')}
                image={<CheckStep1 />}
                className={`step${stepCount + 1} 
                  ${stepCount === 0 ? 'active' : ''} 
                  ${stepLeave === 0 ? 'next-leave' : ''}
                  `}
              />
              <VideoInfoButtonStep1
                next={t('btn.next')}
                stepCount={stepCount}
                setStepCount={setStepCount}
                setStepLeave={setStepLeave}
              />
            </>
          )}
          {stepCount === 1 && (
            <>
              <VideoInfoContents
                number={stepCount + 1}
                desc={t('desc.mic-camera-check-step-2')}
                image={<CheckStep2 />}
                className={`step${stepCount + 1} 
                  ${stepCount === 1 ? 'active' : ''} 
                  ${stepLeave === 1 ? 'next-leave' : ''}`}
              />
              <VideoInfoButtonStep2
                next={t('btn.next')}
                prev={t('btn.previous')}
                stepCount={stepCount}
                setStepCount={setStepCount}
                setStepLeave={setStepLeave}
              />
            </>
          )}
          {stepCount === 2 && (
            <>
              <VideoInfoContents
                number={stepCount + 1}
                desc={t('desc.mic-camera-check-step-3')}
                image={<CheckStep3 />}
                className={`step${stepCount + 1} ${
                  stepCount === 2 ? 'active' : ''
                }`}
              />
              <VideoInfoButtonStep3
                done={t('btn.confirm-complete')}
                prev={t('btn.previous')}
                setStepCount={setStepCount}
                setStepLeave={setStepLeave}
                assessment={assessment}
                logoURL={logoURL}
                userPlan={userPlan}
              />
            </>
          )}
        </div>
      </div>
    </main>
  );
}

export default VideoInfoLayout;
