import React from 'react';
import { Radio } from '@mui/material';
import { convertHtmlForDisplay } from 'utils/string';

function DemoAnswerListItem({
  selectedNumber,
  setSelectedNumber,
  answer,
  idx,
}) {
  return (
    <li
      className={`answer-list-item ${selectedNumber === idx && 'selected'}`}
      onClick={() => {
        setSelectedNumber(idx);
      }}
    >
      <div className="container">
        <Radio
          className="radio-button"
          checked={selectedNumber === idx}
          onChange={() => {
            setSelectedNumber(idx);
          }}
          value={answer}
          name="radio-buttons"
          sx={{
            '& .MuiSvgIcon-root': {
              fontSize: 20,
            },
          }}
          inputProps={{ 'aria-label': answer }}
        />
        <p
          className="fs16"
          dangerouslySetInnerHTML={{ __html: convertHtmlForDisplay(answer) }}
        />
      </div>
    </li>
  );
}

export default DemoAnswerListItem;

export const DemoListeningAnswerItem = ({
  onClick,
  playAudio,
  choices,
  question_index,
  current_question,
  question_id,
  selectedListening,
  question,
  isCheckedImage,
}) => (
  <div className="answer">
    <h5 className="question-title">Q {question_index + 1}.</h5>
    <ul className="answer-list">
      {choices &&
        (choices || []).map((choi, idx) => (
          <li
            key={idx}
            className={`answer-list-item fs14 ${
              selectedListening[question_index] === idx && 'selected'
            } ${playAudio ? 'active' : 'disabled'}`}
            onClick={() => playAudio && onClick(choi, idx)}
          >
            <div className="container">
              <Radio
                className="radio-button"
                checked={selectedListening[question_index] === idx}
                disabled={!playAudio}
                onChange={() => {
                  onClick(choi, idx);
                }}
                value={choi}
                name="radio-buttons"
                sx={{
                  '& .MuiSvgIcon-root': {
                    fontSize: 20,
                  },
                }}
                inputProps={{ 'aria-label': choi }}
              />
              <p
                className="fs16"
                dangerouslySetInnerHTML={{
                  __html: convertHtmlForDisplay(choi),
                }}
              />
            </div>
          </li>
        ))}
    </ul>
  </div>
);
