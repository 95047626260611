import React, { useEffect, useState, useContext } from 'react';
import { Modal, CircularProgress } from '@mui/material';
import { ReactComponent as WindowSizeIllust } from 'assets/images/illustration/img-browser-size.svg';
import { useTranslation } from 'react-i18next';
import { BrowserSizeToast } from 'components/common/BrowserSizeToast';
import { useResize } from './hooks';

const ProgressContext = React.createContext(null);

export function ProgressProvider({ children }) {
  const { t } = useTranslation();
  const [isEntireLoading, setIsEntireLoading] = useState(false);
  const [isWindowSizeUpModal, setIsWindowSizeUpModal] = useState(false);
  const [clickClose, setClickClose] = useState(false);
  const windowSize = useResize();
  const UA = navigator.userAgent.toLowerCase();

  useEffect(() => {
    // width값이 1280 이하, height값이 720이하 일 때 toast 생성
    // but, 사용자가 닫기 버튼을 클릭 시 화면을 변경하여도 재생성 x
    // 모바일 디바이스로 접속 시 toast 미생성
    if (windowSize?.width <= 1280 || windowSize?.height <= 720) {
      if (!clickClose) {
        if (UA.indexOf('android') > -1 || UA.indexOf('iphone') > 1) {
          setIsWindowSizeUpModal(false);
        } else {
          setIsWindowSizeUpModal(true);
        }
      }
    } else {
      setIsWindowSizeUpModal(false);
    }
  }, [windowSize, clickClose, UA]);

  /** ====================
   * @function discriminatorWindowSize
   * window 너비 값에 따른 modal창 on/off
   * @param {*} boolean(boolean) 
   * @returns 
  ======================== */
  const discriminatorWindowSize = (boolean) => {
    setClickClose(true);
    setIsWindowSizeUpModal(boolean);
  };

  const value = {
    isEntireLoading,
    setIsEntireLoading,
  };

  return (
    <ProgressContext.Provider value={value}>
      <>
        {children}

        <BrowserSizeToast
          open={isWindowSizeUpModal}
          close={discriminatorWindowSize}
          illust={<WindowSizeIllust />}
          title={t('title.browser-size')}
          desc={t('desc.browser-size')}
          onClick={discriminatorWindowSize}
        />
      </>
      <Modal open={isEntireLoading} onClose={() => setIsEntireLoading(false)}>
        <div className="modal circular-progress">
          <CircularProgress color="primary" size={50} />
        </div>
      </Modal>
    </ProgressContext.Provider>
  );
}

export const useProgress = () => useContext(ProgressContext);
