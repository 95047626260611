import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Editor from '@monaco-editor/react';

function InputEditor({
  code,
  language,
  onChange: onChandeCode,
  isLeadonly,
  isLine,
  doneCnt,
}) {
  const { t } = useTranslation();
  const [value, setValue] = useState(
    code || `/* ${t('code.editor-placeholder')} */`
  );

  useEffect(() => {
    setValue(`/* ${t('code.editor-placeholder')} */`);
  }, [doneCnt]);

  const handleEditorChange = (val) => {
    setValue(val);
    onChandeCode('code', val);
  };

  return (
    <Editor
      height="100%"
      width="100%"
      language={language || 'javascript'}
      value={value}
      theme="vs-dark"
      defaultValue={value}
      onChange={handleEditorChange}
      options={{
        readOnly: isLeadonly,
        fontSize: '14px',
        // fontFamily: '/assets/fonts/Pretendard-Bold.subset.woff2',
        // fontWeight: 700,
        lineNumbers: isLine,
        formatOnPaste: true,
        autoIndent: true,
        formatOnType: true,
        scrollBeyondLastLine: false,
        minimap: { enabled: false },
        renderWhitespace: 'all',
        automaticLayout: true,
      }}
    />
  );
}

export default InputEditor;
