import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from '@mui/material';
import {
  currencyList,
  educationLevelList,
  yearsExperienceList,
  birthList,
  sexList,
} from 'constants/survey';
import { useCallbackPrompt } from 'utils/hooks';
import ExitModal from 'components/modal/ExitModal';
import { useResult } from 'utils/result';
import { useProgress } from 'utils/progress';
import { useStatus } from 'utils/status';
import DeviceDetector from 'device-detector-js';
import { ReactComponent as VeryMoodIcon } from 'assets/images/icon/very_mood_bad_40x40.svg';
import { ReactComponent as MoodBadIcon } from 'assets/images/icon/mood_bad_40x40.svg';
import { ReactComponent as NeutralIcon } from 'assets/images/icon/sentiment_neutral_40x40.svg';
import { ReactComponent as SatisfiedIcon } from 'assets/images/icon/sentiment_satisfied_40x40.svg';
import { ReactComponent as MoodIcon } from 'assets/images/icon/mood_60x60.svg';
import { ReactComponent as CompleteIcon } from 'assets/images/icon/check-complete-round-green-28x28.svg';
import { executeAction } from 'utils/redux';
import {
  setSpeechToText,
  setEssayAnswer,
  getCandidateInfo,
  retrySetSpeechToText,
  retrySetEssayAnswer,
} from 'store/slices/testtakerSlice';
import * as Sentry from '@sentry/react';
import {toast} from 'react-toastify';

const POINT = [
  {
    key: 'point-1',
    icon: <VeryMoodIcon />,
    title: 'evaluation.end_survey.very_unsatisfied',
  },
  {
    key: 'point-2',
    icon: <MoodBadIcon />,
    title: 'evaluation.end_survey.unsatisfied',
  },
  {
    key: 'point-3',
    icon: <NeutralIcon />,
    title: 'evaluation.end_survey.neutral',
  },
  {
    key: 'point-4',
    icon: <SatisfiedIcon />,
    title: 'evaluation.end_survey.satisfied',
  },
  {
    key: 'point-5',
    icon: <MoodIcon />,
    title: 'evaluation.end_survey.very_satisfied',
  },
];

const DemoTestEnd = ({
  assessment,
  lastTestIdx,
  currentTestIdx,
  setCurrentTestIdx,
  setCurrentState,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(true);
  const {
    stream,
    recordResult,
    submitResults,
    recordIpAddress,
    beforeIpAddress,
  } = useResult();
  const { setIsEntireLoading } = useProgress();
  const { aid, uid, email: cid, userPlan } = useStatus();
  const [canDone, setCanDone] = React.useState(false);
  const [experienceText, setExperienceText] = React.useState('');
  const [educationLevel, setEducationLevel] = React.useState('');
  const [major, setMajor] = React.useState('');
  const [yearsExperience, setYearsExperience] = React.useState('');
  // const [age, setAge] = React.useState('');
  const [birth, setBirth] = React.useState('');
  const [sex, setSex] = React.useState('');
  const [salary, setSalary] = React.useState('');
  const [currency, setCurrency] = React.useState('');
  const [isFirst, setIsFirst] = React.useState(true);
  const [selectedExperienceScore, setSelectedExperienceScore] =
    React.useState('');
  // const [selectedAccuracies, setSelectedAccuracies] = React.useState({});
  const location = useLocation();
  const { logoURL } = location.state || {};
  const [isLoading, setIsLoading] = React.useState(true);
  const [hideToastModal, setHideToastModal] = React.useState(false);

  React.useEffect(() => {
    if (currentTestIdx === lastTestIdx - 1) {
      if (assessment?.surveyOnOff === 'noSurvey') {
        navigate('/testtaker/evaluation/done', {
          state: { logoURL },
        });
      }
    }
  }, []);

  React.useEffect(() => {
    if (currentTestIdx === lastTestIdx - 1 && isFirst) {
      saveTestResult();
      setIsFirst(false);
    }

    if (userPlan === 100) {
      return setCanDone(true);
    }

    if (
      assessment?.surveyOnOff === 'surveyRequired' ||
      assessment?.surveyOnOff === undefined
    ) {
      if (
        Boolean(educationLevel) &&
        Boolean(major) &&
        Boolean(yearsExperience) &&
        // Boolean(age) &&
        Boolean(birth) &&
        Boolean(sex) &&
        Boolean(salary) &&
        Boolean(currency)
        // Boolean(selectedExperienceScore)
      ) {
        return setCanDone(true);
      }
    } else if (assessment?.surveyOnOff === 'surveyOptional') {
      return setCanDone(true);
    }
    // setCanDone(true);
  }, [
    educationLevel,
    major,
    yearsExperience,
    // age,
    birth,
    sex,
    salary,
    currency,
    selectedExperienceScore,
    // selectedAccuracies,
  ]);

  const onGoNextHandler = () => {
    // setTestInfo({
    //   duration: null,
    //   doneCnt: null,
    //   maxDoneCnt: null,
    // });
    setCurrentTestIdx(currentTestIdx + 1);
    setCurrentState('start');
  };

  const saveTestResult = async () => {
    setIsLoading(true);
    try {
      if (stream && stream.getTracks) {
        stream.getTracks?.()?.forEach((track) => track.stop());
      }
      const device = new DeviceDetector().parse(window.navigator.userAgent);
      recordIpAddress('after').then((ipAddress) => {
        recordResult({
          type: 'detect',
          data: {
            device,
            ip: ipAddress,
            ipOnce: beforeIpAddress === ipAddress,
            // onFullscreen: isFullscreen,
            // isMouseLeave,
          },
        }).then((result) => {
          submitResults(result)
            .then(() => {
              executeAction(
                getCandidateInfo({
                  uid,
                  aid,
                  email: cid,
                })
              )
                .unwrap()
                .then(({ doc }) => {
                  /** ===========================
                     * 테스트 완료 후 비디오, 주관식 테스트
                     * fastAPI 통신 로직
                    =============================== */
                  if (currentTestIdx === lastTestIdx - 1) {
                    if (doc?.results === undefined) return;

                    const answer = [];

                    Object.keys(doc?.results).map((result) => {
                      answer.push(result);
                    });

                    answer
                      .filter((el) => el !== 'detect')
                      ?.map((_tid, idx) => {
                        if (
                          _tid === 'english' ||
                          _tid === 'chinese' ||
                          _tid === 'japanese' ||
                          _tid === 'korean'
                        ) {
                          let language = '';

                          if (_tid === 'english') {
                            language = 'en';
                          } else if (_tid === 'chinese') {
                            language = 'cn';
                          } else if (_tid === 'japanese') {
                            language = 'ja';
                          } else {
                            language = 'ko';
                          }

                          executeAction(
                            setSpeechToText({
                              uid,
                              aid,
                              cid,
                              tid: _tid,
                              voiceurl: doc?.results[_tid]?.speaking[_tid],
                              lang: language,
                            })
                          )
                            .unwrap()
                            .then((res) => {
                              if (res?.message !== 'done') {
                                executeAction(
                                  retrySetSpeechToText({
                                    uid,
                                    aid,
                                    cid,
                                    tid: _tid,
                                    voiceurl:
                                      doc?.results[_tid]?.speaking[_tid],
                                    lang: language,
                                    retryCount: 0,
                                  })
                                )
                                  .then(() => {
                                    setIsLoading(false);
                                  })
                                  .catch((e) => {
                                      Sentry.captureException(e);
                                      Sentry.captureMessage('retrySetSpeechToText', {extra: {uid, aid, cid, tid: _tid}});
                                      setIsLoading(false);
                                  });
                              } else {
                                setIsLoading(false);
                              }
                            })
                            .catch((err) => {
                                Sentry.captureException(err);
                                Sentry.captureMessage('setSpeechToText', {extra: {uid, aid, cid, tid: _tid}});
                                console.error(err);

                              executeAction(
                                retrySetSpeechToText({
                                  uid,
                                  aid,
                                  cid,
                                  tid: _tid,
                                  voiceurl: doc?.results[_tid]?.speaking[_tid],
                                  lang: language,
                                  retryCount: 0,
                                })
                              )
                                .then(() => {
                                  setIsLoading(false);
                                })
                                .catch((e) => {
                                    Sentry.captureException(e);
                                    Sentry.captureMessage('retrySetSpeechToText', {extra: {uid, aid, cid, tid: _tid}});
                                    setIsLoading(false);
                                });
                            });
                        } else if (doc?.results[_tid]?.audio !== undefined) {
                          if (
                            _tid === 'english' ||
                            _tid === 'chinese' ||
                            _tid === 'japanese' ||
                            _tid === 'korean'
                          )
                            return;

                          let isEnglish = false;

                          if (
                            _tid === '99101000000' ||
                            _tid === '99101000001' ||
                            _tid === '99101000002' ||
                            _tid === '99101000003' ||
                            _tid === '99101000004' ||
                            _tid === '99101000005'
                          ) {
                            isEnglish = true;
                          }

                          executeAction(
                            setSpeechToText({
                              uid,
                              aid,
                              cid,
                              tid: _tid,
                              voiceurl: doc?.results[_tid]?.audio,
                              lang: isEnglish ? 'en' : assessment?.language,
                            })
                          )
                            .unwrap()
                            .then((res) => {
                              if (res?.message !== 'done') {
                                executeAction(
                                  retrySetSpeechToText({
                                    uid,
                                    aid,
                                    cid,
                                    tid: _tid,
                                    voiceurl: doc?.results[_tid]?.audio,
                                    lang: isEnglish
                                      ? 'en'
                                      : assessment?.language,
                                    retryCount: 0,
                                  })
                                )
                                  .then(() => {
                                    setIsLoading(false);
                                  })
                                  .catch((e) => {
                                        Sentry.captureException(e);
                                        Sentry.captureMessage('retrySetSpeechToText', {extra: {uid, aid, cid, tid: _tid}});
                                    setIsLoading(false);
                                  });
                              } else {
                                setIsLoading(false);
                              }
                            })
                            .catch((err) => {
                                Sentry.captureException(err);
                                Sentry.captureMessage('setSpeechToText', {extra: {uid, aid, cid, tid: _tid}});
                                console.error(err);

                              executeAction(
                                retrySetSpeechToText({
                                  uid,
                                  aid,
                                  cid,
                                  tid: _tid,
                                  voiceurl: doc?.results[_tid]?.audio,
                                  lang: isEnglish ? 'en' : assessment?.language,
                                  retryCount: 0,
                                })
                              )
                                .then(() => {
                                  setIsLoading(false);
                                })
                                .catch((e) => {
                                    Sentry.captureException(e);
                                    Sentry.captureMessage('retrySetSpeechToText', {extra: {uid, aid, cid, tid: _tid}});
                                  setIsLoading(false);
                                });
                            });
                        } else if (doc?.results[_tid]?.text !== undefined) {
                          executeAction(
                            setEssayAnswer({
                              db:
                                process.env.REACT_APP_NODE_ENV === 'production'
                                  ? 'firebase_prod'
                                  : 'firebase_test',
                              question: doc?.results[_tid]?.question,
                              uid,
                              aid,
                              cid,
                              tid: _tid,
                              type: 'video',
                              answer: doc?.results[_tid]?.text,
                              lang: assessment?.language,
                            })
                          )
                            .unwrap()
                            .then((res) => {
                              if (res !== 200) {
                                executeAction(
                                  retrySetEssayAnswer({
                                    db:
                                      process.env.REACT_APP_NODE_ENV ===
                                      'production'
                                        ? 'firebase_prod'
                                        : 'firebase_test',
                                    question: doc?.results[_tid]?.question,
                                    uid,
                                    aid,
                                    cid,
                                    tid: _tid,
                                    answer: doc?.results[_tid]?.text,
                                    type: 'video',
                                    lang: assessment?.language,
                                  })
                                )
                                  .then(() => {
                                    setIsLoading(false);
                                  })
                                  .catch((e) => {
                                        Sentry.captureException(e);
                                        Sentry.captureMessage('retrySetEssayAnswer', {extra: {uid, aid, cid, tid: _tid}});
                                    setIsLoading(false);
                                  });
                              } else {
                                setIsLoading(false);
                              }
                            })
                            .catch((err) => {
                              executeAction(
                                retrySetEssayAnswer({
                                  db:
                                    process.env.REACT_APP_NODE_ENV ===
                                    'production'
                                      ? 'firebase_prod'
                                      : 'firebase_test',
                                  question: doc?.results[_tid]?.question,
                                  uid,
                                  aid,
                                  cid,
                                  tid: _tid,
                                  answer: doc?.results[_tid]?.text,
                                  type: 'video',
                                  lang: assessment?.language,
                                })
                              )
                                .then(() => {
                                  setIsLoading(false);
                                })
                                .catch(() => {
                                    Sentry.captureException(err);
                                    Sentry.captureMessage('retrySetEssayAnswer', {extra: {uid, aid, cid, tid: _tid}});
                                    toast(t('desc.save-failed'));
                                  setIsLoading(false);
                                });
                            });
                        } else if (doc?.results[_tid]?.img !== undefined) {
                          setIsLoading(false);
                        } else {
                          setIsLoading(false);
                          return;
                        }
                      });
                  }
                })
                .catch((e) => {
                    Sentry.captureException(e);
                    Sentry.captureMessage('getCandidateInfo', {extra: {uid, aid, email: cid}});
                  setIsLoading(false);
                });
            })
            .catch((e) => {
                Sentry.captureException(e);
                Sentry.captureMessage('submitResults', {extra: {uid, aid, email: cid}});
              setIsLoading(false);
            });
        });
      });
    } catch (e) {
        Sentry.captureException(e);
        Sentry.captureMessage('recordIpAddress', {extra: {uid, aid, email: cid}});
      setIsLoading(false);
    }
  };

  /** ========================
   * @function onSubmitHandler
   * survey submit 함수(범용적 사용)
  =========================== */
  const onSubmitHandler = async () => {
    // TODO : data to app. (grading server)
    // setTestInfo({
    //   duration: null,
    //   doneCnt: null,
    //   maxDoneCnt: null,
    // });
    setHideToastModal(true);
    setIsEntireLoading(true);
    try {
      if (stream && stream.getTracks) {
        stream.getTracks?.()?.forEach((track) => track.stop());
      }
      recordResult({
        type: 'survey',
        data: {
          experienceText,
          educationLevel,
          major,
          yearsExperience,
          birth,
          sex,
          salary,
          currency,
          selectedExperienceScore,
          // selectedAccuracies,
        },
      }).then(() => {
        const device = new DeviceDetector().parse(window.navigator.userAgent);
        recordIpAddress('after').then((ipAddress) => {
          recordResult({
            type: 'detect',
            data: {
              device,
              ip: ipAddress,
              ipOnce: beforeIpAddress === ipAddress,
              // onFullscreen: isFullscreen,
              // isMouseLeave,
            },
          }).then((result) => {
            submitResults(result)
              .then(() => {
                setIsEntireLoading(false);
                navigate('/testtaker/evaluation/done', {
                  state: { logoURL },
                  // replace: true,
                });
              })
              .catch((e) => {
                  Sentry.captureException(e);
                  Sentry.captureMessage('submitResults', {extra: {uid, aid, email: cid}});
                  console.dir(e);
                setIsEntireLoading(false);
              });
          });
        });
      });
    } catch (e) {
        Sentry.captureException(e);
        Sentry.captureMessage('recordIpAddress', {extra: {uid, aid, email: cid}});
        console.dir(e);
      setIsEntireLoading(false);
    }
  };

  const onlyNumber = (e) => {
    const { value } = e.target;
    const number = value.replace(/[^0-9]/g, '');
    setSalary(Number(number).toLocaleString('ko-KR'));
  };

  /** ==================
   * 한 테스트가 완료되었을 때
   ==================== */
  if (currentTestIdx < lastTestIdx - 1) {
    return (
      <main className="demo-pages-protected-evaluation-test-end">
        <ExitModal
          showPrompt={showPrompt}
          confirmNavigation={confirmNavigation}
          cancelNavigation={cancelNavigation}
        />
        <div className="container">
          <div className="container-inner">
            <h2 className="title fs28to24">{t('title.test-complete')}</h2>
            <div className="description">{t('desc.test-complete')}</div>
            <button
              className="go-next common-button fsbtn16 black active"
              onClick={onGoNextHandler}
            >
              {t('evaluation.language_test.btn_start_now')}
            </button>
          </div>
        </div>
      </main>
    );
  }

  // promenade-ai survey
  return (
    <main className="demo-pages-protected-evaluation-test-end last">
      <ExitModal
        showPrompt={showPrompt}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
      />
      <div className="container">
        <div className="container-inner">
          <div className="title-box">
            <h1 className="title fs50">{t('evaluation.end_survey.title')}</h1>
            <p className="desc fs18">{t('evaluation.end_survey.desc')}</p>
          </div>
          <section className="experience">
            <h2 className="experience-title">
              {t('evaluation.end_survey.question_title')}
            </h2>
            <div
              className={`experience-buttons ${
                Boolean(selectedExperienceScore) && 'selected'
              }`}
            >
              {POINT.map((it, idx) => (
                <div
                  key={idx}
                  className={`experience-buttons-button ${it.key} ${
                    selectedExperienceScore === it.key && 'selected'
                  }`}
                  onClick={() => setSelectedExperienceScore(it.key)}
                >
                  <div className="button-content-box">
                    <div className="button-icon-box">{it.icon}</div>
                    <div className="button-title-box">
                      <span>{t(it.title)}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="experience-text-field">
              <TextField
                fullWidth
                minRows={6}
                label={t('evaluation.end_survey.comment_placeholder')}
                multiline
                maxRows={6}
                value={experienceText}
                sx={{ backgroundColor: '#fff' }}
                onChange={(event) => setExperienceText(event.target.value)}
              />
            </div>
          </section>
          {userPlan !== 100 && (
            <section className="survey">
              <div className="survey-inputs row">
                <FormControl>
                  <InputLabel>
                    {t('evaluation.end_survey.education_level_label')}
                  </InputLabel>
                  <Select
                    label={t('evaluation.end_survey.education_level_label')}
                    value={educationLevel}
                    sx={{ backgroundColor: '#fff' }}
                    onChange={(event) => setEducationLevel(event.target.value)}
                  >
                    {educationLevelList.map((it, idx) => (
                      <MenuItem key={idx} value={it.key}>
                        {t(it.i18nKey)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  label={t('evaluation.end_survey.major_label')}
                  variant="outlined"
                  sx={{ backgroundColor: '#fff' }}
                  value={major}
                  onChange={(event) => setMajor(event.target.value)}
                />
              </div>
              <div className="survey-inputs row">
                <FormControl>
                  <InputLabel>
                    {t('evaluation.end_survey.total_experience')}
                  </InputLabel>
                  <Select
                    label={t('evaluation.end_survey.total_experience')}
                    value={yearsExperience}
                    sx={{ backgroundColor: '#fff' }}
                    onChange={(event) => setYearsExperience(event.target.value)}
                  >
                    {yearsExperienceList.map((it, idx) => (
                      <MenuItem value={it.key} key={idx}>
                        {t(it.i18nKey)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl>
                  <InputLabel>{t('evaluation.end_survey.birthday')}</InputLabel>
                  <Select
                    label={t('evaluation.end_survey.birthday')}
                    value={birth}
                    sx={{ backgroundColor: '#fff' }}
                    onChange={(event) => setBirth(event.target.value)}
                  >
                    {birthList.map((it, idx) => (
                      <MenuItem key={idx} value={it.key}>
                        {t(it.i18nKey)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="survey-inputs row">
                <FormControl>
                  <InputLabel>{t('evaluation.end_survey.gender')}</InputLabel>
                  <Select
                    label={t('evaluation.end_survey.gender')}
                    value={sex}
                    sx={{ backgroundColor: '#fff' }}
                    onChange={(event) => setSex(event.target.value)}
                  >
                    {sexList.map((it, idx) => (
                      <MenuItem key={idx} value={it.key}>
                        {t(it.i18nKey)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <div className="survey-inputs-right">
                  <FormControl>
                    <InputLabel>
                      {t('evaluation.end_survey.currency')}
                    </InputLabel>
                    <Select
                      label={t('evaluation.end_survey.currency')}
                      value={currency}
                      sx={{ backgroundColor: '#fff' }}
                      onChange={(event) => setCurrency(event.target.value)}
                    >
                      {currencyList.map((it, idx) => (
                        <MenuItem key={idx} value={it.key}>
                          {it.key}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <TextField
                    label={t('evaluation.end_survey.current_salary')}
                    variant="outlined"
                    value={salary}
                    sx={{ backgroundColor: '#fff' }}
                    onChange={(event) => onlyNumber(event)}
                  />
                  <div className="currency-absolute">
                    {currency && currency}
                  </div>
                </div>
              </div>
            </section>
          )}
          <button
            className={`submit common-button fsbtn16 black ${
              canDone ? 'active' : 'disabled'
            }`}
            disabled={!canDone}
            onClick={canDone ? onSubmitHandler : null}
          >
            {t('evaluation.end_survey.btn_submit')}
          </button>
        </div>
      </div>
      {/* TODO: ditto 로딩바 분리 이후 번역작업 필요 */}
      <div
        className={`final-alert-modal ${
          isLoading ? 'loading' : 'finished-loading'
        } ${hideToastModal ? 'hide' : ''}`}
      >
        <div className="final-alert-text-box">
          {isLoading ? (
            <>
              <div className="final-loading-box">
                <CircularProgress style={{ color: '#89949f' }} size={28} />
              </div>
              <p className="final-alert-desc">
                {t('toast.desc.please-do-not-close')}
              </p>
            </>
          ) : (
            <>
              <div className="final-loading-box">
                <CompleteIcon />
              </div>
              <p className="final-alert-desc">
                {t('toast.desc.your-save-is-complete')}
              </p>
            </>
          )}
        </div>
      </div>
    </main>
  );
};

export default DemoTestEnd;
