import React, { useEffect, useState } from 'react';
import { useResult } from 'utils/result';
import { useProgress } from 'utils/progress';
import LanguageTestStart from 'components/languageTest/LanguageTestStart';
import LanguageCommonBody from 'components/languageTest/LanguageCommonBody';
import { useTranslation } from 'react-i18next';
import LanguageListening from './test/LanguageListening';
import LanguageMultipleChoice from './test/LanguageMultipleChoice';
import LanguageSpeaking from './test/LanguageSpeaking';
import DemoLanguageSpeaking from './test/DemoLanguageSpeaking';
import DemoLanguageMultipleChoice from './test/DemoLanguageMultipleChoice';
import DemoLanguageListening from './test/DemoLanguageListening';

function LanguageCommon({
  test,
  setCurrentState,
  language,
  currentStateIndex,
  selectedLanguages,
  setCurrentStateIndex,
}) {
  const { t } = useTranslation();
  const { recordResult } = useResult();
  const { setIsEntireLoading } = useProgress();
  const [selectedListening, setSelectedListening] = useState([]);
  const [playAudio, setPlayAudio] = useState(false);
  const [exampleStep, setExampleStep] = useState(0);
  // reading result state
  const [questionData, setQuestionData] = useState({});
  // speaking result 'video' state
  const [speakBlob, setSpeakBlob] = useState(null);
  const [speakAudioBlob, setAudioSpeakBlob] = useState(null);
  // listening result state
  const [listeningData, setListeningData] = useState({});
  const [languageQuestion, setLanguageQuestion] = useState('');

  useEffect(() => {
    let questions;

    if (language === 'english') {
      questions = [
        'Tell us about a place you have visited recently. What did you like/dislike about the place?',
        'Tell us about a film you have watched recently. What was the film about? What did you like/dislike about the film?',
        'Tell us about a hobby you enjoy. How did you start on the hobby? What do you like the most about it?',
      ];
    } else if (language === 'chinese') {
      questions = [
        '最近你去过的地方是哪里？你喜欢或不喜欢那个地方的什么？',
        '最近你看过的电影是什么？那部电影讲述了什么故事？你喜欢或不喜欢那部电影的什么？',
        '你喜欢的爱好是什么？你是如何开始追求这个爱好的？你最喜欢它的什么？',
      ];
    } else if (language === 'japanese') {
      questions = [
        '最近訪れた場所について教えてください。その場所について好きなこと／嫌いなことは何ですか？',
        '最近観た映画について教えてください。その映画は何についてでしたか？映画について好きなこと／嫌いなことは何ですか？',
        'お楽しみの趣味について教えてください。どのようにしてその趣味を始めましたか？その趣味で一番好きなことは何ですか？',
      ];
    } else if (language === 'korean') {
      questions = [
        '나의 취미에 대해 공유해 주세요. 어떻게 취미를 가지게 되었고, 어떤 점이 좋은가요?',
        '나는 어떤 사람들과 사귀는 것을 좋아하나요? 나의 가장 친한 친구에 대해 소개 해 주세요.',
        '선의의 거짓말을 해 본적이 있나요? 해 본적이 있다면 어떤 상황이었고, 왜 하게 되었나요? 만약 없다면, 선의의 거짓말을 하지 않는 자신만의 이유가 있으신가요?',
      ];
    }

    const randomQuestion = questions.sort(() => Math.random() - 0.5)[0];

    setLanguageQuestion(randomQuestion);
  }, [language]);

  /** ==========
   * record and store the value
   * of problem solving.
  ============== */
  useEffect(() => {
    recordResult({
      sid: language,
      langTest: {
        reading: {
          grade: questionData?.result,
          question_history: questionData?.question_history,
          result_history: questionData?.result_history,
          judged_difficulty: questionData?.judged_difficulty,
        },
        listening: {
          grade: listeningData?.result,
          question_history: listeningData?.questionHistory,
          result_history: listeningData?.resultHistory,
          judged_difficulty: listeningData?.difficulty,
        },
        speaking: {
          grade: 'Preparing',
          [language]: speakBlob,
          question: languageQuestion,
        },
        video: speakBlob,
        audio: speakAudioBlob,
      },
    });
  }, [questionData?.result, listeningData?.result, speakBlob, speakAudioBlob]);

  /** ================
   * 공통 지문의 question 길이와 선택한 답안의 길이가 같을 때,
   * (한 지문을 모두 풀었을 때)
   * selectedListening을 reset 함.
  ==================== */
  useEffect(() => {
    if (listeningData?.passageGroup?.length === selectedListening.length) {
      setSelectedListening([]);
      return;
    }
  }, [listeningData?.passageGroup?.length, selectedListening.length]);

  return (
    <>
      {exampleStep === 0 && (
        <LanguageTestStart
          exampleStep={exampleStep}
          setExampleStep={setExampleStep}
          title={`${language.replace(/^[a-z]/, (char) =>
            char.toUpperCase()
          )} Test - Reading`}
        />
      )}

      {exampleStep === 1 && (
        <LanguageCommonBody>
          <DemoLanguageMultipleChoice
            test={test}
            language={language}
            setExampleStep={setExampleStep}
            questionData={questionData}
            setQuestionData={setQuestionData}
            setIsEntireLoading={setIsEntireLoading}
          />
          {/* <LanguageMultipleChoice
            test={test}
            language={language}
            setExampleStep={setExampleStep}
            questionData={questionData}
            setQuestionData={setQuestionData}
            setIsEntireLoading={setIsEntireLoading}
          /> */}
        </LanguageCommonBody>
      )}

      {exampleStep === 2 && (
        <LanguageTestStart
          exampleStep={exampleStep}
          setExampleStep={setExampleStep}
          title={`${language.replace(/^[a-z]/, (char) =>
            char.toUpperCase()
          )} Test - Listening`}
        />
      )}

      {exampleStep === 3 && (
        <LanguageCommonBody>
          <DemoLanguageListening
            language={language}
            playAudio={playAudio}
            setPlayAudio={setPlayAudio}
            listeningData={listeningData}
            setListeningData={setListeningData}
            setExampleStep={setExampleStep}
            selectedListening={selectedListening}
            setSelectedListening={setSelectedListening}
          />
          {/* <LanguageListening
            language={language}
            playAudio={playAudio}
            setPlayAudio={setPlayAudio}
            listeningData={listeningData}
            setListeningData={setListeningData}
            setExampleStep={setExampleStep}
            selectedListening={selectedListening}
            setSelectedListening={setSelectedListening}
          /> */}
        </LanguageCommonBody>
      )}

      {exampleStep === 4 && (
        <LanguageTestStart
          exampleStep={exampleStep}
          setExampleStep={setExampleStep}
          title={`${language.replace(/^[a-z]/, (char) =>
            char.toUpperCase()
          )} Test - Speaking`}
        />
      )}

      {exampleStep === 5 && (
        <DemoLanguageSpeaking
          language={language}
          setSpeakBlob={setSpeakBlob}
          setAudioSpeakBlob={setAudioSpeakBlob}
          difficulty={listeningData?.difficulty}
          setCurrentState={setCurrentState}
          setIsEntireLoading={setIsEntireLoading}
          currentStateIndex={currentStateIndex}
          selectedLanguages={selectedLanguages}
          setCurrentStateIndex={setCurrentStateIndex}
          languageQuestion={languageQuestion}
        />
        // <LanguageSpeaking
        //   language={language}
        //   setSpeakBlob={setSpeakBlob}
        //   setAudioSpeakBlob={setAudioSpeakBlob}
        //   difficulty={listeningData?.difficulty}
        //   setCurrentState={setCurrentState}
        //   setIsEntireLoading={setIsEntireLoading}
        //   currentStateIndex={currentStateIndex}
        //   selectedLanguages={selectedLanguages}
        //   setCurrentStateIndex={setCurrentStateIndex}
        //   languageQuestion={languageQuestion}
        // />
      )}
    </>
  );
}

export default LanguageCommon;
