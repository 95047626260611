import React from 'react';
import { useTranslation } from 'react-i18next';

function OutputEditor({ outputResult }) {
  const { t } = useTranslation();

  return (
    <div className="output-result-box">
      {outputResult ? (
        <pre className="fs16">{outputResult}</pre>
      ) : (
        <p className="fs16">{t('code.result-desc')}</p>
      )}
    </div>
  );
}

export default OutputEditor;
