import React from 'react';
import { useTranslation } from 'react-i18next';
import { useResult } from 'utils/result';
import { useCallbackPrompt } from 'utils/hooks';
import ExitModal from 'components/modal/ExitModal';
import { useStatus } from 'utils/status';
import { executeAction } from 'utils/redux';
import { getTestInfos } from 'store/slices/testtakerSlice';
import { useProgress } from 'utils/progress';
import { shuffle } from 'utils/array';
import Question from './common/Question';
import DemoQuestion from './common/DemoQuestion';

const DemoCommon = ({ test, setCurrentState }) => {
  const { t } = useTranslation();
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(true);
  const { setTestInfo, getTestInfo, userPlan } = useStatus();
  const { duration, doneCnt, maxDoneCnt, canSubmit } = getTestInfo();
  const lang = t('lang');
  const { recordResult } = useResult();
  const [canDone, setCanDone] = React.useState(false);
  const [tid, setTid] = React.useState(null);
  const [curIdx, setCurIdx] = React.useState(0);
  const [selectedValue, setSelectedValue] = React.useState(-1);
  const [questions, setQuestions] = React.useState([]);
  const { setIsEntireLoading } = useProgress();

  React.useEffect(() => {
    setIsEntireLoading(true);
    setTestInfo({
      duration,
      doneCnt,
      maxDoneCnt,
      canSubmit: false,
      buttonName: t('btn.next'),
    });

    setQuestions(test?.test);
  }, []);

  React.useEffect(() => {
    const skillEndEventListener = (event) => {
      onSubmit();
    };

    window.document.addEventListener('skill_end', skillEndEventListener);
    return () => {
      window.document.removeEventListener('skill_end', skillEndEventListener);
    };
  }, []);

  React.useEffect(() => {
    const testDoneEventListener = (event) => {
      if (curIdx < (questions || []).length - 1) {
        onNextHandler();
      } else {
        onSubmitHandler();
      }
    };

    window.document.addEventListener('test_done', testDoneEventListener);
    return () => {
      window.document.removeEventListener('test_done', testDoneEventListener);
    };
  }, [curIdx, questions, canDone]);

  React.useEffect(() => {
    if (selectedValue < 0) {
      return setCanDone(false);
    }

    setCanDone(true);
  }, [selectedValue]);

  React.useEffect(() => {
    let btnName;
    if (curIdx < (questions || []).length - 1) {
      btnName = t('btn.next');
    } else {
      btnName = t('btn.submit');
    }
    setTestInfo({
      duration,
      doneCnt,
      maxDoneCnt,
      canSubmit: canDone,
      buttonName: btnName,
    });
  }, [canDone, questions, curIdx]);

  React.useEffect(() => {
    setTid(questions?.[curIdx]?.tid || questions?.[curIdx]);

    window.scrollTo(0, 0);
  }, [questions, curIdx]);

  const onNextHandler = () => {
    if (!canDone) return;
    recordResult({
      sid: test?.uuid,
      tid,
      checks: [selectedValue],
    });
    setCurIdx(curIdx + 1);
    let dur;
    if (test?.limit) {
      dur = test?.limit * 60;
    } else {
      dur = 900;
    }
    let btnName;
    if (curIdx < (questions || []).length - 1) {
      btnName = t('btn.next');
    } else {
      btnName = t('btn.submit');
    }
    setTestInfo({
      duration: dur,
      doneCnt: doneCnt + 1,
      maxDoneCnt,
      canSubmit: false,
      buttonName: btnName,
    });
  };

  const onSubmitHandler = () => {
    if (!canDone) return;
    onSubmit();
  };

  const onSubmit = () => {
    recordResult({
      sid: test?.uuid,
      tid,
      checks: [selectedValue],
    });
    setCurrentState('end');
    setTestInfo({
      duration: null,
      doneCnt: null,
      maxDoneCnt: null,
      canSubmit: false,
    });
  };

  return (
    <main className="demo-pages-protected-evaluation-objectives-common">
      <ExitModal
        showPrompt={showPrompt}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
      />
      <div className="container">
        <DemoQuestion
          sid={test?.tid}
          tid={tid}
          selectedValue={selectedValue}
          setSelectedValue={setSelectedValue}
        />

        {/* <button
          className={`submit common-button fsbtn16 primary ${canDone && "active"}`}
          onClick={
            curIdx < (questions || []).length - 1
              ? onNextHandler
              : onSubmitHandler
          }
        >
          {curIdx < (questions || []).length - 1
            ? t("btn.next")
            : t("btn.submit")}
        </button> */}
      </div>
    </main>
  );
};

export default DemoCommon;
