import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowIcon } from 'assets/images/icon/dropdown-arrow-icon.svg';

export function DropdownMenu({
  frontIcon,
  selectedLabel,
  menuList,
  onClick,
  width,
}) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = React.useState(false);
  const menuRef = React.useRef(null);

  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleMenuClick = (menu) => {
    onClick(menu);
    setIsOpen(false);
  };

  return (
    <div className="dropdown-container" style={{ width }} ref={menuRef}>
      <button className="dropdown-toggle-button" onClick={toggleMenu}>
        <span>
          {frontIcon !== undefined ? frontIcon : <></>}
          {selectedLabel !== '' ? selectedLabel : t('survey.none')}
        </span>
        <ArrowIcon />
      </button>
      {selectedLabel !== '' && isOpen && menuList?.length > 0 && (
        <div className="dropdown-list-box">
          <ul className="dropdown-list">
            {(menuList || []).map((menu, idx) => (
              <li
                className="dropdown-list-item"
                key={`menu_${idx}`}
                onClick={() => handleMenuClick(menu)}
              >
                {menu?.label}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}
