import React from 'react';
import { Outlet } from 'react-router-dom';
import AppHeader from '../header/AppHeader';
import Footer from '../footer/Footer';

function AppLayout() {
  return (
    <>
      <AppHeader />
      <Outlet />
      <Footer />
    </>
  );
}

export default AppLayout;
