import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useStatus } from 'utils/status';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as RightArrow } from 'assets/images/icon/demo-right-arrow.svg';
import { ReactComponent as MobileRightArrow } from 'assets/images/icon/arrow-forward-white-20x20.svg';
import { executeAction } from 'utils/redux';
import {
  getAssessmentInfo,
  updateCondition,
} from 'store/slices/testtakerSlice';
import { useProgress } from 'utils/progress';
import { divideSkillScope } from 'utils/status';
import { convertTextToSec, formatTimeMinutes, displayName } from 'utils/string';
import { ReactComponent as ErrorIcon } from 'assets/images/icon/error_24px.svg';
import { __NO_LIMIT_DURATION__ } from 'constants/testtaker';
import { useResize } from 'utils/hooks';
import { useResult } from 'utils/result';
import * as Sentry from '@sentry/react';

const __GROUP_TEST_TIME__ = 10;

const DemoTips = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const windowSize = useResize();
  const isPersonality = location?.state?.isPersonality || false;
  const { uid, aid, firstName, lastName, logoURL, userPlan, email } =
    useStatus();
  const { recordIpAddress } = useResult();
  const [assessment, setAssessment] = React.useState(null);
  const { setIsEntireLoading } = useProgress();
  const [timeLimit, setTimeLimit] = React.useState(0);
  const [questionBoolean, setQuestionBoolean] = React.useState(Boolean);
  const [hasLauguageTests, setHasLanguageTests] = React.useState(false);
  const [hasVideoTests, setHasVideoTests] = React.useState(false);

  React.useEffect(() => {
    if (!Boolean(uid) || !Boolean(aid)) return;

    setIsEntireLoading(true);
    executeAction(
      getAssessmentInfo({
        uid,
        aid,
      })
    )
      .unwrap()
      .then(({ doc }) => {
        setIsEntireLoading(false);
        setAssessment(doc);
      })
      .catch((e) => {
        Sentry.captureException(e);
        setIsEntireLoading(false);
        setAssessment(null);
      });
  }, [uid, aid]);

  React.useEffect(() => {
    if (!Boolean(assessment)) return;

    let time = 0;
    for (const skill of assessment?.tests || []) {
      if (divideSkillScope(skill) === 'objective') {
        if (skill.limit) {
          time += skill.limit * 60;
        } else {
          time += 900;
        }
      } else if (divideSkillScope(skill) === 'grouptest') {
        time += skill?.testcnt * __GROUP_TEST_TIME__ * 60;
      } else if (divideSkillScope(skill) === 'subjective') {
        const addValue = convertTextToSec(skill?.limit);
        time += addValue === __NO_LIMIT_DURATION__ ? 0 : addValue;
      }
    }
    setTimeLimit(time);
  }, [assessment]);

  React.useEffect(() => {
    const testArr = [];

    assessment?.tests?.map((test, idx) => {
      testArr.push(test.question_type);
    });

    testArr.find((test) => {
      if (test === 'tag.code') {
        setQuestionBoolean(true);
        return;
      }
      if (test === 'tag.language') {
        setHasLanguageTests(true);
      }
      if (test === 'tag.video') {
        setHasVideoTests(true);
      }
    });
  }, [assessment]);

  const isMobile =
    /iPhone|iPad|iPod|Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );

  const onSubmitHandler = () => {
    recordIpAddress('before').then(() => {
      executeAction(
        updateCondition({
          uid,
          aid,
          cid: email,
          condition: 1,
          progress: 0,
        })
      )
        .unwrap()
        .catch((e) => {
          Sentry.captureException(e);
          console.dir(e);
        });
    });

    if (assessment?.AntiCheating === false) {
      if (assessment?.groupTests && assessment?.groupTests?.testcnt > 0) {
        navigate('/testtaker/progress/selecttest', {
          state: { logoURL, userPlan },
          replace: true,
        });
        return;
      }
      assessment?.tests?.find((x) => {
        if (x.section === 'video' || hasVideoTests || hasLauguageTests) {
          navigate('/testtaker/progress/voice-test', {
            state: { assessment, logoURL, userPlan },
            replace: true,
          });
        } else {
          if (
            assessment?.introVideoUrl !== '' &&
            assessment?.introVideoUrl !== undefined
          ) {
            navigate('/testtaker/progress/intro', {
              state: { logoURL, userPlan },
              replace: true,
            });
          } else {
            if (userPlan === 100) {
              navigate('/testtaker/progress/store-survey', {
                state: { logoURL, userPlan },
                replace: true,
              });
            } else {
              navigate('/testtaker/evaluation', {
                state: { logoURL, userPlan },
                replace: true,
              });
            }
          }
        }
      });
    } else {
      navigate('/testtaker/progress/voice-test', {
        state: { assessment, logoURL, userPlan },
        replace: true,
      });
    }
  };
  const ceilMin = (number) => {
    return Math.ceil(Number(number));
  };

  return (
    <>
      {!isPersonality ? (
        <div className="demo-tips-container">
          <div className="demo-tips-above-container">
            <div className="candidate-name">
              <Trans
                t={t}
                i18nKey={'title.assessment-start'}
                values={{
                  candidate_name: firstName,
                }}
              />
            </div>
            <div
              className="Greeting"
            >
              <Trans 
                t={t}
                i18nKey='intro.demo_welcome_text'
                values={{
                  candidate_name: `${lastName} ${firstName}`
                }}
              />
            </div>
          </div>
          <div className="demo-tips-down-container">
            <div className="align-right">
              <div>{t('intro.instruction_title')}</div>
              <div>
                <ul>
                  <li>
                    {t('intro.record_time_guide')}{' '}
                    {ceilMin(formatTimeMinutes(timeLimit))}{' '}
                    {t('intro.record_time_unit')}
                  </li>
                  <li>{t('intro.interview_guide')}</li>
                  <li>{t('intro.record_device_guide')}</li>
                </ul>
              </div>
              {(isMobile || windowSize?.width < 1024) && (
                <div>
                  <Trans t={t} i18nKey={'intro.interview_helper'} />
                </div>
              )}
            </div>
          </div>
          {isMobile || windowSize?.width < 1024 ? (
            questionBoolean ? (
              <React.Fragment>
                <div>
                  <div className="code-alert">
                    <ErrorIcon width="24px" height="24px" />
                    <span className="desc fs14">
                      {t('desc.no-mobile-support-2')}
                    </span>
                  </div>
                </div>

                <div className="start-button-container">
                  <div className="start-button disabled" onClick={null}>
                    {t('intro.btn_start')} <MobileRightArrow />
                  </div>
                </div>
              </React.Fragment>
            ) : (
              <div className="start-button-container">
                <div className="start-button" onClick={onSubmitHandler}>
                  {t('intro.btn_start')} <MobileRightArrow />
                </div>
              </div>
            )
          ) : (
            <div className="start-button-container">
              <div className="start-button" onClick={onSubmitHandler}>
                {t('intro.btn_start')}{' '}
                {windowSize.width < 1024 ? (
                  <MobileRightArrow />
                ) : (
                  <RightArrow />
                )}
              </div>
            </div>
          )}
        </div>
      ) : (
        <main className="pages-public-tips">
          <div className="container">
            <div className="container-inner">
              <header className="header">
                <h1 className="header-title fs28to24">
                  <Trans
                    t={t}
                    i18nKey={'title.assessment-start'}
                    values={{
                      candidate_name: displayName(
                        firstName,
                        lastName,
                        t('lang')
                      ),
                    }}
                  />
                </h1>
                <div className="header-description">
                  <Trans t={t} i18nKey={'desc.assessment-start'} />
                </div>
              </header>
              <section className="alert">
                <h2 className="alert-title fs20">
                  {t('title.assessment-start-tips')}
                </h2>
                <ul className="alert-list">
                  {[
                    'desc.tips-assessment-time',
                    'desc.tips-time-limit',
                    'desc.tips-allow-camera',
                    'desc.tips-speaker-on',
                    // "desc.tips-pen-paper-available",
                    // "desc.tips-assessment-in-one-go",
                  ].map((i18nKey, idx) =>
                    assessment?.AntiCheating === true ? (
                      <li key={idx} className="alert-list-item">
                        <Trans
                          t={t}
                          i18nKey={i18nKey}
                          values={{
                            minute: ceilMin(formatTimeMinutes(timeLimit)),
                          }}
                        />
                      </li>
                    ) : (
                      i18nKey !== 'desc.tips-speaker-on' && (
                        <li key={idx} className="alert-list-item">
                          <Trans
                            t={t}
                            i18nKey={i18nKey}
                            values={{
                              minute: ceilMin(formatTimeMinutes(timeLimit)),
                            }}
                          />
                        </li>
                      )
                    )
                  )}
                </ul>
              </section>
              {isMobile ? (
                questionBoolean ? (
                  <React.Fragment>
                    <div>
                      <div className="refresh-alert">
                        <ErrorIcon width="24px" height="24px" />
                        <span className="desc fs14">
                          {t('desc.no-refresh')}
                        </span>
                      </div>
                      <div className="code-alert">
                        <ErrorIcon width="24px" height="24px" />
                        <span className="desc fs14">
                          {t('desc.no-mobile-support')}
                        </span>
                      </div>
                    </div>

                    <button
                      className="submit common-button fsbtn16 black disable"
                      disabled={true}
                    >
                      {t('btn.next')}
                    </button>
                  </React.Fragment>
                ) : (
                  <button
                    className="submit common-button fsbtn16 black active"
                    onClick={onSubmitHandler}
                  >
                    {t('btn.next')}
                  </button>
                )
              ) : (
                <React.Fragment>
                  <div className="code-alert">
                    <ErrorIcon width="24px" height="24px" />
                    <span className="desc fs14">{t('desc.no-refresh')}</span>
                  </div>
                  <button
                    className="submit common-button fsbtn16 black active"
                    onClick={onSubmitHandler}
                  >
                    {t('btn.next')}
                  </button>
                </React.Fragment>
              )}
            </div>
          </div>
        </main>
      )}
    </>
  );
};

export default DemoTips;
