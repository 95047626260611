import { CircularProgress } from '@mui/material';
import React from 'react';

export const Button = ({
  frontIcon,
  backIcon,
  label,
  size,
  theme,
  radius,
  isDisabled,
  isLoading,
  fontSize,
  onClick,
  padding,
  className,
}) => (
  <button
    className={`button-component size-${size} ${theme} ${
      isDisabled && isDisabled
    } ${fontSize} ${className}`}
    style={{ borderRadius: `${radius}px`, padding: `${padding}px` }}
    disabled={isDisabled}
    onClick={() => onClick()}
  >
    {frontIcon && frontIcon} {label} {backIcon && backIcon}
    {isLoading && <CircularProgress color="grey" size={25} />}
  </button>
);
