export const nextQuestion = async (
  previous,
  questionDifficulty,
  choices,
  difficultyLevels,
  passageQuestions
) => {
  const timeLimitSeconds = 900; // 최대 시간 제한 15분
  const passageReadSeconds = 180; // 지문당 독해시간 3분
  const answerQuestionSeconds = 60; // 문제당 답변시간 1분
  const questionLimitCount = 15; // 최대 문제수 제한 11문제
  const initialDifficulty = 10; // 최초 난이도 '중'
  let startedTime;
  let inputAnswer = '';
  let resultIndex = 0;
  let questionHistory;
  let resultHistory;
  let questionId;
  let passageId;
  let passageGroup;
  let result;
  let difficulty;

  if (Object.keys(previous).length === 0) {
    // ==========================================================================================
    // ===================================== 처음 문제 생성 ========================================
    // ==========================================================================================
    startedTime = new Date().toISOString();
    const question_id = Object.entries(questionDifficulty).find(
      ([, difficulty]) => difficulty[1] === initialDifficulty
    )[0];
    questionId = Number(question_id);

    const passage_id = Object.entries(passageQuestions)
      .filter(([, question_ids]) =>
        question_ids[1].includes(Number(question_id))
      )
      .pop();
    passageId = Number(passage_id[0]);

    const question_history = passageQuestions[passageId][1];
    questionHistory = question_history;

    const result_history = questionHistory.map(() => '?');
    resultHistory = result_history;
    inputAnswer = '';

    // ===========================================================================================
    // =================================== 처음 문제 생성 END. ======================================
    // ===========================================================================================
  } else {
    startedTime = previous.startedTime;
    questionHistory = previous.questionHistory;
    resultHistory = previous.resultHistory;
    inputAnswer = previous.inputAnswer;
    /** ======================
     * @summary
     * 이전 문제 아직 안풀었는지 확인 
    ========================== */
    for (
      resultIndex = 0;
      resultIndex < resultHistory.length;
      resultIndex += 1
    ) {
      if (resultHistory[resultIndex] === '?') {
        for (const [correctAnswer, correctness] of choices[
          questionHistory[resultIndex]
        ]) {
          if (correctness > 0) {
            if (correctAnswer === inputAnswer) {
              resultHistory[resultIndex] = 'o';
            } else {
              resultHistory[resultIndex] = 'x';
            }
            break;
          }
        }

        if (resultIndex + 1 < resultHistory.length) {
          resultIndex += 1;

          const questionMarkIndex = resultHistory.indexOf('?');
          const question_id = questionHistory[questionMarkIndex];
          questionId = Number(question_id);

          const passage_id = Object.entries(passageQuestions)
            .filter(([, question_ids]) =>
              question_ids[1].includes(Number(question_id))
            )
            .pop();
          passageId = Number(passage_id[0]);
          break;
        }
      }
    }

    /** ===========
     * @summary
     * 현재 난이도 계산
    =============== */
    const currentDifficulty =
      questionDifficulty[questionHistory[questionHistory.length - 1]][1];
    let judgedDifficulty = currentDifficulty;

    /** ==============
     * @summary
     * 추가 문제 제출 판단
    ================== */
    const elapsedSeconds =
      (Date.now() - new Date(startedTime).getTime()) / 1000;
    const timeLeftSeconds = timeLimitSeconds - elapsedSeconds;
    let maxQuestionCount = 0;

    if (timeLeftSeconds > passageReadSeconds + answerQuestionSeconds) {
      maxQuestionCount = parseInt(
        (timeLeftSeconds - passageReadSeconds) / answerQuestionSeconds
      );
    }

    /** =================================
     * @summary
     * 제한 시간 초과 / 최대 문제 수 초과했을 경우
    ==================================== */
    if (
      maxQuestionCount === 0 ||
      questionHistory.length >= questionLimitCount
    ) {
      result = difficultyLevels[judgedDifficulty]; // '상', '중', '하'
      difficulty = judgedDifficulty;
    }

    /** ================================
     * @summary
     * 이전 문제를 모두 풀었다면 새로운 난이도 산출
    ==================================== */
    if (resultHistory[resultIndex] !== '?') {
      /** ----------
       * 결과 정산 채점
      -------------- */
      const correctCount = resultHistory.filter(
        (result) => result === 'o'
      ).length;

      if (parseFloat(correctCount / resultHistory.length) > 0.5) {
        if (judgedDifficulty + 5 <= 25) {
          judgedDifficulty = currentDifficulty + 5;
        }
      } else if (parseFloat(correctCount / resultHistory.length) <= 0.5) {
        if (judgedDifficulty - 5 >= 5) {
          judgedDifficulty = currentDifficulty - 5;
        }
      }

      /** ----------
       * 다음 문제 출제
      -------------- */
      try {
        const question_id = Object.entries(questionDifficulty)
          .filter(
            ([question_id, difficulty]) =>
              difficulty[1] === judgedDifficulty &&
              !questionHistory.includes(Number(question_id))
          )
          .map(([question_id]) => question_id)[0];
        questionId = Number(question_id);

        const passage_id = Object.entries(passageQuestions)
          .filter(([passage_id, question_ids]) =>
            question_ids[1].includes(questionId)
          )
          .pop();

        passageId = Number(passage_id[0]);

        let questionIds = passageQuestions[passageId][1];

        let questionCount = questionIds.length;
        questionIds = questionIds.slice(0, maxQuestionCount);

        const question_Id = questionIds[0];
        questionId = Number(question_Id);

        if (questionCount > maxQuestionCount) {
          questionCount = maxQuestionCount;
        }
        questionHistory.push(...questionIds);
        resultHistory.push(...Array(questionIds.length).fill('?'));
      } catch (error) {
        /** -------------------
         * 더 이상 출제할 문제가 없음
         * '상','중','하' return
        ----------------------- */
        result = difficultyLevels[judgedDifficulty];
        difficulty = judgedDifficulty;
      }
    }
  }

  /** ========
   * @summary
   * 결과 출력
  ============ */
  const resultDictionary = {
    startedTime,
    questionHistory,
    resultHistory,
    passageGroup: result === undefined ? passageQuestions[passageId][1] : [],
    passageAudio:
      result === undefined ? questionDifficulty[questionId][2].audio : [],
    difficulty,
    questionId,
    question: result === undefined ? questionDifficulty[questionId][0] : '',
    passageId,
    passage: result === undefined ? passageQuestions[passageId][0] : 0,
    choices:
      result === undefined
        ? choices[questionId].map((choiceCorrect) => choiceCorrect[0])
        : [],
    result,
    inputAnswer,
  };
  // console.log(resultDictionary);
  return resultDictionary;
};
