import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useCallbackPrompt } from 'utils/hooks';
import ExitModal from 'components/modal/ExitModal';
import { useStatus } from 'utils/status';
import { convertTextToSec, formatTimeMinutes } from 'utils/string';
import { __NO_LIMIT_DURATION__ } from 'constants/testtaker';

const LanguageTestStart = ({ test, setExampleStep, title, exampleStep }) => {
  const { t } = useTranslation();
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(true);
  const { setTestInfo, userPlan } = useStatus();
  const [seconds, setSeconds] = React.useState(10);

  const nextFuc = () => {
    setTestInfo({
      duration: Math.abs(15 * 60),
      doneCnt: 1,
      maxDoneCnt: '-',
      canSubmit: false,
      buttonName: t('btn.submit'),
    });
    setExampleStep(exampleStep + 1);
    return;
  };

  React.useEffect(() => {
    if (seconds <= 0) {
      nextFuc();
    }
    const countdown = setTimeout(() => {
      setSeconds(seconds - 1);
    }, 1000);
    return () => clearTimeout(countdown);
  }, [seconds]);

  const onSkipHandler = () => {
    nextFuc();
  };

  const ceilMin = (number) => Math.ceil(Number(number));

  return (
    <main className="demo-pages-protected-evaluation-test-start">
      <ExitModal
        showPrompt={showPrompt}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
      />
      <div className="container">
        <div className="container-inner">
          <section className="title">
            <h2 className="title-main fs28to24">{title}</h2>
          </section>
          <section className="desc">
            <Trans
              t={t}
              parent="p"
              i18nKey="desc.next-test"
              values={{
                minute:
                  ceilMin(formatTimeMinutes(convertTextToSec(test?.limit))) ||
                  '15',
              }}
            />
          </section>
          <section className="alert">
            {seconds > 1 ? (
              <Trans
                t={t}
                parent="p"
                i18nKey="desc.test-start-seconds"
                values={{
                  seconds,
                }}
              />
            ) : (
              <Trans
                t={t}
                parent="p"
                i18nKey="desc.test-start-seconds-1"
                values={{
                  seconds,
                }}
              />
            )}
          </section>
          <button
            className="skip common-button fsbtn16 black active"
            onClick={() => onSkipHandler()}
          >
            {t('evaluation.language_test.btn_start_now')}
          </button>
        </div>
      </div>
    </main>
  );
};

export default LanguageTestStart;
