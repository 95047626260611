import { nextQuestion } from './question';

export async function japaneseListeningQuestion(previous) {
  const baseUrl = `${process.env.REACT_APP_IMATE_URL}/images/audio/japaneseListening`;
  /** ==========
   * 문제
  ============== */
  const questionDifficulty = {
    0: [
      `${baseUrl}/Level-3/Level-3-Q-1.jpg`,
      5,
      {
        audio: [
          [
            `${baseUrl}/Level-3/Level-3-Desc-1~3.mp3`,
            `${baseUrl}/Common-Voice/Japanese-質問.mp3`,
            `${baseUrl}/Common-Voice/Japanese-1.mp3`,
            `${baseUrl}/Level-3/Level-3-Q-1-1.mp3`,
            `${baseUrl}/Common-Voice/Japanese-2.mp3`,
            `${baseUrl}/Level-3/Level-3-Q-1-2.mp3`,
            `${baseUrl}/Common-Voice/Japanese-3.mp3`,
            `${baseUrl}/Level-3/Level-3-Q-1-3.mp3`,
            `${baseUrl}/Common-Voice/Japanese-4.mp3`,
            `${baseUrl}/Level-3/Level-3-Q-1-4.mp3`,
          ],
        ],
      },
    ],
    1: [
      `${baseUrl}/Level-3/Level-3-Q-2.jpg`,
      5,
      {
        audio: [
          [
            `${baseUrl}/Level-3/Level-3-Desc-1~3.mp3`,
            `${baseUrl}/Common-Voice/Japanese-質問.mp3`,
            `${baseUrl}/Common-Voice/Japanese-1.mp3`,
            `${baseUrl}/Level-3/Level-3-Q-2-1.mp3`,
            `${baseUrl}/Common-Voice/Japanese-2.mp3`,
            `${baseUrl}/Level-3/Level-3-Q-2-2.mp3`,
            `${baseUrl}/Common-Voice/Japanese-3.mp3`,
            `${baseUrl}/Level-3/Level-3-Q-2-3.mp3`,
            `${baseUrl}/Common-Voice/Japanese-4.mp3`,
            `${baseUrl}/Level-3/Level-3-Q-2-4.mp3`,
          ],
        ],
      },
    ],
    2: [
      `${baseUrl}/Level-3/Level-3-Q-3.jpg`,
      5,
      {
        audio: [
          [
            `${baseUrl}/Level-3/Level-3-Desc-1~3.mp3`,
            `${baseUrl}/Common-Voice/Japanese-質問.mp3`,
            `${baseUrl}/Common-Voice/Japanese-1.mp3`,
            `${baseUrl}/Level-3/Level-3-Q-3-1.mp3`,
            `${baseUrl}/Common-Voice/Japanese-2.mp3`,
            `${baseUrl}/Level-3/Level-3-Q-3-2.mp3`,
            `${baseUrl}/Common-Voice/Japanese-3.mp3`,
            `${baseUrl}/Level-3/Level-3-Q-3-3.mp3`,
            `${baseUrl}/Common-Voice/Japanese-4.mp3`,
            `${baseUrl}/Level-3/Level-3-Q-3-4.mp3`,
          ],
        ],
      },
    ],
    3: [
      '旅行に行くって聞いたんだけど、いつ旅行に行くの?',
      5,
      {
        audio: [
          [
            `${baseUrl}/Level-3/Level-3-Dialog-4~5.mp3`,
            `${baseUrl}/Common-Voice/Japanese-質問.mp3`,
            `${baseUrl}/Common-Voice/Japanese-1.mp3`,
            `${baseUrl}/Level-3/Level-3-Q-4.mp3`,
            `${baseUrl}/Common-Voice/Japanese-1.mp3`,
            `${baseUrl}/Level-3/Level-3-Q-4-1.mp3`,
            `${baseUrl}/Common-Voice/Japanese-2.mp3`,
            `${baseUrl}/Level-3/Level-3-Q-4-2.mp3`,
            `${baseUrl}/Common-Voice/Japanese-3.mp3`,
            `${baseUrl}/Level-3/Level-3-Q-4-3.mp3`,
            `${baseUrl}/Common-Voice/Japanese-4.mp3`,
            `${baseUrl}/Level-3/Level-3-Q-4-4.mp3`,
          ],
        ],
      },
    ],
    4: [
      '書類提出の締め切り日に間に合わなさそうなんですが…。',
      5,
      {
        audio: [
          [
            `${baseUrl}/Level-3/Level-3-Dialog-4~5.mp3`,
            `${baseUrl}/Common-Voice/Japanese-質問.mp3`,
            `${baseUrl}/Common-Voice/Japanese-1.mp3`,
            `${baseUrl}/Level-3/Level-3-Q-5.mp3`,
            `${baseUrl}/Common-Voice/Japanese-1.mp3`,
            `${baseUrl}/Level-3/Level-3-Q-5-1.mp3`,
            `${baseUrl}/Common-Voice/Japanese-2.mp3`,
            `${baseUrl}/Level-3/Level-3-Q-5-2.mp3`,
            `${baseUrl}/Common-Voice/Japanese-3.mp3`,
            `${baseUrl}/Level-3/Level-3-Q-5-3.mp3`,
            `${baseUrl}/Common-Voice/Japanese-4.mp3`,
            `${baseUrl}/Level-3/Level-3-Q-5-4.mp3`,
          ],
        ],
      },
    ],
    5: [
      `${baseUrl}/Level-4/Level-4-Q-1.jpg`,
      10,
      {
        audio: [
          [
            `${baseUrl}/Level-4/Level-4-Desc-1~2.mp3`,
            `${baseUrl}/Common-Voice/Japanese-質問.mp3`,
            `${baseUrl}/Common-Voice/Japanese-1.mp3`,
            `${baseUrl}/Level-4/Level-4-Q-1-1.mp3`,
            `${baseUrl}/Common-Voice/Japanese-2.mp3`,
            `${baseUrl}/Level-4/Level-4-Q-1-2.mp3`,
            `${baseUrl}/Common-Voice/Japanese-3.mp3`,
            `${baseUrl}/Level-4/Level-4-Q-1-3.mp3`,
            `${baseUrl}/Common-Voice/Japanese-4.mp3`,
            `${baseUrl}/Level-4/Level-4-Q-1-4.mp3`,
          ],
        ],
      },
    ],
    6: [
      `${baseUrl}/Level-4/Level-4-Q-2.jpg`,
      10,
      {
        audio: [
          [
            `${baseUrl}/Level-4/Level-4-Desc-1~2.mp3`,
            `${baseUrl}/Common-Voice/Japanese-質問.mp3`,
            `${baseUrl}/Common-Voice/Japanese-1.mp3`,
            `${baseUrl}/Level-4/Level-4-Q-2-1.mp3`,
            `${baseUrl}/Common-Voice/Japanese-2.mp3`,
            `${baseUrl}/Level-4/Level-4-Q-2-2.mp3`,
            `${baseUrl}/Common-Voice/Japanese-3.mp3`,
            `${baseUrl}/Level-4/Level-4-Q-2-3.mp3`,
            `${baseUrl}/Common-Voice/Japanese-4.mp3`,
            `${baseUrl}/Level-4/Level-4-Q-2-4.mp3`,
          ],
        ],
      },
    ],
    7: [
      'さっきの会議での僕の発言、もしかして気に障った?',
      10,
      {
        audio: [
          [
            `${baseUrl}/Level-4/Level-4-Desc-3~4.mp3`,
            `${baseUrl}/Common-Voice/Japanese-質問.mp3`,
            `${baseUrl}/Common-Voice/Japanese-1.mp3`,
            `${baseUrl}/Level-4/Level-4-Q-3.mp3`,
            `${baseUrl}/Common-Voice/Japanese-1.mp3`,
            `${baseUrl}/Level-4/Level-4-Q-3-1.mp3`,
            `${baseUrl}/Common-Voice/Japanese-2.mp3`,
            `${baseUrl}/Level-4/Level-4-Q-3-2.mp3`,
            `${baseUrl}/Common-Voice/Japanese-3.mp3`,
            `${baseUrl}/Level-4/Level-4-Q-3-3.mp3`,
            `${baseUrl}/Common-Voice/Japanese-4.mp3`,
            `${baseUrl}/Level-4/Level-4-Q-3-4.mp3`,
          ],
        ],
      },
    ],
    8: [
      '佐藤君、さっき頼んだ仕事そっちのけで何やってるの?',
      10,
      {
        audio: [
          [
            `${baseUrl}/Level-4/Level-4-Desc-3~4.mp3`,
            `${baseUrl}/Common-Voice/Japanese-1.mp3`,
            `${baseUrl}/Level-4/Level-4-Q-4.mp3`,
            `${baseUrl}/Common-Voice/Japanese-1.mp3`,
            `${baseUrl}/Level-4/Level-4-Q-4-1.mp3`,
            `${baseUrl}/Common-Voice/Japanese-2.mp3`,
            `${baseUrl}/Level-4/Level-4-Q-4-2.mp3`,
            `${baseUrl}/Common-Voice/Japanese-3.mp3`,
            `${baseUrl}/Level-4/Level-4-Q-4-3.mp3`,
            `${baseUrl}/Common-Voice/Japanese-4.mp3`,
            `${baseUrl}/Level-4/Level-4-Q-4-4.mp3`,
          ],
        ],
      },
    ],
    9: [
      '待ち合わせ場所はどこか。',
      10,
      {
        audio: [
          [
            `${baseUrl}/Level-4/Level-4-Desc-5.mp3`,
            `${baseUrl}/Level-4/Level-4-Dialog-5-1.mp3`,
            `${baseUrl}/Level-4/Level-4-Dialog-5-2.mp3`,
            `${baseUrl}/Level-4/Level-4-Dialog-5-3.mp3`,
            `${baseUrl}/Level-4/Level-4-Dialog-5-4.mp3`,
            `${baseUrl}/Common-Voice/Japanese-質問.mp3`,
            `${baseUrl}/Common-Voice/Japanese-1.mp3`,
            `${baseUrl}/Level-4/Level-4-Q-5.mp3`,
            `${baseUrl}/Common-Voice/Japanese-1.mp3`,
            `${baseUrl}/Level-4/Level-4-Q-5-1.mp3`,
            `${baseUrl}/Common-Voice/Japanese-2.mp3`,
            `${baseUrl}/Level-4/Level-4-Q-5-2.mp3`,
            `${baseUrl}/Common-Voice/Japanese-3.mp3`,
            `${baseUrl}/Level-4/Level-4-Q-5-3.mp3`,
            `${baseUrl}/Common-Voice/Japanese-4.mp3`,
            `${baseUrl}/Level-4/Level-4-Q-5-4.mp3`,
          ],
        ],
      },
    ],
    10: [
      '部長はどうしてあんなに怒っているのかしら?',
      15,
      {
        audio: [
          [
            `${baseUrl}/Level-5/Level-5-Desc-1~2.mp3`,
            `${baseUrl}/Common-Voice/Japanese-質問.mp3`,
            `${baseUrl}/Common-Voice/Japanese-1.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-1.mp3`,
            `${baseUrl}/Common-Voice/Japanese-1.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-1-1.mp3`,
            `${baseUrl}/Common-Voice/Japanese-2.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-1-2.mp3`,
            `${baseUrl}/Common-Voice/Japanese-3.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-1-3.mp3`,
            `${baseUrl}/Common-Voice/Japanese-4.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-1-4.mp3`,
          ],
        ],
      },
    ],
    11: [
      'この間先輩の頼みを引き受けたんだけど、もう懲り懲りだよ。',
      15,
      {
        audio: [
          [
            `${baseUrl}/Level-5/Level-5-Desc-1~2.mp3`,
            `${baseUrl}/Common-Voice/Japanese-質問.mp3`,
            `${baseUrl}/Common-Voice/Japanese-1.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-2.mp3`,
            `${baseUrl}/Common-Voice/Japanese-1.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-2-1.mp3`,
            `${baseUrl}/Common-Voice/Japanese-2.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-2-2.mp3`,
            `${baseUrl}/Common-Voice/Japanese-3.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-2-3.mp3`,
            `${baseUrl}/Common-Voice/Japanese-4.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-2-4.mp3`,
          ],
        ],
      },
    ],
    12: [
      '連絡が遅くなった理由は?',
      15,
      {
        audio: [
          [
            `${baseUrl}/Level-5/Level-5-Desc-3~4.mp3`,
            `${baseUrl}/Level-5/Level-5-Dialog-3-1.mp3`,
            `${baseUrl}/Level-5/Level-5-Dialog-3-2.mp3`,
            `${baseUrl}/Level-5/Level-5-Dialog-3-3.mp3`,
            `${baseUrl}/Level-5/Level-5-Dialog-3-4.mp3`,
            `${baseUrl}/Common-Voice/Japanese-質問.mp3`,
            `${baseUrl}/Common-Voice/Japanese-1.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-3.mp3`,
            `${baseUrl}/Common-Voice/Japanese-1.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-3-1.mp3`,
            `${baseUrl}/Common-Voice/Japanese-2.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-3-2.mp3`,
            `${baseUrl}/Common-Voice/Japanese-3.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-3-3.mp3`,
            `${baseUrl}/Common-Voice/Japanese-4.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-3-4.mp3`,
          ],
        ],
      },
    ],
    13: [
      '定例会議のアジェンダの内容でないものは?',
      15,
      {
        audio: [
          [
            `${baseUrl}/Level-5/Level-5-Desc-3~4.mp3`,
            `${baseUrl}/Level-5/Level-5-Dialog-4-1.mp3`,
            `${baseUrl}/Level-5/Level-5-Dialog-4-2  `,
            `${baseUrl}/Level-5/Level-5-Dialog-4-3.mp3`,
            `${baseUrl}/Level-5/Level-5-Dialog-4-4.mp3`,
            `${baseUrl}/Level-5/Level-5-Dialog-4-5.mp3`,
            `${baseUrl}/Level-5/Level-5-Dialog-4-6.mp3`,
            `${baseUrl}/Common-Voice/Japanese-質問.mp3`,
            `${baseUrl}/Common-Voice/Japanese-1.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-4.mp3`,
            `${baseUrl}/Common-Voice/Japanese-1.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-4-1.mp3`,
            `${baseUrl}/Common-Voice/Japanese-2.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-4-2.mp3`,
            `${baseUrl}/Common-Voice/Japanese-3.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-4-3.mp3`,
            `${baseUrl}/Common-Voice/Japanese-4.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-4-4.mp3`,
          ],
        ],
      },
    ],
    14: [
      '私が朝起きてすぐにすることは何ですか?',
      15,
      {
        audio: [
          [
            `${baseUrl}/Level-5/Level-5-Desc-5.mp3`,
            `${baseUrl}/Level-5/Level-5-Dialog-5.mp3`,
            `${baseUrl}/Common-Voice/Japanese-質問.mp3`,
            `${baseUrl}/Common-Voice/Japanese-1.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-5.mp3`,
            `${baseUrl}/Common-Voice/Japanese-1.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-5-1.mp3`,
            `${baseUrl}/Common-Voice/Japanese-2.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-5-2.mp3`,
            `${baseUrl}/Common-Voice/Japanese-3.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-5-3.mp3`,
            `${baseUrl}/Common-Voice/Japanese-4.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-5-4.mp3`,
          ],
        ],
      },
    ],
    15: [
      'あの銀行、経営がヤバいらしいって噂をきいたんだけど、知ってる?',
      20,
      {
        audio: [
          [
            `${baseUrl}/Level-6/Level-6-Desc-1.mp3`,
            `${baseUrl}/Common-Voice/Japanese-質問.mp3`,
            `${baseUrl}/Common-Voice/Japanese-1.mp3`,
            `${baseUrl}/Level-6/Level-6-Q-1.mp3`,
            `${baseUrl}/Common-Voice/Japanese-1.mp3`,
            `${baseUrl}/Level-6/Level-6-Q-1-1.mp3`,
            `${baseUrl}/Common-Voice/Japanese-2.mp3`,
            `${baseUrl}/Level-6/Level-6-Q-1-2.mp3`,
            `${baseUrl}/Common-Voice/Japanese-3.mp3`,
            `${baseUrl}/Level-6/Level-6-Q-1-3.mp3`,
            `${baseUrl}/Common-Voice/Japanese-4.mp3`,
            `${baseUrl}/Level-6/Level-6-Q-1-4.mp3`,
          ],
        ],
      },
    ],
    16: [
      '接待料理は何にしたか?',
      20,
      {
        audio: [
          [
            `${baseUrl}/Level-6/Level-6-Desc-2~3.mp3`,
            `${baseUrl}/Level-6/Level-6-Dialog-2~3-1.mp3	`,
            `${baseUrl}/Level-6/Level-6-Dialog-2~3-2.mp3`,
            `${baseUrl}/Level-6/Level-6-Dialog-2~3-3.mp3`,
            `${baseUrl}/Level-6/Level-6-Dialog-2~3-4.mp3`,
            `${baseUrl}/Level-6/Level-6-Dialog-2~3-5.mp3`,
            `${baseUrl}/Level-6/Level-6-Dialog-2~3-6.mp3`,
            `${baseUrl}/Level-6/Level-6-Dialog-2~3-7.mp3`,
            `${baseUrl}/Level-6/Level-6-Dialog-2~3-8.mp3`,
            `${baseUrl}/Common-Voice/Japanese-質問.mp3`,
            `${baseUrl}/Common-Voice/Japanese-1.mp3`,
            `${baseUrl}/Level-6/Level-6-Q-2.mp3`,
            `${baseUrl}/Common-Voice/Japanese-1.mp3`,
            `${baseUrl}/Level-6/Level-6-Q-2-1.mp3`,
            `${baseUrl}/Common-Voice/Japanese-2.mp3`,
            `${baseUrl}/Level-6/Level-6-Q-2-2.mp3`,
            `${baseUrl}/Common-Voice/Japanese-3.mp3`,
            `${baseUrl}/Level-6/Level-6-Q-2-3.mp3`,
            `${baseUrl}/Common-Voice/Japanese-4.mp3`,
            `${baseUrl}/Level-6/Level-6-Q-2-4.mp3`,
          ],
        ],
      },
    ],
    17: [
      '接待料理を決めた理由は何か?',
      20,
      {
        audio: [
          [
            `${baseUrl}/Common-Voice/Japanese-質問.mp3`,
            `${baseUrl}/Common-Voice/Japanese-2.mp3`,
            `${baseUrl}/Level-6/Level-6-Q-3.mp3`,
            `${baseUrl}/Common-Voice/Japanese-1.mp3`,
            `${baseUrl}/Level-6/Level-6-Q-3-1.mp3`,
            `${baseUrl}/Common-Voice/Japanese-2.mp3`,
            `${baseUrl}/Level-6/Level-6-Q-3-2.mp3`,
            `${baseUrl}/Common-Voice/Japanese-3.mp3`,
            `${baseUrl}/Level-6/Level-6-Q-3-3.mp3`,
            `${baseUrl}/Common-Voice/Japanese-4.mp3`,
            `${baseUrl}/Level-6/Level-6-Q-3-4.mp3`,
          ],
        ],
      },
    ],
    18: [
      'ベータグルカンにはどんな働きがあるか',
      20,
      {
        audio: [
          [
            `${baseUrl}/Level-6/Level-6-Desc-4~5.mp3`,
            `${baseUrl}/Level-6/Level-6-Dialog-4~5.mp3`,
            `${baseUrl}/Common-Voice/Japanese-質問.mp3`,
            `${baseUrl}/Common-Voice/Japanese-1.mp3`,
            `${baseUrl}/Level-6/Level-6-Q-4.mp3`,
            `${baseUrl}/Common-Voice/Japanese-1.mp3`,
            `${baseUrl}/Level-6/Level-6-Q-4-1.mp3`,
            `${baseUrl}/Common-Voice/Japanese-2.mp3`,
            `${baseUrl}/Level-6/Level-6-Q-4-2.mp3`,
            `${baseUrl}/Common-Voice/Japanese-3.mp3`,
            `${baseUrl}/Level-6/Level-6-Q-4-3.mp3`,
            `${baseUrl}/Common-Voice/Japanese-4.mp3`,
            `${baseUrl}/Level-6/Level-6-Q-4-4.mp3`,
          ],
        ],
      },
    ],
    19: [
      '食物繊維の効果で正しい説明はどれか?',
      20,
      {
        audio: [
          [
            `${baseUrl}/Common-Voice/Japanese-質問.mp3`,
            `${baseUrl}/Common-Voice/Japanese-2.mp3`,
            `${baseUrl}/Level-6/Level-6-Q-5.mp3`,
            `${baseUrl}/Common-Voice/Japanese-1.mp3`,
            `${baseUrl}/Level-6/Level-6-Q-5-1.mp3`,
            `${baseUrl}/Common-Voice/Japanese-2.mp3`,
            `${baseUrl}/Level-6/Level-6-Q-5-2.mp3`,
            `${baseUrl}/Common-Voice/Japanese-3.mp3`,
            `${baseUrl}/Level-6/Level-6-Q-5-3.mp3`,
            `${baseUrl}/Common-Voice/Japanese-4.mp3`,
            `${baseUrl}/Level-6/Level-6-Q-5-4.mp3`,
          ],
        ],
      },
    ],
    20: [
      '新しくオープンしたお店に行くことに決めた理由は?',
      25,
      {
        audio: [
          [
            `${baseUrl}/Level-7/Level-7-Desc-1.mp3`,
            `${baseUrl}/Level-7/Level-7-Dialog-1-1.mp3`,
            `${baseUrl}/Level-7/Level-7-Dialog-1-2.mp3`,
            `${baseUrl}/Level-7/Level-7-Dialog-1-3.mp3`,
            `${baseUrl}/Level-7/Level-7-Dialog-1-4.mp3`,
            `${baseUrl}/Level-7/Level-7-Dialog-1-5.mp3`,
            `${baseUrl}/Level-7/Level-7-Dialog-1-6.mp3`,
            `${baseUrl}/Level-7/Level-7-Dialog-1-7.mp3`,
            `${baseUrl}/Common-Voice/Japanese-質問.mp3`,
            `${baseUrl}/Common-Voice/Japanese-1.mp3`,
            `${baseUrl}/Level-7/Level-7-Q-1.mp3`,
            `${baseUrl}/Common-Voice/Japanese-1.mp3`,
            `${baseUrl}/Level-7/Level-7-Q-1-1.mp3`,
            `${baseUrl}/Common-Voice/Japanese-2.mp3`,
            `${baseUrl}/Level-7/Level-7-Q-1-2.mp3`,
            `${baseUrl}/Common-Voice/Japanese-3.mp3`,
            `${baseUrl}/Level-7/Level-7-Q-1-3.mp3`,
            `${baseUrl}/Common-Voice/Japanese-4.mp3`,
            `${baseUrl}/Level-7/Level-7-Q-1-4.mp3`,
          ],
        ],
      },
    ],
    21: [
      '筆者は今後の観光産業はどうあるべきだと言っていますか。',
      25,
      {
        audio: [
          [
            `${baseUrl}/Level-7/Level-7-Desc-2~3.mp3`,
            `${baseUrl}/Level-7/Level-7-Dialog-2.mp3`,
            `${baseUrl}/Common-Voice/Japanese-質問.mp3`,
            `${baseUrl}/Common-Voice/Japanese-1.mp3`,
            `${baseUrl}/Level-7/Level-7-Q-2.mp3`,
            `${baseUrl}/Common-Voice/Japanese-1.mp3`,
            `${baseUrl}/Level-7/Level-7-Q-2-1.mp3`,
            `${baseUrl}/Common-Voice/Japanese-2.mp3`,
            `${baseUrl}/Level-7/Level-7-Q-2-2.mp3`,
            `${baseUrl}/Common-Voice/Japanese-3.mp3`,
            `${baseUrl}/Level-7/Level-7-Q-2-3.mp3`,
            `${baseUrl}/Common-Voice/Japanese-4.mp3`,
            `${baseUrl}/Level-7/Level-7-Q-2-4.mp3`,
          ],
        ],
      },
    ],
    22: [
      '現状の観光地はどうなっているか',
      25,
      {
        audio: [
          [
            `${baseUrl}/Common-Voice/Japanese-質問.mp3`,
            `${baseUrl}/Common-Voice/Japanese-2.mp3`,
            `${baseUrl}/Level-7/Level-7-Q-3.mp3`,
            `${baseUrl}/Common-Voice/Japanese-1.mp3`,
            `${baseUrl}/Level-7/Level-7-Q-3-1.mp3`,
            `${baseUrl}/Common-Voice/Japanese-2.mp3`,
            `${baseUrl}/Level-7/Level-7-Q-3-2.mp3`,
            `${baseUrl}/Common-Voice/Japanese-3.mp3`,
            `${baseUrl}/Level-7/Level-7-Q-3-3.mp3`,
            `${baseUrl}/Common-Voice/Japanese-4.mp3`,
            `${baseUrl}/Level-7/Level-7-Q-3-4.mp3`,
          ],
        ],
      },
    ],
    23: [
      '相対主義について正しく述べているのは?',
      25,
      {
        audio: [
          [
            `${baseUrl}/Level-7/Level-7-Desc-4~5.mp3`,
            `${baseUrl}/Level-7/Level-7-Dialog-4.mp3`,
            `${baseUrl}/Common-Voice/Japanese-質問.mp3`,
            `${baseUrl}/Common-Voice/Japanese-1.mp3`,
            `${baseUrl}/Level-7/Level-7-Q-4.mp3`,
            `${baseUrl}/Common-Voice/Japanese-1.mp3`,
            `${baseUrl}/Level-7/Level-7-Q-4-1.mp3`,
            `${baseUrl}/Common-Voice/Japanese-2.mp3`,
            `${baseUrl}/Level-7/Level-7-Q-4-2.mp3`,
            `${baseUrl}/Common-Voice/Japanese-3.mp3`,
            `${baseUrl}/Level-7/Level-7-Q-4-3.mp3`,
            `${baseUrl}/Common-Voice/Japanese-4.mp3`,
            `${baseUrl}/Level-7/Level-7-Q-4-4.mp3`,
          ],
        ],
      },
    ],
    24: [
      '筆者はどうあるべきだと言っているか。',
      25,
      {
        audio: [
          [
            `${baseUrl}/Common-Voice/Japanese-質問.mp3`,
            `${baseUrl}/Common-Voice/Japanese-2.mp3`,
            `${baseUrl}/Level-7/Level-7-Q-5.mp3`,
            `${baseUrl}/Common-Voice/Japanese-1.mp3`,
            `${baseUrl}/Level-7/Level-7-Q-5-1.mp3`,
            `${baseUrl}/Common-Voice/Japanese-2.mp3`,
            `${baseUrl}/Level-7/Level-7-Q-5-2.mp3`,
            `${baseUrl}/Common-Voice/Japanese-3.mp3`,
            `${baseUrl}/Level-7/Level-7-Q-5-3.mp3`,
            `${baseUrl}/Common-Voice/Japanese-4.mp3`,
            `${baseUrl}/Level-7/Level-7-Q-5-4.mp3`,
          ],
        ],
      },
    ],
  };

  /** ==========
   * 문제 선택지
  ============== */
  const choices = {
    0: [
      ['1', 0],
      ['2', 1],
      ['3', 0],
      ['4', 0],
    ],
    1: [
      ['1', 0],
      ['2', 0],
      ['3', 1],
      ['4', 0],
    ],
    2: [
      ['1', 1],
      ['2', 0],
      ['3', 0],
      ['4', 0],
    ],
    3: [
      ['1', 0],
      ['2', 1],
      ['3', 0],
      ['4', 0],
    ],
    4: [
      ['1', 0],
      ['2', 0],
      ['3', 0],
      ['4', 1],
    ],
    5: [
      ['1', 0],
      ['2', 1],
      ['3', 0],
      ['4', 0],
    ],
    6: [
      ['1', 0],
      ['2', 0],
      ['3', 1],
      ['4', 0],
    ],
    7: [
      ['1', 1],
      ['2', 0],
      ['3', 0],
      ['4', 0],
    ],
    8: [
      ['1', 0],
      ['2', 1],
      ['3', 0],
      ['4', 0],
    ],
    9: [
      ['1', 0],
      ['2', 0],
      ['3', 0],
      ['4', 1],
    ],
    10: [
      ['1', 0],
      ['2', 1],
      ['3', 0],
      ['4', 0],
    ],
    11: [
      ['1', 0],
      ['2', 0],
      ['3', 1],
      ['4', 0],
    ],
    12: [
      ['1', 0],
      ['2', 0],
      ['3', 0],
      ['4', 1],
    ],
    13: [
      ['1', 1],
      ['2', 0],
      ['3', 0],
      ['4', 0],
    ],
    14: [
      ['1', 0],
      ['2', 1],
      ['3', 0],
      ['4', 0],
    ],
    15: [
      ['1', 1],
      ['2', 0],
      ['3', 0],
      ['4', 0],
    ],
    16: [
      ['1', 0],
      ['2', 0],
      ['3', 0],
      ['4', 1],
    ],
    17: [
      ['1', 0],
      ['2', 0],
      ['3', 1],
      ['4', 0],
    ],
    18: [
      ['1', 1],
      ['2', 0],
      ['3', 0],
      ['4', 0],
    ],
    19: [
      ['1', 0],
      ['2', 1],
      ['3', 0],
      ['4', 0],
    ],
    20: [
      ['1', 1],
      ['2', 0],
      ['3', 0],
      ['4', 0],
    ],
    21: [
      ['1', 0],
      ['2', 0],
      ['3', 0],
      ['4', 1],
    ],
    22: [
      ['1', 0],
      ['2', 1],
      ['3', 0],
      ['4', 0],
    ],
    23: [
      ['1', 1],
      ['2', 0],
      ['3', 0],
      ['4', 0],
    ],
    24: [
      ['1', 0],
      ['2', 0],
      ['3', 1],
      ['4', 0],
    ],
  };

  /** ==========
   * 난이도
  ============== */
  const difficultyLevels = {
    5: 'Beginner',
    10: 'Intermediate',
    15: 'Intermediate',
    20: 'Advanced',
    25: 'Advanced',
  };

  /** ==========
   * 지문 그룹
  ============== */
  const passageQuestions = {
    0: [
      [
        '写真の内容を表している文は、どれですか。1、2、3、4の中から最もよいものを1つ選んでください。',
      ],
      [0],
    ],
    1: [
      [
        '写真の内容を表している文は、どれですか。1、2、3、4の中から最もよいものを1つ選んでください。',
      ],
      [1],
    ],
    2: [
      [
        '写真の内容を表している文は、どれですか。1、2、3、4の中から最もよいものを1つ選んでください。',
      ],
      [2],
    ],
    3: [['正しい応答を。1、2、3、4の中から選択してください。'], [3]],
    4: [['正しい応答を。1、2、3、4の中から選択してください。'], [4]],
    5: [
      [
        '写真の内容を表している文は、どれですか。1、2、3、4の中から最もよいものを1つ選んでください。',
      ],
      [5],
    ],
    6: [
      [
        '写真の内容を表している文は、どれですか。1、2、3、4の中から最もよいものを1つ選んでください。',
      ],
      [6],
    ],
    7: [
      [
        '次の質問を聞き、応答として最もよいものを1、2、3、4の中から1つ選んでください。',
      ],
      [7],
    ],
    8: [
      [
        '次の質問を聞き、応答として最もよいものを1、2、3、4の中から1つ選んでください。',
      ],
      [8],
    ],
    9: [
      [
        '次の会話を聞き、質問の応答として最もよいものを1、2、3、4の中から1つ選んでください。',
        'A:明日、どこで待ち合わせようか?',
        'B:そうだね、駅の改札口はどう?',
        'A:改札口は人の出入りが激しいから3番出口はどうかな?',
        'B:でも映画館は7番出口だから、7番出口で1時間前にしよう。',
      ],
      [9],
    ],
    10: [
      [
        '次の質問を聞き、応答として最もよいものを1、2、3、4の中から1つ選んでください。',
      ],
      [10],
    ],
    11: [
      [
        '次の質問を聞き、応答として最もよいものを1、2、3、4の中から1つ選んでください。',
      ],
      [11],
    ],
    12: [
      [
        '次の会話を聞き、質問への解答として最もよいものを1、2、3、4の中から1つを選んでください。',
        'A:ご連絡が遅くなりまして申し訳ありません',
        'B:大丈夫だよ。でもどうしたの。何かあった?寝坊したとか?',
        'A:携帯の充電器が壊れていたみたいで、充電出来ていなかったんです。',
        'B:そうだったんだ。連絡がないからドキドキしちゃったよ。',
      ],
      [12],
    ],
    13: [
      [
        '次の会話を聞き、質問への解答として最もよいものを1、2、3、4の中から1つを選んでください。',
        'A:定例会議の前日までにアジェンダをメールしておいてくれる?',
        'B:出席者だけでいいですか?他にも送る人がいますか?',
        'A:そうだね、出席者のほかに役員たちもccに入れておいて。',
        'B:何か気を付けることはありますか?',
        'A:問題提起だけじゃなくて解決案と目標金額も記載してね。あ、それと、現在までの進捗状況も併せてお願い。',
        'B:わかりました。そのようにしますね。',
      ],
      [13],
    ],
    14: [
      [
        '次の内容を聞き、質問へ解答として最もよいものを1、2、3、4の中から1つ選んでください。',
        '私の朝は5時に起きることから始まります。起きてすぐコーヒーを淹れ、30分くらいゆっくりしてからシャワーを浴びます。身だしなみを整えたり、会社に行く準備をしたりしていると6時半になります。6時45分には家をでます。会社の最寄り駅近くのコンビニで朝ごはんを買い、会社に到着すると7時半になります。新聞を読みながら朝ごはんを食べ、今日一日のスケジュールを確認したり、会議の準備をすると8時半になります。他の社員たちが出社し始め、9時に正式に業務を開始します。',
      ],
      [14],
    ],
    15: [
      [
        '次の質問を聞き、応答として最もよいものを1、2、3、4の中から1つ選んでください。',
      ],
      [15],
    ],
    16: [
      [
        '次の内容を聞き、質問への回答として最もよいものを1、2、3、4の中から1つ選んでください。',
        'A:来週、アメリカのT社から役員たちが来日するんだけど、接待の食事は何が良いと思う?',
        'B:中華のコース料理はどうですか?',
        'A:寿司か天ぷらがいいかと思ってたんだけど?',
        'B:お寿司や天ぷらは食べ慣れていると思いますが、日本式の中華コースはなかなか食べる機会がないと思いますよ',
        'A:じゃあ、中華のコース料理にしよう。予約頼むよ。',
        'B:わかりました。7時に予約しておきますね。',
      ],
      [16, 17],
    ],
    17: [
      [
        '次の内容を聞き、質問への回答として最もよいものを1、2、3、4の中から1つ選んでください。',
        'キノコの細胞壁には、ベータグルカンという食物繊維の一種が多く含まれているが、総合的な栄養価でみるとキノコの中でもシイタケがおすすめだ。このベータグルカンは免疫を強くする作用がある。海藻も食物繊維が豊富だが、食物繊維は免疫、腸、皮膚、脳などによい影響をもたらす重要な栄養素だ。食物繊維を摂って腸ケアをすることで、皮膚にもよい影響をもたらすことがわかっている。',
      ],
      [18, 19],
    ],
    18: [
      [
        '次の会話を聞き、質問への回答として最もよいものを1、2、3、4の中から1つ選んでください。',
        'A:今日、仕事帰りに一杯やらない?',
        'B:あぁ、いいね。いつもの居酒屋にする?',
        'A:駅の近所に新しくオープンしたお店にいってみない?オープン記念で来週までビール1杯300円らしいよ。刺身盛り合わせも30％引きなんだって。',
        'B:そこにしよう。チューハイも種類が豊富だって聞いたよ。ところで、今日は定時に上がれないんだけど大丈夫?',
        'A:何時頃になりそう?',
        'B:7時には上がれると思う。',
        'A:じゃあ、7時5分に入口玄関前で待ち合わせしよう。',
      ],
      [20],
    ],
    19: [
      [
        '次の内容を聞き、質問への回答として最もよいものを1、2、3、4の中から1つ選んでください。',
        '量が質に転化する。量をこなせば質も高まるという趣旨だが、がむしゃらに突き進んでも手法がまずければ効果は低い。政府が観光政策の「量から質」への転換を目指すという。果たして訪日客数のような数値目標の達成に固執するのではなく、観光地が住みやすくなる施策にシフトできるのだろうか。コロナ禍でも近隣からの来訪を促して収益を維持するといった事例が出てきたが、集客は代理店頼み。繁閑差や価格競争が激しく利益が出ない。訪日客は回復しているが、政府の支援には頼れず、個々人が切磋琢磨している状況だ。EUが昨年公表した観光政策も数値目標を設けず環境への配慮や地場産業の育成に重点を置いた。政府が不安定な雇用を改善し、混雑や環境破壊を招かない観光のあり方を示せるならば、住民や働く人々が安心して暮らせる観光地づくりができるに違いない。',
      ],
      [21, 22],
    ],
    20: [
      [
        '次の内容を聞き、質問への回答として最もよいものを1、2、3、4の中から1つ選んでください。',
        '古代ギリシャの哲学者、プロタゴラスは「人間は万物の尺度である」と言っている。絶対的な真理はなく、基準は人によっていかようにも変わる、との意だ。つまり、人間が尺度であるから、人が変われば尺度が変わってしまうので正しいことも変わってきてしまうということだ。換言すれば、人の数だけ真実があるとも言える。このような絶対的な価値基準はないという立場は、何かと何かを比べてどちらが上とか下とかいう考え方なので、人格形成を考えるなら安心感を得難い考え方ではないかと思う。かといって、絶対主義が良いと主張したいわけではない。けれど、ある程度の共通した尺度、共通した価値観がなければ、社会生活を送るのが非常に難しい。折り合いをつけれなくなるからだ。そう考えると、絶対主義と相対主義、物事によってその中間を決めていくのが、人の道なのかもしれない。',
      ],
      [23, 24],
    ],
  };

  return nextQuestion(
    previous,
    questionDifficulty,
    choices,
    difficultyLevels,
    passageQuestions
  );
}
