import React from 'react';
import { useTranslation } from 'react-i18next';
import { Radio } from '@mui/material';
import { useCallbackPrompt } from 'utils/hooks';
import ExitModal from 'components/modal/ExitModal';
import { useResult } from 'utils/result';
import { useStatus } from 'utils/status';
import {
  formatTime,
  convertTextToSec,
  convertHtmlForDisplay,
} from 'utils/string';

function MultipleChoice({ test, setCurrentState }) {
  const { t } = useTranslation();
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(true);
  const { recordResult } = useResult();
  const { setTestInfo, getTestInfo } = useStatus();
  const [selectedValue, setSelectedValue] = React.useState(-1);
  const { duration, doneCnt, maxDoneCnt, canSubmit } = getTestInfo();

  const onSubmitHandler = () => {
    recordResult({
      sid: test?.uuid || test?.id,
      data: {
        checks: [selectedValue],
      },
    });
    setSelectedValue(-1);
  };

  React.useEffect(() => {
    const skillEndEventListener = (event) => {
      onSubmitHandler();
    };

    window.scrollTo(0, 0);

    window.document.addEventListener('skill_end', skillEndEventListener);
    return () =>
      window.document.removeEventListener('skill_end', skillEndEventListener);
  }, []);

  React.useEffect(() => {
    const testDoneEventListener = (event) => {
      onSubmitHandler();
    };

    let btnName = '';
    if (doneCnt === maxDoneCnt) {
      btnName = t('btn.submit');
    } else {
      btnName = t('btn.next');
    }

    if (selectedValue >= 0) {
      setTestInfo({
        duration,
        doneCnt,
        maxDoneCnt,
        canSubmit: true,
        buttonName: btnName,
      });
    }

    window.document.addEventListener('test_done', testDoneEventListener);
    return () => {
      window.document.removeEventListener('test_done', testDoneEventListener);
    };
  }, [selectedValue]);

  return (
    <main className="pages-protected-evaluation-subjectives-multiple-choice">
      <ExitModal
        showPrompt={showPrompt}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
      />
      <div className="container">
        <div className="separater">
          <section className="separater-left">
            <div className="question">
              {/* <pre className="fs18">{convertHtmlForDisplay(test?.question)}</pre> */}
              <pre
                className="fs18"
                dangerouslySetInnerHTML={{
                  __html: convertHtmlForDisplay(test?.question),
                }}
              />
              {test?.url?.length > 0 && (
                <a
                  href={`${process.env.REACT_APP_IMATE_URL}/${test?.url}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    alt=""
                    src={`${process.env.REACT_APP_IMATE_URL}/${test?.url}`}
                  />
                </a>
              )}
            </div>
            <div className="label">{t('desc.select-only-one')}</div>
            {/* <div className="timer fs18">
              {formatTime(convertTextToSec(test?.limit))}
            </div> */}
          </section>
          <section className="separater-right">
            <div className="answer">
              <ul className="answer-list">
                {(test?.answers?.list || []).map((answer, idx) => (
                  <li
                    key={idx}
                    className={`answer-list-item ${
                      selectedValue === idx && 'selected'
                    }`}
                    onClick={(event) => {
                      setSelectedValue(idx);
                    }}
                  >
                    <div className="container">
                      <Radio
                        className="radio-button"
                        checked={selectedValue === idx}
                        onChange={(event) => {
                          setSelectedValue(idx);
                        }}
                        value={answer}
                        name="radio-buttons"
                        inputProps={{ 'aria-label': answer }}
                      />
                      <p>{answer}</p>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </section>
        </div>
        {/* <button
          className={`submit common-button fsbtn16 primary ${
            (selectedValue >= 0) && 'active'
          }`}
          onClick={(selectedValue >= 0) ? onSubmitHandler : null}
        >
          {t("btn.submit")}
        </button> */}
      </div>
    </main>
  );
}

export default MultipleChoice;
