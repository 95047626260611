import React from 'react';
import { useStatus } from 'utils/status';
import { formatTime } from 'utils/string';
import { useTranslation } from 'react-i18next';
import { ReactComponent as LogoIcon } from 'assets/images/logo/main_logo.svg';
import { __NO_LIMIT_DURATION__ } from 'constants/testtaker';

function TesttakerHeader() {
  const { getTestInfo, logoURL } = useStatus();
  const [seconds, setSeconds] = React.useState(0);
  const [canDone, setCanDone] = React.useState(false);
  const { duration, doneCnt, maxDoneCnt, canSubmit, buttonName } =
    getTestInfo();
  const { t } = useTranslation();

  React.useEffect(() => {
    if (!duration) {
      setSeconds(0);
      return;
    }
    if (seconds >= duration) {
      setTimeout(() => {
        const event = new CustomEvent('skill_end', {
          detail: {
            payload: null,
          },
        });

        window.document.dispatchEvent(event);
      }, 1000);
      return;
    }
    const countdown = setTimeout(() => {
      setSeconds(seconds + 1);
    }, 1000);
    return () => clearTimeout(countdown);
  }, [duration, seconds]);

  React.useEffect(() => {
    if (canSubmit) {
      setCanDone(true);
    } else {
      setCanDone(false);
    }
  }, [canSubmit, seconds]);

  const onSubmitHandler = () => {
    const event = new CustomEvent('test_done', {
      detail: {
        payload: null,
      },
    });
    window.document.dispatchEvent(event);
  };

  return (
    <header className="components-header-testtaker-header">
      <div className="container">
        <div className="logo">
          {logoURL !== '' && logoURL !== undefined ? (
            <img src={logoURL} alt="" />
          ) : (
            <LogoIcon />
          )}
        </div>
        {(doneCnt || duration) && (
          <div className="right">
            <div className="progress">
              <div className="progress-timer" />
              <div className="progress-count">
                {duration && (
                  <div className="progress-count-time" data-duration={duration}>
                    <span className="fs16to14">
                      {duration === __NO_LIMIT_DURATION__
                        ? '-'
                        : formatTime(duration - seconds)}
                    </span>
                    <div className="progress-bar">
                      <div
                        className="progress-bar-fill"
                        style={{
                          width:
                            duration === __NO_LIMIT_DURATION__
                              ? '0%'
                              : `${((duration - seconds) / duration) * 100}%`,
                        }}
                      />
                    </div>
                  </div>
                )}

                {doneCnt && (
                  <div
                    className="progress-count-cnt"
                    data-done-cnt={doneCnt}
                    data-max-done-cnt={maxDoneCnt}
                  >
                    <span className="fs16to14">{`${doneCnt}/${maxDoneCnt}`}</span>
                    <div className="progress-bar">
                      <div
                        className="progress-bar-fill"
                        style={{
                          width: `${(doneCnt / maxDoneCnt) * 100}%`,
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <button
              className={`start common-button fsbtn16 ${canDone && 'active'}`}
              onClick={canDone ? onSubmitHandler : null}
            >
              {buttonName === '다음' ? t('btn.next') : t('btn.submit')}
            </button>
          </div>
        )}
      </div>
    </header>
  );
}

export default TesttakerHeader;
