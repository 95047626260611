import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { executeAction } from 'utils/redux';
import { useProgress } from 'utils/progress';
import { getAssessmentInfo } from 'store/slices/testtakerSlice';
import * as Sentry from '@sentry/react';
import Reject from './takeInvitation/Reject';
import Accept from './takeInvitation/Accept';
import Landing from './Landing';
import DemoAccept from './takeInvitation/DemoAccept';

const TakeInvitation = () => {
  const location = useLocation();
  const [assessment, setAssessment] = React.useState({});
  const { setIsEntireLoading } = useProgress();
  const navigate = useNavigate();

  const {
    uid,
    aid,
    email,
    firstName,
    lastName,
    logoURL,
    userPlan,
    isPersonality,
  } = location.state || {};

  React.useEffect(() => {
    if (!uid || !aid) return;

    setIsEntireLoading(true);
    executeAction(
      getAssessmentInfo({
        uid,
        aid,
      })
    )
      .unwrap()
      .then(({ doc }) => {
        setAssessment(doc);
        setIsEntireLoading(false);
      })
      .catch((e) => {
        Sentry.captureException(e);
        console.dir(e);
        setIsEntireLoading(false);
      });
  }, [uid, aid]);

  if (Boolean(uid) && Boolean(aid) && Boolean(email)) {
    if (assessment?.archived === true) {
      navigate('/testtaker/auth/invalidinvitation', {
        state: { uid, aid, email, firstName, lastName, logoURL, userPlan },
        replace: true,
      });
      return;
    }
    return (
      <div className="relative">
        {/* {!isPersonality && <Landing />} */}
        <DemoAccept
          uid={uid}
          aid={aid}
          email={email}
          firstName={firstName}
          lastName={lastName}
          logoURL={logoURL}
          assessment={assessment}
          userPlan={userPlan}
          isPersonality={isPersonality}
        />
      </div>
    );
  }

  return <Reject />;
};

export default TakeInvitation;
