import React from 'react';
import ReactDOM from 'react-dom';
import { marked } from 'marked';
import hljs from 'highlight.js';
import 'highlight.js/styles/base16/dracula.css';

function SourceCode({ language, code, isSub }) {
  const langCode = language.toLowerCase();
  marked.setOptions({
    langPrefix: 'hljs language-',
    highlight(code) {
      return hljs.highlightAuto(code, [langCode]).value;
    },
  });
  const classNmae = isSub ? 'codeBlock-sub' : 'codeBlock';

  return (
    <div
      className={classNmae}
      dangerouslySetInnerHTML={{ __html: marked(code) }}
    />
  );
}

export default SourceCode;
