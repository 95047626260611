import React from 'react';
import { useTranslation } from 'react-i18next';
import { executeAction } from 'utils/redux';
import { Radio } from '@mui/material';
import { useCallbackPrompt } from 'utils/hooks';
import ExitModal from 'components/modal/ExitModal';
import { useResult } from 'utils/result';
import { useStatus } from 'utils/status';
import { useProgress } from 'utils/progress';
import { getOBPITest, getOBPITestResult } from 'store/slices/testtakerSlice';
import { ReactComponent as RightArrow } from 'assets/images/icon/arrow-right-white.svg';
import * as Sentry from '@sentry/react';

import {
  formatTime,
  convertTextToSec,
  convertHtmlForDisplay,
} from 'utils/string';
import { connectStorageEmulator } from 'firebase/storage';

const rowsPerPage = 10;

const OBPITest = ({ test, setCurrentState }) => {
  const { t } = useTranslation();
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(true);
  const { uid, aid, email: cid } = useStatus();
  const { recordResult } = useResult();
  const { setTestInfo, getTestInfo } = useStatus();
  const [selectedValue, setSelectedValue] = React.useState([]);
  const { setIsEntireLoading } = useProgress();
  const { duration, doneCnt, maxDoneCnt, canSubmit } = getTestInfo();
  const [_obpitest, _setOBPITest] = React.useState({});
  const [obpitest, setOBPITest] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [_answers, _setAnswers] = React.useState([]);
  const [answers, setAnswers] = React.useState([]);
  const [canDone, setCanDone] = React.useState(false);
  const itemsRef = React.useRef([]);
  const containerRef = React.useRef(null);
  const answerArray = [
    'btn.strongdisagree',
    'btn.disagree',
    'btn.agree',
    'btn.strongagree',
  ];

  React.useEffect(() => {
    setIsEntireLoading(true);
    setPage(0);
    executeAction(getOBPITest())
      .unwrap()
      .then((doc) => {
        _setOBPITest(doc);
        setIsEntireLoading(false);
      })
      .catch((e) => {
        Sentry.captureException(e);
        setIsEntireLoading(false);
      });

    const skillEndEventListener = (event) => {
      onSubmit();
    };

    window.document.addEventListener('skill_end', skillEndEventListener);

    return () =>
      window.document.removeEventListener('skill_end', skillEndEventListener);
  }, []);

  React.useEffect(() => {
    const tmp_answers = new Array(rowsPerPage).fill(-1);
    setAnswers(tmp_answers);
    const startIdx = page * rowsPerPage;
    setOBPITest(_obpitest?.questions?.slice(startIdx, startIdx + rowsPerPage));
    for (let item of itemsRef.current) {
      item.classList.remove('selected');
    }
    containerRef.current.scrollTo(0, 0);
  }, [_obpitest, page]);

  React.useEffect(() => {
    let btnName;
    if (page < maxDoneCnt - 1) {
      btnName = t('btn.next');
    } else {
      btnName = t('btn.submit');
    }
    // var cansubmit = (canDone && (page == maxDoneCnt));
    setTestInfo({
      duration,
      doneCnt: page + 1,
      maxDoneCnt,
      canSubmit: canDone,
      buttonName: btnName,
    });

    const testDoneEventListener = (event) => {
      if (page < maxDoneCnt - 1) {
        onNextHandler();
      } else {
        onSubmitHandler();
      }
    };

    window.document.addEventListener('test_done', testDoneEventListener);
    return () => {
      window.document.removeEventListener('test_done', testDoneEventListener);
    };
  }, [canDone, _obpitest, page]);

  React.useEffect(() => {
    let maxpage = _obpitest?.questions?.length / rowsPerPage;
    if (_obpitest?.questions?.length % rowsPerPage > 0) {
      maxpage += 1;
    }
    setTestInfo({
      duration,
      doneCnt,
      maxDoneCnt: maxpage,
      canSubmit: false,
      buttonName: t('btn.next'),
    });
  }, [_obpitest]);

  const onNextHandler = () => {
    if (!canDone) return;
    setPage(page + 1);
    setCanDone(false);

    let btnName;
    if (page < maxDoneCnt - 1) {
      btnName = t('btn.next');
    } else {
      btnName = t('btn.submit');
    }
    setTestInfo({
      duration,
      doneCnt: doneCnt + 1,
      maxDoneCnt,
      canSubmit: false,
      buttonName: btnName,
    });
    _setAnswers([..._answers, ...answers]);
  };

  const onSubmitHandler = () => {
    if (!canDone) return;
    onSubmit();
  };

  const onSubmit = () => {
    setIsEntireLoading(true);
    let items = [..._answers, ...answers];

    let submitresult = {};
    submitresult.token = _obpitest.token;
    submitresult.questions = new Array();

    for (let i = 0; i < items.length; i += 1) {
      let item = { questionId: i + 1, responseNumber: items[i] };
      submitresult.questions.push(item);
    }

    executeAction(getOBPITestResult({ uid, aid, cid, item: submitresult }))
      .unwrap()
      .then((doc) => {
        recordResult({
          sid: 'obpi',
          data: { doc, answer: submitresult },
          // data: {answer:submitresult}
        });
        setCurrentState('end');
        setTestInfo({
          duration: null,
          doneCnt: null,
          maxDoneCnt: null,
          canSubmit: false,
        });

        setIsEntireLoading(false);
      })
      .catch((e) => {
        Sentry.captureException(e);
        recordResult({
          sid: 'obpi',
          data: { answer: submitresult },
        });

        setCurrentState('end');
        setTestInfo({
          duration: null,
          doneCnt: null,
          maxDoneCnt: null,
          canSubmit: false,
        });

        setIsEntireLoading(false);
      });
  };

  const onClickSelectAnswerHandler = (idx, aidx) => {
    const tmp_answers = answers;
    tmp_answers[idx] = aidx + 1;
    for (let i = 0; i < 4; i += 1) {
      itemsRef.current[idx * 4 + i].classList.remove('selected');
    }
    itemsRef.current[idx * 4 + aidx].classList.add('selected');
    let candone = true;
    tmp_answers.forEach((answer) => {
      if (answer < 0) {
        candone = false;
        return;
      }
    });
    setCanDone(candone);
  };

  const addNextButtonHandler = () => {
    if (page < maxDoneCnt - 1) {
      onNextHandler();
    } else {
      onSubmitHandler();
    }
  };

  return (
    <main className="pages-protected-evaluation-obpitest">
      <ExitModal
        showPrompt={showPrompt}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
      />
      <div className="container" ref={containerRef}>
        <div className="container-inner">
          <div className="discription fs16">
            {t('title.how-much-do-you-agree')}
          </div>
          {(obpitest || []).map((test, idx) => (
            <div className="testlist" key={idx}>
              <div className="question fs18">{test.text}</div>
              <div className="answer">
                <ul className="answer-list">
                  {(answerArray || []).map((answer, aidx) => (
                    <li
                      className="answer-list-item"
                      ref={(el) => (itemsRef.current[idx * 4 + aidx] = el)}
                      onClick={() => {
                        onClickSelectAnswerHandler(idx, aidx);
                      }}
                      value={{ idx, aidx }}
                    >
                      <span className="fs16to14">{t(answer)}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
          <div className="button-next">
            <button
              className={`common-button black fsbtn16 ${
                canDone ? 'active' : 'disabled'
              }`}
              onClick={addNextButtonHandler}
            >
              {page < maxDoneCnt - 1 ? t('btn.next') : t('btn.submit')}
              <RightArrow />
            </button>
          </div>
        </div>
      </div>
    </main>
  );
};

export default OBPITest;
