import React from 'react';
import { Editor } from '@tinymce/tinymce-react';

const TinyEditor = React.forwardRef(({ onEditorChange, onKeyUp }, ref) => (
  <Editor
    tinymceScriptSrc={`${process.env.PUBLIC_URL}/tinymce/tinymce.min.js`}
    onInit={(event, editor) => (ref.current = editor)}
    // initialValue="<p>This is the initial content of the editor.</p>"
    onEditorChange={onEditorChange}
    // onKeyPress={onKeyPress}
    onKeyUp={onKeyUp}
    init={{
      // height: 500,
      menubar: false,
      plugins: [
        'advlist',
        'autolink',
        'lists',
        'link',
        'image',
        'charmap',
        'anchor',
        'searchreplace',
        'visualblocks',
        'code',
        'fullscreen',
        'insertdatetime',
        'media',
        'table',
        'preview',
        // 'help',
        // 'wordcount',
      ],
      toolbar:
        'undo redo' +
        '| blocks' +
        '| fullscreen' +
        '| bold italic forecolor' +
        '| image' +
        '| alignleft aligncenter alignright alignjustify' +
        '| bullist numlist outdent indent' +
        '| removeformat ' +
        // '| help' +
        '',
      content_style:
        'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
    }}
  />
));

export default TinyEditor;
