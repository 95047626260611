import { t } from 'i18next';
import React from 'react';
import Select from 'react-select';
import { languageOptions } from './languageOptions';

function LanguagesDropdown({
  onSelectChange,
  selectedCategory,
  objectiveTests,
}) {
  const [selectedOptionIdx, setSelectedOptionIdx] = React.useState(0);
  const [value, setValue] = React.useState(null);

  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
      ...styles,
      backgroundColor: isFocused ? '#3294EE' : null,
      color: isFocused ? '#FFFFFF' : '#2E3338',
    }),
  };

  React.useEffect(() => {
    const _selectOptionIdx = 0;
    const _selectValue = null;
    // languageOptions.forEach((option, idx) => {
    //   if(option.id === objectiveTests[selectedCategory].codeLanguage) {
    //     _selectOptionIdx = idx;
    //     _selectValue = option;
    //     return;
    //   }
    // });
    setSelectedOptionIdx(_selectOptionIdx || 0);
    setValue(_selectValue);
  }, [selectedCategory]);

  React.useEffect(() => {
    setSelectedOptionIdx(objectiveTests?.codeLanguage || 0);
  }, [objectiveTests]);

  const onSelectChangeHandler = (id, idx) => {
    // objectiveTests[idx].codeLanguage = id;
    // console.log(id, idx);
    onSelectChange(id, idx);

    let _selectOptionIdx = 0;
    let _selectValue = null;
    languageOptions.forEach((option, idx) => {
      if (option.id === id) {
        _selectOptionIdx = idx;
        _selectValue = option;
      }
    });
    setSelectedOptionIdx(_selectOptionIdx);
    setValue(_selectValue);
  };
  return (
    <Select
      placeholder={t('title.selected-placeholder')}
      options={languageOptions}
      // defaultValue={}
      onChange={(selectedOption) =>
        onSelectChangeHandler(selectedOption.id, selectedCategory)
      }
      value={value}
      styles={colourStyles}
    />
  );
}

export default LanguagesDropdown;
