import { nextQuestion } from './question';

export async function englishListeningQuestion(previous) {
  const baseUrl = `${process.env.REACT_APP_IMATE_URL}/images/audio/englishListening`;
  /** ==========
 * 문제
============== */
  const questionDifficulty = {
    0: [
      'Where do you shop for groceries?',
      5,
      {
        audio: [
          [
            `${baseUrl}/Level-3/Level-3-Desc-1~3_Listen_and_choose_the_best_respond_to_the_question.mp3`,
            `${baseUrl}/Common-Voice/English-Question.mp3`,
            `${baseUrl}/Common-Voice/English-1.mp3`,
            `${baseUrl}/Level-3/Level-3-Q-1_Where_do_you_shop_for_groceries.mp3`,
            `${baseUrl}/Common-Voice/English-A.mp3`,
            `${baseUrl}/Level-3/Level-3-Q-1-1_Theres_a_market_nearby.mp3`,
            `${baseUrl}/Common-Voice/English-B.mp3`,
            `${baseUrl}/Level-3/Level-3-Q-1-2_I’ve_got_milk_and_vegetables.mp3`,
            `${baseUrl}/Common-Voice/English-C.mp3`,
            `${baseUrl}/Level-3/Level-3-Q-1-3_I_shop_there_twice_a_week.mp3`,
            `${baseUrl}/Common-Voice/English-D.mp3`,
            `${baseUrl}/Level-3/Level-3-Q-1-4_Usually_on_the_weekend.mp3`,
          ],
        ],
      },
    ],
    1: [
      'How much do you want to spend on a new car?',
      5,
      {
        audio: [
          [
            `${baseUrl}/Level-3/Level-3-Desc-1~3_Listen_and_choose_the_best_respond_to_the_question.mp3`,
            `${baseUrl}/Common-Voice/English-Question.mp3`,
            `${baseUrl}/Common-Voice/English-1.mp3`,
            `${baseUrl}/Level-3/Level-3-Q-2_How_much_do_you_want_to_spend_on_a_new_car.mp3`,
            `${baseUrl}/Common-Voice/English-A.mp3`,
            `${baseUrl}/Level-3/Level-3-Q-2-1_A_sport_car.mp3`,
            `${baseUrl}/Common-Voice/English-B.mp3`,
            `${baseUrl}/Level-3/Level-3-Q-2-2_Its_a_good_price.mp3`,
            `${baseUrl}/Common-Voice/English-C.mp3`,
            `${baseUrl}/Level-3/Level-3-Q-2-3_In_cash.mp3`,
            `${baseUrl}/Common-Voice/English-D.mp3`,
            `${baseUrl}/Level-3/Level-3-Q-2-4_Not_more_than_18000_.mp3`,
          ],
        ],
      },
    ],
    2: [
      'Would you like to join us for dinner tonight?',
      5,
      {
        audio: [
          [
            `${baseUrl}/Level-3/Level-3-Desc-1~3_Listen_and_choose_the_best_respond_to_the_question.mp3`,
            `${baseUrl}/Common-Voice/English-Question.mp3`,
            `${baseUrl}/Common-Voice/English-1.mp3`,
            `${baseUrl}/Level-3/Level-3-Q-3_Would_you_like_to_join_us_for_dinner_tonight.mp3`,
            `${baseUrl}/Common-Voice/English-A.mp3`,
            `${baseUrl}/Level-3/Level-3-Q-3-1_That_wont_be_necessary.mp3`,
            `${baseUrl}/Common-Voice/English-B.mp3`,
            `${baseUrl}/Level-3/Level-3-Q-3-2_Id_love_to_but_I_have_other_plans.mp3`,
            `${baseUrl}/Common-Voice/English-C.mp3`,
            `${baseUrl}/Level-3/Level-3-Q-3-3_Sure_I_enjoyed_it_very_much.mp3`,
            `${baseUrl}/Common-Voice/English-D.mp3`,
            `${baseUrl}/Level-3/Level-3-Q-3-4_Yes_please.mp3`,
          ],
        ],
      },
    ],
    3: [
      'Why does the woman want to meet the man?',
      5,
      {
        audio: [
          [
            `${baseUrl}/Level-3/Level-3-Desc-4~5_Listen_to_the_dialogue_and_choose_the_correct_answ.mp3`,
            `${baseUrl}/Level-3/Level-3-Dialog-4-1_I_wont_be_able_to_meet_you_tonight_Ive_got_to_g.mp3`,
            `${baseUrl}/Level-3/Level-3-Dialog-4-2_How_about_getting_together_tomorrow_instead_We_re.mp3`,
            `${baseUrl}/Level-3/Level-3-Dialog-4-3_I_know_Lets_meet_the_day_after_tomorrow_I_know_.mp3`,
            `${baseUrl}/Common-Voice/English-Question.mp3`,
            `${baseUrl}/Common-Voice/English-1.mp3`,
            `${baseUrl}/Level-3/Level-3-Q-4_Why_does_the_woman_want_to_meet_the_man.mp3`,
            `${baseUrl}/Common-Voice/English-A.mp3`,
            `${baseUrl}/Level-3/Level-3-Q-4-1_To_finish_work_together.mp3`,
            `${baseUrl}/Common-Voice/English-B.mp3`,
            `${baseUrl}/Level-3/Level-3-Q-4-2_To_have_his_suit_made.mp3`,
            `${baseUrl}/Common-Voice/English-C.mp3`,
            `${baseUrl}/Level-3/Level-3-Q-4-3_To_discuss_a_trip.mp3`,
            `${baseUrl}/Common-Voice/English-D.mp3`,
            `${baseUrl}/Level-3/Level-3-Q-4-4_To_have_dinner.mp3`,
          ],
        ],
      },
    ],
    4: [
      'Where are the speakers?',
      5,
      {
        audio: [
          [
            `${baseUrl}/Level-3/Level-3-Dialog-5-1_I_dont_know_why_we_had_to_get_here_so_early_Wev.mp3`,
            `${baseUrl}/Level-3/Level-3-Dialog-5-2_Dont_worry_It_wont_be_much_longer_The_train_wi.mp3`,
            `${baseUrl}/Level-3/Level-3-Dialog-5-3_I_hope_I_can_get_a_window_seat_Id_like_to_take_a.mp3`,
            `${baseUrl}/Common-Voice/English-Question.mp3`,
            `${baseUrl}/Common-Voice/English-2.mp3`,
            `${baseUrl}/Level-3/Level-3-Q-5_Where_are_the_speakers.mp3`,
            `${baseUrl}/Common-Voice/English-A.mp3`,
            `${baseUrl}/Level-3/Level-3-Q-5-1_In_a_bus.mp3`,
            `${baseUrl}/Common-Voice/English-B.mp3`,
            `${baseUrl}/Level-3/Level-3-Q-5-2_At_the_train_station.mp3`,
            `${baseUrl}/Common-Voice/English-C.mp3`,
            `${baseUrl}/Level-3/Level-3-Q-5-3_At_a_travel_agency.mp3`,
            `${baseUrl}/Common-Voice/English-D.mp3`,
            `${baseUrl}/Level-3/Level-3-Q-5-4_At_the_airport.mp3`,
          ],
        ],
      },
    ],
    5: [
      'Where did you find this sales data?',
      10,
      {
        audio: [
          [
            `${baseUrl}/Level-4/Level-4-Desc-1~2_Listen_and_choose_the_best_respond_to_the_question.mp3`,
            `${baseUrl}/Common-Voice/English-Question.mp3`,
            `${baseUrl}/Common-Voice/English-1.mp3`,
            `${baseUrl}/Level-4/Level-4-Q-1_Where_did_you_find_this_sales_data.mp3`,
            `${baseUrl}/Common-Voice/English-A.mp3`,
            `${baseUrl}/Level-4/Level-4-Q-1-1_I_sold_the_most_last_year.mp3`,
            `${baseUrl}/Common-Voice/English-B.mp3`,
            `${baseUrl}/Level-4/Level-4-Q-1-2_On_the_twentieth_of_June.mp3`,
            `${baseUrl}/Common-Voice/English-C.mp3`,
            `${baseUrl}/Level-4/Level-4-Q-1-3_On_the_Internet.mp3`,
            `${baseUrl}/Common-Voice/English-D.mp3`,
            `${baseUrl}/Level-4/Level-4-Q-1-4_Its_outdated.mp3`,
          ],
        ],
      },
    ],
    6: [
      'You’ve hired a new accountant, haven’t you?',
      10,
      {
        audio: [
          [
            `${baseUrl}/Level-4/Level-4-Desc-1~2_Listen_and_choose_the_best_respond_to_the_question.mp3`,
            `${baseUrl}/Common-Voice/English-Question.mp3`,
            `${baseUrl}/Common-Voice/English-1.mp3`,
            `${baseUrl}/Level-4/Level-4-Q-2_Youve_hired_a_new_accountant_havent_you.mp3`,
            `${baseUrl}/Common-Voice/English-A.mp3`,
            `${baseUrl}/Level-4/Level-4-Q-2-1_Yes_its_on_the_second_floor.mp3`,
            `${baseUrl}/Common-Voice/English-B.mp3`,
            `${baseUrl}/Level-4/Level-4-Q-2-2_Yes_I_counted_them.mp3`,
            `${baseUrl}/Common-Voice/English-C.mp3`,
            `${baseUrl}/Level-4/Level-4-Q-2-3_Yes_he_started_on_Tuesday.mp3`,
            `${baseUrl}/Common-Voice/English-D.mp3`,
            `${baseUrl}/Level-4/Level-4-Q-2-4_No_I_dont_have_it.mp3`,
          ],
        ],
      },
    ],
    7: [
      'What does the woman suggest?',
      10,
      {
        audio: [
          [
            `${baseUrl}/Level-4/Level-4-Desc-3~5_Listen_to_the_dialogue_and_choose_the_correct_answ.mp3`,
            `${baseUrl}/Level-4/Level-4-Dialog-3-1_All_the_flights_are_booked_and_I_think_the_last_t.mp3`,
            `${baseUrl}/Level-4/Level-4-Dialog-3-2_Lets_just_rent_a_car_and_take_it_there_Northbroo.mp3`,
            `${baseUrl}/Level-4/Level-4-Dialog-3-3_I_guess_we_dont_have_much_choice.mp3`,
            `${baseUrl}/Common-Voice/English-Question.mp3`,
            `${baseUrl}/Common-Voice/English-1.mp3`,
            `${baseUrl}/Level-4/Level-4-Q-3_What_does_the_woman_suggest.mp3`,
            `${baseUrl}/Common-Voice/English-A.mp3`,
            `${baseUrl}/Level-4/Level-4-Q-3-1_Reserving_some_plane_tickets_.mp3`,
            `${baseUrl}/Common-Voice/English-B.mp3`,
            `${baseUrl}/Level-4/Level-4-Q-3-2_Taking_a_train_to_Northbrook.mp3`,
            `${baseUrl}/Common-Voice/English-C.mp3`,
            `${baseUrl}/Level-4/Level-4-Q-3-3_Chartering_a_bus_to_the_destination.mp3`,
            `${baseUrl}/Common-Voice/English-D.mp3`,
            `${baseUrl}/Level-4/Level-4-Q-3-4_Driving_to_the_city.mp3`,
          ],
        ],
      },
    ],
    8: [
      'Why is the man unhappy?',
      10,
      {
        audio: [
          [
            `${baseUrl}/Level-4/Level-4-Desc-3~5_Listen_to_the_dialogue_and_choose_the_correct_answ.mp3`,
            `${baseUrl}/Level-4/Level-4-Dialog-4-1_Im_upset_not_because_the_waiter_brought_me_the_wr.mp3`,
            `${baseUrl}/Level-4/Level-4-Dialog-4-2_Its_our_policy_to_do_that_sir_It_was_stated_on_t.mp3`,
            `${baseUrl}/Level-4/Level-4-Dialog-4-3_Then_this_is_the_last_time_Im_coming_to_this_rest.mp3`,
            `${baseUrl}/Common-Voice/English-Question.mp3`,
            `${baseUrl}/Common-Voice/English-1.mp3`,
            `${baseUrl}/Level-4/Level-4-Q-4_Why_is_the_man_unhappy.mp3`,
            `${baseUrl}/Common-Voice/English-A.mp3`,
            `${baseUrl}/Level-4/Level-4-Q-4-1_His_food_was_bad.mp3`,
            `${baseUrl}/Common-Voice/English-B.mp3`,
            `${baseUrl}/Level-4/Level-4-Q-4-2_The_service_was_poor.mp3`,
            `${baseUrl}/Common-Voice/English-C.mp3`,
            `${baseUrl}/Level-4/Level-4-Q-4-3_He_waited_for_too_long.mp3`,
            `${baseUrl}/Common-Voice/English-D.mp3`,
            `${baseUrl}/Level-4/Level-4-Q-4-4_He_doesnt_like_the_policy.mp3`,
          ],
        ],
      },
    ],
    9: [
      'Why didn’t the woman buy the curtains?',
      10,
      {
        audio: [
          [
            `${baseUrl}/Level-4/Level-4-Desc-3~5_Listen_to_the_dialogue_and_choose_the_correct_answ.mp3`,
            `${baseUrl}/Level-4/Level-4-Dialog-5-1_I_saw_a_beautiful_set_of_curtains_that_would_look_.mp3`,
            `${baseUrl}/Level-4/Level-4-Dialog-5-2_The_curtains_we_have_in_the_kitchen_still_look_cle.mp3`,
            `${baseUrl}/Level-4/Level-4-Dialog-5-3_Because_I_always_wanted_them_to_be_brighter_I_gue.mp3`,
            `${baseUrl}/Common-Voice/English-Question.mp3`,
            `${baseUrl}/Common-Voice/English-1.mp3`,
            `${baseUrl}/Level-4/Level-4-Q-5_Why_didnt_the_woman_buy_the_curtains.mp3`,
            `${baseUrl}/Common-Voice/English-A.mp3`,
            `${baseUrl}/Level-4/Level-4-Q-5-1_She_didnt_like_the_color.mp3`,
            `${baseUrl}/Common-Voice/English-B.mp3`,
            `${baseUrl}/Level-4/Level-4-Q-5-2_She_couldnt_find_the_right_size.mp3`,
            `${baseUrl}/Common-Voice/English-C.mp3`,
            `${baseUrl}/Level-4/Level-4-Q-5-3_They_were_too_expensive.mp3`,
            `${baseUrl}/Common-Voice/English-D.mp3`,
            `${baseUrl}/Level-4/Level-4-Q-5-4_She_already_has_curtains_in_the_kitchen.mp3`,
          ],
        ],
      },
    ],
    10: [
      'That was an impressive presentation she gave.',
      10,
      {
        audio: [
          [
            `${baseUrl}/Level-5/Level-5-Desc-1_Listen_and_choose_the_best_respond_to_the_question.mp3`,
            `${baseUrl}/Common-Voice/English-Question.mp3`,
            `${baseUrl}/Common-Voice/English-1.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-1_That_was_an_impressive_presentation_she_gave.mp3`,
            `${baseUrl}/Common-Voice/English-A.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-1-1_Shes_always_received_everyones_attention.mp3`,
            `${baseUrl}/Common-Voice/English-B.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-1-2_Thank_you_for_the_lovely_gift.mp3`,
            `${baseUrl}/Common-Voice/English-C.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-1-3_I_agree_It_was_very_informative.mp3`,
            `${baseUrl}/Common-Voice/English-D.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-1-4_Being_on_time_is_the_key.mp3`,
          ],
        ],
      },
    ],
    11: [
      'What is the woman doing?',
      15,
      {
        audio: [
          [
            `${baseUrl}/Level-5/Level-5-Desc-2~5_Listen_to_the_dialogue_and_choose_the_correct_answ.mp3`,
            `${baseUrl}/Level-5/Level-5-Dialog-2~3-1_Would_you_mind_answering_a_couple_of_questions_abo.mp3`,
            `${baseUrl}/Level-5/Level-5-Dialog-2~3-2_Okay_as_long_as_it_doesnt_take_too_long.mp3`,
            `${baseUrl}/Level-5/Level-5-Dialog-2~3-3_How_was_the_customer_service_and_did_you_find_eve.mp3`,
            `${baseUrl}/Level-5/Level-5-Dialog-2~3-4_The_clerk_that_helped_me_was_very_friendly_She_he.mp3`,
            `${baseUrl}/Common-Voice/English-Question.mp3`,
            `${baseUrl}/Common-Voice/English-1.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-2_What_is_the_woman_doing_.mp3`,
            `${baseUrl}/Common-Voice/English-A.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-2-1_Selling_items.mp3`,
            `${baseUrl}/Common-Voice/English-B.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-2-2_Stocking_shelves.mp3`,
            `${baseUrl}/Common-Voice/English-C.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-2-3_Telephoning_suppliers.mp3`,
            `${baseUrl}/Common-Voice/English-D.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-2-4_Conducting_a_survey.mp3`,
          ],
        ],
      },
    ],
    12: [
      'What did the man purchase?',
      15,
      {
        audio: [
          [
            `${baseUrl}/Common-Voice/English-Question.mp3`,
            `${baseUrl}/Common-Voice/English-2.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-3_What_did_the_man_purchase.mp3`,
            `${baseUrl}/Common-Voice/English-A.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-3-1_Clothing.mp3`,
            `${baseUrl}/Common-Voice/English-B.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-3-2_Reading_material.mp3`,
            `${baseUrl}/Common-Voice/English-C.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-3-3_Travel_tickets.mp3`,
            `${baseUrl}/Common-Voice/English-D.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-3-4_Plumbing_equipment.mp3`,
          ],
        ],
      },
    ],
    13: [
      'What can be inferred from the conversation?',
      15,
      {
        audio: [
          [
            `${baseUrl}/Level-5/Level-5-Desc-2~5_Listen_to_the_dialogue_and_choose_the_correct_answ.mp3`,
            `${baseUrl}/Level-5/Level-5-Dialog-4~5-1_Dont_you_think_the_advertisement_for_this_months.mp3`,
            `${baseUrl}/Level-5/Level-5-Dialog-4~5-2_The_picture_and_text_go_together_well_Its_a_much.mp3`,
            `${baseUrl}/Level-5/Level-5-Dialog-4~5-3_We_hired_a_new_graphic_designer_last_month_I_thin.mp3`,
            `${baseUrl}/Level-5/Level-5-Dialog-4~5-4_I_think_it_looks_great_too.mp3`,
            `${baseUrl}/Common-Voice/English-Question.mp3`,
            `${baseUrl}/Common-Voice/English-1.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-4_What_can_be_inferred_from_the_conversation.mp3`,
            `${baseUrl}/Common-Voice/English-A.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-4-1_The_man_is_pleased_with_the_advertisement.mp3`,
            `${baseUrl}/Common-Voice/English-B.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-4-2_What_is_the_woman_doing.mp3`,
            `${baseUrl}/Common-Voice/English-C.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-4-3_They_dont_agree_with_something.mp3`,
            `${baseUrl}/Common-Voice/English-D.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-4-4_They_want_to_take_some_photos_together.mp3`,
          ],
        ],
      },
    ],
    14: [
      'How did the advertisement change?',
      15,
      {
        audio: [
          [
            `${baseUrl}/Common-Voice/English-Question.mp3`,
            `${baseUrl}/Common-Voice/English-2.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-5_How_did_the_advertisement_change.mp3`,
            `${baseUrl}/Common-Voice/English-A.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-5-1_It_featured_a_new_model.mp3`,
            `${baseUrl}/Common-Voice/English-B.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-5-2_It_doubled_its_size.mp3`,
            `${baseUrl}/Common-Voice/English-C.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-5-3_It_used_a_different_design.mp3`,
            `${baseUrl}/Common-Voice/English-D.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-5-4_It_eliminated_the_text.mp3`,
          ],
        ],
      },
    ],
    15: [
      'What does the man want the woman to do?',
      20,
      {
        audio: [
          [
            `${baseUrl}/Level-6/Level-6-Desc-1~4_Listen_to_the_dialogue_and_choose_the_correct_answ.mp3`,
            `${baseUrl}/Level-6/Level-6-Dialog-1~2-1_Youll_be_able_to_finish_the_report_by_Tuesday_wo.mp3`,
            `${baseUrl}/Level-6/Level-6-Dialog-1~2-2_Im_afraid_I_wont_Itll_be_done_by_Thursday_for_.mp3`,
            `${baseUrl}/Level-6/Level-6-Dialog-1~2-3_Please_dont_take_too_long_We_need_some_time_to_p.mp3`,
            `${baseUrl}/Level-6/Level-6-Dialog-1~2-4_Dont_worry_well_have_plenty_of_time_to_get_read.mp3`,
            `${baseUrl}/Common-Voice/English-Question.mp3`,
            `${baseUrl}/Common-Voice/English-1.mp3`,
            `${baseUrl}/Level-6/Level-6-Q-1_What_does_the_man_want_the_woman_to_do.mp3`,
            `${baseUrl}/Common-Voice/English-A.mp3`,
            `${baseUrl}/Level-6/Level-6-Q-1-1_Make_a_presentation.mp3`,
            `${baseUrl}/Common-Voice/English-B.mp3`,
            `${baseUrl}/Level-6/Level-6-Q-1-2_Attend_a_conference.mp3`,
            `${baseUrl}/Common-Voice/English-C.mp3`,
            `${baseUrl}/Level-6/Level-6-Q-1-3_Prepare_for_the_meeting.mp3`,
            `${baseUrl}/Common-Voice/English-D.mp3`,
            `${baseUrl}/Level-6/Level-6-Q-1-4_Complete_a_report.mp3`,
          ],
        ],
      },
    ],
    16: [
      'Where are the speakers?',
      20,
      {
        audio: [
          [
            `${baseUrl}/Common-Voice/English-Question.mp3`,
            `${baseUrl}/Common-Voice/English-2.mp3`,
            `${baseUrl}/Level-6/Level-6-Q-2_Where_are_the_speakers.mp3`,
            `${baseUrl}/Common-Voice/English-A.mp3`,
            `${baseUrl}/Level-6/Level-6-Q-2-1_At_a_restaurant.mp3`,
            `${baseUrl}/Common-Voice/English-B.mp3`,
            `${baseUrl}/Level-6/Level-6-Q-2-2_At_an_airport.mp3`,
            `${baseUrl}/Common-Voice/English-C.mp3`,
            `${baseUrl}/Level-6/Level-6-Q-2-3_At_an_office.mp3`,
            `${baseUrl}/Common-Voice/English-D.mp3`,
            `${baseUrl}/Level-6/Level-6-Q-2-4_At_a_hospital.mp3`,
          ],
        ],
      },
    ],
    17: [
      'What was the problem with the credit card bill?',
      20,
      {
        audio: [
          [
            `${baseUrl}/Level-6/Level-6-Desc-1~4_Listen_to_the_dialogue_and_choose_the_correct_answ.mp3`,
            `${baseUrl}/Level-6/Level-6-Dialog-3~4-1_Why_was_there_an_extra_charge_on_my_credit_card_bi.mp3`,
            `${baseUrl}/Level-6/Level-6-Dialog-3~4-2_According_to_our_records_you_went_over_your_spend.mp3`,
            `${baseUrl}/Level-6/Level-6-Dialog-3~4-3_I_see_I_probably_did_that_while_on_vacation_I_wa.mp3`,
            `${baseUrl}/Level-6/Level-6-Dialog-3~4-4_Since_youre_going_to_pay_the_full_amount_now_Ill.mp3`,
            `${baseUrl}/Common-Voice/English-Question.mp3`,
            `${baseUrl}/Common-Voice/English-1.mp3`,
            `${baseUrl}/Level-6/Level-6-Q-3_What_was_the_problem_with_the_credit_card_bill.mp3`,
            `${baseUrl}/Common-Voice/English-A.mp3`,
            `${baseUrl}/Level-6/Level-6-Q-3-1_It_didnt_arrive_in_the_mail.mp3`,
            `${baseUrl}/Common-Voice/English-B.mp3`,
            `${baseUrl}/Level-6/Level-6-Q-3-2_The_man_lost_it_while_on_vacation.mp3`,
            `${baseUrl}/Common-Voice/English-C.mp3`,
            `${baseUrl}/Level-6/Level-6-Q-3-3_There_was_an_extra_charge_on_it.mp3`,
            `${baseUrl}/Common-Voice/English-D.mp3`,
            `${baseUrl}/Level-6/Level-6-Q-3-4_The_man_wanted_two_copies_of_it.mp3`,
          ],
        ],
      },
    ],
    18: [
      'What does the woman offer to do?',
      20,
      {
        audio: [
          [
            `${baseUrl}/Common-Voice/English-Question.mp3`,
            `${baseUrl}/Common-Voice/English-2.mp3`,
            `${baseUrl}/Level-6/Level-6-Q-4_What_does_the_woman_offer_to_do.mp3`,
            `${baseUrl}/Common-Voice/English-A.mp3`,
            `${baseUrl}/Level-6/Level-6-Q-4-1_Resend_his_bill.mp3`,
            `${baseUrl}/Common-Voice/English-B.mp3`,
            `${baseUrl}/Level-6/Level-6-Q-4-2_Change_his_billing_address.mp3`,
            `${baseUrl}/Common-Voice/English-C.mp3`,
            `${baseUrl}/Level-6/Level-6-Q-4-3_Change_his_payment_dates.mp3`,
            `${baseUrl}/Common-Voice/English-D.mp3`,
            `${baseUrl}/Level-6/Level-6-Q-4-4_Remove_the_penalty_charge.mp3`,
          ],
        ],
      },
    ],
    19: [
      'Why has the pool fee been increased?',
      20,
      {
        audio: [
          [
            `${baseUrl}/Level-6/Level-6-Desc-5_Listen_to_the_following_public_announcement_and_an.mp3`,
            `${baseUrl}/Level-6/Level-6-Dialog-5_Hudsonville_Parks_Department_wants_to_remind_you_t.mp3`,
            `${baseUrl}/Common-Voice/English-Question.mp3`,
            `${baseUrl}/Common-Voice/English-1.mp3`,
            `${baseUrl}/Level-6/Level-6-Q-5_Why_has_the_pool_fee_been_increased.mp3`,
            `${baseUrl}/Common-Voice/English-A.mp3`,
            `${baseUrl}/Level-6/Level-6-Q-5-1_To_pay_for_a_new_waterslide.mp3`,
            `${baseUrl}/Common-Voice/English-B.mp3`,
            `${baseUrl}/Level-6/Level-6-Q-5-2_To_pay_for_more_workers.mp3`,
            `${baseUrl}/Common-Voice/English-C.mp3`,
            `${baseUrl}/Level-6/Level-6-Q-5-3_To_pay_for_a_new_playground.mp3`,
            `${baseUrl}/Common-Voice/English-D.mp3`,
            `${baseUrl}/Level-6/Level-6-Q-5-4_To_pay_for_repairs_to_the_waterslide.mp3`,
          ],
        ],
      },
    ],
    20: [
      'Why is this announcement being made?',
      25,
      {
        audio: [
          [
            `${baseUrl}/Level-7/Level-7-Desc-1~3_Listen_to_the_announcement_and_answer_questions_1_.mp3`,
            `${baseUrl}/Level-7/Level-7-Dialog-1~3_We_want_to_do_everything_possible_to_make_your_Bet.mp3`,
            `${baseUrl}/Common-Voice/English-Question.mp3`,
            `${baseUrl}/Common-Voice/English-1.mp3`,
            `${baseUrl}/Level-7/Level-7-Q-1_Why_is_this_announcement_being_made.mp3`,
            `${baseUrl}/Common-Voice/English-A.mp3`,
            `${baseUrl}/Level-7/Level-7-Q-1-1_To_get_feedback_from_customers.mp3`,
            `${baseUrl}/Common-Voice/English-B.mp3`,
            `${baseUrl}/Level-7/Level-7-Q-1-2_To_celebrate_the_stores_anniversary.mp3`,
            `${baseUrl}/Common-Voice/English-C.mp3`,
            `${baseUrl}/Level-7/Level-7-Q-1-3_To_announce_its_grand_sale.mp3`,
            `${baseUrl}/Common-Voice/English-D.mp3`,
            `${baseUrl}/Level-7/Level-7-Q-1-4_To_notify_the_customers_of_upcoming_events.mp3`,
          ],
        ],
      },
    ],
    21: [
      'What are the customers being asked to do?',
      25,
      {
        audio: [
          [
            `${baseUrl}/Common-Voice/English-Question.mp3`,
            `${baseUrl}/Common-Voice/English-2.mp3`,
            `${baseUrl}/Level-7/Level-7-Q-2_What_are_the_customers_being_asked_to_do.mp3`,
            `${baseUrl}/Common-Voice/English-A.mp3`,
            `${baseUrl}/Level-7/Level-7-Q-2-1_Participate_in_a_drawing.mp3`,
            `${baseUrl}/Common-Voice/English-B.mp3`,
            `${baseUrl}/Level-7/Level-7-Q-2-2_Fill_out_a_survey_form.mp3`,
            `${baseUrl}/Common-Voice/English-C.mp3`,
            `${baseUrl}/Level-7/Level-7-Q-2-3_Use_a_different_entrance.mp3`,
            `${baseUrl}/Common-Voice/English-D.mp3`,
            `${baseUrl}/Level-7/Level-7-Q-2-4_Register_for_membership.mp3`,
          ],
        ],
      },
    ],
    22: [
      'What kind of business is Betsy & Shell?',
      25,
      {
        audio: [
          [
            `${baseUrl}/Common-Voice/English-Question.mp3`,
            `${baseUrl}/Common-Voice/English-3.mp3`,
            `${baseUrl}/Level-7/Level-7-Q-3_What_kind_of_business_is_Betsy__Shell.mp3`,
            `${baseUrl}/Common-Voice/English-A.mp3`,
            `${baseUrl}/Level-7/Level-7-Q-3-1_Catering.mp3`,
            `${baseUrl}/Common-Voice/English-B.mp3`,
            `${baseUrl}/Level-7/Level-7-Q-3-2_Gardening.mp3`,
            `${baseUrl}/Common-Voice/English-C.mp3`,
            `${baseUrl}/Level-7/Level-7-Q-3-3_Delivery.mp3`,
            `${baseUrl}/Common-Voice/English-D.mp3`,
            `${baseUrl}/Level-7/Level-7-Q-3-4_Grocery.mp3`,
          ],
        ],
      },
    ],
    23: [
      'Who is the speaker talking to?',
      25,
      {
        audio: [
          [
            `${baseUrl}/Level-7/Level-7-Desc-4~5_Listen_to_the_speech_and_answer_questions_4_-_5.mp3`,
            `${baseUrl}/Level-7/Level-7-Dialog-4~5_Now_since_youll_be_starting_at_our_company_in_a_.mp3`,
            `${baseUrl}/Common-Voice/English-Question.mp3`,
            `${baseUrl}/Common-Voice/English-1.mp3`,
            `${baseUrl}/Level-7/Level-7-Q-4_Who_is_the_speaker_talking_to.mp3`,
            `${baseUrl}/Common-Voice/English-A.mp3`,
            `${baseUrl}/Level-7/Level-7-Q-4-1_A_worker_who_has_resigned.mp3`,
            `${baseUrl}/Common-Voice/English-B.mp3`,
            `${baseUrl}/Level-7/Level-7-Q-4-2_Job_applicants.mp3`,
            `${baseUrl}/Common-Voice/English-C.mp3`,
            `${baseUrl}/Level-7/Level-7-Q-4-3_New_employees.mp3`,
            `${baseUrl}/Common-Voice/English-D.mp3`,
            `${baseUrl}/Level-7/Level-7-Q-4-4_The_customers.mp3`,
          ],
        ],
      },
    ],
    24: [
      'Why does the company use the 401(k) plan?',
      25,
      {
        audio: [
          [
            `${baseUrl}/Common-Voice/English-Question.mp3`,
            `${baseUrl}/Common-Voice/English-2.mp3`,
            `${baseUrl}/Level-7/Level-7-Q-5_Why_does_the_company_use_the_401k_plan.mp3`,
            `${baseUrl}/Common-Voice/English-A.mp3`,
            `${baseUrl}/Level-7/Level-7-Q-5-1_Its_more_convenient.mp3`,
            `${baseUrl}/Common-Voice/English-B.mp3`,
            `${baseUrl}/Level-7/Level-7-Q-5-2_Its_faster.mp3`,
            `${baseUrl}/Common-Voice/English-C.mp3`,
            `${baseUrl}/Level-7/Level-7-Q-5-3_Employees_chose_it.mp3`,
            `${baseUrl}/Common-Voice/English-D.mp3`,
            `${baseUrl}/Level-7/Level-7-Q-5-4_Its_cheaper.mp3`,
          ],
        ],
      },
    ],
  };

  /** ==========
 * 문제 선택지
============== */
  const choices = {
    0: [
      ['A', 1],
      ['B', 0],
      ['C', 0],
      ['D', 0],
    ],
    1: [
      ['A', 0],
      ['B', 0],
      ['C', 0],
      ['D', 1],
    ],
    2: [
      ['A', 0],
      ['B', 1],
      ['C', 0],
      ['D', 0],
    ],
    3: [
      ['A', 0],
      ['B', 0],
      ['C', 1],
      ['D', 0],
    ],
    4: [
      ['A', 0],
      ['B', 1],
      ['C', 0],
      ['D', 0],
    ],
    5: [
      ['A', 0],
      ['B', 0],
      ['C', 1],
      ['D', 0],
    ],
    6: [
      ['A', 0],
      ['B', 0],
      ['C', 1],
      ['D', 0],
    ],
    7: [
      ['A', 0],
      ['B', 0],
      ['C', 0],
      ['D', 1],
    ],
    8: [
      ['A', 0],
      ['B', 0],
      ['C', 0],
      ['D', 1],
    ],
    9: [
      ['A', 0],
      ['B', 0],
      ['C', 1],
      ['D', 0],
    ],
    10: [
      ['A', 0],
      ['B', 0],
      ['C', 1],
      ['D', 0],
    ],
    11: [
      ['A', 0],
      ['B', 0],
      ['C', 0],
      ['D', 1],
    ],
    12: [
      ['A', 0],
      ['B', 1],
      ['C', 0],
      ['D', 0],
    ],
    13: [
      ['A', 1],
      ['B', 0],
      ['C', 0],
      ['D', 0],
    ],
    14: [
      ['A', 0],
      ['B', 0],
      ['C', 1],
      ['D', 0],
    ],
    15: [
      ['A', 0],
      ['B', 0],
      ['C', 0],
      ['D', 1],
    ],
    16: [
      ['A', 0],
      ['B', 0],
      ['C', 1],
      ['D', 0],
    ],
    17: [
      ['A', 0],
      ['B', 0],
      ['C', 1],
      ['D', 0],
    ],
    18: [
      ['A', 0],
      ['B', 0],
      ['C', 0],
      ['D', 1],
    ],
    19: [
      ['A', 1],
      ['B', 0],
      ['C', 0],
      ['D', 0],
    ],
    20: [
      ['A', 1],
      ['B', 0],
      ['C', 0],
      ['D', 0],
    ],
    21: [
      ['A', 0],
      ['B', 1],
      ['C', 0],
      ['D', 0],
    ],
    22: [
      ['A', 0],
      ['B', 1],
      ['C', 0],
      ['D', 0],
    ],
    23: [
      ['A', 0],
      ['B', 1],
      ['C', 0],
      ['D', 0],
    ],
    24: [
      ['A', 0],
      ['B', 0],
      ['C', 0],
      ['D', 1],
    ],
  };

  /** ==========
 * 난이도
============== */
  const difficultyLevels = {
    5: 'Beginner',
    10: 'Intermediate',
    15: 'Intermediate',
    20: 'Advanced',
    25: 'Advanced',
  };

  /** ==========
 * 지문 그룹
============== */
  const passageQuestions = {
    0: [
      ['Listen and choose the best respond to the question or statement.'],
      [0],
    ],
    1: [
      ['Listen and choose the best respond to the question or statement.'],
      [1],
    ],
    2: [
      ['Listen and choose the best respond to the question or statement.'],
      [2],
    ],
    3: [
      [
        'Listen to the dialogue and choose the correct answer to the question.',
        'M: I won’t be able to meet you tonight. I’ve got to go pick up a suit that I had made.',
        'W: How about getting together tomorrow instead? We really should start planning for the trip to Arizona.',
        'M: I know. Let’s meet the day after tomorrow. I know you’re free and I will be able to finish work early as well.',
      ],
      [3, 4],
    ],
    4: [
      ['Listen and choose the best respond to the question or statement.'],
      [5],
    ],
    5: [
      ['Listen and choose the best respond to the question or statement.'],
      [6],
    ],
    6: [
      [
        'Listen to the dialogue and choose the correct answer to the question.',
        'M: All the flights are booked, and I think the last train has already left the station.',
        'W: Let’s just rent a car and take it there. Northbrook is only about four hours from here.',
        'M: I guess we don’t have much choice.',
      ],
      [7],
    ],
    7: [
      [
        'Listen to the dialogue and choose the correct answer to the question.',
        'M: I’m upset not because the waiter brought me the wrong food but because he added 20% to the bill for a tip.',
        'W: It’s our policy to do that sir. It was stated on the menu.',
        'M: Then this is the last time I’m coming to this restaurant.',
      ],
      [8],
    ],
    8: [
      [
        'Listen to the dialogue and choose the correct answer to the question.',
        'W: I saw a beautiful set of curtains that would look just wonderful in our kitchen yesterday, but I couldn’t afford them.',
        'M: The curtains we have in the kitchen still look clean and nice. Why would you want to change them?',
        'W: Because I always wanted them to be brighter. I guess I will have to look for something cheaper.',
      ],
      [9],
    ],
    9: [
      ['Listen and choose the best respond to the question or statement.'],
      [10],
    ],
    10: [
      [
        'Listen to the dialogue and choose the correct answer to each questions.',
        'WB: Would you mind answering a couple of questions about your shopping experience? When we’re done, I’ll give you a coupon for ten percent off your future purchases.',
        'MB: Okay, as long as it doesn’t take too long.',
        'WB: How was the customer service, and did you find everything you were looking for?',
        'MB: The clerk that helped me was very friendly. She helped me find the book I wanted to buy.',
      ],
      [11, 12],
    ],
    11: [
      [
        'Listen to the dialogue and choose the correct answer to each questions.',
        'MB: Don’t you think the advertisement for this month’s issue looks a lot better than the one we used last month?',
        'WA: The picture and text go together well. It’s a much clearer layout overall.',
        'MB: We hired a new graphic designer last month. I think he did a terrific job.',
        'WA: I think it looks great, too.',
      ],
      [13, 14],
    ],
    12: [
      [
        'Listen to the dialogue and choose the correct answer to each questions.',
        'MA: You’ll be able to finish the report by Tuesday, won’t you?',
        'WB: I’m afraid I won’t. It’ll be done by Thursday for sure, just in time for our weekend meeting',
        'MA: Please don’t take too long. We need some time to prepare for our presentation Friday afternoon.',
        'WB: Don’t worry, we’ll have plenty of time to get ready.',
      ],
      [15, 16],
    ],
    13: [
      [
        'Listen to the dialogue and choose the correct answer to each questions.',
        'MB: Why was there an extra charge on my credit card bill this month?',
        'WA: According to our records, you went over your spending limit.',
        'MB: I see. I probably did that while on vacation. I wasn’t watching my spending closely.',
        'WA: Since you’re going to pay the full amount now I’ll remove the over limit fee.',
      ],
      [17, 18],
    ],
    14: [
      [
        'Listen to the following public announcement and answer the question.',
        'Hudsonville Parks Department wants to remind you that fees for using the pool will increase this summer by twenty-five cents. The new cost for all users of the pool will be seventy-five cents. This is to pay for the new children’s waterslide that was installed this past spring. Because of the waterslide’s popularity, the number of patrons at the pool is much higher this year than last year.',
      ],
      [19],
    ],
    15: [
      [
        'Listen to the announcement and answer questions 1 - 3.',
        'We want to do everything possible to make your Betsy & Shell experience the best it can be. So please tell us what we are doing that makes you really happy, or if there’s anything else we could be doing better. Your feedback and ideas about your experience at Betsy & Shell are very important to us. If you can take a moment to fill out our customer survey form located next to the main gate today, we’d like to offer you a coupon that gives you 10% off of any gardening tools you purchase until the end of this month. Thank you again for your business at Betsy & Shell.',
      ],
      [20, 21, 22],
    ],
    16: [
      [
        'Listen to the speech and answer questions 4 - 5.',
        'Now, since you’ll be starting at our company in a few days, I wanted to talk to you about some of your benefits. The most important of which is your retirement plan. In the past, companies offered employees a pension after working twenty or thirty years. That gave the employee a percentage of their total salary earned at the company. Instead at this company we have the 401(k) program. This plan puts a small amount of your salary each week into an account. This money cannot be taxed and can be withdrawn should you ever leave the company. It’s a good plan and best of all, it’s cheaper for our company. Here is a booklet describing the plan in more detail.',
      ],
      [23, 24],
    ],
  };

  return nextQuestion(
    previous,
    questionDifficulty,
    choices,
    difficultyLevels,
    passageQuestions
  );
}
