import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';

import authReducer from './slices/authSlice';
import testtakerReducer from './slices/testtakerSlice';
import variableReducer from './slices/variableSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    testtaker: testtakerReducer,
    variable: variableReducer,
  },
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
});
