import {
  getFirestore,
  collection,
  doc,
  getDoc,
  getDocs,
  addDoc,
  setDoc,
  updateDoc,
  deleteDoc,
  query,
  where,
  Timestamp,
} from 'firebase/firestore';
import {
  getStorage,
  ref,
  uploadBytes,
  uploadBytesResumable,
  getDownloadURL,
} from 'firebase/storage';
import { getFunctions, httpsCallable } from 'firebase/functions';
import * as Sentry from '@sentry/react';
import { app } from './index';

const db = getFirestore(app);
const storage = getStorage(app);
const functions = getFunctions(app, 'asia-northeast1');

export const _getAssessmentInfo = ({ uid, aid }) =>
  new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(db, 'users', uid, 'assessments', aid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        return resolve({
          id: docSnap.id,
          ...docSnap.data(),
        });
      }
      throw new Error('empty_doc');
    } catch (e) {
      return reject(e);
    }
  });

export const _getCandidateInfo = ({ uid, aid, email }) =>
  new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(
        db,
        'users',
        uid,
        'assessments',
        aid,
        'candidates',
        email
      );
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        return resolve({
          id: docSnap.id,
          ...docSnap.data(),
        });
      }
        Sentry.captureMessage('_getCandidateInfo', {extra: {uid, aid, email}});
        throw new Error('empty_doc');
    } catch (e) {
      return reject(e);
    }
  });

export const _getAttributes = ({ sid, lang }) =>
  new Promise(async (resolve, reject) => {
    try {
      const collectionRef = collection(db, `skill_${lang}`, sid, 'values');
      const querySnap = await getDocs(collectionRef);

      const docs = new Array();
      querySnap.forEach((doc) => {
        docs.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      return resolve(docs);
    } catch (e) {
      return reject(e);
    }
  });

export const _getCultureAttributes = ({ sid, lang }) =>
  new Promise(async (resolve, reject) => {
    try {
      const collectionRef = collection(db, `skill_${lang}`, sid, 'cultures');
      const querySnapshot = await getDocs(collectionRef);

      const docs = new Array();
      querySnapshot.forEach((doc) => {
        docs.push({
          id: doc.id,
          ...doc.data(),
        });
      });

      return resolve(docs);
    } catch (e) {
      return reject(e);
    }
  });

export const _getTestInfo = ({ tid, lang }) =>
  new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(db, `test_${lang}`, tid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        return resolve({
          id: docSnap.id,
          ...docSnap.data(),
        });
      }
        Sentry.captureMessage('_getTestInfo', {extra: {tid, lang}});
        throw new Error('empty_doc');
    } catch (e) {
      return reject(e);
    }
  });

export const _getLibTestInfo = ({ tid, lang }) =>
  new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(db, `library_${lang}`, tid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        return resolve({
          id: docSnap.id,
          ...docSnap.data(),
        });
      }
        Sentry.captureMessage('_getLibTestInfo', {extra: {tid, lang}});
        throw new Error('empty_doc');
    } catch (e) {
      return reject(e);
    }
  });

export const _setResults = ({ uid, aid, cid, results }) =>
  new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(
        db,
        'users',
        uid,
        'assessments',
        aid,
        'candidates',
        cid
      );
      await setDoc(
        docRef,
        {
          progress: 1,
          results,
          answeredAt: Timestamp.now(),
        },
        {
          merge: true,
        }
      );
      return resolve(true);
    } catch (e) {
      return reject(e);
    }
  });

export const _uploadBytes = ({ uid, aid, cid, sid, fileName, bytes }) =>
  new Promise(async (resolve, reject) => {
    try {
      const storageRef = ref(
        storage,
        `testtaker/${uid}/${aid}/${cid}/${sid}/${fileName}`
      );
      await uploadBytes(storageRef, bytes);
      const downloadURL = await getDownloadURL(storageRef);
      return resolve(downloadURL);
    } catch (e) {
      return reject(e);
    }
  });

export const _setVoiceTest = ({ uid, aid, cid, results }) =>
  new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(
        db,
        'users',
        uid,
        'assessments',
        aid,
        'candidates',
        cid
      );
      await setDoc(
        docRef,
        {
          results,
        },
        {
          merge: true,
        }
      );
      return resolve(true);
    } catch (e) {
      return reject(e);
    }
  });

export const _updateCondition = ({ uid, aid, cid, condition, progress }) =>
  new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(
        db,
        'users',
        uid,
        'assessments',
        aid,
        'candidates',
        cid
      );
      await setDoc(
        docRef,
        {
          condition,
          progress,
          updatedAt: Timestamp.now(),
        },
        {
          merge: true,
        }
      );
      resolve(true);
    } catch (e) {
      return reject(e);
    }
  });

export const _getSpreadsheets = ({ spreadsheetId }) =>
  new Promise(async (resolve, reject) => {
    try {
      const getSpreadsheets = httpsCallable(functions, 'getSpreadsheets');
      const result = await getSpreadsheets({ spreadsheetId });
      return resolve(result?.data);
    } catch (e) {
      return reject(e);
    }
  });

export const _getSpreadsheetData = ({ spreadsheetId }) =>
  new Promise(async (resolve, reject) => {
    try {
      const getSpreadsheetData = httpsCallable(functions, 'getSpreadsheetData');
      const result = await getSpreadsheetData({ spreadsheetId });
      return resolve(result?.data);
    } catch (e) {
      return reject(e);
    }
  });

export const _updateSpreadsheetData = ({ spreadsheetId, values }) =>
  new Promise(async (resolve, reject) => {
    try {
      const updateSpreadsheetData = httpsCallable(
        functions,
        'updateSpreadsheetData'
      );
      const result = await updateSpreadsheetData({
        spreadsheetId,
        range: 'A1:Z100',
        values,
      });
      return resolve(result?.data);
    } catch (e) {
      return reject(e);
    }
  });

export const _createSpreadsheetsCandidate = ({ properties }) =>
  new Promise(async (resolve, reject) => {
    try {
      const createSpreadsheets = httpsCallable(functions, 'createSpreadsheets');
      const result = await createSpreadsheets({ properties });
      return resolve(result?.data);
    } catch (e) {
      return reject(e);
    }
  });

export const _copySpreadSheet = ({ spreadsheetId, uid, aid, cid }) =>
  new Promise(async (resolve, reject) => {
    try {
      const createSpreadsheets = httpsCallable(functions, 'copySpreadSheet');
      const result = await createSpreadsheets({ spreadsheetId, uid, aid, cid });
      return resolve(result?.data.response);
    } catch (e) {
      return reject(e);
    }
  });

export const _getUserInfo = ({ uid }) =>
  new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(db, 'users', uid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        return resolve(docSnap.data());
      }
      throw new Error('empty');
    } catch (e) {
      return reject(e);
    }
  });
