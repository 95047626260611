import React from 'react';
import { useTranslation } from 'react-i18next';
import { useCallbackPrompt } from 'utils/hooks';
import ExitModal from 'components/modal/ExitModal';
import { useResult } from 'utils/result';
import { useStatus } from 'utils/status';
import {
  formatTime,
  convertTextToSec,
  convertHtmlForDisplay,
} from 'utils/string';
import Iframe from 'react-iframe';
import { executeAction } from 'utils/redux';
import {
  getSpreadsheets,
  createSpreadsheetsCandidate,
} from 'store/slices/testtakerSlice';
import { useProgress } from 'utils/progress';

const Spreadsheets = ({ test, setCurrentState }) => {
  const { t } = useTranslation();
  const { setIsEntireLoading } = useProgress();
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(true);
  const { recordResult } = useResult();
  const { setTestInfo, getTestInfo } = useStatus();
  const [canSubmit, setCanSubmit] = React.useState(false);
  const [spreadsheetsUrl, setSpreadsheetsUrl] = React.useState('');
  const { duration, doneCnt, maxDoneCnt } = getTestInfo();

  React.useEffect(() => {
    const skillEndEventListener = (event) => {
      onSubmitHandler();
    };

    window.scrollTo(0, 0);

    window.document.addEventListener('skill_end', skillEndEventListener);
    return () =>
      window.document.removeEventListener('skill_end', skillEndEventListener);
  }, []);

  React.useEffect(() => {
    const testDoneEventListener = (event) => {
      onSubmitHandler();
    };

    if (canSubmit > 0) {
      setTestInfo({
        duration,
        doneCnt,
        maxDoneCnt,
        canSubmit: true,
        buttonName: t('btn.submit'),
      });
    }

    window.document.addEventListener('test_done', testDoneEventListener);
    return () => {
      window.document.removeEventListener('test_done', testDoneEventListener);
    };
  }, [canSubmit]);

  React.useEffect(() => {
    setTimeout(() => {
      setCanSubmit(true);
    }, 2000);
  }, []);

  React.useEffect(() => {
    if (!test?.spreadsheetsUrl) return;

    // TODO: copy
    setIsEntireLoading(true);
    const { spreadsheetsId: originalSpreadsheetsId } = test || {};
    executeAction(
      getSpreadsheets({
        spreadsheetId: originalSpreadsheetsId,
      })
    )
      .unwrap()
      .then(({ properties }) => {
        executeAction(
          createSpreadsheetsCandidate({
            properties,
          })
        )
          .unwrap()
          .then(({ spreadsheetsId, spreadsheetsUrl }) => {
            setSpreadsheetsUrl(spreadsheetsUrl);
            setIsEntireLoading(false);
          })
          .catch((e) => {
            console.dir(e);
            setIsEntireLoading(false);
          });
      })
      .catch((e) => {
        console.dir(e);
        setIsEntireLoading(false);
      });
  }, [test?.spreadsheetsUrl]);

  const onSubmitHandler = () => {
    setTestInfo({
      duration: null,
      doneCnt: null,
      maxDoneCnt: null,
      canSubmit: false,
      buttonName: null,
    });
    recordResult({
      sid: test?.uuid || test.id,
      data: {
        spreadsheetsUrl,
      },
    });
    setCurrentState('end');
  };

  return (
    <main className="pages-protected-evaluation-subjectives-spreadsheets">
      <ExitModal
        showPrompt={showPrompt}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
      />
      <div className="container">
        <div className="container-inner">
          <header className="header">
            <p className="header-question fs18">question</p>
            {/* <button
              className={`header-submit common-button fsbtn16 primary ${
                canSubmit && "active"
              }`}
              onClick={canSubmit ? onSubmitHandler : null}
            >
              {t("btn.submit")}
            </button> */}
          </header>
          <section
            className="spreadsheets"
            style={{
              flex: 1,
            }}
          >
            <Iframe
              url={spreadsheetsUrl}
              width="100%"
              height="100%"
              position="relative"
            />
          </section>
        </div>
      </div>
    </main>
  );
};

export default Spreadsheets;
