import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@mui/material';

function ExitModal({ showPrompt, cancelNavigation, confirmNavigation }) {
  const { t } = useTranslation();

  return (
    <Modal open={showPrompt} onClose={() => cancelNavigation()}>
      <div className="modal exit">
        <div className="content">
          <h2 className="title">{t('dialog.title.exit-assessment')}</h2>
          <p className="description">{t('dialog.desc.exit-assessment')}</p>
        </div>
        <div className="buttons">
          <button
            className="cancel common-button fsbtn16 grey active"
            onClick={() => cancelNavigation()}
          >
            {t('btn.no')}
          </button>
          <button
            className="confirm common-button fsbtn16 primary active"
            onClick={() => confirmNavigation()}
          >
            {t('btn.yes')}
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default ExitModal;
