import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import TinyEditor from 'components/editor/TinyEditor';
import { useCallbackPrompt } from 'utils/hooks';
import ExitModal from 'components/modal/ExitModal';
import { useResult } from 'utils/result';
import { divideSkillScope, divideSkillSection, useStatus } from 'utils/status';
import { ReactComponent as CheckedIcon } from 'assets/images/icon/round_check_black_26x26.svg';
import { ReactComponent as ResetIcon } from 'assets/images/icon/replay-grey-14x14.svg';
import { Modal } from '@mui/material';
import { boldRegex, convertHtmlForDisplay, spaceRegex } from 'utils/string';
import html2canvas from 'html2canvas';
import { Button } from 'components/common/Button';
import * as Sentry from '@sentry/react';

function Styling({ test, setCurrentState }) {
  const { t } = useTranslation();
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(true);
  const { recordResult, blob, setBlob } = useResult();
  const { setTestInfo, getTestInfo } = useStatus();
  const baseRef = React.useRef(null);
  const [cansubmit, SetCanSubmit] = React.useState(false);
  const { duration, doneCnt, maxDoneCnt } = getTestInfo();
  const [selectedCategory, SetSelectedCategory] = React.useState(0);
  const [tops, SetTopsURL] = React.useState('');
  const [topsNeck, SetTopsNeckURL] = React.useState('');
  const [bottoms, SetBottomsURL] = React.useState('');
  const [bottomsSkirts, SetBottomsSkirtsURL] = React.useState('');
  const [outerwear, SetOuterwearURL] = React.useState('');
  const [outerwearNeck, SetOuterwearNeckURL] = React.useState('');
  const [bags, SetBagsURL] = React.useState('');
  const [shoes, SetShoesURL] = React.useState('');
  const [accessoriesBelt, SetAccessoriesBeltURL] = React.useState('');
  const [accessoriesRing, SetAccessoriesRingURL] = React.useState('');
  const [accessoriesBracelets, SetAccessoriesBraceletsURL] = React.useState('');
  const [accessoriesBrooches, SetAccessoriesBroochesURL] = React.useState('');
  const [accessoriesWatch, SetAccessoriesWatchURL] = React.useState('');
  const [accessoriesEyewear, SetAccessoriesEyewearURL] = React.useState('');
  const [accessoriesHair, SetAccessoriesHairURL] = React.useState('');
  const [accessoriesGloves, SetAccessoriesGlovesURL] = React.useState('');
  const [accessoriesTie, SetAccessoriesTieURL] = React.useState('');
  const [accessoriesSocks, SetAccessoriesSocksURL] = React.useState('');
  const [selectedImages, SetSelectedImages] = React.useState(null);
  const [selectedAccessoriesImages, SetSelectedAccessoriesImages] =
    React.useState(null);
  const [isSelected, SetIsSelected] = React.useState([]);
  const [isSelectedAssessories, SetIsSelectedAssessories] = React.useState([]);
  const [isDressOrJumpsuits, setIsDressOrJumpsuits] = React.useState('');
  const [isHairband, setIsHairband] = React.useState('');
  const [resetRender, setResetRender] = React.useState(false);
  // const [isOuterwear, setIsOuterwear] = React.useState('');
  const [isNeck, setIsNeck] = React.useState(false);

  const itemlist = [
    {
      category: '상의',
      dir: 'tops',
      section: [
        {
          title: t('style.tshirts'),
          dir: 't-shirts',
          images: [
            // '606015_XKA4A_4492_001_100_0000_Light--G.png',
            'Gucci 구찌 빈티지 로고 프린트 티셔츠 Detail 2.png',
            'Gucci 광대 프린트 코튼 저지 티셔츠 Detail 2.png',
            'Gucci 구찌 프린트 코튼 저지 티셔츠 Detail 2.png',
            'Gucci 라운드 GG 프린트 코튼 티셔츠 Detail 2.png',
            'Gucci 구찌 아이스크림 코튼 저지 티셔츠 Detail 2.png',
            'Gucci 하이넥 버니 프린트 코튼 티셔츠 Detail 2.png',
          ],
        },
        {
          title: t('style.polo'),
          dir: 'polo-shirts',
          images: [
            'Gucci 스트레치 코튼 피케 폴로 Detail 2.png',
            'Gucci 코튼 피케 폴로 셔츠 Detail 2.png',
            'Gucci GG 스트레치 코튼 폴로 Detail 2.png',
          ],
        },
        {
          title: t('style.silk'),
          dir: 'silk-shirts',
          images: [
            '657801_ZHS78_9205_001_100_0000_Light-.png',
            '721477_ZAK87_4436_001_100_0000_Light-.png',
            '722868_ZALD5_4371_001_100_0000_Light-GG.png',
            'Gucci Tulle and lace shirt Detail 2.png',
          ],
        },
        {
          title: t('style.sweatshirts'),
          dir: 'sweatshirts',
          images: [
            'Gucci 구찌 하 하 하 체리 스웨트셔츠 Detail 2.png',
            'Gucci 코리아 익스클루시브 펠트 코튼 저지 스웨트셔츠 블랙 Detail 2.png',
            'Gucci Cruise 2023 Gucci Cosmogonie 코튼 저지 스웨트셔츠 Detail 2.png',
            'Gucci GG 울 자카드 가디건 Detail 2.png',
            'Gucci GG 체크 니트 울 스웨터 Detail 2.png',
            'Gucci GG 코튼 니트 스웨트셔츠 Detail 2.png',
            'Gucci 웹Web 인터로킹 G 스트라이프 스웨트셔츠 Detail 2.png',
            'Gucci 코튼 저지 스웨트셔츠 Detail 4.png',
            'Gucci 코튼 저지 스웨트셔츠 Detail 5.png',
          ],
        },
        {
          title: t('style.bowling'),
          dir: 'bowling-shirts',
          images: [
            'Gucci 지오메트릭 하운드투스 프린트 볼링 셔츠 Detail 2.png',
            'Gucci 프린트 코튼 포플린 볼링 셔츠 Detail 2.png',
          ],
        },
        {
          title: t('style.check'),
          dir: 'check-shirts',
          images: ['Gucci 구찌 하 하 하 G 체크 셔츠 Detail 2.png'],
        },
        {
          title: t('style.dresses'),
          dir: 'dresses',
          images: [
            '인터로킹 G 울 드레스.png',
            '644564_XKBNZ_6176_001_100_0000_Light-GG.png',
            '704252_ZAIVA_2076_001_100_0000_Light-GG.png',
            '715614_ZAKUO_7076_001_100_0000_Light--G.png',
            '723471_ZALGG_9304_001_100_0000_Light--x-GG.png',
          ],
        },
        {
          title: t('style.crop'),
          dir: 'crop-tops',
          images: [
            '689201_XJEFU_1117_001_100_0000_Light-GG.png',
            '693448_XJECA_4697_001_100_0000_Light--x-GG.png',
            'Gucci Silk with crystals corset  light pink Detail 2.png',
            'Gucci Viscose jersey top with crystals Detail 2.png',
          ],
        },
        {
          title: t('style.jump'),
          dir: 'jumpsuits',
          images: [
            '703000_ZAI22_9133_001_100_0000_Light--x.png',
            '721151_XKCQX_1127_001_100_0000_Light--GG.png',
            '725971_ZAGN6_1000_001_100_0000_Light-.png',
          ],
        },
      ],
    },
    {
      category: '하의',
      dir: 'bottoms',
      section: [
        {
          title: t('style.shorts'),
          dir: 'pants-shorts',
          images: [
            '675762_ZAH1H_6487_001_100_0000_Light-.png',
            '681154_Z8AV7_2184_001_100_0000_Light--GG.png',
            '703006_ZAJS5_7694_001_100_0000_Light--x.png',
            '721907_ZHW51_1000_002_100_0000_Light-.png',
            '731967_ZAK59_9791_002_100_0000_Light-.png',
            '732759_Z8BDQ_2480_001_100_0000_Light--GG.png',
            'Gucci 네오프렌 배스킷볼 쇼츠 Detail 2.png',
            'Gucci 맥시 GG 코튼 팬츠 Detail 2.png',
            'Gucci 멜란지 클로스 쇼츠 Detail 2.png',
            'Gucci 미러 이펙트 팬츠 Detail 2.png',
            'Gucci 에코 워시드 GG 데님 팬츠 다크 블루아이보리 Detail 2.png',
            'Gucci 워시드 데님 테이퍼드 팬츠 라이트 블루 Detail 2.png',
            'Gucci 워시드 데님 테이퍼드 팬츠 블루 데님 Detail 2.png',
            'Gucci 점보 GG 데님 쇼츠 블루아이보리 Detail 2.png',
            'Gucci 코튼 저지 쇼츠 그레이  Detail 2.png',
            'Gucci 텍스처드 개버딘 크롭 팬츠 Detail 2.png',
            'Gucci Bunny print jersey jogging pant Detail 2.png',
            'Gucci Cotton drill cut-out pant Detail 2.png',
            'Gucci Denim pant with floral embroidery Detail 2.png',
            'Gucci GG 저지 코튼 조깅 팬츠 Detail 2.png',
            'Gucci GG 커프 데님 팬츠  Detail 2.png',
            'Gucci GG embroidered silk shorts Detail 2.png',
          ],
        },
        {
          title: t('style.skirts'),
          dir: 'skirts',
          images: [
            '714293_XDB4N_2560_001_100_0000_Light-.png',
            '블루브라운 체크 승마 프린트 실크 스커트  구찌® 코리아.png',
            'Gucci 데님 스커트 Detail 2.png',
            'Gucci 플리츠 데님 스커트 Detail 2.png',
            'Gucci GG 캔버스 스커트 카멜에보니 Detail 2.png',
          ],
        },
      ],
    },
    {
      category: '아우터웨어',
      dir: 'outerwear',
      section: [
        {
          title: t('style.jackets'),
          dir: 'jackets',
          images: [
            'Gucci 프린스 웨일즈 체크 재킷 Detail 2.png',
            'Gucci 웹Web 벨트 GG 캔버스 재킷 카멜에보니 Detail 2.png',
            'Gucci 구찌 라벨이 달린 벨벳 재킷 블랙 Detail 2.png',
            'Gucci 레더 봄버 재킷 Detail 2.png',
            'Gucci 점보 GG 데님 재킷 블루카멜 Detail 2.png',
            'Gucci 타르탄 울 재킷 Detail 2.png',
            'Gucci 매크로 체크 울 블렌드 재킷 Detail 2.png',
            'Gucci 벨벳 포멀 재킷 Detail 2.png',
            'Gucci 아디다스 x 구찌 인터로킹 G 및 트레포일 재킷 Detail 2.png',
            'Gucci 코리아 익스클루시브 GG 캔버스 재킷 베이지블루 Detail 2.png',
            'Gucci 점보 GG 캔버스 다운 재킷 Detail 2.png',
            'Gucci 체크 울 포멀 재킷 Detail 2.png',
            // 'Gucci 플루이드 드릴 수트 Detail 2.png',
            'Gucci GG 레더 재킷 Detail 2.png',
            'Gucci GG 울 수트 Detail 2.png',
            'gucci 플루드 드릴 수트 green.png',
            'Gucci GG 울 자카드 재킷 다크 그레이  Detail 2.png',
            '코듀로이 카라 데님 재킷.png',
            'Gucci Duchesse jacket with embroidery Detail 2.png',
          ],
        },
        {
          title: t('style.coats'),
          dir: 'coats',
          images: [
            'Gucci 퀼트 GG 점보 재킷 Detail 2.png',
            'Gucci 탈부착식 베스트 디테일의 코튼 코트 Detail 2.png',
            'Gucci 폴리에스테르 리버서블 코트 Detail 2.png',
            'Gucci 하운드투스 코트 Detail 2.png',
            'Gucci 트위드 울 코트 Detail 2.png',
          ],
        },
      ],
    },
    {
      category: '가방',
      dir: 'bags',
      section: [
        {
          title: t('style.belt-bag'),
          dir: 'belt-bags',
          images: [
            'Gucci [오피디아] 벨트백 Detail 2.png',
            'Gucci [재키 1961] 벨트백 Detail 2.png',
            'Gucci [GG 엠보스] 벨트백 Detail 2.png',
            'Gucci 인터로킹 G 벨트백 gg 수프림 Detail 2.png',
            'Gucci 코리아 익스클루시브 벨트백 블루베이지 캔버스 Detail 2.png',
          ],
        },
        {
          title: t('style.shoulder'),
          dir: 'shoulder-bags',
          images: [
            '[구찌 블론디] 숄더백.png',
            '[구찌 홀스빗 1955] 숄더백.png',
            '[디오니서스] GG 스몰 숄더백.png',
            '[재키 1961] 스몰 숄더백.png',
            'Gucci [오피디아] GG 스몰 숄더백 소프트 gg 수프림 Detail 2.png',
            'Gucci [구찌 블론디] 숄더백 Detail 2.png',
            'Gucci [아프로디테] 더블 G 숄더백 Detail 2.png',
            'Gucci [재키 1961] 미니 숄더백 Detail 2.png',
            'Gucci [재키 1961] 스몰 숄더백 파이톤 레더 Detail 4.png',
            'Gucci [GG 마몽] 마틀라세 숄더백 Detail 2.png',
            'Gucci 인터로킹 G 숄더백 Detail 2.png',
          ],
        },
        {
          title: t('style.messenger'),
          dir: 'messenger-bags-mini-bags',
          images: [
            'Gucci [구찌 홀스빗 1955] 코리아 익스클루시브 미니백 블루베이지 캔버스 Detail 2.png',
            'Gucci 인터로킹 G 메신저백  블랙 gg 수프림 Detail 2.png',
            'Gucci GG 수프림 메신저백 Detail 2.png',
            '[구찌 홀스빗 1955] 점보 GG 미니백.png',
            'Gucci [구찌 홀스빗 1955] 미니백 화이트 레더 Detail 10.png',
          ],
        },
        {
          title: t('style.duffle'),
          dir: 'duffle-bags',
          images: [
            'Gucci [GG 엠보스] 더플백 Detail 2.png',
            'Gucci [GG 엠보스] 라지 더플백 Detail 7.png',
            'Gucci GG 롬버스 프린트 더플백 Detail 3.png',
          ],
        },
        {
          title: t('style.handbags'),
          dir: 'handbags',
          images: [
            'Gucci [구찌 다이애나] 스몰 파이톤 핸드백 핑크 Detail 2.png',
            'Gucci [구찌 뱀부 1947] 미니 탑 핸들백 Detail 2.png',
            'Gucci [구찌 블론디] 탑 핸들백 Detail 2.png',
            'Gucci [오피디아] 스몰 크로커다일 탑 핸들백 라일락 Detail 2.png',
            'Gucci GG 마틀라세 스몰 탑 핸들백 Detail 3.png',
            'Gucci GG 마틀라세 스몰 탑 핸들백 Detail 4.png',
          ],
        },
        {
          title: t('style.briefcases'),
          dir: 'briefcases',
          images: [
            '블루 수프림 인터로킹 G 브리프케이스  구찌® 코리아.png',
            'Gucci [GG 엠보스] 브리프케이스 블랙 레더 Detail 2.png',
            'Gucci 인터로킹 G 장식의 비즈니스 케이스 gg 수프림 Detail 2.png',
          ],
        },
        {
          title: t('style.backpack'),
          dir: 'backpacks',
          images: [
            '인터로킹 G 미디엄 백팩.png',
            'Gucci 인터로킹 G 백팩 Detail 2.png',
            'Gucci 점보 GG 백팩 카멜에보니 gg 캔버스 Detail 2.png',
            'Gucci 토널 더블 G 백팩 Detail 2.png',
            'Gucci 뱀부 백팩 Detail 2.png',
            'Gucci [오피디아] 스몰 사이즈 GG 백팩 gg 수프림  Detail 2.png',
          ],
        },
        {
          title: t('style.tote'),
          dir: 'tote-bags',
          images: [
            'Gucci [구찌 다이애나] 미니 토트백 Detail 6.png',
            'Gucci [구찌 다이애나] GG 크리스털 미니 토트백 Detail 2.png',
            'Gucci [오피디아] 라지 토트백 Detail 8.png',
            'Gucci [오피디아] 라지 토트백 Detail 9.png',
            'Gucci [오피디아] 미디엄 토트백 블랙 레더 Detail 2.png',
            'Gucci 점보 GG 미니 토트백 Detail 8.png',
            'Gucci GG 마틀라세 레더 미디엄 토트백 Detail 2.png',
            'Gucci 웹Web 미디엄 토트백 Detail 2.png',
            'Gucci 인터로킹 G 미디엄 토트백 Detail 2.png',
            'Gucci 코리아 익스클루시브 미디엄 인터로킹 G 토트백 블루베이지 캔버스 Detail 2.png',
            'Gucci GG 롬버스 프린트 라지 토트백 Detail 2.png',
          ],
        },
      ],
    },
    {
      category: '슈즈',
      dir: 'shoes',
      section: [
        {
          title: t('style.loafer'),
          dir: 'loafers',
          images: [
            '남성 인터로킹 G 로퍼.png',
            'Gucci [구찌 조던] 남성 레더 로퍼 Detail 2.png',
            'Gucci 남성 태슬 GG 로퍼 블랙 레더 Detail 2.png',
            'Gucci [구찌 조던] 레더 로퍼 블랙 레더 Detail 2.png',
            'Gucci [구찌 조던] 여성 맥시 GG 로퍼 카멜에보니 캔버스 Detail 2.png',
            'Gucci 여성 구찌 로퍼 Detail 2.png',
          ],
        },
        {
          title: t('style.pumps'),
          dir: 'pumps',
          images: [
            '여성 미드힐 페이턴트 펌프스.png',
            'Gucci 여성 더블 G 슬링백 펌프스 Detail 2.png',
            'Gucci 여성 하이힐 펌프스 Detail 2.png',
          ],
        },
        {
          title: t('style.lace-up'),
          dir: 'lace-up-shoes',
          images: [
            'Gucci 남성 더블 G 레이스업 슈즈 Detail 2.png',
            'Gucci 남성 레이스업 슈즈 Detail 4.png',
            'Gucci 남성 레이스업 슈즈 Detail 5.png',
          ],
        },
        {
          title: t('style.sneakers'),
          dir: 'sneakers',
          images: [
            'Gucci 여성 구찌 테니스 1977 스니커즈 Detail 2.png',
            'Gucci 여성 GG 스니커즈 Detail 2.png',
            'Gucci 여성 GG 엠보스 스니커즈 Detail 2.png',
            'Gucci [구찌 런] 남성 스니커즈 블랙 패브릭 Detail 2.png',
            'Gucci [롸이톤] 남성 GG 스니커즈 오리지널 gg 자카드 패브릭 Detail 2.png',
          ],
        },
        {
          title: t('style.ankle'),
          dir: 'boots-ankle-boots',
          images: [
            '남성 앵클 부츠.png',
            'Gucci 남성 더블 G 앵클 부츠 Detail 2.png',
            'Gucci 남성 하네스 부츠 Detail 2.png',
            'Gucci 남성 GG 부츠 멀티컬러 나일론 Detail 2.png',
            'Gucci 남성 GG 앵클 부츠 블랙베이지 울 Detail 2.png',
            'Gucci 아디다스 x 구찌 남성 부츠 Detail 2.png',
            'Gucci 벨트 레더 앵클 부츠 블랙 레더 Detail 2.png',
            'Gucci 여성 더블 G 앵클 부츠 블랙 레더 Detail 2.png',
            'Gucci 여성 로고 미드힐 부츠 Detail 2.png',
            // 'Gucci 여성 하프 홀스빗 부츠 Detail 2.png',
            'Gucci 여성 홀스빗 부츠 Detail 2.png',
            'Gucci 여성 GG 캔버스 앵클 부츠 Detail 2.png',
            '719834_FAAUV_6447_001_100_0000_Light-Womens-GG-matelass-boot.png',
            'Gucci Gucci HA HA HA boot with buckles Detail 2.png',
          ],
        },
        {
          title: t('style.flat'),
          dir: 'flats',
          images: [
            'Gucci 여성 GG 마틀라세 캔버스 에스파드리유 오리지널 gg 캔버스블랙.png',
            'Gucci 여성 슬링백 발레 플랫 Detail 2.png',
            'Gucci 여성 홀스빗 발레 슈즈 블랙 레더 Detail 2.png',
            'Gucci 여성 홀스빗 발레 슈즈 화이트 레더 Detail 2.png',
          ],
        },
        {
          title: t('style.slipper'),
          dir: 'slippers',
          images: [
            'Gucci [프린스타운] 남성 슬리퍼 Detail 2.png',
            'Gucci 남성 홀스빗 GG 슬리퍼 Detail 2.png',
            'Gucci 킹스 홀스빗 레더 슬리퍼 블랙 레더 Detail 2.png',
          ],
        },
        {
          title: t('style.sandal'),
          dir: 'sandals',
          images: [
            '[구찌 블론디] 슬라이드 샌들.png',
            '여성 인터로킹 G 슬라이드 샌들.png',
            'Gucci 여성 더블 G 샌들 블랙 레더 Detail 2.png',
            'Gucci 여성 오리지널 GG 슬라이드 샌들 베이지브릭 레드 gg 캔버스 Detail 2.png',
            'Gucci 여성 인터로킹 G 슬라이드 Detail 2.png',
            'Gucci 여성 인터로킹 G 에스파드리유 슬라이드 Detail 2.png',
            'Gucci 여성 인터로킹 G 컷아웃 슬라이드 샌들 Detail 2.png',
            'Gucci 여성 GG 마틀라세 캔버스 에스파드리유 샌들 오리지널 gg 캔버스블랙.png',
            '남성 GG 슬라이드 샌들.png',
            '아디다스 x 구찌 남성 GG 플랫폼 샌들.png',
            'Gucci 남성 굿 게임 슬라이드 샌들 Detail 2.png',
            'Gucci 남성 슬립온 샌들 화이트 gg 러버 Detail 2.png',
            'Gucci 남성 웹Web 러버 슬라이드 샌들 그린과 레드 코튼 Detail 2.png',
            'Gucci 웹Web 슬라이드 샌들 화이트 러버 Detail 2.png',
          ],
        },
        {
          title: t('style.driving'),
          dir: 'driving-shoes',
          images: [
            '남성 인터로킹 G 드라이버 슈즈.png',
            'Gucci 남성 인터로킹 G 드라이빙 슈즈 Detail 4.png',
            'Gucci 남성 인터로킹 G 드라이빙 슈즈 Detail 5.png',
            'Gucci Mens driver with Interlocking G Detail 2.png',
          ],
        },
      ],
    },
  ];

  const accessorieslist = [
    {
      category: '액세서리',
      dir: 'accessories',
      section: [
        {
          title: t('style.belt'),
          dir: 'belts',
          images: [
            '[구찌 블론디] 벨트.png',
            'Gucci [구찌 블론디] 벨트 Detail 2.png',
            'Gucci [GG 마몽] 리버서블 벨트 Detail 2.png',
            'Gucci [GG 마몽] 와이드 벨트 Detail 2.png',
            'Gucci 더블 G 버클 리버서블 벨트 블랙브라운 레더 Detail 2.png',
            'Gucci 와이드 벨트 Detail 2.png',
          ],
        },
        {
          title: t('style.ring'),
          dir: 'jewelry-rings',
          images: [
            'Gucci 와이드 벨트 Detail 2.png',
            'Gucci Interlocking G gourmette chain ring Detail 2.png',
            'Gucci Lion head ring with crystal aged gold-toned metal Detail 2.png',
          ],
        },
        {
          title: t('style.bracelets'),
          dir: 'jewelry-bracelets',
          images: [
            'Gucci [아이콘] 별 팔찌 Detail 2.png',
            'Gucci Crystal Double G leather bracelet clear Detail 3.png',
            'Gucci Interlocking G bracelet with hearts Detail 3.png',
            'Gucci Leather bracelet with lion heads Detail 3.png',
          ],
        },
        {
          title: t('style.brooches'),
          dir: 'jewelry-brooches',
          images: [
            'Gucci 더블 하트 레진 브로치 Detail 2.png',
            'Gucci Leaf brooch with crystals Detail 2.png',
            'Gucci Pearl Double G brooch gold metal.png',
          ],
        },
        {
          title: t('style.watch'),
          dir: 'watches',
          images: [
            'Gucci [구찌 다이브] 시계, 40mm Detail 2.png',
            'Gucci [G-타임리스] 시계, 29mm 골드 pvd 3.png',
            'Gucci [G-타임리스] 시계, 29mm 골드 pvd Detail 2.png',
            'Gucci [G-타임리스] 시계, 40mm 블루 레더 Detail 2.png',
            'Gucci Dionysus, 13x26mm silver Detail 2.png',
          ],
        },
        {
          title: t('style.eyewear'),
          dir: 'eyewear',
          images: [
            '직사각형 프레임 선글라스.png',
            'Gucci 내비게이터 프레임 선글라스 Detail 2.png',
            'Gucci 아디다스 x 구찌 스퀘어 프레임 선글라스 톨토이즈쉘 아세테이트.png',
            'Gucci 에비에이터 선글라스 골드 메탈 Detail 2.png',
            'Gucci 타원형 프레임 선글라스 Detail 2.png',
            'Gucci 하트 프레임 선글라스 Detail 2.png',
          ],
        },
        {
          title: t('style.hats'),
          dir: 'hats-hair-accessories',
          images: [
            'Gucci 구찌 패치 디테일의 야구 모자 Detail 2.png',
            'Gucci 로이 쏠레이 프린트 실크 헤드 밴드 Detail 2.png',
            'Gucci 리버서블 버킷 모자 Detail 2.png',
            'Gucci 보우 디테일의 스트로우 모자 Detail 2.png',
            'Gucci 점보 GG 버킷 모자 Detail 2.png',
            'Gucci 코리아 익스클루시브 점보 GG 캔버스 야구 모자 베이지블루.png',
            'Gucci 크리스털 구찌 헤어 슬라이드 Detail 2.png',
            'Gucci GG 데님 헤드 밴드 블랙아이보리 Detail 2.png',
            'Gucci GG 수프림 플로라 프린트 야구 모자 Detail 2.png',
          ],
        },
        {
          title: t('style.gloves'),
          dir: 'gloves',
          images: [
            'Gucci 구찌 시그니처 장갑 블랙 구찌 시그니처 레더.png',
            'Gucci 홀스빗 디테일의 GG 캔버스 장갑 베이지에보니 Detail 2.png',
            'Gucci GG 캐시미어 핑거리스 장갑 Detail 2.png',
            'Gucci GG 캐시미어 핑거리스 장갑 Detail 3.png',
          ],
        },
        {
          title: t('style.ties'),
          dir: 'ties',
          images: [
            'Gucci 인터로킹 G 실크 타이 Detail 4.png',
            'Gucci 인터로킹 G 실크 타이 Detail 5.png',
            'Gucci GG 실크 자카드 타이 Detail 2.png',
          ],
        },
        {
          title: t('style.socks-tights'),
          dir: 'socks-tights',
          images: [
            '아디다스 x 구찌 발목 양말 - 핑크화이트  구찌® 코리아  구찌® 코리아.png',
            'Gucci 아디다스 x 구찌 GG 트레포일 나일론 니트 양말 Detail 2.png',
            'Gucci Round Interlocking G cotton socks Detail 2.png',
            'Gucci Wool socks with Interlocking G patch Detail 2.png',
            'Gucci GG 니트 타이즈 Detail 2.png',
            'Gucci GG 패턴 타이즈 베이지 및 에보니 gg 튤 Detail 2.png',
          ],
        },
      ],
    },
  ];

  /** ------------------------------------------------------
   * @param {*} dataurl : canvas.toDateURL 값을 Blob형태로 변환
   * @returns 
   --------------------------------------------------------*/
  function dataURLtoBlob(dataurl) {
    let arr = dataurl.split(',');
    let mime = arr[0].match(/:(.*?);/)[1];
    let bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }

  /** ------------------------------------------
   * html2canvas: 특정 html태그를 canvas태그로 변환 후
   *              img로 저장해주는 라이브러리 
   --------------------------------------------- */
  const onHtmlToImage = () => {
    const capture = window?.document?.getElementById('base');

    html2canvas(capture, {
      useCORS: true,
      allTain: true,
    })
      .then((canvas) => {
        const canvasUrl = canvas.toDataURL('image/png');
        const blob = dataURLtoBlob(canvasUrl);
        blob.name = 'styling';
        blob.createUrl = window?.URL?.createObjectURL(blob);
        blob.id = test?.id;
        setBlob(blob);

        /** ----------------------------------------------------------------------------
       * 1. db와 gcp에 저장될 값을 발송
       * 2. result.js -> submitResults에서 data의 형태에 따라 gcp에 저장
       * 3.           -> setResultsThunk에서 testtakerSlice(setResults)로 비동기 통신
       *                 firebase tasttaker.js에서 최종적으로 값을 저장
       ------------------------------------------------------------------------------*/
        recordResult({
          sid: test?.uuid || test?.id, // 사용자가 직접 만든 문제는 uuid가 존재하고 테스트에 기본으로 제공하는 문제는 id가 존재함
          data: {
            img: blob,
          },
        }).then(() => {
          setBlob(null);
        });
      })
      .catch((e) => {
        Sentry.captureException(e);
        console.error(e);
      });
  };

  const onSubmitHandler = () => {
    onHtmlToImage();
    SetCanSubmit(false);
  };

  React.useEffect(() => {
    let btnName = '';
    if (doneCnt === maxDoneCnt) {
      btnName = t('btn.submit');
    } else {
      btnName = t('btn.next');
    }

    setTestInfo({
      duration,
      doneCnt,
      maxDoneCnt,
      canSubmit: cansubmit,
      buttonName: btnName,
    });

    const skillEndEventListener = (event) => {
      onSubmitHandler();
    };

    window.document.addEventListener('skill_end', skillEndEventListener);
    return () =>
      window.document.removeEventListener('skill_end', skillEndEventListener);
  }, [blob, cansubmit]);

  React.useEffect(() => {
    // const skillEndEventListener = (event) => {
    //   onSubmitHandler();
    // };

    const selected = {};
    const assessoriesSelected = {};
    if (selectedImages === null || Object.keys(selectedImages).length === 0) {
      let accessoriesArr = {};

      itemlist.forEach((item, idx) => {
        selected[idx] = {};
        item.section.forEach((section, sidx) => {
          const imageLen = section.images.length;
          selected[idx][sidx] = new Array(imageLen).fill(false);
        });
      });

      accessorieslist.forEach((item, idx) => {
        accessoriesArr = item;
      });

      accessoriesArr.section.forEach((section, sidx) => {
        const imageLen = section.images.length;
        assessoriesSelected[sidx] = new Array(imageLen).fill(false);
      });

      SetSelectedImages(selected);
      SetSelectedAccessoriesImages(assessoriesSelected);
    }

    const _selected = new Array(itemlist.length).fill(false);
    SetIsSelected(_selected);
    SetIsSelectedAssessories(false);

    window.scrollTo(0, 0);

    return setResetRender(false);
    // window.document.addEventListener("skill_end", skillEndEventListener);
    // return () =>
    //   window.document.removeEventListener("skill_end", skillEndEventListener);
  }, [resetRender]);

  React.useEffect(() => {
    SetCanSubmit(false);
  }, [doneCnt]);

  // React.useEffect(() => {
  //   console.log(selectedImages);
  //   if(selectedImages === null || Object.keys(selectedImages).length === 0) {
  //     return;
  //   }
  //   const _selected = new Array(itemlist.length).fill(false);
  //   (Object.keys(selectedImages) || []).forEach((category) => {
  //     Object.keys(selectedImages[category]).forEach((section) => {
  //       selectedImages[category][section].forEach((isSelected) => {
  //         _selected[category] = _selected[category] || isSelected;
  //       });
  //     });
  //   });
  //   console.log(_selected);
  //   SetIsSelected(_selected);
  // }, [selectedImages]);

  React.useEffect(() => {
    const testDoneEventListener = (event) => {
      onSubmitHandler();
    };

    window.document.addEventListener('test_done', testDoneEventListener);
    return () => {
      window.document.removeEventListener('test_done', testDoneEventListener);
    };
  }, [cansubmit, doneCnt]);

  React.useEffect(() => {
    if (outerwear !== '') {
      setIsNeck(false);
    } else {
      setIsNeck(true);
    }
  }, [outerwear, topsNeck]);

  const canSubmit = (selected) => {
    let cansubmit = true;
    cansubmit = selected.includes(true);
    // selected.forEach((isSelected) => {
    //   cansubmit = cansubmit && isSelected;
    // })
    SetCanSubmit(cansubmit);
  };

  const selectCategory = (category) => {
    SetSelectedCategory(category);
    document.querySelector('#itemlist').scrollTo(0, 0);
  };

  /** ==========================================================================================
   * @function changeImage : 상의 ~ 슈츠까지 변경 되는 image들의 대한 작동 함수
   * @param {*} category(number) : 카테고리 배열에 대한 인덱스 값 (상의 ~ 슈즈)
   * @param {*} sidx(number) : 카테고리의 section 배열에 대한 인덱스 값 (상의 -> 티셔츠 ~ 체크셔츠)
   * @param {*} imgidx(number) : section images 배열에 대한 인덱스 값 (상의 -> 티셔츠 -> 각각의 이미지)
   ============================================================================================= */
  const changeImage = (category, sidx, imgidx) => {
    /** ------------------------------
     * 드레스 / 점프슈트 크기 및 위치 분기처리
    ---------------------------------*/

    if (itemlist[category].dir === 'tops') {
      if (
        itemlist[category].section[sidx].title === t('style.dresses') ||
        itemlist[category].section[sidx].title === t('style.jump')
      ) {
        setIsDressOrJumpsuits('active');
      } else {
        setIsDressOrJumpsuits('');
      }
    }

    const selected = selectedImages;
    const saveSelected = selected[category][sidx][imgidx];

    itemlist[category].section.forEach((section, idx) => {
      const imageLen = section.images.length;
      selected[category][idx] = new Array(imageLen).fill(false);
    });

    selected[category][sidx][imgidx] = !saveSelected;
    SetSelectedImages(selected);

    const _selected = new Array(itemlist.length).fill(false);

    (Object.keys(selected) || []).forEach((category) => {
      Object.keys(selectedImages[category]).forEach((section) => {
        selected[category][section].forEach((isSelected) => {
          _selected[category] = _selected[category] || isSelected;
        });
      });
    });

    SetIsSelected(_selected);

    let imageURL = `${process.env.REACT_APP_IMATE_URL}/images/gucci_styling_test/${itemlist[category].dir}/${itemlist[category].section[sidx].dir}/${itemlist[category].section[sidx].images[imgidx]}?dummy=20230530_2`;

    let neckImageURL;

    // 넥라인 있는 상의
    if (
      itemlist[category].dir === 'tops' ||
      itemlist[category].dir === 'outerwear' ||
      itemlist[category].section[sidx].images[imgidx] ===
        'Gucci 데님 스커트 Detail 2.png'
    ) {
      if (
        itemlist[category].section[sidx].images[imgidx] ===
          'Gucci 폴리에스테르 리버서블 코트 Detail 2.png' ||
        itemlist[category].section[sidx].images[imgidx] ===
          'Gucci 코튼 피케 폴로 셔츠 Detail 2.png' ||
        itemlist[category].section[sidx].images[imgidx] ===
          'Gucci GG 스트레치 코튼 폴로 Detail 2.png' ||
        itemlist[category].section[sidx].images[imgidx] ===
          '721477_ZAK87_4436_001_100_0000_Light-.png' ||
        itemlist[category].section[sidx].images[imgidx] ===
          'Gucci Tulle and lace shirt Detail 2.png' ||
        itemlist[category].section[sidx].images[imgidx] ===
          'Gucci Silk with crystals corset  light pink Detail 2.png' ||
        itemlist[category].section[sidx].images[imgidx] ===
          'Gucci Viscose jersey top with crystals Detail 2.png' ||
        itemlist[category].section[sidx].images[imgidx] ===
          '725971_ZAGN6_1000_001_100_0000_Light-.png'
      ) {
        neckImageURL = '';
      } else {
        neckImageURL = `${process.env.REACT_APP_IMATE_URL}/images/gucci_styling_neck/${itemlist[category].dir}/${itemlist[category].section[sidx].dir}/${itemlist[category].section[sidx].images[imgidx]}?dummy=20230530_2`;
      }
    } else {
      neckImageURL = '';
    }

    if (!_selected[category]) {
      imageURL = '';
      neckImageURL = '';
    }

    switch (category) {
      case 0:
        SetTopsURL(imageURL);
        SetTopsNeckURL(neckImageURL);
        break;
      case 1:
        SetBottomsURL(imageURL);
        SetBottomsSkirtsURL(neckImageURL);
        break;
      case 2:
        SetOuterwearURL(imageURL);
        SetOuterwearNeckURL(neckImageURL);
        break;
      case 3:
        SetBagsURL(imageURL);
        break;
      case 4:
        SetShoesURL(imageURL);
        break;
    }

    canSubmit(_selected);
  };

  /** ==========================================================================================
   * @function accessoriesChangeImage : 액세서리에서 변경 되는 image들의 대한 작동 함수
   * @param {*} sidx(number) : 액세서리의 section 배열에 대한 인덱스 값 (액세서리 -> 벨트 ~ 양말 & 타이즈)
   * @param {*} imgidx(number) : section images 배열에 대한 인덱스 값 (액세서리 -> 벨트 -> 각각의 이미지)
   ============================================================================================= */
  function accessoriesChangeImage(sidx, imgidx) {
    const selected = selectedAccessoriesImages;

    const saveSelected = selected[sidx][imgidx];

    selected[sidx].forEach((item) => {
      selected[sidx] = new Array(selected[sidx].length).fill(false);
    });

    selected[sidx][imgidx] = !saveSelected;
    SetSelectedAccessoriesImages(selected);

    const _selected = new Array(accessorieslist[0].section.length).fill(false);
    (Object.keys(selected) || []).forEach((category) => {
      selected[category].forEach((isSelected) => {
        _selected[category] = _selected[category] || isSelected;
      });
    });

    let selectedBoolean = false;

    _selected.forEach((item) => {
      selectedBoolean = selectedBoolean || item;

      if (selectedBoolean) return;
    });

    SetIsSelectedAssessories(selectedBoolean);

    if (selectedAccessoriesImages?.[6]?.[1]) {
      setIsHairband('active');
    } else {
      setIsHairband('');
    }

    let imageURL = `${process.env.REACT_APP_IMATE_URL}/images/gucci_styling_test/${accessorieslist[0].dir}/${accessorieslist[0]?.section[sidx].dir}/${accessorieslist[0]?.section[sidx].images[imgidx]}?dummy=20230530_2`;

    if (!_selected[sidx]) {
      imageURL = '';
    }

    switch (sidx) {
      case 0:
        SetAccessoriesBeltURL(imageURL);
        break;
      case 1:
        SetAccessoriesRingURL(imageURL);
        break;
      case 2:
        SetAccessoriesBraceletsURL(imageURL);
        break;
      case 3:
        SetAccessoriesBroochesURL(imageURL);
        break;
      case 4:
        SetAccessoriesWatchURL(imageURL);
        break;
      case 5:
        SetAccessoriesEyewearURL(imageURL);
        break;
      case 6:
        SetAccessoriesHairURL(imageURL);
        break;
      case 7:
        SetAccessoriesGlovesURL(imageURL);
        break;
      case 8:
        SetAccessoriesTieURL(imageURL);
        break;
      case 9:
        SetAccessoriesSocksURL(imageURL);
        break;
    }

    canSubmit(_selected);
  }

  const onClickReset = () => {
    SetTopsURL('');
    SetBottomsURL('');
    SetTopsNeckURL('');
    SetBottomsSkirtsURL('');
    SetOuterwearNeckURL('');
    SetOuterwearURL('');
    SetShoesURL('');
    SetBagsURL('');
    SetAccessoriesBeltURL('');
    SetAccessoriesEyewearURL('');
    SetAccessoriesGlovesURL('');
    SetAccessoriesHairURL('');
    SetAccessoriesSocksURL('');
    SetAccessoriesBraceletsURL('');
    SetAccessoriesBeltURL('');
    SetAccessoriesRingURL('');
    SetAccessoriesBraceletsURL('');
    SetAccessoriesBroochesURL('');
    SetAccessoriesWatchURL('');
    SetAccessoriesTieURL('');
    SetSelectedImages(null);
    SetSelectedAccessoriesImages(null);
    SetSelectedCategory(0);
    canSubmit(new Array(itemlist.length).fill(false));
    setResetRender(true);
  };

  return (
    <main className="pages-protected-evaluation-subjectives-styling">
      <ExitModal
        showPrompt={showPrompt}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
      />
      <div className="container more-width">
        <div className="spliter">
          <section className="spliter-left">
            <div className="question">
              <div className="styling-title-wrapper">
                <h3>{t('evaluation.styling.title')}</h3>
                <Button
                  label={t('evaluation.styling.btn_reset')}
                  backIcon={<ResetIcon />}
                  radius={32}
                  size={40}
                  theme="grey"
                  isDisabled={!cansubmit}
                  className="reset-button"
                  onClick={() => onClickReset()}
                />
              </div>

              <pre
                dangerouslySetInnerHTML={{
                  __html: test?.question,
                }}
              />

              <div
                className={`base ${t('lang') === 'en' ? 'en' : 'ko'}`}
                id="base"
                ref={baseRef}
              >
                <div className="mannequin-body">
                  <img
                    alt=""
                    src={require('assets/images/mannequin/women-body.png')}
                    crossOrigin="true"
                  />
                </div>
                <div className="mannequin-right-arm">
                  <img
                    alt=""
                    src={require('assets/images/mannequin/women-right-arm.png')}
                    crossOrigin="true"
                  />
                </div>
                <div className="mannequin-left-arm">
                  <img
                    alt=""
                    src={require('assets/images/mannequin/women-left-arm.png')}
                    crossOrigin="true"
                  />
                </div>
                <div className={`tops ${isDressOrJumpsuits}`}>
                  <img alt="" src={tops} crossOrigin="true" />
                </div>
                {isNeck && (
                  <div className="topsNeck">
                    <img alt="" src={topsNeck} crossOrigin="true" />
                  </div>
                )}
                <div className="outerwearNeck">
                  <img alt="" src={outerwearNeck} crossOrigin="true" />
                </div>
                <div
                  className={`bottoms ${
                    isDressOrJumpsuits === 'active' ? 'down' : ''
                  }`}
                >
                  <img alt="" src={bottoms} crossOrigin="true" />
                </div>
                <div
                  className={`bottomsSkirts ${
                    isDressOrJumpsuits === 'active' ? 'down' : ''
                  }`}
                >
                  <img alt="" src={bottomsSkirts} crossOrigin="true" />
                </div>
                <div className="outerwear">
                  <img alt="" src={outerwear} crossOrigin="true" />
                </div>
                <div className="handbags">
                  <img alt="" src={bags} crossOrigin="true" />
                </div>
                <div className="shoes">
                  <img alt="" src={shoes} crossOrigin="true" />
                </div>
                <div className="accessories-belt">
                  <img alt="" src={accessoriesBelt} crossOrigin="true" />
                </div>
                <div className="accessories-ring">
                  <img alt="" src={accessoriesRing} crossOrigin="true" />
                </div>
                <div className="accessories-bracelets">
                  <img alt="" src={accessoriesBracelets} crossOrigin="true" />
                </div>
                <div className="accessories-brooches">
                  <img alt="" src={accessoriesBrooches} crossOrigin="true" />
                </div>
                <div className="accessories-watch">
                  <img alt="" src={accessoriesWatch} crossOrigin="true" />
                </div>
                <div className="accessories-eyewear">
                  <img alt="" src={accessoriesEyewear} crossOrigin="true" />
                </div>
                <div className={`accessories-hair ${isHairband}`}>
                  <img alt="" src={accessoriesHair} crossOrigin="true" />
                </div>
                <div className="accessories-gloves">
                  <img alt="" src={accessoriesGloves} crossOrigin="true" />
                </div>
                <div className="accessories-tie">
                  <img alt="" src={accessoriesTie} crossOrigin="true" />
                </div>
                <div className="accessories-socks">
                  <img alt="" src={accessoriesSocks} crossOrigin="true" />
                </div>
              </div>
            </div>
          </section>
          <section className="spliter-right">
            <article className="contents">
              <aside className="tab">
                <div className="tabs" id="tabs">
                  <div
                    className={`item ${
                      selectedCategory === 0 ? 'selected' : ''
                    } ${isSelected[0] ? 'checked' : ''}`}
                    onClick={() => selectCategory(0)}
                  >
                    {t('style.top')}
                  </div>
                  <div
                    className={`item ${
                      selectedCategory === 1 ? 'selected' : ''
                    } ${isSelected[1] ? 'checked' : ''}`}
                    onClick={() => selectCategory(1)}
                  >
                    {t('style.bottom')}
                  </div>
                  <div
                    className={`item ${
                      selectedCategory === 2 ? 'selected' : ''
                    } ${isSelected[2] ? 'checked' : ''}`}
                    onClick={() => selectCategory(2)}
                  >
                    {t('style.outer')}
                  </div>
                  <div
                    className={`item ${
                      selectedCategory === 3 ? 'selected' : ''
                    } ${isSelected[3] ? 'checked' : ''}`}
                    onClick={() => selectCategory(3)}
                  >
                    {t('style.bag')}
                  </div>
                  <div
                    className={`item ${
                      selectedCategory === 4 ? 'selected' : ''
                    } ${isSelected[4] ? 'checked' : ''}`}
                    onClick={() => selectCategory(4)}
                  >
                    {t('style.shoes')}
                  </div>
                  <div
                    className={`item ${
                      selectedCategory === 5 ? 'selected' : ''
                    } ${isSelectedAssessories === true ? 'checked' : ''}`}
                    onClick={() => selectCategory(5)}
                  >
                    {t('style.accessories')}
                  </div>
                </div>
              </aside>
              <div className="divider" />

              <aside className="itemlist" id="itemlist">
                <div className="items">
                  {/** =================================================
                   * 액세서리 분기처리
                   * selectedCategory: number,
                   * 0: 상의, 1: 하의, 2: 아우터웨어, 3:가방, 4:슈즈, 5:액세서리
                  ====================================================== */}
                  {selectedCategory && selectedCategory === 5
                    ? (
                        (accessorieslist && accessorieslist[0].section) ||
                        []
                      ).map((section, sid) => (
                        <React.Fragment key={sid}>
                          <div className="title" key={sid}>
                            {section.title}
                          </div>
                          <div className="images">
                            {section.images.map((image, imgidx) => (
                              <div
                                className={`product ${
                                  selectedAccessoriesImages?.[sid]?.[imgidx] ===
                                  true
                                    ? 'selected'
                                    : ''
                                }`}
                                key={imgidx}
                              >
                                <img
                                  alt=""
                                  src={`${process.env.REACT_APP_IMATE_URL}/images/gucci_styling/${accessorieslist[0].dir}/${section.dir}/${image}?dummy=20230530_2`}
                                  onClick={() =>
                                    accessoriesChangeImage(sid, imgidx)
                                  }
                                />
                                <div className="check-icon">
                                  <CheckedIcon />
                                </div>
                              </div>
                            ))}
                          </div>
                        </React.Fragment>
                      ))
                    : (
                        (itemlist && itemlist[selectedCategory]?.section) ||
                        []
                      ).map((section, sid) => (
                        <React.Fragment key={sid}>
                          <div className="title">{section.title}</div>
                          <div className="images">
                            {section.images.map((image, imgidx) => (
                              <div
                                className={`product ${
                                  selectedImages?.[selectedCategory]?.[sid]?.[
                                    imgidx
                                  ] === true
                                    ? 'selected'
                                    : ''
                                }`}
                                key={imgidx}
                              >
                                <img
                                  alt=""
                                  src={`${process.env.REACT_APP_IMATE_URL}/images/gucci_styling/${itemlist[selectedCategory].dir}/${section.dir}/${image}?dummy=20230530_2`}
                                  onClick={() =>
                                    changeImage(selectedCategory, sid, imgidx)
                                  }
                                />
                                <div className="check-icon">
                                  <CheckedIcon />
                                </div>
                              </div>
                            ))}
                          </div>
                        </React.Fragment>
                      ))}
                </div>
              </aside>
            </article>
          </section>
        </div>
      </div>
    </main>
  );
}

export default Styling;
