import React from 'react';
import { useTranslation } from 'react-i18next';
import { convertHtmlForDisplay } from 'utils/string';

function VoiceTestLeftContents() {
  const { t } = useTranslation();

  return (
    <div className="container-left">
      <header className="header">
        <h2 className="header-title fs28to24">{t('title.camera-mic-test')}</h2>
        <p
          className="header-description fs16"
          dangerouslySetInnerHTML={{
            __html: convertHtmlForDisplay(t('desc.camera-mic-test')),
          }}
        />
      </header>
      <section className="read-sentence">
        <div className="read-sentence-wrapper">
          <p className="fs28">{t('desc.hello-Nice-to-meet-you')}</p>
        </div>
      </section>
    </div>
  );
}

export default VoiceTestLeftContents;
