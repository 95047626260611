import React from 'react';
import { useTranslation } from 'react-i18next';
import TinyEditor from 'components/editor/TinyEditor';
import { useCallbackPrompt } from 'utils/hooks';
import ExitModal from 'components/modal/ExitModal';
import { useResult } from 'utils/result';
import { divideSkillScope, divideSkillSection, useStatus } from 'utils/status';
import {
  formatTime,
  convertTextToSec,
  convertHtmlForDisplay,
  convertHtmlToText,
} from 'utils/string';

function Essay({ test, setCurrentState }) {
  const { t } = useTranslation();
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(true);
  const { recordResult } = useResult();
  const { setTestInfo, getTestInfo } = useStatus();
  const editorRef = React.useRef(null);
  const [cansubmit, SetCanSubmit] = React.useState(false);
  const { duration, doneCnt, maxDoneCnt } = getTestInfo();

  const onSubmitHandler = () => {
    setTestInfo({
      duration: null,
      doneCnt: null,
      maxDoneCnt: null,
      canSubmit: false,
      buttonName: null,
    });
    recordResult({
      type: divideSkillScope(test),
      section: divideSkillSection(test),
      sid: test?.uuid || test?.id, // 사용자가 직접 만든 문제는 uuid가 존재하고 테스트에 기본으로 제공하는 문제는 id가 존재함
      data: {
        text: editorRef.current.getContent(),
        question: convertHtmlToText(test?.question),
      },
    });
    setCurrentState('end');
  };

  React.useEffect(() => {
    const skillEndEventListener = (event) => {
      onSubmitHandler();
    };

    window.scrollTo(0, 0);

    window.document.addEventListener('skill_end', skillEndEventListener);
    return () =>
      window.document.removeEventListener('skill_end', skillEndEventListener);
  }, []);

  React.useEffect(() => {
    const testDoneEventListener = (event) => {
      onSubmitHandler();
    };

    if (cansubmit) {
      setTestInfo({
        duration,
        doneCnt,
        maxDoneCnt,
        canSubmit: true,
        buttonName: t('btn.submit'),
      });
    }

    window.document.addEventListener('test_done', testDoneEventListener);
    return () => {
      window.document.removeEventListener('test_done', testDoneEventListener);
    };
  }, [cansubmit]);

  const canSubmit = () => {
    if (editorRef.current.getContent().length >= 0) SetCanSubmit(true);
    else SetCanSubmit(false);
  };

  return (
    <main className="demo-styling-pages-protected-evaluation-subjectives-essay subjective">
      <ExitModal
        showPrompt={showPrompt}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
      />
      <div className="container">
        <div className="spliter">
          <section className="spliter-left">
            <div className="question">
              {test?.videoUrl !== '' && test?.videoUrl !== undefined ? (
                <div className="video-box">
                  <video
                    className="question-video"
                    src={`${process.env.REACT_APP_IMATE_URL}/${test?.videoUrl}`}
                    controls
                    autoPlay
                    playsInline
                  />
                </div>
              ) : (
                <></>
              )}
              <div className="styling-title-wrapper">
                <h3>{t('tag.essay')}</h3>
              </div>
              <pre
                dangerouslySetInnerHTML={{
                  __html: convertHtmlForDisplay(test?.question),
                }}
              />
            </div>
          </section>
          <section className="spliter-right">
            <TinyEditor
              ref={editorRef}
              className="answer"
              onEditorChange={() => {
                canSubmit();
              }}
              onKeuUp={() => {}}
            />
          </section>
        </div>
      </div>
    </main>
  );
}

export default Essay;
