import React, { useState } from 'react';
import LanguageSelect from './LanguageSelect';
import LanguageDistinction from './LanguageDistinction';
import DemoLanguageSelect from './DemoLanguageSelect';

const LanguageTests = ({ test, setCurrentState, assessment }) => {
  const [step, setStep] = useState(0);

  return (
    <>
      {step === 0 ? (
        // <LanguageSelect setStep={setStep} />
        <DemoLanguageSelect
          setStep={setStep}
          setCurrentState={setCurrentState}
          assessment={assessment}
        />
      ) : (
        <LanguageDistinction
          test={test}
          setCurrentState={setCurrentState}
          setStep={setStep}
        />
      )}
    </>
  );
};

export default LanguageTests;
