import React, { useRef, useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useCallbackPrompt, useGetMediaDevices, useResize } from 'utils/hooks';
import ExitModal from 'components/modal/ExitModal';
import { useResult } from 'utils/result';
import { useStatus } from 'utils/status';
import { executeAction } from 'utils/redux';
import {
  formatTime,
  formatKorTime,
  convertTextToSec,
  DemoformatTime,
} from 'utils/string';
import { __NO_LIMIT_DURATION__ } from 'constants/testtaker';
// import MediaSettingModal from 'components/modal/MediaSettingModal';
import IconModal from 'components/modal/IconModal';
import { ReactComponent as RecordingCheck } from 'assets/images/icon/Demo-recording-check.svg';
import { ReactComponent as FailedIcon } from 'assets/images/icon/failed-icon.svg';
import PieChart from 'components/common/PieChart';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as TimerIcon } from 'assets/images/icon/timer.svg';
import DemoModal from 'components/modal/DemoModal';
import * as Sentry from '@sentry/react';

const __RECORD_CNT_LIMIT__ = 2;

function DemoLanguageSpeaking({
  test,
  difficulty,
  language,
  setSpeakBlob,
  setCurrentState,
  selectedLanguages,
  setIsEntireLoading,
  currentStateIndex,
  setCurrentStateIndex,
  languageQuestion,
}) {
  const { t } = useTranslation();
  const windowSize = useResize();
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(true);
  const { setTestInfo, getTestInfo } = useStatus();
  const { duration, doneCnt, maxDoneCnt } = getTestInfo();
  const audioRef = useRef(null);
  const videoTagRef = useRef(null);
  const audioTagRef = useRef(null);
  const {
    stream,
    setStream,
    audioStream,
    setAudioStream,
    blob,
    setBlob,
    audioBlob,
    setAudioBlob,
    recordResult,
  } = useResult();
  const mediaRecorderRef = useRef(null);
  const audioRecorderRef = useRef(null);
  const chunksRef = useRef([]);
  const audioChunksRef = useRef([]);
  const [recordState, setRecordState] = useState('stop');
  const [recordTime, setRecordTime] = useState(convertTextToSec(2));
  const [recordCnt, setRecordCnt] = useState(0);
  const [canRecord, setCanRecord] = useState(true);
  const [animationId, setAnimationId] = useState();
  const [recordActive, setRecordActive] = useState(false);
  const [terminated, setTerminated] = useState(false);
  const [voiceRecognitionFailure, setVoiceRecognitionFailure] = useState(false);
  const [checkedRecord, setCheckedRecord] = useState(false);
  const [isRecord, setIsRecord] = useState(false);
  const [startTimer, setStartTimer] = useState(60);
  const [speakingQuestion, setSpeakingQuestion] = useState({});
  const [openRetryModal, setOpenRetryModal] = React.useState(false);
  const navigate = useNavigate();
  const [
    selectedDeviceCamera,
    setSelectedDeviceCamera,
    selectedDeviceMic,
    setSelectedDeviceMic,
    devicesCamera,
    devicesMic,
  ] = useGetMediaDevices();

  /** =======================
   * @function audioContextFn
   * audioContext생성 후 스펙트럼 표시
  =========================== */
  function audioContextFn() {
    const audioContext = new AudioContext();
    const sourceNode = audioContext.createMediaStreamSource(audioStream);
    const analyserNode = audioContext.createAnalyser();
    analyserNode.fftSize = 2048;
    sourceNode.connect(analyserNode);

    function draw() {
      setAnimationId(requestAnimationFrame(draw));

      const bufferLength = analyserNode.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);
      analyserNode.getByteFrequencyData(dataArray);

      let sum = 0;
      for (let i = 0; i < bufferLength; i += 1) {
        sum += dataArray[i];
      }

      const average = sum / bufferLength;

      if (average > 2) {
        setCheckedRecord(true);
      }
    }
    draw();
  }

  const onRecordStartHandler = () => {
    setOpenRetryModal(false);
    setRecordState('start');
    setRecordActive(true);
    setRecordTime(convertTextToSec(2));
    setStartTimer(0);

    setTimeout(() => {
      setRecordActive(false);
    }, 2000);

    audioContextFn();

    // videoTagRef.current.src = null;
    // audioTagRef.current.src = null;
    // videoTagRef.current.srcObject = stream;
    // audioTagRef.current.srcObject = audioStream;

    mediaRecorderRef.current.start();
    audioRecorderRef.current.start();
  };

  const onRecordStopHandler = () => {
    setRecordState('stop');
    setRecordTime(convertTextToSec(2));

    if (checkedRecord) {
      setTerminated(true);
      setIsRecord(true);
    } else {
      setTerminated(false);
      setIsRecord(false);
      setRecordCnt(0);
      setBlob(null);
      setAudioBlob(null);
      setVoiceRecognitionFailure(true);
      setCheckedRecord(false);
    }
    // videoTagRef.current.srcObject = stream;
    // videoTagRef.current.src = null;
    // audioTagRef.current.srcObject = audioStream;
    // audioTagRef.current.src = null;

    let tmpRecCnt =
      recordCnt + 1 >= __RECORD_CNT_LIMIT__
        ? __RECORD_CNT_LIMIT__
        : recordCnt + 1;

    setRecordCnt(tmpRecCnt);

    mediaRecorderRef.current.stop();
    audioRecorderRef.current.stop();
  };

  const onSubmitHandler = () => {
    if (currentStateIndex < selectedLanguages.length - 1) {
      setCurrentStateIndex(currentStateIndex + 1);
      setTestInfo({
        duration: null,
        doneCnt: null,
        maxDoneCnt: null,
        canSubmit: false,
      });
    } else {
      setCurrentState('end');
      setTestInfo({
        duration: null,
        doneCnt: null,
        maxDoneCnt: null,
        canSubmit: false,
      });
    }
    setSpeakBlob(null);
    setBlob(null);
    setAudioBlob(null);
    setRecordCnt(0);
    setTerminated(false);
    setIsRecord(false);
    setCheckedRecord(false);
    setRecordTime(999999);
    // videoTagRef.current.srcObject = stream;
    // videoTagRef.current.src = null;
    // audioTagRef.current.srcObject = audioStream;
    // audioTagRef.current.src = null;
  };

  const transLimitTime = (time) => {
    const separation = time;
    const regex = /[^0-9]/g;
    const result = separation.replace(regex, '');
    const numberResult = Number(result);

    if (result.length < 2) {
      if (numberResult === 1) {
        return t('text-field.input.1min');
      }
      return t('text-field.input.2mins');
    }
    if (numberResult === 30) {
      return t('text-field.input.30secs');
    }
  };

  const retryRecordClickHandler = (boolean) => {
    setRecordTime(convertTextToSec(2));
    setVoiceRecognitionFailure(boolean);
    setRecordCnt(0);
    setStartTimer(60);
    setBlob(null);
    setAudioBlob(null);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setRecordTime(convertTextToSec(2));
  }, []);

  useEffect(() => {
    if (Object.keys(speakingQuestion).length === 0) return;
    audioRef.current = new Audio(speakingQuestion?.url);
    audioRef.current.play().catch(() => {});

    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current = null;
      }
    };
  }, [speakingQuestion]);

  useEffect(() => {
    if (startTimer <= 0) {
      setTimeout(() => {
        onRecordStartHandler();
      }, 200);

      return;
    }

    const countdown = setTimeout(() => {
      setStartTimer(startTimer - 1);
    }, 1000);

    return () => clearTimeout(countdown);
  }, [startTimer]);

  useEffect(() => {
    let countdown;

    if (recordTime <= 0) {
      setTimeout(() => {
        onRecordStopHandler();
      }, 1000);
      return;
    }

    if (recordState === 'start') {
      countdown = setTimeout(() => {
        setRecordTime(recordTime - 1);
      }, 1000);
    }

    return () => clearTimeout(countdown);
  }, [recordTime, recordState]);

  useEffect(() => {
    if (recordCnt >= __RECORD_CNT_LIMIT__) {
      return setCanRecord(false);
    }
    return setCanRecord(true);
  }, [recordCnt]);

  useEffect(() => {
    const testDoneEventListener = (event) => {
      onSubmitHandler();
    };

    window.document.addEventListener('test_done', testDoneEventListener);
    return () => {
      window.document.removeEventListener('test_done', testDoneEventListener);
    };
  }, [blob]);

  useEffect(() => {
    if (!stream || !audioStream) {
      (async () => {
        await window.navigator.mediaDevices
          .getUserMedia({
            video: {
              deviceId: selectedDeviceCamera?.deviceId,
              width: 640,
              height: 480,
            },
            audio: {
              echoCancellation: true,
              deviceId: selectedDeviceMic?.deviceId,
            },
          })
          .then((stream) => {
            videoTagRef.current.srcObject = stream;
            setStream(stream);
          })
          .catch((e) => {
            Sentry.captureException(e);
            console.error(e);
          });

        await window.navigator.mediaDevices
          .getUserMedia({
            video: false,
            audio: true,
          })
          .then((stream) => {
            audioTagRef.current.srcObject = stream;
            setAudioStream(stream);
          })
          .catch((e) => {
            Sentry.captureException(e);
            console.error(e);
          });
      })();
      return;
    }

    chunksRef.current = [];
    audioChunksRef.current = [];
    let mimeType;

    const options = [
      'video/webm; codecs=vp9',
      'video/webm; codecs=vp8',
      'video/webm; codecs=h264',
      'video/mp4',
    ];
    const audioOptions = [
      'audio/webm; codecs=opus',
      'audio/webm; codecs=vorbis',
      'audio/mp4',
      'audio/ogg; codecs=opus',
      'audio/ogg; codecs=vorbis',
    ];

    for (let i in options) {
      if (MediaRecorder.isTypeSupported(options[i])) {
        mimeType = options[i];
        break;
      }
    }

    // video 설정
    videoTagRef.current.srcObject = stream;
    mediaRecorderRef.current = new MediaRecorder(stream, {
      mimeType,
    });
    mediaRecorderRef.current.onstart = (event) => {
      // videoTagRef.current.srcObject = stream;
      // videoTagRef.current.src = null;
    };
    mediaRecorderRef.current.onstop = (event) => {
      const blob = new Blob(chunksRef.current, { type: 'video/mp4' });
      blob.name = 'video';
      setBlob(blob);

      chunksRef.current = [];
      // videoTagRef.current.srcObject = stream;
      // videoTagRef.current.src = null;
    };
    mediaRecorderRef.current.ondataavailable = (event) => {
      chunksRef.current.push(event.data);
    };

    for (let i in audioOptions) {
      if (MediaRecorder.isTypeSupported(audioOptions[i])) {
        mimeType = audioOptions[i];
        break;
      }
    }
    // audio 설정
    audioTagRef.current.srcObject = audioStream;
    audioRecorderRef.current = new MediaRecorder(audioStream, {
      mimeType,
    });
    audioRecorderRef.current.onstart = (event) => {
      audioTagRef.current.srcObject = audioStream;
      audioTagRef.current.src = null;
    };
    audioRecorderRef.current.onstop = (event) => {
      const audioBlob = new Blob(audioChunksRef.current, {
        type: 'audio/flac',
      });
      audioBlob.name = 'audio';
      setAudioBlob(audioBlob);

      audioChunksRef.current = [];
      audioTagRef.current.srcObject = audioStream;
      audioTagRef.current.src = null;
    };
    audioRecorderRef.current.ondataavailable = (event) => {
      audioChunksRef.current.push(event.data);
    };
  }, [stream, audioStream]);

  useEffect(() => {
    if (terminated) {
      cancelAnimationFrame(animationId);
    }
  }, [animationId, terminated]);

  useEffect(() => {
    let btnName = '';
    if (doneCnt === maxDoneCnt) {
      btnName = t('btn.submit');
    } else {
      btnName = t('btn.next');
    }

    if (blob || audioBlob) {
      setSpeakBlob(blob);
      setTestInfo({
        duration,
        doneCnt,
        maxDoneCnt,
        canSubmit: true,
        buttonName: btnName,
      });
    }

    const skillEndEventListener = (event) => {
      onSubmitHandler();
    };

    window.document.addEventListener('skill_end', skillEndEventListener);
    return () =>
      window.document.removeEventListener('skill_end', skillEndEventListener);
  }, [blob, audioBlob]);

  const onRecordRetryHandler = () => {
    setOpenRetryModal(true);
  };

  const onStartButtonHandler = () => {
    setStartTimer(0);
  };

  return (
    <main className="demo-pages-protected-evaluation-subjectives-video">
      <ExitModal
        showPrompt={showPrompt}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
      />
      <div className="container">
        <div className="spliter">
          <section className="spliter-left">
            <div className="question">
              <pre className="fs18">{languageQuestion && languageQuestion}</pre>
            </div>
            <div className="demo-timer">
              <div className="timer-wrapper">
                <div className="timer-title">{t('evaluation.timer')}</div>

                <div className="time-box">
                  <div className="timer-container">
                    <div className="time-title">{t('title.timer-ready')}</div>
                    <div className="timer-box">
                      <div>{DemoformatTime(startTimer).substring(0, 2)}</div>
                      <div className="timer-line" />
                      <div>{DemoformatTime(startTimer).substring(2)}</div>
                    </div>
                  </div>
                  <div className="timer-container">
                    <div className="time-title">{t('title.timer-answer')}</div>
                    <div className="timer-box">
                      <div>{DemoformatTime(recordTime).substring(0, 2)}</div>
                      <div className="timer-line" />
                      <div>{DemoformatTime(recordTime).substring(2)}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="spliter-right">
            <div
              className={`video ${
                recordCnt > 0 && recordState === 'stop' ? 'stop' : 'start'
              }`}
            >
              <video id="video-interview" ref={videoTagRef} autoPlay muted />
              <audio ref={audioTagRef} />
            </div>
            {windowSize.width < 1024 && (
              <div className="demo-timer">
                <div className="timer-wrapper">
                  <div className="time-box">
                    <div className="timer-container">
                      <div className="time-title">{t('title.timer-ready')}</div>
                      <div className="timer-box">
                        <div className="timer-icon-box">
                          <TimerIcon />
                        </div>
                        <div>{DemoformatTime(startTimer).substring(0, 2)}</div>:
                        <div>{DemoformatTime(startTimer).substring(2)}</div>
                      </div>
                    </div>
                    <div className="timer-container">
                      <div className="time-title">
                        {t('title.timer-answer')}
                      </div>
                      <div className="timer-box">
                        <div className="timer-icon-box">
                          <TimerIcon />
                        </div>
                        <div>{DemoformatTime(recordTime).substring(0, 2)}</div>:
                        <div>{DemoformatTime(recordTime).substring(2)}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {recordCnt === 0 ? (
              <>
                <div className={`record-cnt ${recordState}`}>
                  {t('evaluation.record_rules', {
                    timer: t('evaluation.timer.two_minutes'),
                  })}
                </div>
                <div className="record-helper">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: t('evaluation.record_guide_first_try'),
                    }}
                  />
                </div>
              </>
            ) : recordCnt === 1 ? (
              <>
                <div className={`record-cnt ${recordState}`}>
                  {t('evaluation.record_rules_once', {
                    timer: t('evaluation.timer.two_minutes'),
                  })}
                </div>
                <div className="record-helper">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: t('evaluation.record_guide_second_try'),
                    }}
                  />
                </div>
              </>
            ) : (
              <>
                <article className="alert demo">
                  <div>
                    <RecordingCheck />
                  </div>
                  <div>
                    {t('evaluation.language_test.speaking.record_complete')}
                  </div>
                </article>
              </>
            )}
            {canRecord && (
              <div className="buttons">
                <button
                  className={`start common-button fsbtn16 black
                      ${canRecord && 'active'}`}
                  onClick={
                    recordState === 'stop'
                      ? canRecord
                        ? !recordCnt
                          ? onStartButtonHandler
                          : onRecordRetryHandler
                        : null
                      : onRecordStopHandler
                  }
                  disabled={recordState === 'start' ? recordActive : false}
                >
                  {recordState === 'stop'
                    ? !recordCnt
                      ? t('btn.start')
                      : t('evaluation.btn_retry')
                    : t('evaluation.btn_stop')}
                </button>
              </div>
            )}
          </section>
        </div>
      </div>

      {openRetryModal && (
        <DemoModal
          className="demo-g-video"
          open={openRetryModal}
          close={() => setOpenRetryModal(false)}
          mainTitle={t('title.retry-question')}
          subTitle={t('desc.retry-question')}
          submit={onRecordStartHandler}
        />
      )}

      {voiceRecognitionFailure && (
        // 음성인식 실패 시 모달창
        <IconModal
          open={voiceRecognitionFailure}
          close={setVoiceRecognitionFailure}
          icon={<FailedIcon />}
          title={t('dialog.title.no-voice')}
          desc={t('dialog.desc.no-voice')}
          btnLabel={t('btn.redo')}
          onClick={retryRecordClickHandler}
        />
      )}
    </main>
  );
}

export default DemoLanguageSpeaking;
