import React from 'react';
import { useTranslation } from 'react-i18next';
import { executeAction } from 'utils/redux';
import { getTestInfo } from 'store/slices/testtakerSlice';
import { useProgress } from 'utils/progress';
import { Radio, Checkbox, MenuItem } from '@mui/material';
import { convertHtmlForDisplay, spaceRegex, boldRegex } from 'utils/string';
import * as Sentry from '@sentry/react';
import SourceCode from './SourceCode';

const DemoQuestion = ({ sid, tid, selectedValue, setSelectedValue }) => {
  const { t } = useTranslation();
  const lang = t('lang');
  const { setIsEntireLoading } = useProgress();
  const [test, setTest] = React.useState({});
  const [isMultiChoice, setisMultiChoice] = React.useState(false);
  const [checkedState, setCheckedState] = React.useState([]);
  const [testTitle, setTestTitle] = React.useState('');
  const [code, setCode] = React.useState('');

  React.useEffect(() => {
    if (!tid) return;

    setIsEntireLoading(true);
    setisMultiChoice(false);
    executeAction(getTestInfo({ tid, lang }))
      .unwrap()
      .then(({ doc }) => {
        setSelectedValue(-1);
        setTest(doc || {});
        const title = doc?.[`question_${lang}`];
        const codeTxtIdx = title.indexOf('```');
        if (codeTxtIdx >= 0) {
          setTestTitle(title.substring(0, codeTxtIdx));
          setCode(title.substring(codeTxtIdx));
        } else {
          setTestTitle(title);
          setCode('');
        }
        if (doc?.correct?.length > 1) {
          const initCheckted = new Array(doc?.[`answer_${lang}`].length).fill(
            false
          );
          setCheckedState(initCheckted);
          setisMultiChoice(true);
        }
        setIsEntireLoading(false);
      })
      .catch((e) => {
          Sentry.captureException(e);
          console.dir(e);
        setSelectedValue(-1);
        setTest({});
        setIsEntireLoading(false);
      });
  }, [tid, lang]);

  const handleOnChange = (position) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );

    const selected = [];
    updatedCheckedState.forEach((item, index) => {
      if (item) {
        selected.push(index);
      }
    });

    setCheckedState(updatedCheckedState);
    setSelectedValue(selected);
  };

  if (!tid) {
    return <div>no test</div>;
  }

  return (
    <div className="separater pages-protected-evaluation-objectives-common-question">
      <section className="separater-left">
        <div className="question fs18">
          {/* <pre className="fs18">
            {testTitle}
          </pre> */}
          <pre
            className="fs18"
            dangerouslySetInnerHTML={{
              __html: convertHtmlForDisplay(testTitle)
                .replace(spaceRegex, '\n')
                .replace(boldRegex, ''),
            }}
          />
          {test?.url?.length > 0 && (
            <a
              href={`${process.env.REACT_APP_IMATE_URL}/${test?.url}`}
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt=" "
                src={`${process.env.REACT_APP_IMATE_URL}/${test?.url}`}
              />
            </a>
          )}
        </div>
        {code !== '' ? (
          <SourceCode
            language={test?.[`category_${t('lang')}`]}
            code={code}
            isSub={false}
          />
        ) : (
          <></>
        )}
      </section>
      <section className="separater-right">
        <div className="label">
          {isMultiChoice === true
            ? t('desc.select-multiple-choice')
            : t('desc.select-only-one')}
        </div>
        <div className="answer">
          <ul className="answer-list">
            {(test?.[`answer_${t('lang')}`] || [])
              .filter((x) => {
                if (typeof x === 'boolean') {
                  return String(x);
                }
                return Boolean(x);
              })
              .map((answer, idx) => (
                <li
                  className={`answer-list-item ${
                    (isMultiChoice === true
                      ? checkedState[idx]
                      : selectedValue === idx) && 'selected'
                  }`}
                  onClick={() => {
                    handleOnChange(idx);
                    setSelectedValue(idx);
                  }}
                >
                  <div className="container">
                    {isMultiChoice === true ? (
                      <MenuItem
                        value={answer}
                        selected={checkedState[idx] === true}
                        key={idx}
                      >
                        <Checkbox
                          className="radio-button"
                          checked={checkedState[idx]}
                          onChange={() => {
                            handleOnChange(idx);
                          }}
                          sx={{
                            color: '#2E3338',
                            '&.Mui-checked': {
                              color: '#FFFFFF',
                            },
                          }}
                          value={answer}
                          name="radio-buttons"
                          inputProps={{ 'aria-label': answer }}
                        />
                      </MenuItem>
                    ) : (
                      <Radio
                        className="radio-button"
                        checked={selectedValue === idx}
                        onChange={(event) => {
                          setSelectedValue(idx);
                        }}
                        value={answer}
                        name="radio-buttons"
                        inputProps={{ 'aria-label': answer }}
                      />
                    )}
                    {answer.indexOf('```') === 0 ? (
                      <SourceCode
                        language={test?.[`category_${t('lang')}`]}
                        code={answer}
                        isSub
                      />
                    ) : (
                      !test?.[`url_${idx}`]?.length && (
                        <p
                          className="fs16"
                          dangerouslySetInnerHTML={{
                            __html: convertHtmlForDisplay(
                              typeof answer === 'boolean'
                                ? JSON.stringify(answer)
                                : answer
                            ),
                          }}
                        />
                      )
                    )}
                    {test?.[`url_${idx}`]?.length > 0 && (
                      <div className="hasImage" style={{ width: '100%' }}>
                        <img
                          alt=""
                          src={`${process.env.REACT_APP_IMATE_URL}/${
                            test?.[`url_${idx}`]
                          }`}
                        />
                        <p className="fs16">
                          {typeof answer === 'boolean'
                            ? JSON.stringify(answer)
                            : answer}
                        </p>
                      </div>
                    )}
                  </div>
                </li>
              ))}
          </ul>
        </div>
      </section>
    </div>
  );
};

export default DemoQuestion;
