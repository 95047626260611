import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { convertHtmlForDisplay } from 'utils/string';
import AnswerListItem from 'components/languageTest/AnswerListItem';
import { useResult } from 'utils/result';
import { useStatus } from 'utils/status';
import { executeAction } from 'utils/redux';
import {
  postEnglishQuestion,
  postChineseQuestion,
  postJapaneseQuestion,
  postKoreanQuestion,
} from 'store/slices/testtakerSlice';
import DemoAnswerListItem from 'components/languageTest/DemoAnswerListItem';
import { useResize } from 'utils/hooks';
import * as Sentry from '@sentry/react';

function DemoLanguageMultipleChoice({
  test,
  language,
  setIsEntireLoading,
  setExampleStep,
  questionData,
  setQuestionData,
}) {
  const { t } = useTranslation();
  const { recordResult } = useResult();
  const { setTestInfo, getTestInfo } = useStatus();
  const { duration, doneCnt, maxDoneCnt } = getTestInfo();
  const windowSize = useResize();

  const [postQuestionData, setPostQuestionData] = useState({});
  const [selectedValue, setSelectedValue] = useState('');
  const [selectedNumber, setSelectedNumber] = useState();
  const [canDone, setCanDone] = useState(false);
  const [curIdx, setCurIdx] = useState(0);

  /** ==================
   * @function postQuestionHandler
   * 1. 선택한 답변의 value 값을 input_answer에 저장
   * 2. setPostQuestionData에 json으로 변환하여 저장
   * 3. postEnglishQuestion에 api 재요청
   * 4. 선택한 답변의 index값과 value 초기화
  ====================== */
  const postQuestionHandler = () => {
    const postSelectedValue = questionData;
    postSelectedValue.input_answer = selectedValue;

    setPostQuestionData({ ...postSelectedValue });

    setSelectedValue('');
    setSelectedNumber();
  };

  /** ==================
   * 영어 독해문제 비동기 통신
   * 1. 첫 로딩 시 빈 객체 postQuestionData를 보낸 후 첫번째 문제를 응답받는다.
   * 2. 선택한 selectedValue를 setPostQuestionData에 json형식으로 변환하여 요청
   * 3. 다음 문제 응답
  ====================== */
  const postQuestion = (lang) => {
    switch (lang) {
      case 'english':
        return postEnglishQuestion({ postQuestionData });
      case 'chinese':
        return postChineseQuestion({ postQuestionData });
      case 'japanese':
        return postJapaneseQuestion({ postQuestionData });
      case 'korean':
        return postKoreanQuestion({ postQuestionData });
    }
  };

  /** ==================
   * @function onNextHandler
   * 다음버튼 클릭 이벤트 핸들러
   * setTestInfo:
   * Header에 timer, 현재 테스트 count, 최대 테스트 count, 버튼 활성화, 버튼 텍스트를 지정
   * @returns
  ====================== */
  const onNextHandler = () => {
    if (!canDone) return;
    postQuestionHandler();

    setCurIdx(curIdx + 1);

    let dur;
    if (test?.limit) {
      dur = test?.limit * 60;
    } else {
      dur = 900;
    }

    setTestInfo({
      duration: dur,
      doneCnt: doneCnt + 1,
      maxDoneCnt,
      canSubmit: false,
      buttonName: t('btn.next'),
    });
  };

  const onSubmit = () => {
    setExampleStep(2);
    setTestInfo({
      duration: null,
      doneCnt: null,
      maxDoneCnt: null,
      canSubmit: false,
    });
  };

  /** ==================
   * 문제 답변을 선택 후 다음 버튼 클릭 이벤트
  ====================== */
  useEffect(() => {
    const testDoneEventListener = (event) => {
      onNextHandler();
    };

    window.document.addEventListener('test_done', testDoneEventListener);
    return () => {
      window.document.removeEventListener('test_done', testDoneEventListener);
    };
  }, [curIdx, canDone]);

  /** ==================
   * 다음으로 넘어가는 버튼 활성화
  ====================== */
  useEffect(() => {
    if (selectedValue === '') {
      return setCanDone(false);
    }

    setCanDone(true);
  }, [selectedValue]);

  useEffect(() => {
    setIsEntireLoading(true);

    executeAction(postQuestion(language))
      .unwrap()
      .then((res) => {
        setQuestionData(res);
        setIsEntireLoading(false);
      })
      .catch((e) => {
        Sentry.captureException(e);
        console.error(e);
        setIsEntireLoading(false);
      });
  }, [postQuestionData]);

  useEffect(() => {
    /** ---------------
     * 테스트를 완료 했을 때 modal창을 오픈
     * level 화면에 표시
    ------------------- */
    if (questionData?.result) {
      onSubmit(questionData);
    }

    /** ---------------
     * 시간 15분이 초과 됐을 시
     * 현재 완료된 부분만 재요청 => result값 받은 후
     * db에 저장
    ------------------- */
    const skillEndEventListener = () => {
      setIsEntireLoading(true);
      executeAction(postQuestion(language))
        .unwrap()
        .then((res) => {
          if (res.result !== undefined) {
            setIsEntireLoading(false);
          }
        })
        .catch((e) => {
          Sentry.captureException(e);
          console.error(e);
          setIsEntireLoading(false);
        });
    };

    window.document.addEventListener('skill_end', skillEndEventListener);
    return () => {
      window.document.removeEventListener('skill_end', skillEndEventListener);
    };
  }, [questionData]);

  useEffect(() => {
    if (!questionData || selectedNumber < 0 || selectedNumber === undefined)
      return;
    setSelectedValue(questionData?.choices[selectedNumber]);
  }, [selectedNumber]);

  /** ==================
   * 다음으로 넘어가는 버튼 활성화
  ====================== */
  useEffect(() => {
    if (selectedValue === '') {
      return setCanDone(false);
    }

    setCanDone(true);
  }, [selectedValue]);

  useEffect(() => {
    setTestInfo({
      duration,
      doneCnt,
      maxDoneCnt: '-',
      canSubmit: canDone,
      buttonName: t('btn.next'),
    });
  }, [canDone]);

  return (
    Boolean(Object.keys(questionData).length !== 0) && (
      <>
        {windowSize?.width > 1023 && (
          <div className="objectives-title-box">
            <h3>{t('evaluation.language_test.title')}</h3>
          </div>
        )}
        <div className="separater pages-protected-evaluation-objectives-common-english-question">
          <section className="separater-left">
            <div className="question fs18">
              {(questionData?.passage || []).map((pass, idx) => (
                <React.Fragment key={idx}>
                  <p
                    className="multiple-question"
                    dangerouslySetInnerHTML={{
                      __html: convertHtmlForDisplay(pass),
                    }}
                  />
                </React.Fragment>
              ))}
            </div>
          </section>
          <section className="separater-right">
            {/* todo: 하나만 선택 UI상 변역이 안되보이는 부분으로 인한 주석 디자인 논의가 필요 */}
            {/* <div className="label">{t('desc.select-only-one')}</div> */}
            <p>{questionData?.question}</p>
            <div className="answer">
              <ul className="answer-list">
                {(questionData?.choices || []).map((choice, idx) => (
                  <DemoAnswerListItem
                    selectedNumber={selectedNumber}
                    setSelectedNumber={setSelectedNumber}
                    idx={idx}
                    answer={choice}
                    key={idx}
                  />
                  // <AnswerListItem
                  //   selectedNumber={selectedNumber}
                  //   setSelectedNumber={setSelectedNumber}
                  //   idx={idx}
                  //   answer={choice}
                  //   key={idx}
                  // />
                ))}
              </ul>
            </div>
          </section>
        </div>
      </>
    )
  );
}

export default DemoLanguageMultipleChoice;
