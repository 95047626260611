import React from 'react';
import {
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TablePagination,
  Box,
} from '@mui/material';
import { useStatus } from 'utils/status';
import { useNavigate, Navigate } from 'react-router-dom';
import { executeAction } from 'utils/redux';
import { getCandidateInAssessment } from 'store/slices/authSlice';
import {
  getAssessmentInfo,
} from 'store/slices/testtakerSlice';
import JSConfetti from 'js-confetti';
import { useTranslation, Trans } from 'react-i18next';
import OBPIResult from './obpitest/OBPIResult';

const OBPI_TESTS = [
  {
    category: 'fellowship',
    title: 'title.fellowship',
    left: 'desc.fellowship-left',
    right: 'desc.fellowship-right',
  },
  {
    category: 'authority',
    title: 'title.authority',
    left: 'desc.authority-left',
    right: 'desc.authority-right',
  },
  {
    category: 'conformity',
    title: 'title.conformity',
    left: 'desc.conformity-left',
    right: 'desc.conformity-right',
  },
  {
    category: 'emotion',
    title: 'title.emotion',
    left: 'desc.emotion-left',
    right: 'desc.emotion-right',
  },
  {
    category: 'detail',
    title: 'title.detail',
    left: 'desc.detail-left',
    right: 'desc.detail-right',
  },
  {
    category: 'proficiency',
    title: 'title.proficiency',
    left: 'desc.proficiency-left',
    right: 'desc.proficiency-right',
  },
  {
    category: 'work-orientation',
    title: 'title.work-orientation',
    left: 'desc.work-orientation-left',
    right: 'desc.work-orientation-right',
  },
  {
    category: 'patience',
    title: 'title.patience',
    left: 'desc.patience-left',
    right: 'desc.patience-right',
  },
  {
    category: 'fair-mindedness',
    title: 'title.fair-mindedness',
    left: 'desc.fair-mindedness-left',
    right: 'desc.fair-mindedness-right',
  },
  {
    category: 'loyalty',
    title: 'title.loyalty',
    left: 'desc.loyalty-left',
    right: 'desc.loyalty-right',
  },
  {
    category: 'disclosure',
    title: 'title.disclosure',
    left: 'desc.disclosure-left',
    right: 'desc.disclosure-right',
  },
  {
    category: 'initiative',
    title: 'title.initiative',
    left: 'desc.initiative-left',
    right: 'desc.initiative-right',
  },
];

function Done() {
  const { t } = useTranslation();
  const { uid, aid, email: cid, logoURL } = useStatus();
  const navigate = useNavigate();
  const [assessment, setAssessment] = React.useState({});
  const [objectiveTests, setObjectiveTests] = React.useState([]);
  const [obpiTest, setOBPITest] = React.useState(null);
  const [candidate, setCandidate] = React.useState({});
  const [objectiveScores, setObjectiveScores] = React.useState({});
  const [refreshing, setRefreshing] = React.useState(true);
  const [obpiStanine, setOBPIStanine] = React.useState([]);
  const jsConfetti = new JSConfetti();

  const FirecrackerEvent = () => {
    jsConfetti.addConfetti({
      confettiColors: [
        '#ff0a54',
        '#ff477e',
        '#ff7096',
        '#ff85a1',
        '#fbb1bd',
        '#f9bec7',
      ],
      confettiRadius: 5,
      confettiNumber: 500,
    });
  };

  React.useEffect(() => {
    FirecrackerEvent();

    const docElem = window.document.documentElement;
    if (docElem.exitFullscreen) {
      docElem.exitFullscreen();
    } else if (docElem.webkitExitFullscreen) {
      docElem.webkitExitFullscreen();
    } else if (docElem.msExitFullscreen) {
      docElem.msExitFullscreen();
    } else if (docElem.cancelFullScreen) {
      docElem.cancelFullScreen();
    }
  }, []);

  React.useEffect(() => {
    const obpistanine = new Array();
    for (const obpis of OBPI_TESTS) {
      const score = obpiTest?.traits?.find((x) => x.scale === obpis.category);
      obpistanine.push(score?.stanine);
    }
    setOBPIStanine(obpistanine);
  }, [obpiTest]);

  React.useEffect(() => {
    if (!uid || !aid) {
      navigate('/testtaker/auth/invalidinvitation', {
        state: { uid, aid, logoURL },
        replace: true,
      });
      return;
    }


    executeAction(
      getAssessmentInfo({
        uid,
        aid,
        // uid: 'NxXngb97XSe6Dbpc0sBkDak8ywx2',
        // aid: 'FXeZME07HlAhiftBbTRN',
      })
    )
      .unwrap()
      .then(({ doc }) => {
        setAssessment(doc || {});
        // console.log("aid=" + aid + " setAssessment");
        // console.log(doc);
      })
      .catch((e) => {
        console.dir(e);
        setAssessment({});
      });
  }, [aid]);

  React.useEffect(() => {
    const results = candidate?.results;

    const _objectiveScores = {};
    const tasks = new Array();
    for (const skill of objectiveTests) {
      const suuid = skill?.uuid;
      const result = results?.[suuid];
      const lang2 = assessment?.language;
      if (!result) continue;
      const temp = new Array();
      const category = {};
      const array = new Array();
      const answers = skill.question;

      for (const [key, val] of Object.entries(result || {})) {
        const tid = key;
        const answer = answers.find((x) => x.tid === tid);
        // console.log(answer);
        if (!category[answer.testInfo.skill_en])
          category[answer.testInfo.skill_en] = {};
        if (!category[answer.testInfo.skill_en].cnt)
          category[answer.testInfo.skill_en].cnt = 0;
        if (!category[answer.testInfo.skill_en].correct)
          category[answer.testInfo.skill_en].correct = 0;
        category[answer.testInfo.skill_en].cnt += 1;
        if (answer?.testInfo?.correct.length > 1) {
          let isCorrect = true;
          answer?.testInfo?.correct.forEach((cor, idx) => {
            isCorrect = isCorrect && cor === val?.[0][idx];
          });
          temp.push(true);
          category[answer.testInfo.skill_en].correct += 1;
        } else {
          if (answer?.testInfo?.correct?.[0] === val?.[0]) {
            category[answer.testInfo.skill_en].correct += 1;
          }
          temp.push(answer?.testInfo?.correct?.[0] === val?.[0]);
        }
      }
      let _score = 0;
      if (temp.length) _score = temp.filter((x) => x).length / temp.length;
      const correct = temp.filter((x) => x).length;
      _objectiveScores[suuid] = new Array();
      _objectiveScores[suuid].push(_score);
      _objectiveScores[suuid].push(correct);
      _objectiveScores[suuid].push(temp.length);
    }

    setObjectiveScores(_objectiveScores);
  }, [objectiveTests]);

  React.useEffect(() => {
    if (!assessment || !Object.keys(assessment || {}).length) {
      return;
    }

    const { tests } = assessment || {};
    const newObjectiveTests = new Array();
    const newSubjectiveTests = new Array();
    const newLibraryTests = new Array();
    (tests || []).forEach((test, idx) => {
      if (test?.testcnt > 0 || test?.skills?.length > 0) {
        (test?.skills || []).forEach((skill) => {
          if (candidate?.results?.[skill.uuid]) {
            newObjectiveTests.push(skill);
          }
        });
        return;
      }

      const { section } = test;
      if (
        section === 'video' ||
        section === 'essay' ||
        section === 'multiple-choice' ||
        section === 'file-upload' ||
        section === 'spreadsheets' ||
        section === 'code'
      ) {
        newSubjectiveTests.push(test);
      } else if (
        test?.title_en?.indexOf('인성/심리검사') >= 0 ||
        test?.title_en?.toUpperCase().indexOf('KHAI') >= 0
      ) {
        // if(test?.title_en?.indexOf('OBPI') >= 0) {
        setOBPITest(candidate?.results?.obpi);
      } else if (
        test?.question_type?.indexOf('tag.subjective') >= 0 ||
        test?.question_type?.indexOf('tag.code') >= 0 ||
        test?.question_type?.indexOf('tag.video') >= 0
      ) {
        newLibraryTests.push(test);
      } else {
        newObjectiveTests.push(test);
      }
      // console.log(test);
    });
    setObjectiveTests(newObjectiveTests || []);
  }, [assessment, candidate]);

  React.useEffect(() => {
    if (refreshing) {
      executeAction(
        getCandidateInAssessment({
          // uid: 'NxXngb97XSe6Dbpc0sBkDak8ywx2',
          // aid: 'FXeZME07HlAhiftBbTRN',
          // email: 'kbchoi93@gmail.com',
          uid,
          aid,
          email: cid,
        })
      )
        .unwrap()
        .then(({ doc }) => {
          // console.log(doc);
          setCandidate(doc || {});
          setRefreshing(false);
        })
        .catch((e) => {
          console.dir(e);
          setRefreshing(false);
        });
    }
  }, [aid, cid, refreshing]);

  const getScoreObjective = (test) =>
    // console.log(objectiveScores);
    [
      objectiveScores?.[test?.uuid]?.[0]?.toFixed?.(2) || 0,
      objectiveScores?.[test?.uuid]?.[1] || 0,
      objectiveScores?.[test?.uuid]?.[2] || 0,
    ];
  const getAverageObjectiveScore = () => {
    let score = 0;
    let cnt = 0;
    Object.entries(objectiveScores || {}).forEach(([key, val], idx) => {
      const skill = (objectiveTests || []).find((x) => x.uuid === key);

      if (skill?.id !== '71') {
        // skill.id === 2 : Culture fit.
        if (val?.[0] >= 0 && val?.[0] <= 1) {
          score += val?.[0];
          cnt += 1;
        }
      }
    });

    if (cnt === 0) return 0;

    return (score / cnt)?.toFixed?.(2);
  };

  const getTestColorClass = (score) => {
    if (score >= 0.51) return 'blue';
    if (score >= 0.26) return 'orange';
    if (score > 0) return 'red';
    return '';
  };

  return (
    <main className="pages-protected-evaluation-done">
      <div className="container">
        <div className="container-inner">
          <header className="header">
            <div className="header-img" />
            <div className="header-description flex fs16">
              <span
                className="center"
                dangerouslySetInnerHTML={{ __html: t('desc.final-evaluation') }}
              />
            </div>
          </header>
          {/* <div className="header-title fs28to24">
            <Trans t={t} i18nKey={"evaluation-done.title.thank-you"} />
          </div>
          <div className="header-description fs16">
            <Trans t={t} i18nKey={"evaluation-done.desc.thank-you"} />
          </div>
          <div className="objective-result"
            style={{display:(objectiveTests?.length === 0)? "none" : "block"}}
          >
            <div className="average-score">
              <div className="average-score-title fs18">
                <Trans t={t} i18nKey={"title.test-average-score"} />
              </div>
              <div className="average-score-graph">
                <div className="single-chart">
                  <svg viewBox="0 0 36 36" className={`circular-chart ${getTestColorClass(getAverageObjectiveScore())}`}>
                    <path className="circle-bg"
                      d="M18 2.0845
                        a 15.9155 15.9155 0 0 1 0 31.831
                        a 15.9155 15.9155 0 0 1 0 -31.831"
                    />
                    <path className="circle"
                      strokeDasharray={`${parseInt(getAverageObjectiveScore() * 100)} , 100`}
                      d="M18 2.0845
                        a 15.9155 15.9155 0 0 1 0 31.831
                        a 15.9155 15.9155 0 0 1 0 -31.831"
                    />
                  </svg>
                </div>
                <div className="percentage">
                  <span className="number fs36">{`${parseInt(getAverageObjectiveScore() * 100)}`}</span>
                  <span className="unit fs24">%</span>
                </div>
              </div>
            </div>
            <section className="result-objective">
              <article className="result-objective-table">
                <Paper>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            className="title thead fs16"
                            width="55%"
                          >
                            {t('column.test')}
                          </TableCell>
                          <TableCell
                            className="score thead fs16"
                            width="45%"
                          >
                            {t('column.score')}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                      {(objectiveTests || []).map(
                          (test, idx) => (
                            (test?.title_en?.length > 0 || test?.title?.length > 0) ?
                        <TableRow>
                          <TableCell
                            sx={{
                              fontWeight: 'bold',
                            }}
                            className="title tbody fs16"
                          >
                            {test?.[
                                `title_${t(
                                    'en'
                                )}`
                              ] ||
                              test?.title}
                          </TableCell>
                          <TableCell
                            className={`score tbody fs16 ${getScoreObjective(test)?.[0]}`}
                          >
                            <div className="container">
                              <span>{`${
                                      parseInt(getScoreObjective(test)?.[0] *100)
                                      }% `
                                      }
                              </span>
                              <div className="progress-bar">
                                <div
                                  className={`fill ${getTestColorClass(getScoreObjective(test)?.[0])}`}
                                  style={{width: getScoreObjective(test)?.[0] *100 +'%',}}
                                ></div>
                              </div>
                            </div>
                          </TableCell>
                        </TableRow>
                        :
                        <></>
                          )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </article>
          </section>
          </div>
          <section className="obpi-result"
            style={{display:(!obpiTest) && "none"}}
          >
            <div className="obpi-result-title fs18">{t('title.obpi-result')}</div>
            <div className="obpi-detail-result"
              style={{display:(!obpiTest?.traits) && "none"}}
            >
              {(OBPI_TESTS || []).map((obpi, idx)=>(
              <OBPIResult key={idx} title={t(obpi.title)} selected={obpiStanine?.[idx]} desc_left={t(obpi.left)} desc_right={t(obpi.right)} />
              ))}
            </div>
            <div className="obpi-detail-result"
              style={{display:(obpiTest?.traits) && "none"}}
            >
              <div className="obpi-detail-result-error">
                <div className="title fs18">{t('title.obpi-result-error')}</div>
                <div className="desc fs16">
                <Trans
                t={t}
                i18nKey={"desc.obpi-result-error"}
                components={{
                  href_customer_center: (
                    <a
                      href="https://v1n62.channel.io"
                      target="_blank"
                      onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        window.open(
                          "https://v1n62.channel.io",
                          "_target"
                        );
                      }}
                    />
                  ),
                }}
              />
                </div>
              </div> 
            </div>
          </section> 
          <div className="footer">
            <Trans t={t} i18nKey={"desc.exclude-custom-questions"} />
          </div> */}
        </div>
      </div>
    </main>
  );
}

export default Done;
