import React from 'react';
import { useTranslation } from 'react-i18next';
import { useResult } from 'utils/result';
import { useCallbackPrompt } from 'utils/hooks';
import ExitModal from 'components/modal/ExitModal';
import { useStatus } from 'utils/status';
import { executeAction } from 'utils/redux';
import { getTestInfos, getLibTestInfos } from 'store/slices/testtakerSlice';
import { useProgress } from 'utils/progress';
import { shuffle } from 'utils/array';
import Question from './common/Question';
import Essay from './Essay';
import Video from './Video';
import MultipleChoice from './MultipleChoice';
import FileUpload from './FileUpload';
import Spreadsheets from './Spreadsheets';
import Code from './Code';
import Styling from './Styling';
import FrontCode from './FrontCode';
import GVideo from './GVideo';
import DemoStyling from './DemoStyling';
import DemoEssay from './DemoEssay';
import DemoGVideo from './DemoGVideo';
import DemoStylingEssay from './DemoStylingEssay';

const CommonLib = ({ test, setCurrentState, assessment }) => {
  const { t } = useTranslation();
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(true);
  const { setTestInfo, getTestInfo } = useStatus();
  const { duration, doneCnt, maxDoneCnt, canSubmit } = getTestInfo();
  const lang = t('lang');
  const { recordResult } = useResult();
  const [canDone, setCanDone] = React.useState(false);
  const [tid, setTid] = React.useState(null);
  const [curIdx, setCurIdx] = React.useState(0);
  const [selectedValue, setSelectedValue] = React.useState(-1);
  const [questions, setQuestions] = React.useState([]);
  const [detailQuestion, setDetailQuestions] = React.useState([]);
  const { setIsEntireLoading } = useProgress();

  React.useEffect(() => {
    setIsEntireLoading(true);
    setTestInfo({
      duration,
      doneCnt,
      maxDoneCnt,
      canSubmit: false,
      buttonName: t('btn.next'),
    });
    setQuestions(test?.test);
  }, []);

  React.useEffect(() => {
    executeAction(getLibTestInfos({ tids: questions, lang }))
      .unwrap()
      .then(({ docs }) => {
        const details = new Array();
        test?.question?.forEach((question, idx) => {
          if (question?.selected === true) {
            details.push(docs[idx]);
          }
        });
        // setDetailQuestions(details);
        // setIsEntireLoading(false);

        executeAction(getTestInfos({ tids: questions, lang }))
          .unwrap()
          .then(({ basics, intermediates, advanceds }) => {
            const objdocs = [...basics, ...intermediates, ...advanceds];

            details.forEach((detail) => {
              const obj = (objdocs || []).find((x) => x.id === detail.id);
              if (obj?.url?.length > 0) {
                detail.url = obj?.url;
              }
            });
            setDetailQuestions(details);
            setIsEntireLoading(false);
          })
          .catch((e) => {
            console.dir(e);
            setDetailQuestions(details);
            setIsEntireLoading(false);
          });
      })
      .catch((e) => {
        console.dir(e);
        setIsEntireLoading(false);
      });
  }, [questions]);

  React.useEffect(() => {
    const skillEndEventListener = (event) => {
      onSubmit();
    };

    window.document.addEventListener('skill_end', skillEndEventListener);
    return () => {
      window.document.removeEventListener('skill_end', skillEndEventListener);
    };
  }, []);

  React.useEffect(() => {
    const testDoneEventListener = (event) => {
      if (curIdx < maxDoneCnt - 1) {
        onNextHandler();
      } else {
        onSubmitHandler();
      }
    };

    window.document.addEventListener('test_done', testDoneEventListener);
    return () => {
      window.document.removeEventListener('test_done', testDoneEventListener);
    };
  }, [curIdx, questions, canDone]);

  React.useEffect(() => {
    if (curIdx < maxDoneCnt - 1) {
      setCanDone(false);
    } else {
      setCanDone(true);
    }
  }, [curIdx]);

  React.useEffect(() => {
    let btnName;
    if (curIdx < maxDoneCnt - 1) {
      btnName = t('btn.next');
    } else {
      btnName = t('btn.submit');
    }
    setTestInfo({
      duration,
      doneCnt,
      maxDoneCnt,
      canSubmit: canDone,
      buttonName: btnName,
    });
  }, [canDone, questions, curIdx]);

  React.useEffect(() => {
    setTid(questions?.[curIdx]?.tid || questions?.[curIdx]);

    window.scrollTo(0, 0);
  }, [questions, curIdx]);

  const onNextHandler = () => {
    // if (!canDone) return;
    // recordResult({
    //   sid: test?.uuid,
    //   tid,
    //   checks: [selectedValue],
    // });
    setCurIdx(curIdx + 1);
    let dur;
    if (test?.limit) {
      dur = test?.limit * 60;
    } else {
      dur = 900;
    }
    let btnName;
    if (curIdx < (questions || []).length - 1) {
      btnName = t('btn.next');
    } else {
      btnName = t('btn.submit');
    }
    setTestInfo({
      duration: dur,
      doneCnt: doneCnt + 1,
      maxDoneCnt,
      canSubmit: false,
      buttonName: btnName,
    });
  };

  const onSubmitHandler = () => {
    if (!canDone) return;
    onSubmit();
  };

  const onSubmit = () => {
    setCurrentState('end');
    setTestInfo({
      duration: null,
      doneCnt: null,
      maxDoneCnt: null,
      canSubmit: false,
    });
  };

  // 일단 subjectives에 있는 Essay, FileUpload, MultipleChoice, Spreadsheets, Video를 그대로 복사해서 사용한다.
  // 별다른 문제가 없으면 소스 유지보수를 위해 기존 subjectives로 통합할 예정
  switch (detailQuestion?.[curIdx]?.section) {
    case 'video':
      return test?.id === '991010' || test?.id === '991111' ? (
        <DemoGVideo
          test={detailQuestion?.[curIdx]}
          setCurrentState={setCurrentState}
        />
      ) : (
        <Video
          test={detailQuestion?.[curIdx]}
          setCurrentState={setCurrentState}
        />
      );
    case 'essay':
      return test?.id === '990001' ? (
        <DemoStylingEssay
          totalTest={test}
          test={detailQuestion?.[curIdx]}
          setCurrentState={setCurrentState}
        />
      ) : (
        <DemoEssay
          totalTest={test}
          test={detailQuestion?.[curIdx]}
          setCurrentState={setCurrentState}
          onNextHandler={onNextHandler}
          onSubmitHandler={onSubmitHandler}
        />
      );
    case 'multiple-choice':
      return (
        <MultipleChoice
          test={detailQuestion?.[curIdx]}
          setCurrentState={setCurrentState}
        />
      );
    case 'file-upload':
      return (
        <FileUpload
          test={detailQuestion?.[curIdx]}
          setCurrentState={setCurrentState}
        />
      );
    case 'styling':
      return (
        // <DemoStyling
        //   test={detailQuestion?.[curIdx]}
        //   setCurrentState={setCurrentState}
        // />
        <Styling
          test={detailQuestion?.[curIdx]}
          setCurrentState={setCurrentState}
        />
      );
    case 'spreadsheets':
      return (
        <Spreadsheets
          test={detailQuestion?.[curIdx]}
          setCurrentState={setCurrentState}
        />
      );
    case 'code':
      return (
        <Code
          test={detailQuestion?.[curIdx]}
          setCurrentState={setCurrentState}
          assessment={assessment}
          objectiveTests={test}
        />
      );
    case 'front-code':
      return (
        <FrontCode
          test={detailQuestion?.[curIdx]}
          setCurrentState={setCurrentState}
          assessment={assessment}
          objectiveTests={test}
        />
      );
    // default:
    //   return (
    //     <Essay
    //       test={detailQuestion?.[curIdx]}
    //       setCurrentState={setCurrentState}
    //     />
    //   );
  }
};

export default CommonLib;
