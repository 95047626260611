import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { TextField, InputAdornment } from '@mui/material';
import { executeAction } from 'utils/redux';
import { updateCandidateInfo } from 'store/slices/authSlice';
import { validateEmail } from 'utils/auth';
import { useProgress } from 'utils/progress';
import { useNavigate } from 'react-router-dom';
import { useStatus } from 'utils/status';
import { ReactComponent as EmailIcon } from 'assets/images/icon/trailing-icon.svg';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/react';

const Accept = ({
  uid,
  aid,
  assessment,
  logoURL,
  userPlan,
  user,
  isPersonality,
}) => {
  const { t } = useTranslation();
  const { setInfo } = useStatus();
  const { setIsEntireLoading } = useProgress();
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [checked, setChecked] = React.useState(false);
  const [canSubmit, setCanSubmit] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [testNumber, setTestNumber] = React.useState('');
  const [birth, setBirth] = React.useState('');
  const navigate = useNavigate();
  const birthHandleInput = (value) => {
    const number = value.replace(/[^0-9]/g, '');
    const regexValue = number.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');

    if (regexValue.length > 10) {
      return toast(t('toast.desc.10-characters'));
    }
    setBirth(regexValue);
  };
  React.useEffect(() => {
    if (
      checked &&
      validateEmail(email) &&
      Boolean(firstName) &&
      Boolean(lastName)
    ) {
      setCanSubmit(true);
    } else {
      setCanSubmit(false);
    }
  }, [email, checked, firstName, lastName]);

  const onSubmitHandler = () => {
    const regex = /^[가-힣|a-z|A-Z]+$/;
    if (!regex.test(firstName) || !regex.test(lastName)) {
      toast(t('toast.desc.error-name'));
      return;
    }
    if (!checked) {
      toast(t('toast.desc.check-privacy-policy'));
      return;
    }
    if (!firstName || !lastName) {
      return;
    }
    setIsEntireLoading(true);
    executeAction(
      updateCandidateInfo({
        uid,
        aid,
        email,
        firstName,
        lastName,
        birth,
        testNumber,
      })
    )
      .unwrap()
      .then(() => {
        const lang = t('lang');
        setIsEntireLoading(false);
        setInfo({
          uid,
          aid,
          email,
          firstName,
          lastName,
          testNumber,
          birth,
          lang,
        });
        navigate('/testtaker/progress/tips', {
          state: { logoURL, userPlan, isPersonality },
          replace: true,
        });
      })
      .catch((e) => {
        Sentry.captureException(e);
        console.dir(e);
        setIsEntireLoading(false);
      });
  };

  return (
    <div
      className={`demo-auth-container ${isPersonality ? 'personality' : ''}`}
    >
      {/* <div className="left-container" /> */}
      <div className="right-container">
        <div className="form">
          <div className="assessment-name-box">{assessment?.name}</div>
          <div className="demo-form-input-container public-invitation">
            <TextField
              className="public-invitation-from-input fs14"
              label={t('text-field.placeholder.email')}
              size="small"
              value={email.toLowerCase()}
              onChange={(event) => setEmail(event.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <EmailIcon />
                  </InputAdornment>
                ),
              }}
            />
            {user?.account === 'khai' && (
              <>
                <TextField
                  className="public-invitation-from-input fs14"
                  label={t('text-field.placeholder.test-number')}
                  size="small"
                  value={testNumber}
                  onChange={(event) => setTestNumber(event.target.value)}
                />
                <TextField
                  className="public-invitation-from-input fs14"
                  label={t('evaluation.end_survey.birthday')}
                  placeholder={`${t('text-field.placeholder.birth')}`}
                  size="small"
                  value={birth}
                  onChange={(event) => birthHandleInput(event.target.value)}
                />
              </>
            )}
            <TextField
              className="demo-form-input fs14"
              label={t('text-field.placeholder.first-name')}
              size="small"
              value={firstName}
              onChange={(event) => setFirstName(event.target.value)}
            />
            <TextField
              className="demo-form-input fs14"
              label={t('text-field.placeholder.last-name')}
              size="small"
              value={lastName}
              onChange={(event) => setLastName(event.target.value)}
            />
          </div>
          <div className="helper-container">
            <p>
              <i>*</i> {t('text-field.helper.write-your-name-country')}
            </p>
          </div>
          <div className="policy-check-container">
            <label
              className={`form-checkbox ${checked && 'checked'}`}
              onClick={() => setChecked(!checked)}
            >
              <div>
                <Trans
                  t={t}
                  i18nKey="check-box.label.privacy-policy"
                  components={{
                    href_privacy_policy: (
                      <a
                        href="https://munchskill.wpcomstaging.com/privacy-policy/"
                        target="_blank"
                        onClick={(event) => {
                          event.preventDefault();
                          event.stopPropagation();
                          window.open(
                            `https://munchskill.wpcomstaging.com/${
                              t('lang') === 'ko' ? 'ko/' : ''
                            }privacy-policy/`,
                            '_blank'
                          );
                        }}
                        rel="noreferrer"
                      />
                    ),
                  }}
                />
              </div>
            </label>
          </div>
          <button
            className={`demo-form-submit ${canSubmit && 'active'}`}
            onClick={onSubmitHandler}
          >
            {t('btn.submit')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Accept;
