import { supportedLangs } from 'i18n';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { setLang } from 'store/slices/variableSlice';
import { useOnClickOutside } from 'utils/hooks';
import { executeAction } from 'utils/redux';
import { ReactComponent as EarthIcon } from 'assets/images/icon/earth.svg';
import { ReactComponent as ChevronIcon } from 'assets/images/icon/chevron-bottom.svg';

const LanguageDropdown = () => {
  const { t } = useTranslation();
  const [visibilityDropdown, setVisibilityDropdown] = React.useState(false);

  const dropdownRef = useRef(null);

  useOnClickOutside(dropdownRef, () => setVisibilityDropdown(false));

  const langSelectedHandler = (key) => {
    executeAction(setLang(key));
    setVisibilityDropdown(false);
  };

  return (
    <article ref={dropdownRef} className="language-selector">
      <div
        className={`language-selector-toggle ${visibilityDropdown && 'show'}`}
        onClick={() => setVisibilityDropdown(!visibilityDropdown)}
      >
        <div className="language-info-wrapper">
          <EarthIcon />
          {window.localStorage
              .getItem('i18nextLng') && t(
            `label.lang-${window.localStorage
              .getItem('i18nextLng')
              .slice(0, 2)}`
          )}
        </div>
        <div className="language-arrow-wrapper">
          <ChevronIcon />
        </div>
      </div>
      {visibilityDropdown && (
        <div className="language-selector-dropdown">
          <ul className="language-selector-dropdown-list">
            {(supportedLangs || []).map((lang, idx) => (
              <li
                key={lang.key}
                className={`language-selector-dropdown-list-item ${lang.key}`}
                onClick={() => langSelectedHandler(lang.key)}
              >
                {t(`label.lang-${lang.key}`)}
              </li>
            ))}
          </ul>
        </div>
      )}
    </article>
  );
};

export default LanguageDropdown;
