import React from 'react';
import {Outlet, useLocation} from 'react-router-dom';
import AuthHeader from 'components/header/AuthHeader';
import Footer from 'components/footer/Footer';

function AuthLayout() {
    const location = useLocation();
    const { aid } = location.state || {};
  return (
    <>
        {aid !== 'jhHMxUyLWkcm7ZQNaojK' && <AuthHeader/>}
      <Outlet />
      <Footer />
    </>
  );
}

export default AuthLayout;
