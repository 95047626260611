import React from 'react';
import { useTranslation } from 'react-i18next';
import { executeAction } from 'utils/redux';
import { useCallbackPrompt } from 'utils/hooks';
import ExitModal from 'components/modal/ExitModal';
import { useResult } from 'utils/result';
import { useStatus } from 'utils/status';
import { useProgress } from 'utils/progress';
import { getKHAITestResult } from 'store/slices/testtakerSlice';
import { ReactComponent as RightArrow } from 'assets/images/icon/arrow-right-white.svg';
import { ReactComponent as KHAILogo } from 'assets/images/logo/testtaker-logo-khai.svg';
import * as Sentry from '@sentry/react';

const rowsPerPage = 10;

const KHAITest = ({ test, setCurrentState }) => {
  const { t } = useTranslation();
  const lang = t('lang');
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(true);
  const { recordResult } = useResult();
  const { setTestInfo, getTestInfo, email } = useStatus();
  const { setIsEntireLoading } = useProgress();
  const { duration, doneCnt, maxDoneCnt } = getTestInfo();
  const [_khaitest, _setKHAITest] = React.useState([]);
  const [khaitest, setKHAITest] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [_answers, _setAnswers] = React.useState([]);
  const [answers, setAnswers] = React.useState([]);
  const [canDone, setCanDone] = React.useState(false);
  const [khaitype, setKhaiType] = React.useState('P');
  const [answerArray, setAnswerArray] = React.useState([]);
  const itemsRef = React.useRef([]);
  const containerRef = React.useRef(null);

  React.useEffect(() => {
    setIsEntireLoading(true);
    setPage(0);

    _setKHAITest(test?.question);
    if (test?.question[0]?.testInfo?.[`category_${lang}`]?.indexOf('P') >= 0) {
      setKhaiType('P');
    } else {
      setKhaiType('O');
    }
    setIsEntireLoading(false);

    // window.document.addEventListener("skill_end", skillEndEventListener);

    // return () =>
    //   window.document.removeEventListener("skill_end", skillEndEventListener);
  }, []);

  React.useEffect(() => {
    if (khaitype === 'P') {
      setAnswerArray([
        'btn.khai-strongdisagree',
        'btn.khai-disagree',
        'btn.khai-neutral',
        'btn.khai-agree',
        'btn.khai-strongagree',
      ]);
    } else {
      setAnswerArray(['btn.khai-o-agree', 'btn.khai-o-disagree']);
    }
  }, [khaitype]);

  React.useEffect(() => {
    const tmp_answers = new Array(rowsPerPage).fill(-1);
    if (page < maxDoneCnt) setAnswers(tmp_answers);
    const startIdx = page * rowsPerPage;
    setKHAITest(_khaitest?.slice(startIdx, startIdx + rowsPerPage));
    for (const item of itemsRef.current) {
      item.classList.remove('selected');
    }
    containerRef.current.scrollTo(0, 0);
  }, [_khaitest, page]);

  React.useEffect(() => {
    let btnName;
    if (page < maxDoneCnt - 1) {
      btnName = t('btn.next');
    } else {
      btnName = t('btn.submit');
    }
    // var cansubmit = (canDone && (page == maxDoneCnt));
    setTestInfo({
      duration,
      doneCnt: page + 1,
      maxDoneCnt,
      canSubmit: canDone,
      buttonName: btnName,
    });

    const testDoneEventListener = (event) => {
      if (page < maxDoneCnt - 1) {
        onNextHandler();
      } else {
        onSubmitHandler();
      }
    };

    window.document.addEventListener('test_done', testDoneEventListener);
    return () => {
      window.document.removeEventListener('test_done', testDoneEventListener);
    };
  }, [canDone, _khaitest, page]);

  React.useEffect(() => {
    if (!_khaitest || _khaitest.length <= 0) return;
    let maxpage = parseInt(_khaitest?.length / rowsPerPage);
    if (_khaitest?.length % rowsPerPage > 0) {
      maxpage += 1;
    }
    setTestInfo({
      duration,
      doneCnt,
      maxDoneCnt: maxpage,
      canSubmit: false,
      buttonName: t('btn.next'),
    });
  }, [_khaitest]);

  React.useEffect(() => {
    window.document.addEventListener('skill_end', skillEndEventListener);
    return () =>
      window.document.removeEventListener('skill_end', skillEndEventListener);
  }, [_answers]);

  const skillEndEventListener = (event) => {
    onSubmit(null);
  };

  const onNextHandler = () => {
    if (!canDone) return;
    setPage(page + 1);
    setCanDone(false);

    let btnName;
    if (page < maxDoneCnt - 1) {
      btnName = t('btn.next');
    } else {
      btnName = t('btn.submit');
    }
    setTestInfo({
      duration,
      doneCnt: doneCnt + 1,
      maxDoneCnt,
      canSubmit: false,
      buttonName: btnName,
    });
    _setAnswers([..._answers, ...answers]);
  };

  const onSubmitHandler = () => {
    if (!canDone) return;
    onSubmit(answers);
  };

  const onSubmit = (answer) => {
    setIsEntireLoading(true);
    let items = [..._answers];
    if (answer && answer.length > 0) {
      items = [...items, ...answer];
    }
    // KHAIPResult에 API에서 받은 결과를 저장하고 해당 값을 DB에 저장한다.
    const KHAIPResult = {
      total: {
        score: 90,
        grade: 'S',
        level: '+10',
        response_reliability: 'normal',
      },
      'personal-propensity': [
        {
          title: 'title.positive-attitude',
          myscore: 93.33,
          bigdatascore: 80,
          tooltip: [
            '긍정적으로 사고하고 행동으로 실행함',
            '할 수 있다는 믿음으로 매사에 당당하게 임하는 자세',
            '넘치는 에너지와 힘으로 생동감을 유지함',
          ],
        },
        {
          title: 'title.trust-will',
          myscore: 95.83,
          bigdatascore: 70,
          tooltip: [
            '자신의 생각과 믿음에 따라 일관되게 행동함',
            '거짓 없이 정직하게 행동하고 신뢰롭게 행동함',
            '어려움을 참고 맡은 임무를 끈기 있게 완수함',
            '자신의 양심에 따라 원칙과 규정을 준수하는 역량',
          ],
        },
        {
          title: 'title.self-management',
          myscore: 94.44,
          bigdatascore: 60,
          tooltip: [
            '감정을 조절하고 통제하여 평정심을 유지할 수 있음',
            '자신의 발전을 위해 스스로 학습하고 개발함',
            '신체/정신적으로 최적의 상태를 유지하고자 노력함',
          ],
        },
      ],
      'task-propensity': [
        {
          title: 'title.creativity-innovation',
          myscore: 88.89,
          bigdatascore: 95,
          tooltip: [
            '어렵거나 새로운 일에 도전하고 경쟁을 즐기는 행동',
            '독특하고 유용한 아이디어를 생각하고 적용함',
            '새로운 방식이나 기술을 기꺼이 수용하고자 노력함',
          ],
        },
        {
          title: 'title.execution-promotion',
          myscore: 96.67,
          bigdatascore: 80,
          tooltip: [
            '생각을 행동으로 옮기고 적용하고자 노력함',
            '자발적으로 일을 맡고 주도적으로 처리하는 행동',
            '맡은 일을 기한 내에 마무리 하기위해 노력함',
            '맡은 임무에 애정과 열정을 다해 최선을 다하는 역량',
          ],
        },
        {
          title: 'title.analysis-management',
          myscore: 99.17,
          bigdatascore: 100,
          tooltip: [
            '문제를 통합적으로 분석하고 해석할 수 있음',
            '사전에 처리방법이나 프로세스 등을 계획함',
            '가용한 정보나 자원을 꼼꼼하게 점검함',
            '실수없이 철저하고 세밀하게 검토/점검하는 역량',
          ],
        },
        {
          title: 'title.teamwork',
          myscore: 97.78,
          bigdatascore: 50,
          tooltip: [
            '동료나 구성원들을 통합하여 이끌고 관리할 수 있음',
            '사전에 처리방법이나 프로세스 등을 계획함',
            '가용한 정보나 자원을 꼼꼼하게 점검함',
            '실수없이 철저하고 세밀하게 검토/점검하는 역량',
          ],
        },
      ],
      'relationship-propensity': [
        {
          title: 'title.acceptance-understanding',
          myscore: 98.98,
          bigdatascore: 60,
          tooltip: [
            '다양한 의견이나 가치관을 인정/존중함',
            '위계를 존중하고 타인의 충고에 개방적인 태도',
            '상대방의 생각이나 감정을 이해하고 배려함',
          ],
        },
        {
          title: 'title.consideration-relationship',
          myscore: 100,
          bigdatascore: 80,
          tooltip: [
            '스스로를 내새우기보다 타인을 먼저 존중함',
            '때와 장소,대상에 따라 적절한 예의를 지킬 수 있음',
            '쉽게 타인과 어울리고 친밀감을 형성할 수 있음',
          ],
        },
      ],
      interview: {
        self: {
          strong:
            '감정조절+자신의 감정을 이해하고 부정적인 감정을 통제하여, 일상 생활에서 평정심을 유지할 수 있습니다.+상대방으로 인해 화가나거나 불편한 상황이지만, 감정을 표출하지 않고 조절하려 노력해본 경험이 있다면 말씀해주시기 바랍니다.||- 어떤 부분에서 화가 많이 났었습니까?|- 그때 본인은 어떤 생각을 하였고, 자신의 상태를 컨트롤 해야겠다고 마음먹은 이유는 무엇 때문이었습니까?|- 이런 경험처럼 대체적으로 화가나는 일이 있는경우 잘 참는 편입니까? 반대로 화를 냈던 경험이 있다면 언제였습니까?',
          weak: '자신감+자신이 가진 능력을 부정적으로 평가하여, 실수나 실패에 대한 불안, 두려움을 갖는 경향이 있습니다.+맡은 일에 대한 자신감이 부족해, 불안하거나 초초한 감정을 느낀 경험이 있다면 말씀해주시기 바랍니다.||- 맡은 일에서 자신감이 부족했던 이유는 무엇입니까?|- 불안과 초조함을 느낀 이유는 무엇입니까?|- (실수, 실패에 대한 두려움 때문이라면) 그 상황을 극복하기 위해 어떤 방법을 사용하였습니까?',
        },
        work: {
          strong:
            '꼼꼼함+작은 오류나 실수도 발생하지 않도록 업무 수행 과정과 결과물을 철저하게 확인하고 점검합니다.+작성한 보고서나 결과물에 실수가 있을까 우려되어, 지속적으로 검토하고 확인하여 좋은 결과가 있었던 경험이 있다면 말씀해주시기 바랍니다.||- 했던 일을 다시 검토해야 할 이유가 있었다면 무엇입니까?|- 다시 검토 했을 때 어떤 부분들이 수정되었습니까?|- 다시 검토 하지 않았다면 어떤 문제가 예상 되었을까요?',
          weak: '분석적사고+주어진 정보를 정확하게 분석하고 해석하여 중요한 의미를 찾아내는 일련의 과정에 어려움을 겪습니다.+처음 접하는 상황이나 문제를 이해하기 위해 다양한 정보를 수집하여 분석했던 경험이 있다면 말씀해 주시기바랍니다.||- 본인에게 그런 상황이나 문제가 어떤 부분에서 생소하였습니까?|- 그런 상황이나 문제를 이해하기 위해 어떤 정보가 필요하다고 생각했습니까?|- 필요한 정보를 수집하기 위해 어떤 조치들을 하였으며, 그 상황이나 문제에 대해 어떻게 이해할 수 있게 되었습니까?',
        },
        people: {
          strong:
            '겸손+자신의 성과, 능력을 자랑하기 보다 타인을 존중하고 자신을 낮추려는 겸손한 태도를 갖고 있습니다.+팀, 집단 속에서 나의 기여가 더 많았음에도 불구하고, 팀원들의 성과를 인정하고 격려했던 경험이 있다면 말씀해주시기 바랍니다.||- 본인의 역할은 무엇이었으며, 함께한 팀원들의 역할은 무엇이었습니까?|- 본인의 역할이나 기여가 조금 더 컸다고 생각 한 이유는 무엇 때문입니까?|- 팀원들의 성과를 인정하고, 격려코자 했던 이유는 무엇이었습니까?',
          weak: '순응성+보편적 가치와 규범에 따르기 보다는 자신의 의견, 방식을 우선시 하는 경향이 있습니다.+윗 사람의 지시나 방식이 아무래도 문제가 있다고 판단에 다른 방식으로 일을 했던 적이 있다면 말씀해주시기 바랍니다.||- 어떤 상황이었으며, 윗 사람의 지시에는 어떤 문제가 있다고 생각했습니까?|- 본인이 생각한 방식에는 어떤 이점이 있었습니까?|- 윗 사람의 지시와는 달라 나중에 알게 되었을 때 걱정이 되지는 않았습니까?',
        },
      },
    };

    if (items.length < test?.question?.length) {
      for (let i = items.length; i < test?.question?.length; i += 1) {
        items.push(-1);
      }
    }

    executeAction(
      getKHAITestResult({ khaitype, lang: 'en', item: items, email })
    )
      .unwrap()
      .then((doc) => {
        // 기존 초기화 한 KHAIPResult에 점수 또는 필요한 정보를 업데이트 한다.
        // 현재는 자기 점수만 있고 추후 bigdata score, 결과 텍스트 등이 필요하다.
        if (khaitype === 'P') {
          KHAIPResult.total.score = doc?.overall_results?.total_average_score;
          KHAIPResult.total.level = doc?.overall_results?.level;
          KHAIPResult.total.grade = doc?.overall_results?.grade;
          KHAIPResult.total.response_reliability = doc?.response_reliability;
          KHAIPResult['personal-propensity'][0].myscore =
            doc?.individual_personality?.positive_behavior?.subtotal;
          KHAIPResult['personal-propensity'][1].myscore =
            doc?.individual_personality?.trust_will?.subtotal;
          KHAIPResult['personal-propensity'][2].myscore =
            doc?.individual_personality?.self_management?.subtotal;
          KHAIPResult['task-propensity'][0].myscore =
            doc?.task_oriented?.creativity_innovation?.subtotal;
          KHAIPResult['task-propensity'][1].myscore =
            doc?.task_oriented?.carry_forward?.subtotal;
          KHAIPResult['task-propensity'][2].myscore =
            doc?.task_oriented?.analysis_management?.subtotal;
          KHAIPResult['task-propensity'][3].myscore =
            doc?.task_oriented?.team_work?.subtotal;
          KHAIPResult['relationship-propensity'][0].myscore =
            doc?.relation_oriented?.acceptance_understand?.subtotal;
          KHAIPResult['relationship-propensity'][1].myscore =
            doc?.relation_oriented?.consideration_relation?.subtotal;
          KHAIPResult.interview.self = doc?.['면접질문_자아'];
          KHAIPResult.interview.work = doc?.['면접질문_업무'];
          KHAIPResult.interview.people = doc?.['면접질문_사람'];
          recordResult({
            sid: `Khai-${khaitype}`,
            data: { result: KHAIPResult, answer: items },
          });
          setCurrentState('end');
        } else {
          recordResult({
            sid: `Khai-${khaitype}`,
            data: { result: doc, answer: items },
          });
          setCurrentState('end');
        }
        setTestInfo({
          duration: null,
          doneCnt: null,
          maxDoneCnt: null,
          canSubmit: false,
        });

        setIsEntireLoading(false);
      })
      .catch((e) => {
        Sentry.captureException(e);
        recordResult({
          sid: `Khai-${khaitype}`,
          data: { answer: items },
        });

        setCurrentState('end');
        setTestInfo({
          duration: null,
          doneCnt: null,
          maxDoneCnt: null,
          canSubmit: false,
        });

        setIsEntireLoading(false);
      });
  };

  const onClickSelectAnswerHandler = (idx, aidx) => {
    const tmp_answers = answers;
    tmp_answers[idx] = aidx + 1;
    for (let i = 0; i < answerArray?.length; i += 1) {
      itemsRef.current[idx * answerArray?.length + i].classList.remove(
        'selected'
      );
    }
    itemsRef.current[idx * answerArray?.length + aidx].classList.add(
      'selected'
    );
    let candone = true;
    let answeredCnt = 0;
    tmp_answers.forEach((answer) => {
      if (answer < 0) {
        candone = false;
        return;
      }
      answeredCnt += 1;
    });
    setCanDone(candone);
    if (_answers.length + answeredCnt === test?.question?.length)
      setCanDone(true);
  };

  const addNextButtonHandler = () => {
    if (page < maxDoneCnt - 1) {
      onNextHandler();
    } else {
      onSubmitHandler();
    }
  };

  return (
    <main className="pages-protected-evaluation-obpitest">
      <ExitModal
        showPrompt={showPrompt}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
      />
      <div className="container" ref={containerRef}>
        <div className={`container-inner khai-${khaitype}`}>
          <div className="discription khai fs18">
            <KHAILogo />
            {t('title.khai-o-p-question')}
          </div>
          {(khaitest || []).map((test, idx) => (
            <div className="testlist" key={idx}>
              <div className="question fs18">
                {test?.testInfo?.[`question_${lang}`]}
              </div>
              <div className="answer">
                <ul className="answer-list">
                  {(answerArray || []).map((answer, aidx) => (
                    <li
                      className="answer-khai-list-item"
                      key={aidx}
                      ref={(el) =>
                        (itemsRef.current[idx * answerArray?.length + aidx] =
                          el)
                      }
                      onClick={() => {
                        onClickSelectAnswerHandler(idx, aidx);
                      }}
                      value={{ idx, aidx }}
                    >
                      <span className="fs16to14">{t(answer)}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
          <div className="button-next">
            <button
              className={`common-button black fsbtn16 ${
                canDone ? 'active' : 'disabled'
              }`}
              onClick={addNextButtonHandler}
            >
              {page < maxDoneCnt - 1 ? t('btn.next') : t('btn.submit')}
              <RightArrow />
            </button>
          </div>
        </div>
      </div>
    </main>
  );
};

export default KHAITest;
