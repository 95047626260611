import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import {
  InputLabel,
  Checkbox,
  FormControlLabel,
  TextField,
  Radio,
} from '@mui/material';
import {
  languageGucciList,
  newCareerGucciList,
  changingGucciList,
  retailCareerGucciList,
  yesOrNoGucciList,
  levelGucciList,
  importantValueGucciList,
  competencyGucciList,
} from 'constants/survey_gucci_store';
import { useResize } from 'utils/hooks';
import { useResult } from 'utils/result';
import { useStatus } from 'utils/status';
import * as Sentry from '@sentry/react';

const StoreSurvey = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { stream, recordResult } = useResult();
  const { userPlan } = useStatus();
  const location = useLocation();
  const windowSize = useResize();
  const { logoURL } = location.state || {};

  const radioArray = new Array(
    [...importantValueGucciList],
    [...competencyGucciList]
  );
  const checkBoxArray = new Array(
    [...newCareerGucciList],
    // [...languageGucciList]
  );
  const langQuestionArray = new Array(
    [...yesOrNoGucciList],
    [...levelGucciList]
  );

  const [feedbackGucci, setFeedbackGucci] = React.useState('');
  const [overSeasYesOrNoGucci, setOverSeasYesOrNoGucci] = React.useState('');
  const [langLevelGucci, setLangLevelGucci] = React.useState('');
  const [changingGucci, setChangingGucci] = React.useState('');
  const [changingOtherGucci, setChangingOtherGucci] = React.useState('');
  const [retailCareerGucci, setRetailCareerGucci] = React.useState([]);
  const [retailCareerOtherGucci, setRetailCareerOtherGucci] =
    React.useState('');
  const [newCareerGucci, setNewCareerGucci] = React.useState([]);
  const [newCareerOtherGucci, setNewCareerOtherGucci] = React.useState('');
  const [languageGucci, setLanguageGucci] = React.useState([]);
  const [languageOtherGucci, setLanguageOtherGucci] = React.useState('');
  const [importantValueGucci, setImportantValueGucci] = React.useState('');
  const [importantOtherGucci, setImportantOtherGucci] = React.useState('');
  const [competencyGucci, setCompetencyGucci] = React.useState('');
  const [competencyOtherGucci, setCompetencyOtherGucci] = React.useState('');
  const [isChecked, setIsChecked] = React.useState(false);
  const [canDoneGucci, setCanDoneGucci] = React.useState(false);

  React.useEffect(() => {
    if (
      Boolean(newCareerGucci.length) &&
      Boolean(importantValueGucci) &&
      Boolean(competencyGucci) &&
      Boolean(overSeasYesOrNoGucci) &&
      Boolean(langLevelGucci)
    ) {
      return setCanDoneGucci(true);
    }
  }, [
    newCareerGucci,
    importantValueGucci,
    competencyGucci,
    overSeasYesOrNoGucci,
    langLevelGucci,
  ]);

  const otherHandler = (value, idx) => {
    if (idx === 0) setNewCareerOtherGucci(value);
    if (idx === 1) setLanguageOtherGucci(value);
    if (idx === 2) setChangingOtherGucci(value);
    if (idx === 3) setRetailCareerOtherGucci(value);
    if (idx === 4) setImportantOtherGucci(value);
    if (idx === 5) setCompetencyOtherGucci(value);
  };

  const checkedItemHandler = (value, isChecked, index, idx) => {
    if (isChecked) {
      if (index === 0) {
        if (value === t('survey.none')) {
          setNewCareerGucci([value]);
          setRetailCareerGucci([]);
          setChangingGucci('');
        } else if (!newCareerGucci.includes(t('survey.none')))
          setNewCareerGucci((prev) => [...prev, value]);
      } else if (index === 1) {
        setLanguageGucci((prev) => [...prev, value]);
      } else if (index === 2) {
        setRetailCareerGucci((prev) => [...prev, value]);
      }
      return;
    }

    if (!isChecked) {
      if (index === 0) {
        if (value === t('survey.etc')) setNewCareerOtherGucci('');
        setNewCareerGucci(newCareerGucci.filter((item) => item !== value));
      } else if (index === 1) {
        if (value === t('survey.etc')) setLanguageOtherGucci('');
        setLanguageGucci(languageGucci.filter((item) => item !== value));
      } else if (index === 2) {
        if (value === t('survey.etc')) setRetailCareerOtherGucci('');
        setRetailCareerGucci(
          retailCareerGucci.filter((item) => item !== value)
        );
      }
      return;
    }

    return;
  };

  const radioItemHandler = (value, idx) => {
    if (idx === 0) {
      setImportantOtherGucci('');
      setImportantValueGucci(t(value));
    } else {
      setCompetencyOtherGucci('');
      setCompetencyGucci(t(value));
    }
  };

  const handleOnChange = (e, value, index, idx) => {
    setIsChecked(!isChecked);
    checkedItemHandler(value, e.target.checked, index, idx);
  };

  const onSubmitHandlerGucci = async () => {
    const newCareer = newCareerGucci.filter((item) => item !== t('survey.etc'));
    const retailCareer = retailCareerGucci.filter(
      (item) => item !== t('survey.etc')
    );
    const language = languageGucci.filter((item) => item !== t('survey.etc'));
    const gucciNewSurvey = {
      newCareerGucci: newCareerOtherGucci
        ? [...newCareer, newCareerOtherGucci]
        : [...newCareer],
      retailCareerGucci: retailCareerOtherGucci
        ? [...retailCareer, retailCareerOtherGucci]
        : [...retailCareer],
      changingGucci: changingOtherGucci || changingGucci,
      languageGucci: languageOtherGucci
        ? [...language, languageOtherGucci]
        : [...language],
      langLevelGucci,
      overSeasYesOrNoGucci,
      importantValueGucci: importantOtherGucci || importantValueGucci,
      competencyGucci: competencyOtherGucci || competencyGucci,
      feedbackGucci,
    };
    try {
      if (stream && stream.getTracks) {
        stream.getTracks?.()?.forEach((track) => track.stop());
      }
      recordResult({
        type: 'survey',
        data: {
          gucciNewSurvey,
        },
      }).then(() => {
        navigate('/testtaker/evaluation', {
          state: { logoURL, userPlan },
          replace: true,
        });
      });
    } catch (e) {
      Sentry.captureException(e);
      console.dir(e);
    }
  };

  return (
    <main className="pages-protected-evaluation-test-end last">
      {windowSize?.width < 1024 && (
        <div className="mobile-title-wrapper">
          <h1 className="title gucci">{t('survey.title.gucci-mcq')}</h1>
        </div>
      )}
      <div className="container">
        <div className="container-inner">
          {windowSize?.width > 1023 && (
            <>
              <h1 className="title fs28to24 gucci">
                {t('survey.title.gucci-mcq')}
              </h1>
            </>
          )}
          <section className="survey">
            {checkBoxArray?.map((list, index) => (
              <React.Fragment key={index}>
                <div className="survey-inputs-check" key={index}>
                  <InputLabel className="fs18">{t(list[0]?.title)}</InputLabel>
                  {list[0]?.section?.map((item, idx) => (
                    <React.Fragment key={idx}>
                      <FormControlLabel
                        value={t(item.key)}
                        label={t(item.i18nKey)}
                        control={<Checkbox />}
                        onChange={(e) => {
                          handleOnChange(e, t(item.key), index, idx);
                        }}
                        checked={
                          index === 0
                            ? newCareerGucci.includes(t(item.key))
                            : languageGucci.includes(t(item.key))
                        }
                      />
                      {index === 0 &&
                        item.key === 'survey.etc' &&
                        newCareerGucci.includes(t('survey.other')) && (
                          <TextField
                            className="fs14"
                            id="margin-normal"
                            size="big"
                            onChange={(e) => otherHandler(e.target.value, 0)}
                          />
                        )}
                      {index === 1 &&
                        item.key === 'survey.etc' &&
                        languageGucci.includes(t('survey.etc')) && (
                          <TextField
                            className="fs14"
                            id="margin-normal"
                            size="big"
                            onChange={(e) => otherHandler(e.target.value, 1)}
                          />
                        )}
                    </React.Fragment>
                  ))}
                </div>
                {index === 0 &&
                  newCareerGucci.length !== 0 &&
                  !newCareerGucci.includes(t('survey.none')) && (
                    <div className="survey-inputs-check">
                      <InputLabel className="fs18">
                        {t(changingGucciList[0]?.title)}
                      </InputLabel>
                      {changingGucciList[0]?.section?.map((item, idx) => (
                        <>
                          <FormControlLabel
                            key={idx}
                            value={t(item.key)}
                            label={t(item.i18nKey)}
                            control={<Radio />}
                            onChange={(e) => {
                              setChangingGucci(t(item.key));
                            }}
                            checked={changingGucci === t(item.key)}
                          />
                          {item.key === 'survey.etc' &&
                            changingGucci === t('survey.etc') && (
                              <TextField
                                className="fs14"
                                id="margin-normal"
                                size="big"
                                onChange={(e) =>
                                  otherHandler(e.target.value, 2)
                                }
                              />
                            )}
                        </>
                      ))}
                    </div>
                  )}
                {index === 0 &&
                  newCareerGucci.length !== 0 &&
                  newCareerGucci.includes(t('survey.retail')) && (
                    <div className="survey-inputs-check">
                      <InputLabel className="fs18">
                        {t(retailCareerGucciList[0]?.title)}
                      </InputLabel>
                      {retailCareerGucciList[0]?.section?.map((item, idx) => (
                        <>
                          <FormControlLabel
                            key={idx}
                            value={t(item.key)}
                            label={t(item.i18nKey)}
                            control={<Checkbox />}
                            onChange={(e) => {
                              handleOnChange(e, t(item.key), 2, idx);
                            }}
                            checked={retailCareerGucci.includes(t(item.key))}
                          />
                          {item.key === 'survey.etc' &&
                            retailCareerGucci.includes(t('survey.etc')) && (
                              <TextField
                                className="fs14"
                                id="margin-normal"
                                size="big"
                                onChange={(e) =>
                                  otherHandler(e.target.value, 3)
                                }
                              />
                            )}
                        </>
                      ))}
                    </div>
                  )}
                {langQuestionArray?.map((list, index) => (
                    <div className="survey-inputs-check" key={index}>
                      <InputLabel className="fs18">
                        {t(list[0]?.title)}
                      </InputLabel>
                      {list[0]?.section?.map((item, idx) => (
                        <FormControlLabel
                          key={idx}
                          value={t(item.key)}
                          label={t(item.i18nKey)}
                          control={<Radio />}
                          onChange={(e) => {
                            index === 0
                              ? setOverSeasYesOrNoGucci(t(item.key))
                              : setLangLevelGucci(t(item.key));
                          }}
                          checked={
                            index === 0
                              ? overSeasYesOrNoGucci === t(item.key)
                              : langLevelGucci === t(item.key)
                          }
                        />
                      ))}
                    </div>
                  ))}
              </React.Fragment>
            ))}
            {radioArray?.map((list, index) => (
              <div className="survey-inputs-check" key={index}>
                <InputLabel className="fs18">{t(list[0]?.title)}</InputLabel>
                {list[0]?.section?.map((item, idx) => (
                  <>
                    <FormControlLabel
                      key={idx}
                      value={t(item.key)}
                      label={t(item.i18nKey)}
                      control={<Radio />}
                      onChange={(e) => {
                        radioItemHandler(item.key, index);
                      }}
                      checked={
                        index === 0
                          ? importantValueGucci === t(item.key)
                          : competencyGucci === t(item.key)
                      }
                    />
                    {index === 0 &&
                      item.key === 'survey.value-other' &&
                      importantValueGucci === t('survey.value-other') && (
                        <TextField
                          className="fs14"
                          id="margin-normal"
                          size="big"
                          onChange={(e) => otherHandler(e.target.value, 4)}
                        />
                      )}
                    {index === 1 &&
                      item.key === 'survey.etc' &&
                      competencyGucci === t('survey.etc') && (
                        <TextField
                          className="fs14"
                          id="margin-normal"
                          size="big"
                          onChange={(e) => otherHandler(e.target.value, 5)}
                        />
                      )}
                  </>
                ))}
              </div>
            ))}
            <div className="survey-inputs-check">
              <InputLabel className="fs18">
                <Trans 
                  t={t}
                  i18nKey='survey.title-feedback'
                />
              </InputLabel>
              <TextField
                className="fs14"
                id="margin-normal"
                size="big"
                value={feedbackGucci}
                onChange={(event) => setFeedbackGucci(event.target.value)}
              />
            </div>
          </section>
          <button
            className={`submit common-button fsbtn16 black ${
              canDoneGucci ? 'active' : 'disabled'
            }`}
            onClick={canDoneGucci ? onSubmitHandlerGucci : null}
          >
            {t('btn.submit')}
          </button>
        </div>
      </div>
    </main>
  );
};

export default StoreSurvey;
