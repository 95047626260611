import React from 'react';
import { useLocation } from 'react-router-dom';
import { ReactComponent as LogoIcon } from 'assets/images/logo/main_logo.svg';

function AuthHeader() {
  const location = useLocation();
  const { logoURL } = location.state || {};

  return (
    <header className="components-header-auth-header">
      <div className="container">
        <div className="logo">
          {logoURL !== '' ? <img src={logoURL} alt="" /> : <LogoIcon />}
        </div>
      </div>
    </header>
  );
}

export default AuthHeader;
