import React from 'react';
import { ReactComponent as CloseButton } from 'assets/images/icon/close_24px.svg';

export function BrowserSizeToast({ open, title, desc, onClick }) {
  const onClickHandler = (boolean) => {
    onClick(boolean);
  };

  return (
    <div className={`toast-modal ${open ? 'show' : 'hide'}`}>
      <section className="content">
        <section className="title">
          <h2 className="title-main fs18">{title && title}</h2>
          <div className="title-sub fs14">{desc && desc}</div>
        </section>
      </section>

      <section className="buttons">
        <button className="close" onClick={() => onClickHandler(false)}>
          <CloseButton />
        </button>
      </section>
    </div>
  );
}
