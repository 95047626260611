import React from 'react';
import Editor from '@monaco-editor/react';

function HtmlEditor({ htmlCode, onChange }) {
  const [value, setValue] = React.useState(htmlCode || '');

  const handleEditorChange = (val) => {
    setValue(val);
    onChange('htmlCode', val);
  };

  return (
    <aside className="editor-input">
      <Editor
        height="100%"
        width="100%"
        language="html"
        value={htmlCode}
        theme="vs-dark"
        defaultValue={value}
        onChange={handleEditorChange}
        options={{
          readOnly: false,
          fontSize: '14px',
          lineNumbers: true,
          formatOnPaste: true,
          autoIndent: true,
          formatOnType: true,
          scrollBeyondLastLine: false,
          minimap: { enabled: false },
          renderWhitespace: 'all',
          automaticLayout: true,
        }}
      />
    </aside>
  );
}

export default HtmlEditor;
