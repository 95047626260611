import React from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import DemoVoiceStream from './DemoVoiceStream';


function DemoVoiceTestLeftContents({ uid, aid, cid, userPlan, setLoadingVoiceTest, recordState, setRecordState, startFunctionState, setStartFunctionState }) {
    const navigate = useNavigate();

    const location = useLocation();
    if(!location?.state) {
        navigate('/testtaker/auth/invalidinvitation', {
            replace: true,
        });
        return;
    }
    const { assessment, logoURL } = location?.state;
    return (
        <div className="container-left">
            <DemoVoiceStream
                uid={uid}
                aid={aid}
                cid={cid}
                assessment={assessment}
                logoURL={logoURL}
                userPlan={userPlan}
                setLoadingVoiceTest={setLoadingVoiceTest}
                recordState={recordState}
                setRecordState={setRecordState}
                startFunctionState={startFunctionState}
                setStartFunctionState={setStartFunctionState}
            />
        </div>
    );
}

export default DemoVoiceTestLeftContents;