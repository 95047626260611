import DOMPurify from 'dompurify';
import { __NO_LIMIT_DURATION__ } from 'constants/testtaker';
import { getI18n } from 'react-i18next';
import CryptoJS from 'crypto-js';

export const formatTime = (seconds) => {
  if (seconds === __NO_LIMIT_DURATION__) {
    return getI18n().t('text-field.input.no-limit');
  }

  const mm = Math.floor(seconds / 60);
  const ss = Math.floor(seconds % 60);
  return `${mm}:${ss < 10 ? `0${ss}` : ss}`;
};

export const DemoformatTime = (seconds) => {
  if (seconds === __NO_LIMIT_DURATION__) {
    return getI18n().t('text-field.input.no-limit');
  }

  const mm = Math.floor(seconds / 60);
  const ss = Math.floor(seconds % 60);
  return `${mm < 10 ? `0${mm}` : mm}${ss < 10 ? `0${ss}` : ss}`;
};

export const formatKorTime = (seconds, mins, sec) => {
  if (seconds === __NO_LIMIT_DURATION__) {
    return getI18n().t('text-field.input.no-limit');
  }

  const mm = Math.floor(seconds / 60);
  const ss = Math.floor(seconds % 60);
  return `${mm}${mins} ${ss < 10 ? `0${ss}` : ss}${sec}`;
};

export const convertHtmlToText = (htmlString) => {
  try {
    const regexp = /<[^>]*>/g;
    return htmlString.replace(regexp, '');
  } catch (e) {
    return htmlString;
  }
};

export const formatTimeMinutes = (seconds) => {
  if (!seconds) return null;
  if (seconds === __NO_LIMIT_DURATION__) {
    return getI18n().t('text-field.input.no-limit');
  }

  return (seconds / 60)?.toFixed?.(1) || Math.floor(seconds / 60);
};

export const convertTextToSec = (_text) => {
  try {
    if (_text === 'no-limit') {
      return __NO_LIMIT_DURATION__;
    }
    let mod = 0;
    const charRegexp = /[^a-zA-Z]/g;
    const numRegexp = /[^0-9]/g;

    if (typeof _text === 'number') {
      return _text * 60;
    }

    switch (_text.replace(charRegexp, '')) {
      case 'hour':
      case 'hours':
        mod = 60 * 60;
        break;
      case 'min':
      case 'mins':
        mod = 60;
        break;
      case 'sec':
      case 'secs':
        mod = 1;
        break;
    }

    return Number(_text.replace(numRegexp, '')) * mod;
  } catch (e) {
    return _text;
  }
};

export const convertHtmlForDisplay = (str) => DOMPurify.sanitize(str);
// export const convertHtmlForDisplayTest = (str) => DOMPurify.sanitize(str, {ALLOWED_ATTR: ['id','onkeyup']});

export const convertSkillTypeToI18nKey = (skillType) => {
  switch (skillType) {
    case 'video':
      return 'btn.video';
    case 'essay':
      return 'btn.essay';
    case 'multiple-choice':
      return 'btn.multiple-choice';
    case 'upload-file':
    case 'file-upload':
      return 'btn.file-upload';
    case 'spreadsheets':
      return 'btn.spreadsheet';
    case 'code':
      return 'btn.code';
    default:
      return skillType;
  }
};

export const displayName = (firstname, lastname, lang = 'ko') => {
  if (lang === 'ko') {
    return `${lastname} ${firstname}`;
  }
  return `${firstname} ${lastname}`;
};

const __AES_KEY__ = 'munchskill';

export const encryptAES = (text) =>
  CryptoJS.AES.encrypt(text, __AES_KEY__).toString();

export const decryptAES = (encrypted) => {
  const bytes = CryptoJS.AES.decrypt(encrypted, __AES_KEY__);
  return bytes.toString(CryptoJS.enc.Utf8);
};

/**
 * spaceRegex: 줄바꿈 후 인덴트 삭제
 * boldRegex: <b></b>태그 삭제
 */
export const spaceRegex = /\n+\s+/g;
export const boldRegex = /<\/?b>/g;
