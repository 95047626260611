import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useCallbackPrompt } from 'utils/hooks';
import ExitModal from 'components/modal/ExitModal';
import { useStatus } from 'utils/status';
import { convertTextToSec, formatTimeMinutes } from 'utils/string';
import { __NO_LIMIT_DURATION__ } from 'constants/testtaker';

const __GROUP_TEST_TIME__ = 10;

function TestStart({ setCurrentState, test }) {
  const { t } = useTranslation();
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(true);
  const { setTestInfo, userPlan } = useStatus();
  const [seconds, setSeconds] = React.useState(10);

  // React.useEffect(() => {
  //   if(test === undefined) return;

  //   if (userPlan >= 100) {
  //       let maxDoneCnt = test?.test?.length;
  //       if(test?.question_type?.indexOf('tag.subjective') >= 0
  //         || test?.question_type?.indexOf('tag.code') >= 0
  //         || test?.question_type?.indexOf('tag.video') >= 0
  //         ) {
  //         maxDoneCnt = 0;
  //         test?.question.forEach((question) => {
  //           if(question?.selected === true) {
  //             maxDoneCnt++;
  //           }
  //         });
  //       }

  //       if (test?.id && !test?.section) {
  //         var duration;
  //         if(test.limit) {
  //           duration = test.limit * 60;
  //         } else {
  //           duration = 60 * __GROUP_TEST_TIME__;
  //         }
  //         if (test?.id === "71") {
  //           setTestInfo({
  //             duration: 900,
  //             doneCnt: 1,
  //             maxDoneCnt: 2,
  //             canSubmit:false,
  //             buttonName : t('btn.next'),
  //           });
  //         } else if (test?.id === "1321") {
  //           setTestInfo({
  //             duration: duration,
  //             doneCnt: 1,
  //             maxDoneCnt: 20,
  //             canSubmit:false,
  //             buttonName : t('btn.next'),
  //           });
  //         } else {
  //           setTestInfo({
  //             duration: duration,
  //             doneCnt: 1,
  //             maxDoneCnt: maxDoneCnt,
  //             canSubmit:false,
  //             buttonName : t('btn.next'),
  //           });
  //         }
  //       } else {
  //         const _duration = convertTextToSec(test?.limit);
  //         switch (test?.section) {
  //           case "video":
  //             setTestInfo({
  //               duration: __NO_LIMIT_DURATION__,
  //               doneCnt: 1,
  //               maxDoneCnt: 1,
  //               canSubmit:false,
  //               buttonName : t('btn.submit'),
  //             });
  //             break;
  //           case "essay":
  //             setTestInfo({
  //               duration: _duration,
  //               doneCnt: 1,
  //               maxDoneCnt: 1,
  //               canSubmit:false,
  //               buttonName : t('btn.submit'),
  //             });
  //             break;
  //           case "multiple-choice":
  //             setTestInfo({
  //               duration: _duration,
  //               doneCnt: 1,
  //               maxDoneCnt: 1,
  //               canSubmit:false,
  //               buttonName : t('btn.submit'),
  //             });
  //             break;
  //           case "file-upload":
  //             setTestInfo({
  //               duration: _duration,
  //               doneCnt: 1,
  //               maxDoneCnt: 1,
  //               canSubmit:false,
  //               buttonName : t('btn.submit'),
  //             });
  //             break;
  //           case "spreadsheets":
  //             setTestInfo({
  //               duration: _duration,
  //               doneCnt: 1,
  //               maxDoneCnt: 1,
  //               canSubmit:false,
  //               buttonName : t('btn.submit'),
  //             });
  //             break;
  //           default:
  //             break;
  //         }
  //       }

  //       setCurrentState("progress");
  //     return;
  //   }
  // }, [test])

  React.useEffect(() => {
    if (test?.id === '6010') setCurrentState('progress');
  }, [test]);

  React.useEffect(() => {
    let maxDoneCnt = test?.test?.length;
    if (
      test?.question_type?.indexOf('tag.subjective') >= 0 ||
      test?.question_type?.indexOf('tag.code') >= 0 ||
      test?.question_type?.indexOf('tag.video') >= 0
    ) {
      maxDoneCnt = 0;
      test?.question.forEach((question) => {
        if (question?.selected === true) {
          maxDoneCnt += 1;
        }
      });
    }
    if (seconds <= 0) {
      setTimeout(() => {
        if (test?.id && !test?.section) {
          let duration;
          if (test.limit) {
            duration = test.limit * 60;
          } else {
            duration = 60 * __GROUP_TEST_TIME__;
          }
          if (test?.id === '71') {
            setTestInfo({
              duration: 900,
              doneCnt: 1,
              maxDoneCnt: 2,
              canSubmit: false,
              buttonName: t('btn.next'),
            });
          } else {
            setTestInfo({
              duration,
              doneCnt: 1,
              maxDoneCnt,
              canSubmit: false,
              buttonName: t('btn.next'),
            });
          }
        } else {
          const _duration = convertTextToSec(test?.limit);
          switch (test?.section) {
            case 'video':
              setTestInfo({
                duration: __NO_LIMIT_DURATION__,
                doneCnt: 1,
                maxDoneCnt: 1,
                canSubmit: false,
                buttonName: t('btn.submit'),
              });
              break;
            case 'essay':
              setTestInfo({
                duration: _duration,
                doneCnt: 1,
                maxDoneCnt: 1,
                canSubmit: false,
                buttonName: t('btn.submit'),
              });
              break;
            case 'multiple-choice':
              setTestInfo({
                duration: _duration,
                doneCnt: 1,
                maxDoneCnt: 1,
                canSubmit: false,
                buttonName: t('btn.submit'),
              });
              break;
            case 'file-upload':
              setTestInfo({
                duration: _duration,
                doneCnt: 1,
                maxDoneCnt: 1,
                canSubmit: false,
                buttonName: t('btn.submit'),
              });
              break;
            case 'spreadsheets':
              setTestInfo({
                duration: _duration,
                doneCnt: 1,
                maxDoneCnt: 1,
                canSubmit: false,
                buttonName: t('btn.submit'),
              });
              break;
            default:
              break;
          }
        }

        setCurrentState('progress');
      }, 1000);
      return;
    }
    const countdown = setTimeout(() => {
      setSeconds(seconds - 1);
    }, 1000);
    return () => clearTimeout(countdown);
  }, [seconds]);

  const getTestTitle = () => {
    if (test?.title) return test?.title;
    if (test?.[`title_${t('en')}`]) return test?.[`title_${t('en')}`];
    if (test?.section) {
      switch (test?.section) {
        case 'video':
          return 'btn.video';
        case 'essay':
          return 'btn.essay';
        case 'multiple-choice':
          return 'btn.multiple-choice';
        case 'file-upload':
          return 'btn.file-upload';
        case 'spreadsheets':
          return 'btn.spreadsheet';
        default:
          return '';
      }
    }
    if (test?.question_type) {
      if (
        test?.question_type.indexOf('tag.subjective') >= 0 ||
        test?.question_type.indexOf('tag.cod') >= 0 ||
        test?.question_type.indexOf('tag.video') >= 0
      ) {
        return test?.category_skill;
      }
    }
    return '';
  };

  function onSkipHandler() {
    let maxDoneCnt = test?.test?.length;
    if (
      test?.question_type?.indexOf('tag.subjective') >= 0 ||
      test?.question_type?.indexOf('tag.code') >= 0 ||
      test?.question_type?.indexOf('tag.video') >= 0
    ) {
      maxDoneCnt = 0;
      test?.question.forEach((question) => {
        if (question?.selected === true) {
          maxDoneCnt += 1;
        }
      });
    }

    if (test?.id && !test?.section) {
      let duration;
      if (test.limit) {
        duration = test.limit * 60;
      } else {
        duration = 60 * __GROUP_TEST_TIME__;
      }
      if (test?.id === '71') {
        setTestInfo({
          duration: 900,
          doneCnt: 1,
          maxDoneCnt: 2,
          canSubmit: false,
          buttonName: t('btn.next'),
        });
      } else {
        setTestInfo({
          duration,
          doneCnt: 1,
          maxDoneCnt,
          canSubmit: false,
          buttonName: t('btn.next'),
        });
      }
    } else {
      const _duration = convertTextToSec(test?.limit);
      switch (test?.section) {
        case 'video':
          setTestInfo({
            duration: __NO_LIMIT_DURATION__,
            doneCnt: 1,
            maxDoneCnt: 1,
            canSubmit: false,
            buttonName: t('btn.submit'),
          });
          break;
        case 'essay':
          setTestInfo({
            duration: _duration,
            doneCnt: 1,
            maxDoneCnt: 1,
            canSubmit: false,
            buttonName: t('btn.submit'),
          });
          break;
        case 'multiple-choice':
          setTestInfo({
            duration: _duration,
            doneCnt: 1,
            maxDoneCnt: 1,
            canSubmit: false,
            buttonName: t('btn.submit'),
          });
          break;
        case 'file-upload':
          setTestInfo({
            duration: _duration,
            doneCnt: 1,
            maxDoneCnt: 1,
            canSubmit: false,
            buttonName: t('btn.submit'),
          });
          break;
        case 'spreadsheets':
          setTestInfo({
            duration: _duration,
            doneCnt: 1,
            maxDoneCnt: 1,
            canSubmit: false,
            buttonName: t('btn.submit'),
          });
          break;
        default:
          break;
      }
    }

    setCurrentState('progress');
  }

  const ceilMin = (number) => Math.ceil(Number(number));

  return (
    <main className="pages-protected-evaluation-test-start">
      <ExitModal
        showPrompt={showPrompt}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
      />
      <div className="container">
        <div className="container-inner">
          <section className="title">
            <div className="title-sub">{t('title.next-test')}</div>
            <h2 className="title-main fs28to24">{t(getTestTitle())}</h2>
          </section>
          <section className="desc">
            <Trans
              t={t}
              parent="p"
              i18nKey="desc.next-test"
              values={{
                minute:
                  ceilMin(formatTimeMinutes(convertTextToSec(test?.limit))) ||
                  '15',
              }}
            />
            {test?.id === '991010' ||
              (test?.id === '991111' && (
                <article className="video-desc">
                  <div className="video-desc-grey-box">
                    <h5 className="video-desc-title fs16">
                      {t('title.video-test-guide')}
                    </h5>
                    <ol className="video-desc-list">
                      <li className="video-desc-list-item fs16">
                        {t('desc.video-test-guide-1')}
                      </li>
                      <li className="video-desc-list-item fs16">
                        {t('desc.video-test-guide-2')}
                      </li>
                      <li className="video-desc-list-item fs16">
                        {t('desc.video-test-guide-3')}
                      </li>
                    </ol>
                  </div>
                </article>
              ))}
          </section>
          <section className="alert">
            {seconds > 1 ? (
              <Trans
                t={t}
                parent="p"
                className="fs18"
                i18nKey="desc.test-start-second"
                values={{
                  seconds,
                }}
              />
            ) : (
              <Trans
                t={t}
                parent="p"
                className="fs18"
                i18nKey="desc.test-start-second-1"
                values={{
                  seconds,
                }}
              />
            )}
          </section>
          <button
            className="skip common-button fsbtn16 primary active"
            onClick={onSkipHandler}
          >
            {t('btn.start-right-away')}
          </button>
        </div>
      </div>
    </main>
  );
}

export default TestStart;
