import { Modal } from '@mui/material';
import { Button } from 'components/common/Button';
import React from 'react';
import { useTranslation } from 'react-i18next';

const DemoModal = ({ open, close, mainTitle, subTitle, className, submit }) => {
  const { t } = useTranslation();

  return (
    <Modal open={open}>
      <div className={`dark-modal ${className}`}>
        <div className="dark-modal-container">
          <div className="dark-modal-title-wrapper">
            <h5 className="main-title">{mainTitle}</h5>
            <p className="sub-title">{subTitle}</p>
          </div>
          <div className="dark-modal-button-wrapper">
            <div className="flex">
              <Button
                label={t('btn.yes')}
                theme="grey"
                radius={50}
                size={56}
                padding="20 10"
                onClick={submit}
                fontSize="fs16"
                className="dark-modal-submit"
              />
              <Button
                label={t('btn.no')}
                theme="grey700"
                radius={50}
                size={56}
                padding="20 10"
                onClick={close}
                fontSize="fs16"
                className="dark-modal-close"
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DemoModal;
