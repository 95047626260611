export const applyGucciList = [
  {
    key: 'Client Advisor',
    i18nKey: 'Client Advisor',
  },
  {
    key: 'Senior Client Advisor',
    i18nKey: 'Senior Client Advisor',
  },

  // {
  //   key: 'Operation Specialist',
  //   i18nKey: 'Operation Specialist',
  // },
  {
    key: 'Team Manager',
    i18nKey: 'Team Manager',
  },
  {
    key: 'GRADE',
    i18nKey: 'GRADE',
  },
  // {
  //   key: "Clienteling",
  //   i18nKey: "Clienteling",
  // },
];

export const channelGucciList = [
  {
    title: 'survey.preferred-channel',
    section: [
      {
        key: 'survey.flagship',
        i18nKey: 'survey.flagship',
      },
      {
        key: 'survey.outlet',
        i18nKey: 'survey.outlet',
      },
      {
        key: 'survey.duty-free-shops',
        i18nKey: 'survey.duty-free-shops',
      },
    ],
  },
];

export const areaGucciList = [
  {
    title: 'survey.preferred-location',
    section: [
      {
        key: 'survey.seoul',
        i18nKey: 'survey.seoul',
      },
      {
        key: 'survey.gyeonggi-incheon',
        i18nKey: 'survey.gyeonggi-incheon',
      },
      {
        key: 'survey.daejeon',
        i18nKey: 'survey.daejeon',
      },
      {
        key: 'survey.daegu',
        i18nKey: 'survey.daegu',
      },
      {
        key: 'survey.gwangju',
        i18nKey: 'survey.gwangju',
      },
      {
        key: 'survey.busan',
        i18nKey: 'survey.busan',
      },
      {
        key: 'survey.malaysia',
        i18nKey: 'survey.malaysia',
      },
      {
        key: 'survey.singapore',
        i18nKey: 'survey.singapore',
      },
    ],
  },
];

export const educationLevelGucciList = [
  {
    key: '고졸',
    i18nKey: '고졸',
  },
  {
    key: '초대졸',
    i18nKey: '초대졸',
  },
  {
    key: '대졸',
    i18nKey: '대졸',
  },
  {
    key: '대학원/PHD',
    i18nKey: '대학원/PHD',
  },
];

export const majorGucciList = [
  {
    key: 'survey.fashion',
    i18nKey: 'survey.fashion',
  },
  {
    key: 'survey.foreign-language',
    i18nKey: 'survey.foreign-language',
  },
  {
    key: 'survey.retail-services',
    i18nKey: 'survey.retail-services',
  },
  {
    key: 'survey.etc',
    i18nKey: 'survey.etc',
  },
];

export const languageGucciList = [
  {
    title: 'survey.other-language',
    section: [
      {
        key: 'survey.english',
        i18nKey: 'survey.english',
      },
      {
        key: 'survey.chinese',
        i18nKey: 'survey.chinese',
      },
      {
        key: 'survey.japanese',
        i18nKey: 'survey.japanese',
      },
      {
        key: 'survey.etc',
        i18nKey: 'survey.etc',
      }
    ],
  },
];

export const yearsExperienceGucciList = [
  {
    key: '신입',
    i18nKey: '신입',
  },
  {
    key: '1년 미만 경력',
    i18nKey: '1년 미만 경력',
  },
  {
    key: '1~3년 경력',
    i18nKey: '1~3년 경력',
  },
  {
    key: '3~5년 이상 경력',
    i18nKey: '3~5년 이상 경력',
  },
  {
    key: '5년 이상 경력',
    i18nKey: '5년 이상 경력',
  },
];

export const specialExperienceGucciList = [
  {
    key: 'Ready to wear(RTW)',
    i18nKey: 'Ready to wear(RTW)',
  },
  {
    key: 'Shoes',
    i18nKey: 'Shoes',
  },
  {
    key: 'High-end Watch',
    i18nKey: 'High-end Watch',
  },
  {
    key: 'Jewelry',
    i18nKey: 'Jewelry',
  },
  {
    key: 'Clienteling',
    i18nKey: 'Clienteling',
  },
  {
    key: 'survey.none',
    i18nKey: 'survey.none',
  },
];

export const careerGucciList = [
  {
    key: 'survey.luxury-fashion',
    i18nKey: 'survey.luxury-fashion',
  },
  {
    key: 'Watch',
    i18nKey: 'Watch',
  },
  {
    key: 'Jewelry',
    i18nKey: 'Jewelry',
  },
  {
    key: 'survey.domestic-fashion',
    i18nKey: 'survey.domestic-fashion',
  },
  {
    key: 'SPA',
    i18nKey: 'SPA',
  },
  {
    key: 'Hotel & Airline',
    i18nKey: 'Hotel & Airline',
  },
  {
    key: 'survey.etc',
    i18nKey: 'survey.etc',
  },
];

export const saleLuxryExperienceGucciList = [
  {
    key: 'menu.input.ats-yes',
    i18nKey: 'menu.input.ats-yes',
  },
  {
    key: 'menu.input.ats-no',
    i18nKey: 'menu.input.ats-no',
  },
];

export const saleExperienceGucciList = [
  {
    key: 'menu.input.ats-yes',
    i18nKey: 'menu.input.ats-yes',
  },
  {
    key: 'menu.input.ats-no',
    i18nKey: 'menu.input.ats-no',
  },
];

export const newCareerGucciList = [
  {
    title: 'survey.industry-background',
    section: [
      {
        key: 'survey.none',
        i18nKey: 'survey.none'
      },
        {
      key: 'survey.retail',
      i18nKey: 'survey.retail',
    },
      {
        key: 'survey.hospitality',
        i18nKey: 'survey.hospitality',
      },
      {
        key: 'survey.FNB',
        i18nKey: 'survey.FNB',
      },
      {
        key: 'survey.airline',
        i18nKey: 'survey.airline',
      },
      {
        key: 'survey.etc',
        i18nKey: 'survey.etc',
      }]
  }
];

export const retailCareerGucciList = [
  {
    title: 'survey.retail-industry',
    section: [{
    key: 'survey.retail',
    i18nKey: 'survey.retail',
  },
  {
    key: 'survey.retail-luxury',
    i18nKey: 'survey.retail-luxury',
  },
  {
    key: 'survey.domestic-fashion',
    i18nKey: 'survey.domestic-fashion',
  },
  {
    key: 'survey.spa',
    i18nKey: 'survey.spa',
  },
  {
    key: 'survey.cosmetics',
    i18nKey: 'survey.cosmetics',
  },
  {
    key: 'survey.etc',
    i18nKey: 'survey.etc',
  }]
}
];

export const yesOrNoGucciList = [
  {
    title: 'survey.title-lang-worked-or-lived-abroad',
    section: [{
    key: 'menu.input.ats-yes',
    i18nKey: 'menu.input.ats-yes',
  },
  {
    key: 'menu.input.ats-no',
    i18nKey: 'menu.input.ats-no',
  }]
}
];

export const levelGucciList = [
  {
    title: 'survey.title-lang-level',
    section: [{
    key: 'survey.top',
    i18nKey: 'survey.top',
  },
  {
    key: 'survey.middle',
    i18nKey: 'survey.middle',
  },
  {
    key: 'survey.bottom',
    i18nKey: 'survey.bottom',
  }]
}
];

export const importantValueGucciList = [
  {
    title: 'survey.title-important-value',
    section: [
      {
        key: 'survey.value-benefits',
        i18nKey: 'survey.value-benefits',
      },
      {
        key: 'survey.value-promotions',
        i18nKey: 'survey.value-promotions',
      },
      {
        key: 'survey.value-worklife',
        i18nKey: 'survey.value-worklife',
      },
      {
        key: 'survey.value-education',
        i18nKey: 'survey.value-education',
      },
      {
        key: 'survey.value-coworker',
        i18nKey: 'survey.value-coworker',
      },
      {
        key: 'survey.value-other',
        i18nKey: 'survey.value-other',
      },
    ]
  }
];

export const competencyGucciList = [
  {
    title: 'survey.title-competency',
    section: [
      {
        key: 'survey.competency-sales',
        i18nKey: 'survey.competency-sales',
      },
      {
        key: 'survey.competency-customer-care',
        i18nKey: 'survey.competency-customer-care',
      },
      {
        key: 'survey.competency-product-knowledge',
        i18nKey: 'survey.competency-product-knowledge',
      },
      {
        key: 'survey.competency-inventory',
        i18nKey: 'survey.competency-inventory',
      },
      {
        key: 'survey.etc',
        i18nKey: 'survey.etc',
      },
    ]
  }
];

export const changingGucciList = [
  {
    title: 'survey.title-changing-jobs',
    section: [
      {
        key: 'survey.value-benefits',
        i18nKey: 'survey.value-benefits',
      },
      {
        key: 'survey.value-promotions',
        i18nKey: 'survey.value-promotions',
      },
      {
        key: 'survey.value-worklife',
        i18nKey: 'survey.value-worklife',
      },
      {
        key: 'survey.value-coworker',
        i18nKey: 'survey.value-coworker',
      },
      {
        key: 'survey.value-work-condition',
        i18nKey: 'survey.value-work-condition',
      },
      {
        key: 'survey.etc',
        i18nKey: 'survey.etc',
      },
    ]
  }
];
