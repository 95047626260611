export const __NO_LIMIT_DURATION__ = 999999;

export const __COUNTRY_CODE__ = [
  { key: 'US', i18nKey: 'menu.country-code-us', code: '+1 US' },
  { key: 'CA', i18nKey: 'menu.country-code-ca', code: '+1 CA' },
  { key: 'RU', i18nKey: 'menu.country-code-ru', code: '+7' },
  { key: 'FR', i18nKey: 'menu.country-code-fr', code: '+33' },
  { key: 'ES', i18nKey: 'menu.country-code-es', code: '+34' },
  { key: 'UK', i18nKey: 'menu.country-code-uk', code: '+44' },
  { key: 'DE', i18nKey: 'menu.country-code-de', code: '+49' },
  { key: 'AU', i18nKey: 'menu.country-code-au', code: '+61' },
  { key: 'PH', i18nKey: 'menu.country-code-ph', code: '+63' },
  { key: 'NZ', i18nKey: 'menu.country-code-nz', code: '+64' },
  { key: 'SG', i18nKey: 'menu.country-code-sg', code: '+65' },
  { key: 'TH', i18nKey: 'menu.country-code-th', code: '+66' },
  { key: 'JP', i18nKey: 'menu.country-code-jp', code: '+81' },
  { key: 'KR', i18nKey: 'menu.country-code-kr', code: '+82' },
  { key: 'VN', i18nKey: 'menu.country-code-vn', code: '+84' },
  { key: 'CN', i18nKey: 'menu.country-code-cn', code: '+86' },
  { key: 'HK', i18nKey: 'menu.country-code-hk', code: '+852' },
  { key: 'TW', i18nKey: 'menu.country-code-tw', code: '+886' },
  { key: 'IN', i18nKey: 'menu.country-code-in', code: '+91' },
  { key: 'AE', i18nKey: 'menu.country-code-ae', code: '+971' },
];
