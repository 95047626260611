import { initializeApp } from 'firebase/app';
// import firebase from "firebase/compat/app";
import { getAnalytics } from 'firebase/analytics';
import firebaseConfig from 'config/firebaseConfig';

// const firebaseConfig = {
//     apiKey: process.env.REACT_APP_API_KDY,
//     authDomain: process.env.REACT_APP_AUTH_DOMAIN,
//     projectId: process.env.REACT_APP_PROJECT_ID,
//     storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
//     messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
//     appId: process.env.REACT_APP_APP_ID,
//     measurementId: process.env.REACT_APP_MEASUREMENT_ID,
// }

// export const app = firebase.initializeApp(firebaseConfig);
export const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
