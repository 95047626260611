import React from 'react';
import { useResize } from 'utils/hooks';

function VideoInfoContents({ number, desc, image, className }) {
  const windowSize = useResize();
  const [maxHeightCheck, setMaxHeightCheck] = React.useState(false);

  React.useEffect(() => {
    if (windowSize.height < 820) {
      setMaxHeightCheck(true);
    } else {
      setMaxHeightCheck(false);
    }
  }, [windowSize.height]);

  return (
    <section className={`section-wrapper ${className}`}>
      <article className="section-text-box">
        <aside className="section-text-number">
          <span className="fs36">{number && number}.</span>
        </aside>
        <aside className="section-text-description">
          <p className="fs20">{desc && desc}</p>
        </aside>
      </article>

      <article
        className={`section-img-box ${maxHeightCheck ? 'small' : 'large'}`}
      >
        <div className="section-img-wrap">{image && image}</div>
      </article>
    </section>
  );
}

export default VideoInfoContents;
