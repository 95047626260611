import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useResult } from 'utils/result';
import { toast } from 'react-toastify';
import { updateCondition } from 'store/slices/testtakerSlice';
import { getAssessmentInfo } from 'store/slices/testtakerSlice';
import { executeAction } from 'utils/redux';
import { useStatus } from 'utils/status';
import { useProgress } from 'utils/progress';
import { Radio, Checkbox, MenuItem } from '@mui/material';

const __GROUP_TEST_TIME__ = 10;

const SelectTest = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { uid, aid, email: cid } = useStatus();
  const { setIsEntireLoading } = useProgress();
  const [canSubmit, setCanSubmit] = React.useState(false);
  const [selectedDeviceCamera, setSelectedDeviceCamera] = React.useState(null);
  const [assessment, setAssessment] = React.useState(null);
  const [checkedState, setCheckedState] = React.useState([]);
  const [selectedValue, setSelectedValue] = React.useState([]);

  React.useEffect(() => {
    if (!Boolean(uid) || !Boolean(aid)) return;

    setIsEntireLoading(true);
    executeAction(
      getAssessmentInfo({
        uid,
        aid,
      })
    )
      .unwrap()
      .then(({ doc }) => {
        setIsEntireLoading(false);
        setAssessment(doc);
        const initCheckted = new Array(doc?.groupTests?.skills.length).fill(
          false
        );
        setCheckedState(initCheckted);
      })
      .catch((e) => {
        setIsEntireLoading(false);
        setAssessment(null);
      });
  }, [uid, aid]);

  React.useEffect(() => {
    if (selectedValue.length === assessment?.groupTests?.testcnt) {
      setCanSubmit(true);
    } else {
      setCanSubmit(false);
    }
  }, [selectedValue]);

  const onSubmitHandler = () => {
    if (!Boolean(selectedDeviceCamera)) {
      toast(t('toast.desc.camera-setting-error'));
    }
  };
  const handleOnChange = (event, position) => {
    event.preventDefault();
    event.stopPropagation();

    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );

    const selected = [];
    updatedCheckedState.forEach((item, index) => {
      if (item) {
        selected.push(index);
      }
    });

    if (selected.length > assessment?.groupTests?.testcnt) {
      checkedState[position] = false;
      // toast(t("toast.desc.camera-setting-error"));
      return;
    }

    setCheckedState(updatedCheckedState);
    setSelectedValue(selected);
  };

  return (
    <main className="pages-public-selecttest">
      <div className="container">
        <div className="container-inner">
          <header className="header">
            <div className="header-title fs28to24">
              {t('title.select-tests')}
            </div>
            <div className="header-description fs16">
              <Trans
                t={t}
                i18nKey={'desc.select-tests'}
                values={{
                  total: assessment?.groupTests?.testcnt,
                }}
              />
              <Trans
                t={t}
                i18nKey={'desc.left-test-count'}
                values={{
                  cnt: assessment?.groupTests?.testcnt - selectedValue.length,
                }}
              />
            </div>
            <div className="header-description fs16">
              {t('column.duration')}{' '}
              {assessment?.groupTests?.testcnt * __GROUP_TEST_TIME__}
              {t('desc.minute')}
            </div>
          </header>
          <section className="test-select">
            <ul className="test-list">
              {(assessment?.groupTests?.skills || []).map((skill, idx) => (
                <li
                  className={`test-list-item ${
                    checkedState[idx] && 'selected'
                  }`}
                  key={idx}
                >
                  <div
                    className="container"
                    onClick={(e) => {
                      handleOnChange(e, idx);
                    }}
                  >
                    <MenuItem
                      value={skill?.title_en}
                      selected={checkedState[idx] === true}
                      key={idx}
                    >
                      <Checkbox
                        className="radio-button"
                        checked={checkedState[idx]}
                        onChange={(e) => {
                          handleOnChange(e, idx);
                        }}
                        value={skill?.title_en}
                        name="radio-buttons"
                        sx={{
                          color: '#2E3338',
                          '&.Mui-checked': {
                            color: '#FFFFFF',
                          },
                        }}
                        inputProps={{ 'aria-label': skill?.title_en }}
                      />
                    </MenuItem>
                    <p className="fs16">{skill?.title_en}</p>
                  </div>
                </li>
              ))}
            </ul>
          </section>
          <section className="submit">
            <button
              className={`submit common-button fsbtn16 primary ${
                canSubmit && 'active'
              }`}
              onClick={() =>
                canSubmit &&
                navigate('/testtaker/evaluation', {
                  state: { gtid: selectedValue },
                })
              }
            >
              {t('progress-status.start')}
            </button>
          </section>
        </div>
      </div>
    </main>
  );
};

export default SelectTest;
