import React from 'react';
import ReactDOM from 'react-dom';
import { useStatus } from 'utils/status';
import {
  getAssessmentInfo,
  getCandidateInfo,
} from 'store/slices/testtakerSlice';
import { executeAction } from 'utils/redux';
import { useTranslation } from 'react-i18next';
import { useLocation, Navigate } from 'react-router-dom';
import { useProgress } from 'utils/progress';
import * as Sentry from '@sentry/react';
import TestStart from './evaluation/TestStart';
import TestEnd from './evaluation/TestEnd';
import Common from './evaluation/objectives/Common';
import CommonLib from './evaluation/objectives/CommonLib';
import CultureFit from './evaluation/objectives/CultureFit';
import Essay from './evaluation/subjectives/Essay';
import Video from './evaluation/subjectives/Video';
import MultipleChoice from './evaluation/subjectives/MultipleChoice';
import FileUpload from './evaluation/subjectives/FileUpload';
import OBPITest from './evaluation/obpitest/OBPITest';
import KHAITest from './evaluation/khaitest/KHAITest';
import Spreadsheets from './evaluation/subjectives/Spreadsheets';
import LanguageTests from './evaluation/languageTest/LanguageTests';
import DemoTestEnd from './evaluation/DemoTestEnd';
import DemoTestStart from './evaluation/DemoTestStart';
import DemoCommon from './evaluation/objectives/DemoCommon';

const Evaluation = () => {
  const { uid, aid, email: cid } = useStatus();
  const { t } = useTranslation();
  const [doc, setDoc] = React.useState();
  const [tests, setTests] = React.useState([]);
  const [currentTestIdx, setCurrentTestIdx] = React.useState(0);
  const [currentState, setCurrentState] = React.useState('start');
  const { setIsEntireLoading } = useProgress();
  const location = useLocation();
  const [testDone, setTestDone] = React.useState(false);
  const groupTests = location?.state?.gtid || [];

  React.useEffect(() => {
    const docElem = window.document.documentElement;
    if (docElem.requestFullscreen) docElem.requestFullscreen();
  }, []);

  React.useEffect(() => {
    if (!uid || !aid || !cid) return;

    setIsEntireLoading(true);
    executeAction(
      getCandidateInfo({
        uid,
        aid,
        email: cid,
      })
    )
      .unwrap()
      .then(({ doc }) => {
        if (doc?.answeredAt) {
          setTestDone(true);
        }
        setIsEntireLoading(false);
      })
      .catch((e) => {
        Sentry.captureException(e);
        console.dir(e);
        setIsEntireLoading(false);
      });
  }, [uid, aid, cid]);

  React.useEffect(() => {
    if (!uid || !aid) return;

    executeAction(
      getAssessmentInfo({
        uid,
        aid,
      })
    )
      .unwrap()
      .then(({ doc }) => {
        setDoc(doc || {});
        let tests;
        const testlists = new Array();
        doc.tests.forEach((test, idx) => {
          // console.log(test);
          if (
            (test?.question && test?.question?.length > 0) ||
            test?.question === ''
          ) {
            // console.log(test?.test);
            // shuffle(test?.test);
            // console.log(test?.test);
            // console.log(test);
            if (
              test?.question_type?.indexOf('tag.subjective') >= 0 ||
              test?.question_type?.indexOf('tag.code') >= 0 ||
              test?.question_type?.indexOf('tag.video') >= 0
            ) {
              doc.tests[idx].test = test?.test;
              testlists.push(doc.tests[idx]);
            } else {
              if (typeof test?.question === 'object') {
                tests = test?.question?.filter((question) => question.selected);
              } else {
                tests = test?.question.slice(0, test?.questionNum);
              }
              doc.tests[idx].test = tests;
              testlists.push(doc.tests[idx]);
            }
            // test?.test.forEach((tid) =>{
            //   const lang = t('lang');
            //   console.log("list = " + tid + ", lang = " + lang);
            //   executeAction(getTestInfo({ tid, lang }))
            //   .unwrap()
            //   .then(({ doc }) => {
            //     console.log(doc?.level_en);
            //   })
            //   .catch((e) => {
            //     console.dir(e);
            //   });
            // });
          } else if (groupTests.length > 0) {
            groupTests.forEach((gtid) => {
              tests = doc?.groupTests?.skills[gtid];
              tests.test = doc?.groupTests?.skills[gtid]?.question;
              testlists.push(tests);
            });
          } else {
            shuffle(test?.test);
            tests = test?.test.slice(0, 30);
            doc.tests[idx].test = tests;
            testlists.push(doc.tests[idx]);
          }
        });
        // setTests(doc.tests || []);
        // console.log(doc.tests);
        setTests(testlists || []);
        // console.log(tests);
      })
      .catch((e) => {
        Sentry.captureException(e);
        console.dir(e);
      });
  }, [uid, aid]);

  if (testDone) {
    return <Navigate to="/testtaker/auth/invalidinvitation" />;
  }

  switch (currentState) {
    case 'start':
      return (
        <DemoTestStart
          setCurrentState={setCurrentState}
          test={tests?.[currentTestIdx]}
        />
        // <TestStart
        //   setCurrentState={setCurrentState}
        //   test={tests?.[currentTestIdx]}
        // />
      );
      break;
    case 'progress':
      if (tests[currentTestIdx]?.section) {
        switch (tests?.[currentTestIdx]?.section) {
          case 'video':
            return (
              <Video
                test={tests?.[currentTestIdx]}
                setCurrentState={setCurrentState}
              />
            );
          case 'essay':
            return (
              <Essay
                test={tests?.[currentTestIdx]}
                setCurrentState={setCurrentState}
              />
            );
          case 'multiple-choice':
            return (
              <MultipleChoice
                test={tests?.[currentTestIdx]}
                setCurrentState={setCurrentState}
              />
            );
          case 'file-upload':
            return (
              <FileUpload
                test={tests?.[currentTestIdx]}
                setCurrentState={setCurrentState}
              />
            );
          case 'spreadsheets':
            return (
              <Spreadsheets
                test={tests?.[currentTestIdx]}
                uid={uid}
                aid={aid}
                cid={cid}
                setCurrentState={setCurrentState}
              />
            );
          default:
            if (tests?.[currentTestIdx]?.id === '71') {
              return (
                <CultureFit
                  test={tests?.[currentTestIdx]}
                  setCurrentState={setCurrentState}
                />
              );
            }

          // return (
          //   <OBPITest />
          // );
        }
      } else if (tests?.[currentTestIdx]?.category_skill) {
        return (
          <CommonLib
            test={tests?.[currentTestIdx]}
            setCurrentState={setCurrentState}
            assessment={doc}
          />
        );
      } else {
        if (tests?.[currentTestIdx]?.title_en?.indexOf('인성/심리검사') >= 0) {
          // if(tests?.[currentTestIdx]?.title_en?.indexOf('OBPI') >= 0 ) {
          return <OBPITest setCurrentState={setCurrentState} />;
        }
        if (
          tests?.[currentTestIdx]?.title_en?.toUpperCase().indexOf('KHAI') >= 0
        ) {
          return (
            <KHAITest
              setCurrentState={setCurrentState}
              test={tests?.[currentTestIdx]}
            />
          );
        }

        return tests?.[currentTestIdx]?.id === '6010' ? (
          // test : object
          <LanguageTests
            test={tests?.[currentTestIdx]}
            setCurrentState={setCurrentState}
            assessment={doc}
          />
        ) : (
          <DemoCommon
            test={tests?.[currentTestIdx]}
            setCurrentState={setCurrentState}
          />
        );
      }
    case 'end':
      return (
        <DemoTestEnd
          assessment={doc}
          tests={tests}
          lastTestIdx={tests.length}
          currentTestIdx={currentTestIdx}
          setCurrentTestIdx={setCurrentTestIdx}
          setCurrentState={setCurrentState}
        />
        // <TestEnd
        //   assessment={doc}
        //   tests={tests}
        //   lastTestIdx={tests.length}
        //   currentTestIdx={currentTestIdx}
        //   setCurrentTestIdx={setCurrentTestIdx}
        //   setCurrentState={setCurrentState}
        // />
      );
  }
};

const shuffle = (array) => {
  let currentIndex = array.length;
  let randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
};

export default Evaluation;
