export const educationLevelList = [
  {
    key: 'high-school-graduation',
    i18nKey: 'menu.input.high-school-graduation',
  },
  {
    key: 'college-graduation',
    i18nKey: 'menu.input.college-graduation',
  },
  {
    key: 'while-in-university',
    i18nKey: 'menu.input.while-in-university',
  },
  {
    key: 'university-graduation',
    i18nKey: 'menu.input.university-graduation',
  },
  {
    key: 'finish-graduate-school',
    i18nKey: 'menu.input.finish-graduate-school',
  },
];

export const yearsExperienceList = [
  {
    key: '0-year',
    i18nKey: 'menu.input.0-year',
  },
  {
    key: '1-year',
    i18nKey: 'menu.input.1-year',
  },
  {
    key: '2-years',
    i18nKey: 'menu.input.2-years',
  },
  {
    key: '3-years',
    i18nKey: 'menu.input.3-years',
  },
  {
    key: '4-years',
    i18nKey: 'menu.input.4-years',
  },
  {
    key: '5-years',
    i18nKey: 'menu.input.5-years',
  },
  {
    key: '6-years',
    i18nKey: 'menu.input.6-years',
  },
  {
    key: '7-years',
    i18nKey: 'menu.input.7-years',
  },
  {
    key: '8-years',
    i18nKey: 'menu.input.8-years',
  },
  {
    key: '9-years',
    i18nKey: 'menu.input.9-years',
  },
  {
    key: '10-years',
    i18nKey: 'menu.input.10-years',
  },
  {
    key: '11-years',
    i18nKey: 'menu.input.11-years',
  },
  {
    key: '12-years',
    i18nKey: 'menu.input.12-years',
  },
  {
    key: '13-years',
    i18nKey: 'menu.input.13-years',
  },
  {
    key: '14-years',
    i18nKey: 'menu.input.14-years',
  },
  {
    key: '15-years',
    i18nKey: 'menu.input.15-years',
  },
  {
    key: '16-years',
    i18nKey: 'menu.input.16-years',
  },
  {
    key: '17-years',
    i18nKey: 'menu.input.17-years',
  },
  {
    key: '18-years',
    i18nKey: 'menu.input.18-years',
  },
  {
    key: '19-years',
    i18nKey: 'menu.input.19-years',
  },
  {
    key: '20-years',
    i18nKey: 'menu.input.20-years',
  },
  {
    key: 'for-over-21-years',
    i18nKey: 'menu.input.for-over-21-years',
  },
];

export const birthList = [
  {
    key: 'menu-input-2023',
    i18nKey: 'menu.input.2023',
  },
  {
    key: 'menu-input-2022',
    i18nKey: 'menu.input.2022',
  },
  {
    key: 'menu-input-2021',
    i18nKey: 'menu.input.2021',
  },
  {
    key: 'menu-input-2020',
    i18nKey: 'menu.input.2020',
  },
  {
    key: 'menu-input-2019',
    i18nKey: 'menu.input.2019',
  },
  {
    key: 'menu-input-2018',
    i18nKey: 'menu.input.2018',
  },
  {
    key: 'menu-input-2017',
    i18nKey: 'menu.input.2017',
  },
  {
    key: 'menu-input-2016',
    i18nKey: 'menu.input.2016',
  },
  {
    key: 'menu-input-2015',
    i18nKey: 'menu.input.2015',
  },
  {
    key: 'menu-input-2014',
    i18nKey: 'menu.input.2014',
  },
  {
    key: 'menu-input-2013',
    i18nKey: 'menu.input.2013',
  },
  {
    key: 'menu-input-2012',
    i18nKey: 'menu.input.2012',
  },
  {
    key: 'menu-input-2011',
    i18nKey: 'menu.input.2011',
  },
  {
    key: 'menu-input-2010',
    i18nKey: 'menu.input.2010',
  },
  {
    key: 'menu-input-2009',
    i18nKey: 'menu.input.2009',
  },
  {
    key: 'menu-input-2008',
    i18nKey: 'menu.input.2008',
  },
  {
    key: 'menu-input-2007',
    i18nKey: 'menu.input.2007',
  },
  {
    key: 'menu-input-2006',
    i18nKey: 'menu.input.2006',
  },
  {
    key: 'menu-input-2005',
    i18nKey: 'menu.input.2005',
  },
  {
    key: 'menu-input-2004',
    i18nKey: 'menu.input.2004',
  },
  {
    key: 'menu-input-2003',
    i18nKey: 'menu.input.2003',
  },
  {
    key: 'menu-input-2002',
    i18nKey: 'menu.input.2002',
  },
  {
    key: 'menu-input-2001',
    i18nKey: 'menu.input.2001',
  },
  {
    key: 'menu-input-2000',
    i18nKey: 'menu.input.2000',
  },
  {
    key: 'menu-input-1999',
    i18nKey: 'menu.input.1999',
  },
  {
    key: 'menu-input-1998',
    i18nKey: 'menu.input.1998',
  },
  {
    key: 'menu-input-1997',
    i18nKey: 'menu.input.1997',
  },
  {
    key: 'menu-input-1996',
    i18nKey: 'menu.input.1996',
  },
  {
    key: 'menu-input-1995',
    i18nKey: 'menu.input.1995',
  },
  {
    key: 'menu-input-1994',
    i18nKey: 'menu.input.1994',
  },
  {
    key: 'menu-input-1993',
    i18nKey: 'menu.input.1993',
  },
  {
    key: 'menu-input-1992',
    i18nKey: 'menu.input.1992',
  },
  {
    key: 'menu-input-1991',
    i18nKey: 'menu.input.1991',
  },
  {
    key: 'menu-input-1990',
    i18nKey: 'menu.input.1990',
  },
  {
    key: 'menu-input-1989',
    i18nKey: 'menu.input.1989',
  },
  {
    key: 'menu-input-1988',
    i18nKey: 'menu.input.1988',
  },
  {
    key: 'menu-input-1987',
    i18nKey: 'menu.input.1987',
  },
  {
    key: 'menu-input-1986',
    i18nKey: 'menu.input.1986',
  },
  {
    key: 'menu-input-1985',
    i18nKey: 'menu.input.1985',
  },
  {
    key: 'menu-input-1984',
    i18nKey: 'menu.input.1984',
  },
  {
    key: 'menu-input-1983',
    i18nKey: 'menu.input.1983',
  },
  {
    key: 'menu-input-1982',
    i18nKey: 'menu.input.1982',
  },
  {
    key: 'menu-input-1981',
    i18nKey: 'menu.input.1981',
  },
  {
    key: 'menu-input-1980',
    i18nKey: 'menu.input.1980',
  },
  {
    key: 'menu-input-1979',
    i18nKey: 'menu.input.1979',
  },
  {
    key: 'menu-input-1978',
    i18nKey: 'menu.input.1978',
  },
  {
    key: 'menu-input-1977',
    i18nKey: 'menu.input.1977',
  },
  {
    key: 'menu-input-1976',
    i18nKey: 'menu.input.1976',
  },
  {
    key: 'menu-input-1975',
    i18nKey: 'menu.input.1975',
  },
  {
    key: 'menu-input-1974',
    i18nKey: 'menu.input.1974',
  },
  {
    key: 'menu-input-1973',
    i18nKey: 'menu.input.1973',
  },
  {
    key: 'menu-input-1972',
    i18nKey: 'menu.input.1972',
  },
  {
    key: 'menu-input-1971',
    i18nKey: 'menu.input.1971',
  },
  {
    key: 'menu-input-1970',
    i18nKey: 'menu.input.1970',
  },
  {
    key: 'menu-input-1969',
    i18nKey: 'menu.input.1969',
  },
  {
    key: 'menu-input-1968',
    i18nKey: 'menu.input.1968',
  },
  {
    key: 'menu-input-1967',
    i18nKey: 'menu.input.1967',
  },
  {
    key: 'menu-input-1965',
    i18nKey: 'menu.input.1965',
  },
  {
    key: 'menu-input-1964',
    i18nKey: 'menu.input.1964',
  },
  {
    key: 'menu-input-1963',
    i18nKey: 'menu.input.1963',
  },
  {
    key: 'menu-input-1962',
    i18nKey: 'menu.input.1962',
  },
  {
    key: 'menu-input-1961',
    i18nKey: 'menu.input.1961',
  },
  {
    key: 'menu-input-1960',
    i18nKey: 'menu.input.1960',
  },
  {
    key: 'menu-input-1959',
    i18nKey: 'menu.input.1959',
  },
  {
    key: 'menu-input-1958',
    i18nKey: 'menu.input.1958',
  },
  {
    key: 'menu-input-1957',
    i18nKey: 'menu.input.1957',
  },
  {
    key: 'menu-input-1956',
    i18nKey: 'menu.input.1956',
  },
  {
    key: 'menu-input-1955',
    i18nKey: 'menu.input.1955',
  },
  {
    key: 'menu-input-1954',
    i18nKey: 'menu.input.1954',
  },
  {
    key: 'menu-input-1953',
    i18nKey: 'menu.input.1953',
  },
  {
    key: 'menu-input-1952',
    i18nKey: 'menu.input.1952',
  },
  {
    key: 'menu-input-1951',
    i18nKey: 'menu.input.1951',
  },
  {
    key: 'menu-input-1950',
    i18nKey: 'menu.input.1950',
  },
  {
    key: 'menu-input-1949',
    i18nKey: 'menu.input.1949',
  },
  {
    key: 'menu-input-1948',
    i18nKey: 'menu.input.1948',
  },
  {
    key: 'menu-input-1947',
    i18nKey: 'menu.input.1947',
  },
  {
    key: 'menu-input-1946',
    i18nKey: 'menu.input.1946',
  },
  {
    key: 'menu-input-1945',
    i18nKey: 'menu.input.1945',
  },
  {
    key: 'menu-input-1944',
    i18nKey: 'menu.input.1944',
  },
  {
    key: 'menu-input-1943',
    i18nKey: 'menu.input.1943',
  },
  {
    key: 'menu-input-1942',
    i18nKey: 'menu.input.1942',
  },
  {
    key: 'menu-input-1941',
    i18nKey: 'menu.input.1941',
  },
  {
    key: 'menu-input-1940',
    i18nKey: 'menu.input.1940',
  },
  {
    key: 'menu-input-1939',
    i18nKey: 'menu.input.1939',
  },
  {
    key: 'menu-input-1938',
    i18nKey: 'menu.input.1938',
  },
  {
    key: 'menu-input-1937',
    i18nKey: 'menu.input.1937',
  },
  {
    key: 'menu-input-1936',
    i18nKey: 'menu.input.1936',
  },
  {
    key: 'menu-input-1935',
    i18nKey: 'menu.input.1935',
  },
  {
    key: 'menu-input-1934',
    i18nKey: 'menu.input.1934',
  },
  {
    key: 'menu-input-1933',
    i18nKey: 'menu.input.1933',
  },
  {
    key: 'menu-input-1932',
    i18nKey: 'menu.input.1932',
  },
  {
    key: 'menu-input-1931',
    i18nKey: 'menu.input.1931',
  },
  {
    key: 'menu-input-1930',
    i18nKey: 'menu.input.1930',
  },
];
// export const ageList = [
//   {
//     key: "under-18-years-old",
//     i18nKey: "menu.input.under-18-years-old",
//   },
//   {
//     key: "18-24-years-old",
//     i18nKey: "menu.input.18-24-years-old",
//   },
//   {
//     key: "25-34-years-old",
//     i18nKey: "menu.input.25-34-years-old",
//   },
//   {
//     key: "35-44-years-old",
//     i18nKey: "menu.input.35-44-years-old",
//   },
//   {
//     key: "45-54-years-old",
//     i18nKey: "menu.input.45-54-years-old",
//   },
//   {
//     key: "55-64-years-old",
//     i18nKey: "menu.input.55-64-years-old",
//   },
//   {
//     key: "64-years-old-and-above",
//     i18nKey: "menu.input.64-years-old-and-above",
//   },
// ];

export const sexList = [
  {
    key: 'male',
    i18nKey: 'menu.label.male',
  },
  {
    key: 'female',
    i18nKey: 'menu.label.female',
  },
  {
    key: 'non-disclosure',
    i18nKey: 'menu.label.non-disclosure',
  },
];

export const currencyList = [
  {
    key: 'EUR',
  },
  {
    key: 'JPY',
  },
  {
    key: 'KRW',
  },
  {
    key: 'SGD',
  },
  {
    key: 'USD',
  },
];