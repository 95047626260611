import React from 'react';
import { useTranslation } from 'react-i18next';
import { Radio } from '@mui/material';
import { useCallbackPrompt, useResize } from 'utils/hooks';
import ExitModal from 'components/modal/ExitModal';
import { useResult } from 'utils/result';
import { useStatus } from 'utils/status';
import {
  formatTime,
  convertTextToSec,
  convertHtmlForDisplay,
} from 'utils/string';
import DemoAnswerListItem from 'components/languageTest/DemoAnswerListItem';

function MultipleChoice({ test, setCurrentState }) {
  const { t } = useTranslation();
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(true);
  const windowSize = useResize();
  const { recordResult } = useResult();
  const { setTestInfo, getTestInfo } = useStatus();
  const [selectedValue, setSelectedValue] = React.useState(-1);
  const { duration, doneCnt, maxDoneCnt, canSubmit } = getTestInfo();

  const onSubmitHandler = () => {
    setTestInfo({
      duration: null,
      doneCnt: null,
      maxDoneCnt: null,
      canSubmit: false,
      bottonName: null,
    });
    recordResult({
      sid: test?.uuid || test?.id,
      data: {
        checks: [selectedValue],
      },
    });
    setCurrentState('end');
  };
  React.useEffect(() => {
    const skillEndEventListener = (event) => {
      onSubmitHandler();
    };

    window.scrollTo(0, 0);

    window.document.addEventListener('skill_end', skillEndEventListener);
    return () =>
      window.document.removeEventListener('skill_end', skillEndEventListener);
  }, []);

  React.useEffect(() => {
    const testDoneEventListener = (event) => {
      onSubmitHandler();
    };

    if (selectedValue >= 0) {
      setTestInfo({
        duration,
        doneCnt,
        maxDoneCnt,
        canSubmit: true,
        buttonName: t('btn.submit'),
      });
    }

    window.document.addEventListener('test_done', testDoneEventListener);
    return () => {
      window.document.removeEventListener('test_done', testDoneEventListener);
    };
  }, [selectedValue]);

  return (
    <main className="demo-pages-protected-evaluation-objectives-common subjective">
      <ExitModal
        showPrompt={showPrompt}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
      />
      <div className="container">
        {windowSize?.width > 1023 && (
          <div className="objectives-title-box">
            <h3>{t('tag.multiple-choice')}</h3>
          </div>
        )}
        <div className="separater">
          <section className="separater-left">
            <div className="question fs18">
              {test?.videoUrl !== '' && test?.videoUrl !== undefined ? (
                <div className="video-box">
                  <video
                    className="question-video"
                    src={`${process.env.REACT_APP_IMATE_URL}/${test?.videoUrl}`}
                    controls
                    autoPlay
                    playsInline
                  />
                </div>
              ) : (
                <></>
              )}
              <p
                className="multiple-question"
                dangerouslySetInnerHTML={{
                  __html: convertHtmlForDisplay(test?.question),
                }}
              />
            </div>
          </section>
          <section className="separater-right">
            <div className="answer">
              <ul className="answer-list">
                {(test?.answers?.list || []).map((answer, idx) => (
                  <DemoAnswerListItem
                    selectedNumber={selectedValue}
                    setSelectedNumber={setSelectedValue}
                    idx={idx}
                    answer={answer}
                    key={idx}
                  />
                ))}
              </ul>
            </div>
          </section>
        </div>
      </div>
    </main>
  );
}

export default MultipleChoice;
