import DemoVoiceTestLayout from 'components/voice_test/Demo/DemoVoiceTestLayout';
import React from 'react';
import { useStatus } from 'utils/status';

const DemoVoiceTest = () => {
    const { uid, aid, email: cid, userPlan } = useStatus();

    return <DemoVoiceTestLayout uid={uid} aid={aid} cid={cid} userPlan={userPlan} />;
};

export default DemoVoiceTest;