import React from 'react';
import { useNavigate, useSearchParams, Navigate, Link } from 'react-router-dom';
import { useProgress } from 'utils/progress';
import { executeAction } from 'utils/redux';
import {
  getAssessmentInfo,
  getCandidateInfo,
} from 'store/slices/testtakerSlice';
import { useTranslation, Trans } from 'react-i18next';
import { convertHtmlToText, encryptAES, decryptAES } from 'utils/string';

const useMountEffect = (fun) => React.useEffect(fun, []);

const DownloadFile = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [createTime, setCreateTime] = React.useState(0);
  const [orgURL, setOrgURL] = React.useState('');
  const encodedParam = searchParams.get('key');
  const current = new Date().getTime();

  React.useEffect(() => {
    if (!Boolean(encodedParam)) return;
    const decodedParam = decryptAES(encodedParam);
    const [cTime, orgurl] = decodedParam.split('|');
    setCreateTime(cTime);
    setOrgURL(orgurl);
  }, [encodedParam]);

  return (
    <main className="pages-auth-take-invitation-invalid">
      {current - createTime <= 30 * 24 * 60 * 60 * 1000 ? (
        <div className="hidden">{(window.location.href = orgURL)}</div>
      ) : (
        <div className="container">
          <header className="header">
            <div className="header-img"></div>
            <div className="header-description flex">
              <span className="center">{t('desc.invalid-file')}</span>
            </div>
          </header>
        </div>
      )}
    </main>
  );
};

export default DownloadFile;
