import React from 'react';
import { executeAction } from 'utils/redux';
import { getUserInfo } from 'store/slices/authSlice';

const StatusContext = React.createContext(null);

export function StatusProvider({ children }) {
  const [uid, setUid] = React.useState('');
  const [aid, setAid] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [corporation, setCorporation] = React.useState('');
  const [logoURL, setLogoURL] = React.useState('');
  const [userPlan, setUserPlan] = React.useState('');

  const [timer, setTimer] = React.useState(null);
  const [duration, setDuration] = React.useState(null);
  const [doneCnt, setDoneCnt] = React.useState(null);
  const [maxDoneCnt, setMaxDoneCnt] = React.useState(null);
  const [canSubmit, setCanSubmit] = React.useState(false);
  const [buttonName, setButtonName] = React.useState('');

  React.useEffect(() => {
    if (timer >= (duration || 9999)) {
      setTimeout(() => {}, 1000);
      return;
    }
    const countdown = setTimeout(() => {
      setTimer(timer + 1);
    }, 1000);
    return () => clearTimeout(countdown);
  }, [timer]);

  const startTimer = () => {
    setTimer(0);
  };

  const stopTimer = () => {};

  const setInfo = ({ uid, aid, email, firstName, lastName }) => {
    if (uid) {
      setUid(uid);
      executeAction(
        getUserInfo({
          uid,
        })
      )
        .unwrap()
        .then(({ doc }) => {
          setCorporation(doc?.corporation || '');
          setLogoURL(doc?.logoURL || '');
          setUserPlan(doc?.plan || 0);
        })
        .catch((e) => {
          console.dir(e);
        });
    }
    if (aid) setAid(aid);
    if (email) setEmail(email);
    if (firstName) setFirstName(firstName);
    if (lastName) setLastName(lastName);
  };

  const setTestInfo = ({
    duration: _duration,
    doneCnt: _cnt,
    maxDoneCnt: _maxCnt,
    canSubmit: _canSubmit,
    buttonName: _buttonName,
  }) => {
    setDuration(_duration);
    setDoneCnt(_cnt);
    setMaxDoneCnt(_maxCnt || 1);
    setCanSubmit(_canSubmit || false);
    setButtonName(_buttonName || '');
  };

  const getTestInfo = () => ({
    duration,
    doneCnt,
    maxDoneCnt,
    canSubmit,
    buttonName,
  });

  const value = {
    uid,
    aid,
    email,
    firstName,
    lastName,
    corporation,
    logoURL,
    userPlan,
    setInfo,
    setTestInfo,
    getTestInfo,
    startTimer,
    stopTimer,
  };

  return (
    <StatusContext.Provider value={value}>{children}</StatusContext.Provider>
  );
}

export const useStatus = () => React.useContext(StatusContext);

export const divideSkillScope = (skill) => {
  if (skill?.group?.length > 0 && skill?.testcnt > 0) {
    return 'grouptest';
  }
  switch (skill?.section) {
    case 'video':
    case 'essay':
    case 'multiple-choice':
    case 'file-upload':
    case 'spreadsheets':
      return 'subjective';
    default:
      return 'objective';
  }
};

export const divideSkillSection = (skill) => {
  switch (skill?.section) {
    case 'video':
      return 'video';
    case 'essay':
      return 'essay';
    case 'multiple-choice':
      return 'multiple-choice';
    case 'file-upload':
      return 'file-upload';
    case 'spreadsheets':
      return 'spreadsheets';
    default:
      if (skill?.id === '71') return 'culture-fit';
      return 'common';
  }
};
