import ExitModal from 'components/modal/ExitModal';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCallbackPrompt } from 'utils/hooks';
import { useResult } from 'utils/result';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Typography,
} from '@mui/material';
import { useStatus } from 'utils/status';
import DemoModal from '../../../../components/modal/DemoModal';

const DemoLanguageSelect = ({ setStep, setCurrentState, assessment }) => {
  const { t } = useTranslation();
  const { recordResult } = useResult();
  const { uid } = useStatus();
  const [openSkipModal, setOpenSkipModal] = useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(true);
  const [checked, setChecked] = useState(
    assessment?.name === 'Overseas Job Concert'
      ? {
          english: true,
          chinese: false,
          japanese: false,
        }
      : assessment?.name === 'Overseas Job Concert - Taiwanese'
      ? {
          korean: true,
        }
      : uid === '1zcGDEVBtney2QVFkeRpIe0DNcn1'
      ? { english: true, korean: false, chinese: false, japanese: false }
      : {
          english: true,
          // korean: false,
          chinese: false,
          japanese: false,
        }
  );

  /** ========
   * @handelCheckboxChange : 언어 선택 클릭 이벤트
   * @param {*} event
  ============ */
  const handelCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setChecked((prevChecked) => ({
      ...prevChecked,
      [name]: checked,
    }));
  };

  /** ========
   * @function onStartHandler : 시작 버튼(어떤 언어를 선택했는지 result에 저장)
  ============ */
  const onStartHandler = () => {
    setStep(1);

    recordResult({
      sid: 'selected_lang',
      selected: checked,
    });
  };

  const onSkipHandler = () => {
    setCurrentState('end');
  };

  return (
    <main className="demo-pages-protected-evaluation-language-test">
      <ExitModal
        showPrompt={showPrompt}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
      />
      <div className="container">
        <div className="container-inner">
          <section className="title">
            <h2 className="title-main fs28to24">
              {t('evaluation.language_test.title')}
            </h2>
            <div className="title-sub">
              <p>{t('evaluation.language_test.desc')}</p>
            </div>
          </section>
          <section className="select-container">
            <article className="select-wrapper">
              <FormControl component="fieldset">
                {(Object.keys(checked) || []).map((lang, idx) => (
                  <FormControlLabel
                    key={`label_${idx}`}
                    className={`check-button ${
                      checked[lang] ? 'selected' : ''
                    }`}
                    control={
                      <Checkbox
                        name={lang}
                        checked={checked[lang]}
                        onChange={handelCheckboxChange}
                        sx={{
                          '& .MuiSvgIcon-root': {
                            fontSize: 18,
                          },
                        }}
                      />
                    }
                    label={
                      <Typography
                        variant="body2"
                        sx={{
                          '&.MuiTypography-body2': {
                            fontSize: 16,
                            fontWeight: 500,
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%',
                          },
                        }}
                      >
                        {lang === 'english' && 'English'}
                        {lang === 'korean' && 'Korean'}
                        {lang === 'chinese' && 'Chinese'}
                        {lang === 'japanese' && 'Japanese'}
                      </Typography>
                    }
                  />
                ))}
              </FormControl>
            </article>
          </section>
          <div className="button-container">
            <button
              className={`start common-button fsbtn16 black ${
                checked.japanese ||
                checked.chinese ||
                checked.english ||
                checked.korean
                  ? 'active'
                  : 'disabled'
              }`}
              onClick={
                checked.japanese ||
                checked.chinese ||
                checked.english ||
                checked.korean
                  ? () => onStartHandler()
                  : undefined
              }
            >
              {t('evaluation.language_test.btn_next')}
            </button>
            <button
              className="start common-button fsbtn16 black active"
              onClick={() => setOpenSkipModal(true)}
            >
              {t('evaluation.language_test.btn_skip')}
            </button>
          </div>
        </div>
      </div>
      {openSkipModal && (
        <DemoModal
          className="demo-g-video"
          open={openSkipModal}
          close={() => setOpenSkipModal(false)}
          mainTitle={t('evaluation.language_test.skip_modal_title')}
          subTitle={t('evaluation.language_test.skip_modal_desc')}
          submit={onSkipHandler}
        />
      )}
    </main>
  );
};

export default DemoLanguageSelect;
