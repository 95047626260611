import React from 'react';
import { useTranslation } from 'react-i18next';
import { useCallbackPrompt, useResize } from 'utils/hooks';
import ExitModal from 'components/modal/ExitModal';

function LanguageCommonBody({ children }) {
  const { t } = useTranslation();
  const windowSize = useResize();
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(true);

  return (
    <main className="demo-pages-protected-evaluation-objectives-common">
      <ExitModal
        showPrompt={showPrompt}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
      />
      {windowSize?.width < 1024 && (
        <div className="objectives-title-box">
          <h3>{t('evaluation.language_test.title')}</h3>
        </div>
      )}
      <div className="container">{children}</div>
    </main>
  );
}

export default LanguageCommonBody;
