import React, { useEffect } from 'react';
import { read, utils } from 'xlsx';
import { setResults } from 'store/slices/testtakerSlice';
import AWS from 'aws-sdk';
import { executeAction } from 'utils/redux';

function Excel() {
  const [gucciExcelCandidateList, setGucciExcelCandidateList] = React.useState(
    []
  );

  function readExcel(excelDate) {
    const reader = new FileReader();

    reader.onload = function () {
      const data = reader.result;
      const workbook = read(data, { type: 'binary' });
      const excelList = new Array();

      workbook.SheetNames.forEach((sheetName) => {
        excelList.push(utils.sheet_to_json(workbook.Sheets[sheetName]));
      });

      setGucciExcelCandidateList(excelList[0]);
    };
    reader.readAsBinaryString(excelDate);
  }

  React.useEffect(() => {
    const region = 'us-west-2';
    const bucket = 's3-munchskill-test';
    const s3 = new AWS.S3({
      accessKeyId: 'AKIAZU5AR7ASHCNWKTHE',
      secretAccessKey: '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',
      region,
    });

    const params = {
      Bucket: bucket,
      Key: 'publicfile/gucci_apply/GUCCI interview raw data_20230411.xlsx',
    };

    s3.getObject(params, (err, data) => {
      if (err) {
        console.log(err, err.stack);
      } else {
        const excelDate = new Blob([data.Body], { type: data.ContentType });
        readExcel(excelDate);
      }
    });
  }, []);

  useEffect(() => {
    // const text = gucciExcelCandidateList.slice(0, 7)
    gucciExcelCandidateList?.map((list, idx) => {
      if (list['테스트 아이디'] < 99111100000) return;
      const result = {};
      if (
        list['AI 스코어'] === undefined ||
        list['AI 스코어'] === 'na' ||
        list['AI 스코어'] === ''
      )
        return;
      if (list['결과 텍스트'] === undefined) {
        list['결과 텍스트'] = '';
      }

      result[list['테스트 아이디']] = {
        preset_score: list['AI 스코어'],
        text: list['결과 텍스트'],
      };

      // setResultFunction(list["이메일"], result)
    });
  }, [gucciExcelCandidateList]);

  const setResultFunction = (cid, result) => {
    console.log(cid, result);
    executeAction(
      setResults({
        uid: 'NutKIKbumXTu6piL12SodBLNsqQ2',
        aid: 'sIPgrxPE5C337AxBnXIr',
        cid,
        results: result,
      })
    )
      .unwrap()
      .then(() => {
        console.log('success');
      })
      .catch((e) => console.log('error'));
  };

  return <></>;
}

export default Excel;
