import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as FileUploadIcon } from 'assets/images/icon/file-upload.svg';
import { useCallbackPrompt } from 'utils/hooks';
import ExitModal from 'components/modal/ExitModal';
import { useResult } from 'utils/result';
import { useStatus } from 'utils/status';
import {
  spaceRegex,
  formatTime,
  convertTextToSec,
  convertHtmlForDisplay,
  boldRegex,
} from 'utils/string';

function FileUpload({ test, setCurrentState }) {
  const { t } = useTranslation();
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(true);
  const { recordResult } = useResult();
  const { setTestInfo, getTestInfo } = useStatus();
  const [file, setFile] = React.useState(null);
  const fileUploadRef = React.useRef(null);
  const { duration, doneCnt, maxDoneCnt, canSubmit } = getTestInfo();

  const onSubmitHandler = () => {
    recordResult({
      sid: test?.uuid || test?.id,
      data: {
        file,
      },
    });
    setFile(null);
  };

  React.useEffect(() => {
    const skillEndEventListener = (event) => {
      onSubmitHandler();
    };

    window.scrollTo(0, 0);

    window.document.addEventListener('skill_end', skillEndEventListener);
    return () =>
      window.document.removeEventListener('skill_end', skillEndEventListener);
  }, []);

  React.useEffect(() => {
    const testDoneEventListener = (event) => {
      onSubmitHandler();
    };

    let btnName = '';
    if (doneCnt === maxDoneCnt) {
      btnName = t('btn.submit');
    } else {
      btnName = t('btn.next');
    }
    if (file) {
      setTestInfo({
        duration,
        doneCnt,
        maxDoneCnt,
        canSubmit: true,
        buttonName: btnName,
      });
    }

    window.document.addEventListener('test_done', testDoneEventListener);
    return () => {
      window.document.removeEventListener('test_done', testDoneEventListener);
    };
  }, [file]);

  const onFileUploadClickHandler = (event) => {};

  const onFileUploadChangeHandler = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const file = event.target.files?.[0];
    setFile(file);
    window.document.documentElement.requestFullscreen();
  };

  return (
    <main className="pages-protected-evaluation-subjectives-file-upload">
      <ExitModal
        showPrompt={showPrompt}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
      />
      <div className="container">
        <div className="container-inner">
          <div className="separater">
            <section className="separater-left">
              <div className="question">
                {/* <pre className="fs18">{convertHtmlForDisplay(test?.question)}</pre> */}
                <pre
                  className="fs18"
                  dangerouslySetInnerHTML={{
                    __html: convertHtmlForDisplay(test?.question)
                      .replace(spaceRegex, '\n')
                      .replace(boldRegex, ''),
                  }}
                />
                {test?.url?.length > 0 && (
                  <a
                    href={`${process.env.REACT_APP_IMATE_URL}/${test?.url}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      alt=""
                      src={`${process.env.REACT_APP_IMATE_URL}/${test?.url}`}
                    />
                  </a>
                )}
              </div>
              {/* <div className="timer fs18">
                {formatTime(convertTextToSec(test?.limit))}
              </div> */}
            </section>
            <section className="separater-right">
              <div
                className="answer file-upload"
                onClick={() => fileUploadRef.current?.click?.()}
              >
                <FileUploadIcon className="file-upload-icon" />
                <span className="file-upload-label">
                  {file ? t('btn.uploaded-file-name') : t('btn.upload-file')}
                </span>
                {Boolean(file) && (
                  <span className="file-upload-name">{file?.name}</span>
                )}
                <input
                  ref={fileUploadRef}
                  type="file"
                  onClick={onFileUploadClickHandler}
                  onChange={onFileUploadChangeHandler}
                  hidden
                />
              </div>
            </section>
          </div>
        </div>
      </div>
    </main>
  );
}

export default FileUpload;
