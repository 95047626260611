import LanguageDropdown from 'components/common/LanguageDropdown';
import React from 'react';
import {useLocation} from 'react-router-dom';

const Footer = () =>{
const location = useLocation();
const { aid } = location.state || {};
  return(
      <footer className="components-footer-auth-footer">
    <div className="language-select">
        {aid !== 'jhHMxUyLWkcm7ZQNaojK' && <LanguageDropdown/>}
    </div>
    <address>
      <div>© Promenade AI Inc.</div>
      <div>Technical Support : help@munchskill.com</div>
    </address>
  </footer>
  );
};

export default Footer;
