import React from 'react';
import { useNavigate } from 'react-router-dom';

export function VideoInfoButtonStep1({
  next,
  setStepCount,
  setStepLeave,
  stepCount,
}) {
  const nextButtonClickHandler = () => {
    if (stepCount !== 1) {
      setStepLeave(stepCount);
      setTimeout(() => {
        setStepCount(1);
      }, 100);
    }
  };

  return (
    <div className="button-wrapper">
      <button
        className="form-submit common-button fsbtn16 primary active submit"
        onClick={nextButtonClickHandler}
      >
        {next && next}
      </button>
    </div>
  );
}

export function VideoInfoButtonStep2({
  next,
  prev,
  stepCount,
  setStepCount,
  setStepLeave,
}) {
  const nextButtonClickHandler = () => {
    if (stepCount !== 2) {
      setStepLeave(stepCount);
      setTimeout(() => {
        setStepCount(2);
      }, 100);
    }
  };

  const prevButtonClickHandler = () => {
    // setStepCount(0);
    setTimeout(() => {
      setStepCount(0);
      setStepLeave(-1);
    }, 100);
  };

  return (
    <div className="button-wrapper">
      <button
        className="form-submit common-button fsbtn16 white active submit"
        onClick={prevButtonClickHandler}
      >
        {prev && prev}
      </button>
      <button
        className="form-submit common-button fsbtn16 primary active submit"
        onClick={nextButtonClickHandler}
      >
        {next && next}
      </button>
    </div>
  );
}

export function VideoInfoButtonStep3({
  done,
  prev,
  setStepCount,
  assessment,
  logoURL,
  setStepLeave,
  userPlan,
}) {
  const navigate = useNavigate();

  const doneButtonClickHandler = () => {
    navigate('/testtaker/progress/voice-test', {
      state: { assessment, logoURL, userPlan },
      replace: true,
    });
  };

  const prevButtonClickHandler = () => {
    // setStepCount(1);
    setTimeout(() => {
      setStepCount(1);
      setStepLeave(0);
    }, 100);
  };

  return (
    <div className="button-wrapper">
      <button
        className="form-submit common-button fsbtn16 white active submit"
        onClick={prevButtonClickHandler}
      >
        {prev && prev}
      </button>
      <button
        className="form-submit common-button fsbtn16 primary active submit"
        onClick={doneButtonClickHandler}
      >
        {done && done}
      </button>
    </div>
  );
}
