import React from 'react';
import 'styles/app.scss';
import { Routes, Route } from 'react-router-dom';
import AppLayout from 'components/layouts/AppLayout';
import AuthLayout from 'components/layouts/AuthLayout';
import TesttakerLayout from 'components/layouts/TesttakerLayout';
import {
  AuthRouter,
  PublicInvitation,
  TakeInvitation,
  InvalidInvitation,
  PreparingInvitation,
  Tips,
  Intro,
  Setup,
  Evaluation,
  SelectTest,
  EvaluationDone,
  ShowVideo,
  DownloadFile,
  Code,
  FrontIframe,
  VideoInfomation,
  VoiceTest,
  Position,
  StoreSurvey,
} from 'pages';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';
import { StatusProvider } from 'utils/status';
import { ProgressProvider } from 'utils/progress';
import { ResultProvider } from 'utils/result';
import { ReactComponent as CloseIcon } from 'assets/images/icon/close-blue500.svg';
import DemoTips from './pages/public/DemoTips';
import DemoVoiceTest from './pages/public/DemoVoiceTest';
import DemoDone from './pages/protected/evaluation/DemoDone';

const App = () => (
  <ProgressProvider>
    <StatusProvider>
      <ResultProvider>
        <Routes>
          <Route path="/front-iframe" element={<FrontIframe />} />
          <Route path="/code" element={<Code />} />
          <Route path="/testtaker">
            <Route path="auth" element={<AuthLayout />}>
              <Route path="redirect" element={<AuthRouter />} />
              <Route path="publicinvitation" element={<PublicInvitation />} />
              <Route path="takeinvitation" element={<TakeInvitation />} />
              <Route path="invalidinvitation" element={<InvalidInvitation />} />
              <Route
                path="preparinginvitation"
                element={<PreparingInvitation />}
              />
            </Route>
            <Route path="progress" element={<AppLayout />}>
              <Route path="intro" element={<Intro />} />
              {/* <Route path="tips" element={<Tips />} /> */}
              <Route path="tips" element={<DemoTips />} />
              <Route path="setup" element={<Setup />} />
              <Route path="video-information" element={<VideoInfomation />} />
              {/* <Route path="voice-test" element={<VoiceTest />} /> */}
              <Route path="voice-test" element={<DemoVoiceTest />} />
              <Route path="position" element={<Position />} />
              <Route path="selecttest" element={<SelectTest />} />
              <Route path="store-survey" element={<StoreSurvey />} />
            </Route>
            <Route path="evaluation" element={<TesttakerLayout />}>
              <Route path="" element={<Evaluation />} />
              {/* <Route path="done" element={<EvaluationDone />} /> */}
              <Route path="done" element={<DemoDone />} />
            </Route>
          </Route>
          <Route path="/pub" element={<TesttakerLayout />}>
            <Route path="view_video" element={<ShowVideo />} />
            <Route path="file" element={<DownloadFile />} />
          </Route>
        </Routes>
        <ToastContainer
          position="bottom-center"
          autoClose={3000}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          closeButton={CloseButton}
        />
      </ResultProvider>
    </StatusProvider>
  </ProgressProvider>
);

const CloseButton = ({ closeToast }) => {
  const { t } = useTranslation();

  return (
    <button
      className="toast-close-button fsbtn16 text-button primary"
      onClick={closeToast}
    >
      <CloseIcon className="close-button" />
      {/* {t("btn.ok")} */}
    </button>
  );
};

export default App;
