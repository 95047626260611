import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { TextField } from '@mui/material';
import { toast } from 'react-toastify';
import { executeAction } from 'utils/redux';
import { updateCandidateInfo } from 'store/slices/authSlice';
import { useStatus } from 'utils/status';
import { useProgress } from 'utils/progress';
import * as Sentry from '@sentry/react';

const Accept = ({
  uid,
  aid,
  email,
  firstName: _firstName,
  lastName: _lastName,
  logoURL,
  assessment,
  isPersonality,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setInfo, userPlan } = useStatus();
  const { setIsEntireLoading } = useProgress();

  const [firstName, setFirstName] = React.useState(_firstName || '');
  const [lastName, setLastName] = React.useState(_lastName || '');
  const [checked, setChecked] = React.useState(false);
  const [canSubmit, setCanSubmit] = React.useState(false);

  React.useEffect(() => {
    if (checked) {
      if (Boolean(firstName) && Boolean(lastName)) {
        return setCanSubmit(true);
      }
      return setCanSubmit(false);
    }
    return setCanSubmit(false);
  }, [checked, firstName, lastName]);

  const onSubmitHandler = () => {
    const regex = /^[가-힣|a-z|A-Z]+$/;

    if (!regex.test(firstName) || !regex.test(lastName)) {
      toast(t('toast.desc.error-name'));
      return;
    }

    if (!checked) {
      toast(t('toast.desc.check-privacy-policy'));
      return;
    }

    if (!firstName || !lastName) {
      return;
    }

    setIsEntireLoading(true);
    executeAction(
      updateCandidateInfo({
        uid,
        aid,
        email,
        firstName,
        lastName,
      })
    )
      .unwrap()
      .then(() => {
        const lang = t('lang');
        setIsEntireLoading(false);
        setInfo({
          uid,
          aid,
          email,
          firstName,
          lastName,
          lang,
        });
        navigate('/testtaker/progress/tips', {
          state: { logoURL, userPlan, isPersonality },
          replace: true,
        });
      })
      .catch((e) => {
        Sentry.captureException(e);
        console.dir(e);
        setIsEntireLoading(false);
      });
  };

  return (
    <div
      className={`demo-auth-container ${isPersonality ? 'personality' : ''}`}
    >
      {/* <div className="left-container" /> */}
      <div className="right-container">
        <div className="form">
          <div className="assessment-name-box">{assessment?.name}</div>
          <div className="demo-form-input-container">
            <TextField
              className="demo-form-input fs16"
              label="First name*"
              value={firstName}
              onChange={(event) => setFirstName(event.target.value)}
              size="small"
            />
            <TextField
              className="demo-form-input fs16"
              label="Last name*"
              value={lastName}
              size="small"
              onChange={(event) => setLastName(event.target.value)}
            />
          </div>
          <div className="policy-check-container">
            <label
              className={`form-checkbox ${checked && 'checked'}`}
              onClick={() => setChecked(!checked)}
            >
              <div>
                <Trans
                  t={t}
                  i18nKey="check-box.label.privacy-policy"
                  components={{
                    href_privacy_policy: (
                      <a
                        href="https://munchskill.wpcomstaging.com/privacy-policy/"
                        target="_blank"
                        onClick={(event) => {
                          event.preventDefault();
                          event.stopPropagation();
                          window.open(
                            `https://munchskill.wpcomstaging.com/${
                              t('lang') === 'ko' ? 'ko/' : ''
                            }privacy-policy/`,
                            '_blank'
                          );
                        }}
                        rel="noreferrer"
                      />
                    ),
                  }}
                />
              </div>
            </label>
          </div>
          <button
            className={`demo-form-submit ${canSubmit && 'active'}`}
            onClick={onSubmitHandler}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default Accept;
