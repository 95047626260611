import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@mui/material';
import { ReactComponent as FailedIcon } from 'assets/images/icon/failed-icon.svg';
import { ReactComponent as SuccessedIcon } from 'assets/images/icon/demo-success-icon.svg';

function MediaTestModal({
  open,
  close,
  confirmFace,
  confirmVoice,
  setFixedFace,
  onClick,
}) {
  const { t } = useTranslation();
  const [fixedConfirmFace, setFixedConfirmFace] = useState(false);

  useEffect(() => {
    setFixedConfirmFace(confirmFace);
  }, []);

  useEffect(() => {
    // 얼굴 인식 고정된 값을 VoiceStream.js로 보냄
    // VoiceStream.js => fixedRecognitionFace
    setFixedFace(fixedConfirmFace);
  }, [fixedConfirmFace]);

  const onClickHandler = (boolean) => {
    onClick(boolean);
  };

  /** ================
   * @function convertTitleStatus
   * 얼굴인식 / 음성테스트 상태에 따른 타이틀 변경
   * @returns 
  ==================== */
  const convertTitleStatus = () => {

    let subj = '';

    if (!fixedConfirmFace && !confirmVoice) {
      subj = t('voicetest.testmodal.face_voice_recog_fail');
    } else if (!fixedConfirmFace && confirmVoice) {
      subj = t('voicetest.testmodal.face_recog_fail');
    } else if (fixedConfirmFace && !confirmVoice) {
      subj = t('voicetest.testmodal.voice_recog_fail');
    } else {
      subj = t('voicetest.testmodal.face_voice_success');
    }

    return subj;
  };

  return (
    <Modal open={open} onClose={() => close(false)}>
      <div className="modal media-test-modal">
        <section className="content">
          <section className="header-title">
            <h2 className="title-main fs20">{convertTitleStatus()}</h2>
          </section>

          <section className="body-contents">
            {/* 얼굴 인식 */}
            <DescriptionItem
              title={
                !fixedConfirmFace
                  ? t('voicetest.testmodal.confirmface.face_failed_title')
                  : t('voicetest.testmodal.confirmface.face_success_title')
              }
              desc={
                !fixedConfirmFace
                  ? t('voicetest.testmodal.confirmface.face_failed_desc')
                  : t('voicetest.testmodal.confirmface.face_success_desc')
              }
              icon={!fixedConfirmFace ? <FailedIcon /> : <SuccessedIcon />}
            />
            {/* 음성 인식 */}
            <DescriptionItem
              title={
                !confirmVoice
                  ? t('voicetest.testmodal.confirmvoice.voice_failed_title')
                  : t('voicetest.testmodal.confirmvoice.voice_success_title')
              }
              desc={
                !confirmVoice
                  ? t('voicetest.testmodal.confirmvoice.voice_failed_desc')
                  : t('voicetest.testmodal.confirmvoice.voice_success_desc')
              }
              icon={!confirmVoice ? <FailedIcon /> : <SuccessedIcon />}
            />
            {/* 버튼 */}
            <Button
              color={fixedConfirmFace && confirmVoice ? 'black' : 'black'}
              btnLabel={
                fixedConfirmFace && confirmVoice ? t('voicetest.btn_next') : t('voicetest.btn_retry')
              }
              onClick={onClickHandler}
            />
          </section>
        </section>
      </div>
    </Modal>
  );
}

export default MediaTestModal;

function DescriptionItem({ title, desc, icon }) {
  return (
    <article className="contents-item">
      <aside className="icon">
        <i>{icon}</i>
      </aside>
      <aside className="desc">
        <h6 className="desc-title fs18">{title}</h6>
        <p className="desc-p fs14">{desc}</p>
      </aside>
    </article>
  );
}

function Button({ color, btnLabel, onClick }) {
  return (
    <article className="buttons">
      <button
        className={`confirm common-button fsbtn16 primary active ${color}`}
        onClick={() => onClick(false)}
      >
        {btnLabel}
      </button>
    </article>
  );
}
