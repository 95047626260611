import React from 'react';
import { convertHtmlForDisplayTest } from 'utils/string';

const FrontIframe = () => {
  const [htmlMessage, setHtmlMessage] = React.useState('');
  const [javascriptMessage, setJavascriptMessage] = React.useState('');
  const [cssMessage, setCssMessage] = React.useState('');

  React.useEffect(() => {
    window.addEventListener('message', function (e) {
      if (e.origin !== process.env.REACT_APP_ASSESSMENT_HOST) return;

      setHtmlMessage(e.data.type === 'webpackWarnings' ? '' : e.data.htmlCode);
      setJavascriptMessage(
        e.data.type === 'webpackWarnings' ? '' : e.data.javascriptCode
      );
      setCssMessage(e.data.type === 'webpackWarnings' ? '' : e.data.cssCode);

      document.querySelector('#modal').setAttribute('style', 'display: none');
      document
        .querySelector('.Toastify')
        .setAttribute('style', 'display: none');
    });
  }, []);

  /**
   * iframe 헤더에 <style>태그 생성 후 코드실행 할때마다 변경
   */
  React.useEffect(() => {
    const head = document.getElementsByTagName('head')[0];
    const isStyle = head.querySelector('#__FRONT_CODE_CSS');

    if (isStyle === null || isStyle === undefined) {
      const style = document.createElement('style');
      style.type = 'text/css';
      style.id = '__FRONT_CODE_CSS';
      style.innerHTML = cssMessage;

      head.appendChild(style);
    } else {
      isStyle.innerHTML = cssMessage;
    }
  }, [cssMessage]);

  /**
   * iframe 헤더에 <script>태그 생성 후 코드실행 할때마다 변경
   */
  React.useEffect(() => {
    const head = document.getElementsByTagName('head')[0];
    const isScript = head.querySelector('#__FRONT_CODE_JAVASCRIPT');

    if (isScript === null || isScript === undefined) {
      const script = document.createElement('script');
      script.id = '__FRONT_CODE_JAVASCRIPT';
      script.innerHTML = javascriptMessage;

      head.appendChild(script);
    } else {
      isScript.innerHTML = javascriptMessage;
    }
  }, [javascriptMessage]);

  return (
    <>
      <div
        className="container"
        dangerouslySetInnerHTML={{ __html: htmlMessage }}
      ></div>
    </>
  );
};

export default FrontIframe;
