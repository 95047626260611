import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useCallbackPrompt, useGetMediaDevices } from 'utils/hooks';
import ExitModal from 'components/modal/ExitModal';
import { useResult } from 'utils/result';
import { useStatus } from 'utils/status';
import {
  spaceRegex,
  formatTime,
  formatKorTime,
  convertTextToSec,
  convertHtmlForDisplay,
  boldRegex,
} from 'utils/string';
import { __NO_LIMIT_DURATION__ } from 'constants/testtaker';
import MediaSettingModal from 'components/modal/MediaSettingModal';
import IconModal from 'components/modal/IconModal';
import { ReactComponent as TimerIcon } from 'assets/images/icon/timer.svg';
import { ReactComponent as FailedIcon } from 'assets/images/icon/failed-icon.svg';
import PieChart from 'components/common/PieChart';

const __RECORD_CNT_LIMIT__ = 1;

function Video({ test, setCurrentState }) {
  const { t } = useTranslation();
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(true);
  const { setTestInfo, getTestInfo } = useStatus();
  const { duration, doneCnt, maxDoneCnt } = getTestInfo();
  const videoTagRef = React.useRef(null);
  const audioTagRef = React.useRef(null);
  const {
    stream,
    setStream,
    audioStream,
    setAudioStream,
    blob,
    setBlob,
    audioBlob,
    setAudioBlob,
    recordResult,
  } = useResult();
  const mediaRecorderRef = React.useRef(null);
  const audioRecorderRef = React.useRef(null);
  const chunksRef = React.useRef([]);
  const audioChunksRef = React.useRef([]);
  const [recordState, setRecordState] = React.useState('stop');
  const [recordTime, setRecordTime] = React.useState(9999999);
  const [recordCnt, setRecordCnt] = React.useState(0);
  const [canRecord, setCanRecord] = React.useState(true);
  const [isOpenSettingModal, setIsOpenSettingModal] = React.useState(false);
  const [dropdownCamera, setDropdownCamera] = React.useState(false);
  const [dropdownMic, setDropdownMic] = React.useState(false);
  const [animationId, setAnimationId] = React.useState();
  const [recordActive, setRecordActive] = React.useState(false);
  const [terminated, setTerminated] = React.useState(false);
  const [voiceRecognitionFailure, setVoiceRecognitionFailure] =
    React.useState(false);
  const [checkedRecord, setCheckedRecord] = React.useState(false);
  const [isRecord, setIsRecord] = React.useState(false);
  const [pieChartTimer, setPieChartTimer] = React.useState(0);
  const [showPieChart, setShowPieChart] = React.useState(false);
  const [
    selectedDeviceCamera,
    setSelectedDeviceCamera,
    selectedDeviceMic,
    setSelectedDeviceMic,
    devicesCamera,
    devicesMic,
  ] = useGetMediaDevices();
  const onRecordStopHandler = () => {
    setRecordState('stop');
    setShowPieChart(false);

    if (checkedRecord) {
      setTerminated(true);
      setIsRecord(true);
      setCheckedRecord(false);
      setRecordTime(999999);
    } else {
      setTerminated(false);
      setIsRecord(false);
      setRecordCnt(0);
      setBlob(null);
      setAudioBlob(null);
      setRecordTime(999999);
      setVoiceRecognitionFailure(true);
      setCheckedRecord(false);
      videoTagRef.current.srcObject = stream;
      videoTagRef.current.src = null;
      audioTagRef.current.srcObject = audioStream;
      audioTagRef.current.src = null;
    }

    // video.muted = false;

    const tmpRecCnt =
      recordCnt + 1 >= __RECORD_CNT_LIMIT__
        ? __RECORD_CNT_LIMIT__
        : recordCnt + 1;
    setRecordCnt(tmpRecCnt);

    mediaRecorderRef.current.stop();
    audioRecorderRef.current.stop();
  };

  const onSubmitHandler = () => {
    // video.muted = true;

    recordResult({
      sid: test?.uuid || test.id,
      data: {
        video: blob,
        audio: audioBlob,
      },
    });
    setBlob(null);
    setAudioBlob(null);
    setRecordCnt(0);
    setTerminated(false);
    setIsRecord(false);
    setCheckedRecord(false);
    setRecordTime(999999);
    videoTagRef.current.srcObject = stream;
    videoTagRef.current.src = null;
    audioTagRef.current.srcObject = audioStream;
    audioTagRef.current.src = null;
  };

  React.useEffect(() => {
    // const skillEndEventListener = (event) => {
    //   onSubmitHandler();
    // };

    window.scrollTo(0, 0);

    // window.document.addEventListener("skill_end", skillEndEventListener);
    // return () =>
    //   window.document.removeEventListener("skill_end", skillEndEventListener);
  }, []);

  React.useEffect(() => {
    if (!convertTextToSec(test?.limit)) {
      return;
    }
    if (recordTime <= 0) {
      setTimeout(() => {
        onRecordStopHandler();
      }, 1000);
      return;
    }

    const countdown = setTimeout(() => {
      setRecordTime(recordTime - 1);
    }, 1000);

    return () => clearTimeout(countdown);
  }, [recordTime]);

  React.useEffect(() => {
    if (recordCnt >= __RECORD_CNT_LIMIT__) {
      return setCanRecord(false);
    }
    return setCanRecord(true);
  }, [recordCnt]);

  React.useEffect(() => {
    const testDoneEventListener = (event) => {
      onSubmitHandler();
    };

    window.document.addEventListener('test_done', testDoneEventListener);
    return () => {
      window.document.removeEventListener('test_done', testDoneEventListener);
    };
  }, [blob]);

  React.useEffect(() => {
    if (!stream || !audioStream) {
      (async () => {
        await window.navigator.mediaDevices
          .getUserMedia({
            video: {
              deviceId: selectedDeviceCamera?.deviceId,
              width: 640,
              height: 480,
            },
            audio: {
              echoCancellation: true,
              deviceId: selectedDeviceMic?.deviceId,
            },
          })
          .then((stream) => {
            videoTagRef.current.srcObject = stream;
            setStream(stream);
          })
          .catch((e) => {
            console.error(e);
          });

        await window.navigator.mediaDevices
          .getUserMedia({
            video: false,
            audio: true,
          })
          .then((stream) => {
            audioTagRef.current.srcObject = stream;
            setAudioStream(stream);
          })
          .catch((e) => {
            console.error(e);
          });
      })();
      return;
    }

    chunksRef.current = [];
    audioChunksRef.current = [];
    let mimeType;

    const options = [
      'video/webm; codecs=vp9',
      'video/webm; codecs=vp8',
      'video/webm; codecs=h264',
      'video/mp4',
    ];
    const audioOptions = [
      'audio/webm; codecs=opus',
      'audio/webm; codecs=vorbis',
      'audio/mp4',
      'audio/ogg; codecs=opus',
      'audio/ogg; codecs=vorbis',
    ];

    for (let i in options) {
      if (MediaRecorder.isTypeSupported(options[i])) {
        mimeType = options[i];
        break;
      }
    }

    // video 설정
    videoTagRef.current.srcObject = stream;
    mediaRecorderRef.current = new MediaRecorder(stream, { mimeType });
    mediaRecorderRef.current.onstart = (event) => {
      videoTagRef.current.srcObject = stream;
      videoTagRef.current.src = null;
    };
    mediaRecorderRef.current.onstop = (event) => {
      const blob = new Blob(chunksRef.current, { type: 'video/mp4' });
      blob.name = 'video';
      setBlob(blob);

      chunksRef.current = [];
      videoTagRef.current.srcObject = stream;
      // videoTagRef.current.src = window.URL.createObjectURL(blob);
      videoTagRef.current.src = null;
    };
    mediaRecorderRef.current.ondataavailable = (event) => {
      chunksRef.current.push(event.data);
    };

    for (let i in audioOptions) {
      if (MediaRecorder.isTypeSupported(audioOptions[i])) {
        mimeType = audioOptions[i];
        break;
      }
    }
    // audio 설정
    audioTagRef.current.srcObject = audioStream;
    audioRecorderRef.current = new MediaRecorder(audioStream, { mimeType });
    audioRecorderRef.current.onstart = (event) => {
      audioTagRef.current.srcObject = audioStream;
      audioTagRef.current.src = null;
    };
    audioRecorderRef.current.onstop = (event) => {
      const audioBlob = new Blob(audioChunksRef.current, {
        type: 'audio/flac',
      });
      audioBlob.name = 'audio';
      setAudioBlob(audioBlob);

      audioChunksRef.current = [];
      audioTagRef.current.srcObject = audioStream;
      // audioTagRef.current.src = window.URL.createObjectURL(audioBlob);
      audioTagRef.current.src = null;
    };
    audioRecorderRef.current.ondataavailable = (event) => {
      audioChunksRef.current.push(event.data);
    };
  }, [stream, audioStream]);

  React.useEffect(() => {
    if (terminated) {
      cancelAnimationFrame(animationId);
    }
  }, [animationId, terminated]);

  React.useEffect(() => {
    let btnName = '';
    if (doneCnt === maxDoneCnt) {
      btnName = t('btn.submit');
    } else {
      btnName = t('btn.next');
    }

    if (blob || audioBlob) {
      setTestInfo({
        duration,
        doneCnt,
        maxDoneCnt,
        canSubmit: true,
        buttonName: btnName,
      });
    }

    const skillEndEventListener = (event) => {
      onSubmitHandler();
    };

    window.document.addEventListener('skill_end', skillEndEventListener);
    return () =>
      window.document.removeEventListener('skill_end', skillEndEventListener);
  }, [blob, audioBlob]);

  // const video = window.document.querySelector("#video-interview");

  /** =======================
   * @function audioContextFn
   * audioContext생성 후 스펙트럼 표시
  =========================== */
  function audioContextFn() {
    const audioContext = new AudioContext();
    const sourceNode = audioContext.createMediaStreamSource(audioStream);
    const analyserNode = audioContext.createAnalyser();
    analyserNode.fftSize = 2048;
    sourceNode.connect(analyserNode);

    function draw() {
      setAnimationId(requestAnimationFrame(draw));

      const bufferLength = analyserNode.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);
      analyserNode.getByteFrequencyData(dataArray);

      let sum = 0;
      for (let i = 0; i < bufferLength; i += 1) {
        sum += dataArray[i];
      }

      const average = sum / bufferLength;

      if (average > 2) {
        setCheckedRecord(true);
      }
    }
    draw();
  }

  const onRecordStartHandler = () => {
    setRecordState('start');
    setShowPieChart(true);
    setRecordActive(true);
    setRecordTime(convertTextToSec(test?.limit));

    setTimeout(() => {
      setRecordActive(false);
    }, 20000);

    audioContextFn();

    // video.muted = true;

    videoTagRef.current.src = null;
    audioTagRef.current.src = null;
    videoTagRef.current.srcObject = stream;
    audioTagRef.current.srcObject = audioStream;

    mediaRecorderRef.current.start();
    audioRecorderRef.current.start();
  };

  const transLimitTime = (time) => {
    const separation = time;
    const regex = /[^0-9]/g;
    const result = separation.replace(regex, '');
    const numberResult = Number(result);

    if (result.length < 2) {
      if (numberResult === 1) {
        return t('text-field.input.1min');
      }
      return t('text-field.input.2mins');
    }
    if (numberResult === 30) {
      return t('text-field.input.30secs');
    }
  };

  /** ====================
   * @function settingModalClickHandler
   * 설정창 open click 함수
   * @param {*} boolean(boolean) 
  ======================== */
  const settingModalClickHandler = (boolean) => {
    setIsOpenSettingModal(boolean);
  };

  /** ====================
   * @function onCameraClickHandler
   * 1. 설정창 카메라 dropdown 메뉴 활성화
   * 2. 선택한 카메라 세팅
   * @param {*} device 
  ======================== */
  const onCameraClickHandler = (device) => {
    setSelectedDeviceCamera(device);
    setDropdownCamera(false);
  };

  /** ====================
   * @function onMicClickHandler
   * 1. 설정창 마이크 dropdown 메뉴 활성화
   * 2. 선택한 마이크 세팅
   * @param {*} device 
  ======================== */
  const onMicClickHandler = (device) => {
    setSelectedDeviceMic(device);
    setDropdownMic(false);
  };

  const retryRecordClickHandler = (boolean) => {
    setRecordTime(999999);
    setVoiceRecognitionFailure(boolean);
    setRecordCnt(0);
    setBlob(null);
    setAudioBlob(null);
  };

  return (
    <main className="pages-protected-evaluation-subjectives-video">
      <ExitModal
        showPrompt={showPrompt}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
      />
      <div className="container">
        <div className="spliter">
          <section className="spliter-left">
            <div className="question">
              <pre
                className="fs18"
                dangerouslySetInnerHTML={{
                  __html: convertHtmlForDisplay(test?.question)
                    .replace(spaceRegex, '\n')
                    .replace(boldRegex, ''),
                }}
              />
              {test?.url?.length > 0 && (
                <a
                  href={`${process.env.REACT_APP_IMATE_URL}/${test?.url}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    alt=""
                    src={`${process.env.REACT_APP_IMATE_URL}/${test?.url}`}
                  />
                </a>
              )}
            </div>
            {showPieChart && (
              <div className="timer">
                <PieChart
                  time={formatTime(recordTime)}
                  graghTime={parseInt(
                    (recordTime / convertTextToSec(test?.limit)) * 100
                  )}
                />
              </div>
            )}
            {/* <div className="timer">
              {formatTime(convertTextToSec(test?.limit))}
            </div> */}
          </section>
          <section className="spliter-right">
            <div
              className={`video ${
                recordCnt > 0 && recordState === 'stop' ? 'stop' : 'start'
              }`}
            >
              <video id="video-interview" ref={videoTagRef} autoPlay muted />
              <audio ref={audioTagRef} />
              <div className={`timer fs20 ${recordState}`}>
                <TimerIcon />
                {`${formatKorTime(
                  recordTime,
                  t('desc.minute'),
                  t('desc.second')
                )}`}
              </div>
            </div>
            {/* <div className={`timer fs18 ${recordState}`}>{`${formatTime(
              recordTime
            )} / ${formatTime(convertTextToSec(test?.limit))}`}</div> */}

            {/* 음성 녹음이 됐는지 안됐는지에 따라 ui 변경
              isRecord: stop 버튼을 눌렀을 때 checkedRecord가 true이면 true로 반환
              recordState: start버튼 클릭 => 'start'
                           stop버튼 클릭 => 'stop' 
            */}
            {!isRecord ? (
              <>
                <div className={`alert-message ${recordState}`}>
                  <p className="fs16">{t('desc.min-30sec')}</p>
                </div>
                <div className={`record-cnt ${recordState}`}>
                  <Trans
                    t={t}
                    i18nKey="desc.attempts-record-time"
                    values={{
                      recordCnt: __RECORD_CNT_LIMIT__ - recordCnt,
                      limitTime: transLimitTime(test?.limit),
                    }}
                  />
                </div>
                <div className="buttons">
                  <button
                    className={`start common-button fsbtn16 
                      ${recordState === 'stop' ? 'primary' : 'secondary'} 
                      ${canRecord && 'active'}`}
                    onClick={
                      recordState === 'stop'
                        ? canRecord
                          ? onRecordStartHandler
                          : null
                        : onRecordStopHandler
                    }
                    disabled={recordActive}
                  >
                    {recordState === 'stop'
                      ? t('btn.start-recording')
                      : t('btn.stop-recording')}
                  </button>
                </div>
                <article className={`alert ${recordState}`}>
                  <h3 className="alert-title fs18">{t('title.camera-tips')}</h3>
                  <button
                    className="media-setting-button fs16"
                    onClick={() => setIsOpenSettingModal(true)}
                  >
                    {t('btn.camera-setting')}
                  </button>
                  {/* <ul className="alert-list">
                    {[
                      "desc.tips-permission-browser",
                      "desc.tips-supported-browser",
                      "desc.tips-permission-device",
                      "desc.tips-private-window",
                      "desc.tips-update-browser",
                      "desc.tips-restart-device",
                    ].map((i18nKey, idx) => (
                      <li key={idx} className="alert-list-item">
                        {t(i18nKey)}
                      </li>
                    ))}
                  </ul> */}
                </article>
              </>
            ) : (
              <>
                <article className="alert">
                  <h3 className="alert-title fs16">
                    {t('desc.recording-complete')}
                  </h3>
                </article>
              </>
            )}
          </section>
        </div>
        {/* <button
          className={`submit common-button fsbtn16 primary ${blob && "active"}`}
          onClick={blob ? onSubmitHandler : null}
        >
          {t("btn.submit")}
        </button> */}
      </div>

      {isOpenSettingModal && (
        // 비디오 및 카메라 설정 모달창
        <MediaSettingModal
          open={isOpenSettingModal}
          close={settingModalClickHandler}
          selectedDeviceCamera={selectedDeviceCamera}
          devicesCamera={devicesCamera}
          onCameraClickHandler={onCameraClickHandler}
          selectedDeviceMic={selectedDeviceMic}
          devicesMic={devicesMic}
          onMicClickHandler={onMicClickHandler}
        />
      )}

      {voiceRecognitionFailure && (
        // 음성인식 실패 시 모달창
        <IconModal
          open={voiceRecognitionFailure}
          close={setVoiceRecognitionFailure}
          icon={<FailedIcon />}
          title={t('dialog.title.no-voice')}
          desc={t('dialog.desc.no-voice')}
          btnLabel={t('btn.redo')}
          onClick={retryRecordClickHandler}
        />
      )}
    </main>
  );
}

export default Video;
