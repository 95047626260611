import React from 'react';
import Editor from '@monaco-editor/react';

function CssEditor({ cssCode, onChange }) {
  const [value, setValue] = React.useState(cssCode || '');

  const handleEditorChange = (val) => {
    setValue(val);
    onChange('cssCode', val);
  };

  return (
    <aside className="editor-input">
      <Editor
        height="100%"
        width="100%"
        language="css"
        value={cssCode}
        theme="vs-dark"
        defaultValue={value}
        onChange={handleEditorChange}
        options={{
          readOnly: false,
          fontSize: '14px',
          lineNumbers: true,
          formatOnPaste: true,
          autoIndent: true,
          formatOnType: true,
          scrollBeyondLastLine: false,
          minimap: { enabled: false },
          renderWhitespace: 'all',
          automaticLayout: true,
        }}
      />
    </aside>
  );
}

export default CssEditor;
