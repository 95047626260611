import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useProgress } from 'utils/progress';
import { executeAction } from 'utils/redux';
import {
  getAssessmentInfo,
  getCandidateInfo,
  getUserInfo,
} from 'store/slices/testtakerSlice';
import { getI18n } from 'react-i18next';
import * as Sentry from '@sentry/react';

const AuthRouter = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const type = searchParams.get('type');
  const uid = searchParams.get('uid');
  const aid = searchParams.get('aid');
  const email = searchParams.get('email');
  const firstName = searchParams.get('firstName');
  const lastName = searchParams.get('lastName');
  const { setIsEntireLoading } = useProgress();
  const [init, setInit] = React.useState(false);
  const [testDone, setTestDone] = React.useState(-1);
  const [logoURL, setLogoURL] = React.useState('');
  const [userPlan, setUserPlan] = React.useState('');
  const [isPreparing, setIsPreparing] = React.useState(true);
  const [isPersonality, setIsPersonality] = React.useState(false);

  React.useEffect(() => {
    if(!uid) return;
    executeAction(getUserInfo(uid))
      .unwrap()
      .then(({ doc }) => {
        setUserPlan(doc.plan);
        setLogoURL(doc.logoURL);
      })
      .catch((e) => {
        Sentry.captureException(e);
        console.dir(e);
      });
  }, [uid]);

  React.useEffect(() => {
    if (!uid || !aid || !email) return;

    setIsEntireLoading(true);
    executeAction(
      getCandidateInfo({
        uid,
        aid,
        email,
      })
    )
      .unwrap()
      .then(({ doc }) => {
        // console.log(doc);
        let testdone = 0;
        if (doc?.answeredAt) {
          testdone = 1;
        }
        if (
          doc?.periodOnOff &&
          doc?.startPeriodDateTime > new Date().getTime()
        ) {
          setIsPreparing(false);
        }
        setTestDone(testdone);
        setIsEntireLoading(false);
      })
      .catch((e) => {
        Sentry.captureException(e);
        console.dir(e);
        // setTestDone(1);
        setIsEntireLoading(false);
      });
  }, [uid, aid, email]);

  React.useEffect(() => {
    if (!uid || !aid) return;

    setIsEntireLoading(true);
    executeAction(
      getAssessmentInfo({
        uid,
        aid,
      })
    )
      .unwrap()
      .then(({ doc }) => {
        const { language } = doc || {};
        getI18n().changeLanguage(language);
        setIsEntireLoading(false);
        setInit(true);
        if (
          doc?.periodOnOff &&
          doc?.startPeriodDateTime > new Date().getTime()
        ) {
          setIsPreparing(false);
        }

        const personalityTest = doc?.tests?.some(
          (item) =>
            item?.id === '1310' || item?.id === '1321' || item?.id === '1322'
        );

        setIsPersonality(personalityTest);
      })
      .catch((e) => {
        Sentry.captureMessage('getAssessmentInfo', {extra: {uid, aid}});
        setIsEntireLoading(false);
        setTestDone(1);
        setInit(true);
      });
  }, [uid, aid]);

  React.useEffect(() => {
    if (!init) return;
    if (!type) return;

    if (testDone >= 1) {
      navigate('/testtaker/auth/invalidinvitation', {
        state: { uid, aid, email, firstName, lastName, logoURL, userPlan },
        replace: true,
      });
      return;
    }

    if (!isPreparing) {
      navigate('/testtaker/auth/preparinginvitation', {
        state: { uid, aid, email, firstName, lastName, logoURL, userPlan },
        replace: true,
      });
      return;
    }

    switch (type) {
      case 'publicinvitation':
        navigate('/testtaker/auth/publicinvitation', {
          state: {
            uid,
            aid,
            logoURL,
            userPlan,
            isPersonality,
          },
          replace: true,
        });
        break;
      case 'takeinvitation':
        navigate('/testtaker/auth/takeinvitation', {
          state: {
            uid,
            aid,
            email,
            firstName,
            lastName,
            logoURL,
            userPlan,
            isPersonality,
          },
          replace: true,
        });
        break;
      default:
        break;
    }
  }, [init, type]);

  return <main />;
};

export default AuthRouter;
