import React from 'react';
import { useResult } from 'utils/result';
import { useTranslation } from 'react-i18next';
import { ReactComponent as TimerIcon } from 'assets/images/icon/timer.svg';
import { executeAction } from 'utils/redux';
import {
  setVoiceTestThunk,
  setVoiceTest,
  uploadBytes,
} from 'store/slices/testtakerSlice';
import { useNavigate } from 'react-router-dom';
import { useGetMediaDevices, useRecognitionFace } from 'utils/hooks';
import { ReactComponent as SettingIcon } from 'assets/images/icon/demo-setting.svg';
import * as Sentry from '@sentry/react';
import DemoMediaSettingModal from '../../modal/DemoMediaSettingModal';
import DemoMediaTestModal from '../../modal/DemoMediaTestModal';

function DemoVideoStream({
  uid,
  aid,
  cid,
  assessment,
  logoURL,
  userPlan,
  setLoadingVoiceTest,
  recordState,
  setRecordState,
  startFunctionState,
  setStartFunctionState,
}) {
  const {
    stream,
    setStream,
    audioStream,
    setAudioStream,
    blob,
    setBlob,
    audioBlob,
    setAudioBlob,
    results,
    recordResult,
  } = useResult();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const mediaRecorderRef = React.useRef(null);
  const audioRecorderRef = React.useRef(null);
  const videoTagRef = React.useRef(null);
  const audioTagRef = React.useRef(null);
  const chunksRef = React.useRef([]);
  const canvasRef = React.useRef(null);
  const audioChunksRef = React.useRef([]);
  const [successedVoiceTest, setSuccessedVoiceTest] = React.useState(false);
  const [recordTime, setRecordTime] = React.useState(0);
  const [isOpenSettingModal, setIsOpenSettingModal] = React.useState(false);
  const [isOpenTestModal, setIsOpenTestModal] = React.useState(false);
  const [fixedRecognitionFace, setFixedRecognitionFace] = React.useState(false);
  const [videoUrl, setVideoUrl] = React.useState('');
  const [profileUrl, setProfileUrl] = React.useState('');

  const [
    selectedDeviceCamera,
    setSelectedDeviceCamera,
    selectedDeviceMic,
    setSelectedDeviceMic,
    devicesCamera,
    devicesMic,
  ] = useGetMediaDevices();
  const [setIsPaused, successedRecognitionFace] = useRecognitionFace(
    videoTagRef,
    canvasRef
  );

  React.useEffect(() => {
    setStreamHandler();
  }, [selectedDeviceCamera, selectedDeviceMic]);

  React.useEffect(() => {
    if (!stream || !audioStream) {
      setStreamHandler();
      return;
    }

    chunksRef.current = [];
    audioChunksRef.current = [];

    // video 설정
    // videoTagRef.current.srcObject = stream;
    mediaRecorderRef.current = new MediaRecorder(stream);
    mediaRecorderRef.current.onstart = () => {
      // videoTagRef.current.srcObject = stream;
      // videoTagRef.current.src = null;
    };
    mediaRecorderRef.current.onstop = () => {
      const blob = new Blob(chunksRef.current, { type: 'video/mp4' });
      blob.name = 'video';
      setBlob(blob);

      chunksRef.current = [];
      // videoTagRef.current.srcObject = stream;
      // videoTagRef.current.src = null;
    };
    mediaRecorderRef.current.ondataavailable = (event) => {
      chunksRef.current.push(event.data);
    };

    // audio 설정
    audioTagRef.current.srcObject = audioStream;
    audioRecorderRef.current = new MediaRecorder(audioStream);
    audioRecorderRef.current.onstart = () => {
      audioTagRef.current.srcObject = audioStream;
      audioTagRef.current.src = null;
    };
    audioRecorderRef.current.onstop = () => {
      const audioBlob = new Blob(audioChunksRef.current, {
        type: 'audio/flac',
      });
      audioBlob.name = 'audio';
      setAudioBlob(audioBlob);

      audioChunksRef.current = [];
      audioTagRef.current.srcObject = audioStream;
      // audioTagRef.current.src = window.URL.createObjectURL(audioBlob);
      audioTagRef.current.src = null;
    };
    audioRecorderRef.current.ondataavailable = (event) => {
      audioChunksRef.current.push(event.data);
    };
  }, [stream, audioStream]);

  React.useEffect(() => {
    if (blob || audioBlob) {
      setLoadingVoiceTest(true);

      recordResult({
        sid: 'voiceTest',
        data: {
          video: blob,
          // audio: audioBlob,
        },
      }).then((result) => {
        if (result) {
          for (const [key, value] of Object.entries(result)) {
            if (value.video instanceof Blob) {
              executeAction(
                uploadBytes({
                  uid,
                  aid,
                  cid,
                  sid: key,
                  fileName: 'video',
                  bytes: value.video,
                })
              )
                .unwrap()
                .then(({ downloadURL }) => {
                  setVideoUrl(downloadURL);
                })
                .catch((e) => {
                  Sentry.captureException(e);
                  console.error(e);
                });
            }
            if (value.profile instanceof Blob) {
              executeAction(
                uploadBytes({
                  uid,
                  aid,
                  cid,
                  sid: key,
                  fileName: 'profile',
                  bytes: value.profile,
                })
              )
                .unwrap()
                .then(({ downloadURL }) => {
                  setProfileUrl(downloadURL);
                })
                .catch((e) => {
                  Sentry.captureException(e);
                  console.error(e);
                });
            }
          }
        }
      });
    }
  }, [blob, audioBlob]);

  React.useEffect(() => {
    if(!uid || !aid || !cid) return;

    executeAction(
      setVoiceTest({
        uid,
        aid,
        cid,
        results: {
          detect: {
            onWebcam: results?.detect?.onWebcam,
            profile: profileUrl,
          },
          voiceTest: {
            video: videoUrl,
          },
        },
      })
    )
      .unwrap()
      .then(() => {
        if (videoUrl !== '') {
          executeAction(
            setVoiceTestThunk({
              videourl: videoUrl,
              lang: t('lang'),
            })
          )
            .then((res) => {
              if (res.payload?.result) {
                setSuccessedVoiceTest(true);
                setIsOpenTestModal(true);
              } else {
                setSuccessedVoiceTest(false);
                setIsOpenTestModal(true);
              }
              setLoadingVoiceTest(false);
            })
            .catch((e) => {
              Sentry.captureException(e);
              console.error(e);
              setSuccessedVoiceTest(false);
              setLoadingVoiceTest(false);
            });
        }
      })
      .catch((e) => {
        Sentry.captureException(e);
        console.error(e);
      });
  }, [videoUrl, profileUrl]);

  const setStreamHandler = () => {
    (async () => {
      const _stream = await window.navigator.mediaDevices.getUserMedia({
        video: { deviceId: selectedDeviceCamera?.deviceId },
        audio: true,
      });

      const _audioStream = await window.navigator.mediaDevices.getUserMedia({
        video: false,
        audio: {
          echoCancellation: true,
          deviceId: selectedDeviceMic?.deviceId,
        },
      });

      videoTagRef.current.srcObject = _stream;
      audioTagRef.current.srcObject = _audioStream;

      setStream(_stream);
      setAudioStream(_audioStream);
    })();
  };

  /** =============================
   * @function onRecordStopHandler
   * - 녹화시간(10초) 경과 시 동작하는 함수
   ================================= */
  const onRecordStopHandler = () => {
    setRecordState('stop');

    mediaRecorderRef.current.stop();
    audioRecorderRef.current.stop();
  };

  React.useEffect(() => {
    if (recordState === 'start') {
      setTimeout(async () => {
        onRecordStopHandler();
      }, 5000);
    }
  }, [recordState]);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setRecordTime(recordTime - 1);
    }, 1000);

    return () => clearTimeout(timer);
  }, [recordTime]);

  /** ====================
   * @function settingModalClickHandler
   * 설정창 open click 함수
   * @param {*} boolean(boolean)
  ======================== */
  const settingModalClickHandler = (boolean) => {
    setIsOpenSettingModal(boolean);
  };

  /** ====================
   * @function onCameraClickHandler
   * 1. 설정창 카메라 dropdown 메뉴 활성화
   * 2. 선택한 카메라 세팅
   * @param {*} device
  ======================== */
  const onCameraClickHandler = (device) => {
    setSelectedDeviceCamera(device);
  };

  /** ====================
   * @function onMicClickHandler
   * 1. 설정창 마이크 dropdown 메뉴 활성화
   * 2. 선택한 마이크 세팅
   * @param {*} device
  ======================== */
  const onMicClickHandler = (device) => {
    setSelectedDeviceMic(device);
  };

  /** =============================
   * @function onRecordStartHandler
   * - 녹화 시작 버튼 클릭 시 동작하는 함수
  ================================= */

  React.useEffect(() => {
    if (startFunctionState) {
      setRecordState('start');
      setRecordTime(5);
      setIsPaused(false);

      // videoTagRef.current.src = null;
      // audioTagRef.current.src = null;
      // videoTagRef.current.srcObject = stream;
      // audioTagRef.current.srcObject = audioStream;

      mediaRecorderRef.current.start();
      audioRecorderRef.current.start();
    }
  }, [startFunctionState]);

  const modalClickHandler = (boolean) => {
    if (successedVoiceTest && fixedRecognitionFace) {
      setSuccessedVoiceTest(boolean);
      setBlob(null);
      setAudioBlob(null);
      setStream(null);
      setAudioStream(null);
      setIsPaused(true);

      const canvas = canvasRef.current;
      canvas
        .getContext('2d')
        .drawImage(videoTagRef.current, 0, 0, canvas.width, canvas.height);
      canvas.toBlob((blob) => {
        recordResult({
          type: 'detect',
          data: {
            profile: blob,
          },
        }).then(() => {
          // recordIpAddress('before').then(() => {
          // executeAction(
          //   updateCondition({
          //     uid,
          //     aid,
          //     cid,
          //     condition: 1,
          //     progress: 0,
          //   })
          // )
          //   .unwrap()
          //   .catch((e) => {
          //     console.dir(e);
          //   });
          // });
        });
      });

      if (
        assessment?.introVideoUrl !== '' &&
        assessment?.introVideoUrl !== undefined
      ) {
        navigate('/testtaker/progress/intro', {
          state: { logoURL, userPlan },
          replace: true,
        });
      } else {
        userPlan === 100
          ? navigate('/testtaker/progress/store-survey', {
              state: { logoURL, userPlan },
              replace: true,
            })
          : navigate('/testtaker/evaluation', {
              state: { logoURL },
              replace: true,
            });
      }
    } else {
      setStartFunctionState(false);
      setIsOpenTestModal(boolean);
    }
  };

  return (
    <>
      <article className="video-stream-contents">
        <div className="video-container">
          <div className="video-box">
            <video
              id="video-test"
              className="video"
              ref={videoTagRef}
              autoPlay
              muted
            />
            <canvas className="face-canvas" ref={canvasRef} />
            <audio ref={audioTagRef} className="audio" />
            {recordState === 'start' && (
              <div className="record-time">
                <TimerIcon />
                <span className="fs20">
                  {recordTime}
                  {t('desc.second')}
                </span>
              </div>
            )}
            <div className="video-bg">
              <div className="video-bg-rect" />
            </div>
          </div>
          <div className="fs12 cam-guide-box">
            <li>{t('voicetest.cam_guide_helper')}</li>
          </div>
          <div
            className="media-setting-alert"
            onClick={() => setIsOpenSettingModal(true)}
          >
            <div className="media-setting-icon">
              <SettingIcon />
            </div>
            <div className="media-setting-text">
              {t('voicetest.device_setting')}
            </div>
          </div>
        </div>
      </article>

      {isOpenSettingModal && (
        // 비디오 및 카메라 설정 모달창
        <DemoMediaSettingModal
          open={isOpenSettingModal}
          close={settingModalClickHandler}
          selectedDeviceCamera={selectedDeviceCamera}
          devicesCamera={devicesCamera}
          onCameraClickHandler={onCameraClickHandler}
          selectedDeviceMic={selectedDeviceMic}
          devicesMic={devicesMic}
          onMicClickHandler={onMicClickHandler}
        />
      )}
      {isOpenTestModal && (
        // 얼굴인식 및 음성테스트 확인 모달창
        <DemoMediaTestModal
          open={isOpenTestModal}
          close={setIsOpenTestModal}
          confirmFace={successedRecognitionFace}
          confirmVoice={successedVoiceTest}
          setFixedFace={setFixedRecognitionFace}
          onClick={modalClickHandler}
        />
      )}
    </>
  );
}

export default DemoVideoStream;
