import React from 'react';
import { Modal } from '@mui/material';

function IconModal({ open, close, icon, title, desc, btnLabel, onClick }) {
  const onClickHandler = (boolean) => {
    onClick(boolean);
  };

  return (
    <Modal open={open} onClose={() => close(false)}>
      <div className="modal icon-modal">
        <section className="content">
          <section className="icon">{icon && icon}</section>
          <section className="title">
            <h2 className="title-main">{title && title}</h2>
            <div className="title-sub">{desc && desc}</div>
          </section>
        </section>
        <section className="buttons">
          <button
            className="confirm common-button fsbtn16 black active"
            onClick={() => onClickHandler(false)}
          >
            {btnLabel && btnLabel}
          </button>
        </section>
      </div>
    </Modal>
  );
}

export default IconModal;
