import React from 'react';
import VideoInfoHeader from 'components/video_infomation/VideoInfoHeader';
import { useTranslation } from 'react-i18next';
import PositionContents from 'components/position/PositionContents';
import { useNavigate, useLocation } from 'react-router-dom';

const Position = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { logoURL, assessment, userPlan } = location.state;

  const ContentsList = [
    '스토어 목표 달성을 위한 팀 액션 플랜 및 직원 코칭',
    '비즈니스 성장을 위한 상품 구성 및 재고 확보를 위한 유관 부서와의 커뮤니케이션',
    '브랜드 스탠다드에 맞는 직원 서비스 코칭 및 최상의 스토어 환경 유지',
    '팀원의 커리어 개발 요구 사항을 파악하고 커리어 성장 기회 제공을 위한 스토어 매니저와 논의',
  ];

  const onSubmitHandler = () => {
    if (assessment?.groupTests && assessment?.groupTests?.testcnt > 0) {
      navigate('/testtaker/progress/selecttest', {
        state: { logoURL },
        replace: true,
      });
    } else {
      navigate('/testtaker/evaluation', {
        state: { logoURL },
        replace: true,
      });
    }
  };

  return (
    <main className="pages-public-position">
      <div className="container">
        <div className="container-inner">
          <VideoInfoHeader
            // title={t('')}
            // desc={t('')}
            title={'지원 포지션을 확인합니다.'}
            desc={'지원하신 포지션이 맞다면 다음 버튼을 클릭해 주세요.'}
          />
          <PositionContents
            title={'Team Manager'}
            desc={
              '스토어 매니저를 지원하여 비즈니스의 효과적인 구현을 통해 세일즈를 최적화합니다. 또한, 고객관리 활동(Clienteling)에 중점을 둔 Client Advisor의 성과와 교육, 성장을 지원합니다.'
            }
            list={ContentsList}
            onClick={onSubmitHandler}
            btnLabel={t('btn.next')}
          />
        </div>
      </div>
    </main>
  );
};

export default Position;
