import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useCallbackPrompt, useGetMediaDevices, useResize } from 'utils/hooks';
import ExitModal from 'components/modal/ExitModal';
import { useResult } from 'utils/result';
import { useStatus } from 'utils/status';
import {
  spaceRegex,
  formatTime,
  formatKorTime,
  convertTextToSec,
  convertHtmlForDisplay,
  boldRegex,
  DemoformatTime,
} from 'utils/string';
import { __NO_LIMIT_DURATION__ } from 'constants/testtaker';
import IconModal from 'components/modal/IconModal';
import { ReactComponent as RecordingCheck } from 'assets/images/icon/Demo-recording-check.svg';
import { ReactComponent as FailedIcon } from 'assets/images/icon/failed-icon.svg';
import { ReactComponent as TimerIcon } from 'assets/images/icon/timer.svg';
import DemoModal from 'components/modal/DemoModal';
import * as Sentry from '@sentry/react';

const __RECORD_CNT_LIMIT__ = 2;

function GVideo({ test, setCurrentState }) {
  const { t } = useTranslation();
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(true);
  const { setTestInfo, getTestInfo } = useStatus();
  const { duration, doneCnt, maxDoneCnt } = getTestInfo();
  const windowSize = useResize();
  const videoTagRef = React.useRef(null);
  const audioTagRef = React.useRef(null);
  const {
    stream,
    setStream,
    audioStream,
    setAudioStream,
    blob,
    setBlob,
    audioBlob,
    setAudioBlob,
    recordResult,
  } = useResult();
  const mediaRecorderRef = React.useRef(null);
  const audioRecorderRef = React.useRef(null);
  const chunksRef = React.useRef([]);
  const audioChunksRef = React.useRef([]);
  const [recordState, setRecordState] = React.useState('stop');
  const [recordTime, setRecordTime] = React.useState(
    convertTextToSec(test?.limit)
  );
  const [recordCnt, setRecordCnt] = React.useState(0);
  const [canRecord, setCanRecord] = React.useState(true);
  // const [isOpenSettingModal, setIsOpenSettingModal] = React.useState(false);
  const [animationId, setAnimationId] = React.useState();
  const [recordActive, setRecordActive] = React.useState(true);
  const [terminated, setTerminated] = React.useState(false);
  const [voiceRecognitionFailure, setVoiceRecognitionFailure] =
    React.useState(false);
  const [checkedRecord, setCheckedRecord] = React.useState(false);
  const [isRecord, setIsRecord] = React.useState(false);
  const [startTimer, setStartTimer] = React.useState(60);
  const [openRetryModal, setOpenRetryModal] = React.useState(false);
  // const [showPieChart, setShowPieChart] = React.useState(false);
  const [
    selectedDeviceCamera,
    setSelectedDeviceCamera,
    selectedDeviceMic,
    setSelectedDeviceMic,
    devicesCamera,
    devicesMic,
  ] = useGetMediaDevices();

  const onSubmitHandler = () => {
    recordResult({
      sid: test?.uuid || test.id,
      data: {
        video: blob,
        audio: audioBlob,
      },
    });
    setBlob(null);
    setAudioBlob(null);
    setRecordCnt(0);
    setTerminated(false);
    setIsRecord(false);
    setCheckedRecord(false);
    setRecordActive(true);
    setStartTimer(60);
    setVoiceRecognitionFailure(false);
    // videoTagRef.current.srcObject = stream;
    // videoTagRef.current.src = null;
    // audioTagRef.current.srcObject = audioStream;
    // audioTagRef.current.src = null;
  };

  /** =======================
   * @function audioContextFn
   * audioContext생성 후 스펙트럼 표시
  =========================== */
  function audioContextFn() {
    const audioContext = new AudioContext();
    const sourceNode = audioContext.createMediaStreamSource(audioStream);
    const analyserNode = audioContext.createAnalyser();
    analyserNode.fftSize = 2048;
    sourceNode.connect(analyserNode);

    function draw() {
      setAnimationId(requestAnimationFrame(draw));

      const bufferLength = analyserNode.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);
      analyserNode.getByteFrequencyData(dataArray);

      let sum = 0;
      for (let i = 0; i < bufferLength; i += 1) {
        sum += dataArray[i];
      }

      const average = sum / bufferLength;

      if (average > 2) {
        setCheckedRecord(true);
      }
    }
    draw();
  }

  const onRecordRetryHandler = () => {
    setOpenRetryModal(true);
  };

  const onRecordStartHandler = () => {
    setOpenRetryModal(false);
    setRecordState('start');
    setRecordActive(true);
    setRecordTime(convertTextToSec(test?.limit));
    setStartTimer(0);

    setTimeout(() => {
      setRecordActive(false);
    }, 20000);

    audioContextFn();

    // videoTagRef.current.src = null;
    // audioTagRef.current.src = null;
    // videoTagRef.current.srcObject = stream;
    // audioTagRef.current.srcObject = audioStream;

    mediaRecorderRef.current.start();
    audioRecorderRef.current.start();
  };

  const onRecordStopHandler = () => {
    setRecordState('stop');
    setRecordTime(convertTextToSec(test?.limit));
    if (checkedRecord) {
      setTerminated(true);
      setIsRecord(true);
    } else {
      setTerminated(false);
      setIsRecord(false);
      setRecordCnt(0);
      setBlob(null);
      setAudioBlob(null);
      setVoiceRecognitionFailure(true);
      setCheckedRecord(false);
    }
    // videoTagRef.current.srcObject = stream;
    // videoTagRef.current.src = null;
    // audioTagRef.current.srcObject = audioStream;
    // audioTagRef.current.src = null;

    let tmpRecCnt =
      recordCnt + 1 >= __RECORD_CNT_LIMIT__
        ? __RECORD_CNT_LIMIT__
        : recordCnt + 1;

    setRecordCnt(tmpRecCnt);

    mediaRecorderRef.current.stop();
    audioRecorderRef.current.stop();
  };

  React.useEffect(() => {
    // const skillEndEventListener = (event) => {
    //   onSubmitHandler();
    // };
    window.scrollTo(0, 0);

    // window.document.addEventListener("skill_end", skillEndEventListener);
    // return () =>
    //   window.document.removeEventListener("skill_end", skillEndEventListener);
  }, []);

  React.useEffect(() => {
    setRecordTime(convertTextToSec(test?.limit));
  }, [test]);

  React.useEffect(() => {
    if (startTimer <= 0) {
      setTimeout(() => {
        onRecordStartHandler();
      }, 200);

      return;
    }

    const countdown = setTimeout(() => {
      setStartTimer(startTimer - 1);
    }, 1000);

    return () => clearTimeout(countdown);
  }, [startTimer]);

  React.useEffect(() => {
    if (!convertTextToSec(test?.limit)) {
      return;
    }

    let countdown;

    // if(recordState === 'start') {
    //   timer = setInterval(() => {
    //     if(recordTime > 0) {
    //       setRecordTime(recordTime - 1);
    //     }
    //   }, 1000);
    // }
    // return () => clearInterval(timer);
    if (recordTime <= 0) {
      setTimeout(() => {
        onRecordStopHandler();
      }, 1000);
      return;
    }

    if (recordState === 'start') {
      countdown = setTimeout(() => {
        setRecordTime(recordTime - 1);
      }, 1000);
    }

    return () => clearTimeout(countdown);
  }, [recordTime, recordState]);

  React.useEffect(() => {
    if (recordCnt >= __RECORD_CNT_LIMIT__) {
      return setCanRecord(false);
    }
    return setCanRecord(true);
  }, [recordCnt]);

  React.useEffect(() => {
    const testDoneEventListener = (event) => {
      onSubmitHandler();
    };

    window.document.addEventListener('test_done', testDoneEventListener);
    return () => {
      window.document.removeEventListener('test_done', testDoneEventListener);
    };
  }, [blob]);

  React.useEffect(() => {
    if (!stream || !audioStream) {
      (async () => {
        await window.navigator.mediaDevices
          .getUserMedia({
            video: {
              deviceId: selectedDeviceCamera?.deviceId,
              width: 640,
              height: 480,
            },
            audio: {
              echoCancellation: true,
              deviceId: selectedDeviceMic?.deviceId,
            },
          })
          .then((stream) => {
            videoTagRef.current.srcObject = stream;
            setStream(stream);
          })
          .catch((e) => {
            Sentry.captureException(e);
            console.error(e);
          });

        await window.navigator.mediaDevices
          .getUserMedia({
            video: false,
            audio: true,
          })
          .then((stream) => {
            audioTagRef.current.srcObject = stream;
            setAudioStream(stream);
          })
          .catch((e) => {
            Sentry.captureException(e);
            console.error(e);
          });
      })();
      return;
    }

    chunksRef.current = [];
    audioChunksRef.current = [];
    let mimeType;

    const options = [
      'video/webm; codecs=vp9',
      'video/webm; codecs=vp8',
      'video/webm; codecs=h264',
      'video/mp4',
    ];
    const audioOptions = [
      'audio/webm; codecs=opus',
      'audio/webm; codecs=vorbis',
      'audio/mp4',
      'audio/ogg; codecs=opus',
      'audio/ogg; codecs=vorbis',
    ];

    for (let i in options) {
      if (MediaRecorder.isTypeSupported(options[i])) {
        mimeType = options[i];
        break;
      }
    }
    // video 설정
    videoTagRef.current.srcObject = stream;
    mediaRecorderRef.current = new MediaRecorder(stream, { mimeType });
    mediaRecorderRef.current.onstart = (event) => {
      // videoTagRef.current.srcObject = stream;
      // videoTagRef.current.src = null;
    };
    mediaRecorderRef.current.onstop = (event) => {
      const blob = new Blob(chunksRef.current, { type: 'video/mp4' });
      blob.name = 'video';
      setBlob(blob);

      chunksRef.current = [];
      // videoTagRef.current.srcObject = stream;
      // videoTagRef.current.src = window.URL.createObjectURL(blob);
      // videoTagRef.current.src = null;
    };
    mediaRecorderRef.current.ondataavailable = (event) => {
      chunksRef.current.push(event.data);
    };

    for (let i in audioOptions) {
      if (MediaRecorder.isTypeSupported(audioOptions[i])) {
        mimeType = audioOptions[i];
        break;
      }
    }
    // audio 설정
    audioTagRef.current.srcObject = audioStream;
    audioRecorderRef.current = new MediaRecorder(audioStream, { mimeType });
    audioRecorderRef.current.onstart = (event) => {
      audioTagRef.current.srcObject = audioStream;
      audioTagRef.current.src = null;
    };
    audioRecorderRef.current.onstop = (event) => {
      const audioBlob = new Blob(audioChunksRef.current, {
        type: 'audio/flac',
      });
      audioBlob.name = 'audio';
      setAudioBlob(audioBlob);

      audioChunksRef.current = [];
      audioTagRef.current.srcObject = audioStream;
      // audioTagRef.current.src = window.URL.createObjectURL(audioBlob);
      audioTagRef.current.src = null;
    };
    audioRecorderRef.current.ondataavailable = (event) => {
      audioChunksRef.current.push(event.data);
    };
  }, [stream, audioStream]);

  React.useEffect(() => {
    if (terminated) {
      cancelAnimationFrame(animationId);
    }
  }, [animationId, terminated]);

  React.useEffect(() => {
    let btnName = '';
    let cansubmit = false;

    if (doneCnt === maxDoneCnt) {
      btnName = t('btn.submit');
    } else {
      btnName = t('btn.next');
    }

    if (blob === null || audioBlob === null) {
      cansubmit = false;
    } else {
      cansubmit = true;
    }

    setTestInfo({
      duration,
      doneCnt,
      maxDoneCnt,
      canSubmit: cansubmit,
      buttonName: btnName,
    });

    const skillEndEventListener = (event) => {
      onSubmitHandler();
    };

    window.document.addEventListener('skill_end', skillEndEventListener);
    return () =>
      window.document.removeEventListener('skill_end', skillEndEventListener);
  }, [blob, audioBlob]);

  const transLimitTime = (time) => {
    const separation = time;
    const regex = /[^0-9]/g;
    const result = separation.replace(regex, '');
    const numberResult = Number(result);

    if (result.length < 2) {
      if (numberResult === 1) {
        return t('evaluation.timer.one_minutes');
      }
      return t('evaluation.timer.two_minutes');
    }
    if (numberResult === 30) {
      return t('evaluation.timer.thirty_seconds');
    }
  };

  const retryRecordClickHandler = (boolean) => {
    setRecordTime(convertTextToSec(test?.limit));
    setVoiceRecognitionFailure(boolean);
    setRecordCnt(0);
    setStartTimer(60);
    setBlob(null);
    setAudioBlob(null);
    setRecordActive(true);
  };

  const onStartButtonHandler = () => {
    setStartTimer(0);
  };

  const onSecondStartButton = () => {
    setBlob(null);
    setAudioBlob(null);

    onRecordStartHandler();
  };

  return (
    <main className="demo-pages-protected-evaluation-subjectives-video">
      <ExitModal
        showPrompt={showPrompt}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
      />
      <div className={`container ${test?.url?.length > 0 && 'role-playing'}`}>
        {windowSize?.width < 1024 && test?.url?.length > 0 && (
          <div className="role-title">{t('evaluation.role_playing_title')}</div>
        )}
        <div className="spliter">
          <section
            className={`spliter-left ${
              test?.url?.length > 0 && 'role-playing'
            }`}
          >
            <div className="question">
              {test?.url?.length > 0 ? (
                windowSize?.width < 1024 ? (
                  <>
                    <pre
                      dangerouslySetInnerHTML={{
                        __html: convertHtmlForDisplay(test?.question)
                          .replace(spaceRegex, '\n')
                          .replace(boldRegex, ''),
                      }}
                    />
                    <hr />
                    <a
                      href={`${process.env.REACT_APP_IMATE_URL}/${test?.url}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        alt=""
                        src={`${process.env.REACT_APP_IMATE_URL}/${test?.url}`}
                      />
                    </a>
                  </>
                ) : (
                  <>
                    <div className="role-title">
                      {t('evaluation.role_playing_title')}
                    </div>
                    <a
                      href={`${process.env.REACT_APP_IMATE_URL}/${test?.url}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        alt=""
                        src={`${process.env.REACT_APP_IMATE_URL}/${test?.url}`}
                      />
                    </a>
                    <hr />
                    <pre
                      dangerouslySetInnerHTML={{
                        __html: convertHtmlForDisplay(test?.question)
                          .replace(spaceRegex, '\n')
                          .replace(boldRegex, ''),
                      }}
                    />
                  </>
                )
              ) : (
                <>
                  <div className="role-title">
                    {t('evaluation.interview_title')}
                  </div>
                  <pre
                    dangerouslySetInnerHTML={{
                      __html: convertHtmlForDisplay(test?.question)
                        .replace(spaceRegex, '\n')
                        .replace(boldRegex, ''),
                    }}
                  />
                </>
              )}
            </div>
            {!test?.url?.length && (
              <div className="demo-timer">
                <div className="timer-wrapper">
                  <div className="timer-title">{t('evaluation.timer')}</div>

                  <div className="time-box">
                    <div className="timer-container">
                      <div className="time-title">{t('title.timer-ready')}</div>
                      <div className="timer-box">
                        <div>{DemoformatTime(startTimer).substring(0, 2)}</div>
                        <div className="timer-line" />
                        <div>{DemoformatTime(startTimer).substring(2)}</div>
                      </div>
                    </div>
                    <div className="timer-container">
                      <div className="time-title">
                        {t('title.timer-answer')}
                      </div>
                      <div className="timer-box">
                        <div>{DemoformatTime(recordTime).substring(0, 2)}</div>
                        <div className="timer-line" />
                        <div>{DemoformatTime(recordTime).substring(2)}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </section>
          <section
            className={`spliter-right ${
              test?.url?.length > 0 && 'role-playing'
            }`}
          >
            <div
              className={`video ${
                recordCnt > 0 && recordState === 'stop' ? 'stop' : 'start'
              }`}
            >
              <video
                id="video-interview"
                ref={videoTagRef}
                autoPlay
                muted
                playsInline
              />
              <audio ref={audioTagRef} />
            </div>

            {windowSize.width < 1024 && (
              <div className="demo-timer">
                <div className="timer-wrapper">
                  <div className="time-box">
                    <div className="timer-container">
                      <div className="time-title">{t('title.timer-ready')}</div>
                      <div className="timer-box">
                        <div className="timer-icon-box">
                          <TimerIcon />
                        </div>
                        <div>{DemoformatTime(startTimer).substring(0, 2)}</div>:
                        <div>{DemoformatTime(startTimer).substring(2)}</div>
                      </div>
                    </div>
                    <div className="timer-container">
                      <div className="time-title">
                        {t('title.timer-answer')}
                      </div>
                      <div className="timer-box">
                        <div className="timer-icon-box">
                          <TimerIcon />
                        </div>
                        <div>{DemoformatTime(recordTime).substring(0, 2)}</div>:
                        <div>{DemoformatTime(recordTime).substring(2)}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {recordCnt === 0 ? (
              <>
                <div className={`record-cnt ${recordState}`}>
                  {t('evaluation.record_rules', {
                    timer: transLimitTime(test?.limit),
                  })}
                </div>
                <div className="record-helper">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: t('evaluation.record_guide_first_try'),
                    }}
                  />
                </div>
              </>
            ) : recordCnt === 1 ? (
              <>
                <div className={`record-cnt ${recordState}`}>
                  {t('evaluation.record_rules_once', {
                    timer: transLimitTime(test?.limit),
                  })}
                </div>
                <div className="record-helper">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: t('evaluation.record_guide_second_try'),
                    }}
                  />
                </div>
              </>
            ) : (
              <>
                <article className="alert demo">
                  <div>
                    <RecordingCheck />
                  </div>
                  <div>
                    {t('evaluation.language_test.speaking.record_complete')}
                  </div>
                </article>
              </>
            )}
            {canRecord && (
              <div className="buttons">
                <button
                  className={`start common-button fsbtn16 black
                      ${canRecord && 'active'}`}
                  onClick={
                    recordState === 'stop'
                      ? canRecord
                        ? !recordCnt
                          ? onStartButtonHandler
                          : onRecordRetryHandler
                        : null
                      : onRecordStopHandler
                  }
                  disabled={recordState === 'start' ? recordActive : false}
                >
                  {recordState === 'stop'
                    ? !recordCnt
                      ? t('btn.start')
                      : t('evaluation.btn_retry')
                    : t('evaluation.btn_stop')}
                </button>
              </div>
            )}

            {test?.url?.length > 0 && windowSize?.width > 1023 && (
              <div className="demo-timer">
                <div className="timer-wrapper">
                  <div className="timer-title">{t('evaluation.timer')}</div>

                  <div className="time-box">
                    <div className="timer-container">
                      <div className="time-title">{t('title.timer-ready')}</div>
                      <div className="timer-box">
                        <div>{DemoformatTime(startTimer).substring(0, 2)}</div>
                        <div className="timer-line" />
                        <div>{DemoformatTime(startTimer).substring(2)}</div>
                      </div>
                    </div>
                    <div className="timer-container">
                      <div className="time-title">
                        {t('title.timer-answer')}
                      </div>
                      <div className="timer-box">
                        <div>{DemoformatTime(recordTime).substring(0, 2)}</div>
                        <div className="timer-line" />
                        <div>{DemoformatTime(recordTime).substring(2)}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </section>
        </div>
      </div>

      {openRetryModal && (
        <DemoModal
          className="demo-g-video"
          open={openRetryModal}
          close={() => setOpenRetryModal(false)}
          mainTitle={t('title.retry-question')}
          subTitle={t('desc.retry-question')}
          submit={onSecondStartButton}
        />
      )}

      {voiceRecognitionFailure && (
        // 음성인식 실패 시 모달창
        <IconModal
          open={voiceRecognitionFailure}
          close={setVoiceRecognitionFailure}
          icon={<FailedIcon />}
          title={t('dialog.title.no-voice')}
          desc={t('dialog.desc.no-voice')}
          btnLabel={t('btn.redo')}
          onClick={retryRecordClickHandler}
        />
      )}
    </main>
  );
}

export default GVideo;
