import React from 'react';
import { useTranslation, Trans } from 'react-i18next';

function PreparingInvitation() {
  const { t } = useTranslation();

  return (
    <main className="pages-auth-take-invitation-preparing">
      <div className="container">
        <header className="header">
          <div className="header-img" />
          <div className="header-description flex">
            <span className="center">{t('desc.preparing-assessment')}</span>
          </div>
        </header>
      </div>
    </main>
  );
}

export default PreparingInvitation;
