import React from 'react';
import {
  UNSAFE_NavigationContext,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import * as faceapi from 'face-api.js';

const useBlocker = (blocker, when) => {
  const { navigator } = React.useContext(UNSAFE_NavigationContext);

  React.useEffect(() => {
    if (!when) return;

    const unblock = navigator.block((tx) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          unblock();
          tx.retry();
        },
      };

      blocker(autoUnblockingTx);
    });

    return unblock;
  }, [navigator, blocker, when]);
};

export const useCallbackPrompt = (when) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showPrompt, setShowPrompt] = React.useState(false);
  const [lastLocation, setLastLocation] = React.useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = React.useState(false);

  const confirmNavigation = React.useCallback(() => {
    setShowPrompt(false);
    setConfirmedNavigation(true);
  }, []);

  const cancelNavigation = React.useCallback(() => {
    setShowPrompt(false);
  }, []);

  // handle blocking when user click on another route prompt will be shown
  const handleBlockedNavigation = React.useCallback(
    (nextLocation) => {
      if (
        !confirmedNavigation &&
        nextLocation.location.pathname !== location.pathname
      ) {
        setShowPrompt(true);
        setLastLocation(nextLocation);
        if (nextLocation?.action === 'PUSH') {
          return confirmNavigation();
        }
        return false;
      }
      return true;
    },
    [confirmedNavigation]
  );

  React.useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      navigate(lastLocation.location.pathname);
    }
  }, [confirmedNavigation, lastLocation]);

  useBlocker(handleBlockedNavigation, when);

  return [showPrompt, confirmNavigation, cancelNavigation];
};

export const useStateWithCallback = (initialState) => {
  const [state, _setState] = React.useState(initialState);

  const callbackRef = React.useRef();
  const isFirstCallbackCall = React.useRef(true);

  const setState = React.useCallback((setStateAction, callback) => {
    callbackRef.current = callback;
    _setState(setStateAction);
  }, []);

  React.useEffect(() => {
    if (isFirstCallbackCall.current) {
      isFirstCallbackCall.current = false;
      return;
    }
    callbackRef.current?.(state);
  }, [state]);

  return [state, setState];
};

export const useOnClickOutside = (ref, handler) => {
  React.useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }

      handler(event);
    };

    window.document.addEventListener('mousedown', listener);
    window.document.addEventListener('touchstart', listener);

    return () => {
      window.document.removeEventListener('mousedown', listener);
      window.document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
};

/** ===========================
 * @function useGetMediaDevices
 * 응시자 device의 카메라/마이크 정보들을 불러오는 로직
 * @returns 
 * selectedDeviceCamera(object)      : (카메라)선택한 device 정보
 * setSelectedDeviceCamera(function) : (카메라)device 정보를 수정
 * devicesCamera(array)              : (카메라)전체 리스트 정보
 * selectedDeviceMic(object)         : (마이크)선택한 device 정보
 * setSelectedDeviceMic(function)    : (마이크)device 정보를 수정
 * devicesMic(array)                 : (마이크)전체 리스트 정보
 * setDeviceListRender(function)     : 브라우저에서 device 권한 설정 변경 시 device list rendering 해주는 state
=============================== */
export const useGetMediaDevices = () => {
  const cameraInfo = JSON.parse(sessionStorage.getItem('camera'));
  const micInfo = JSON.parse(sessionStorage.getItem('mic'));
  const [selectedDeviceCamera, setSelectedDeviceCamera] = React.useState(
    cameraInfo?.camera || null
  );
  const [selectedDeviceMic, setSelectedDeviceMic] = React.useState(
    micInfo?.mic || null
  );
  const [devicesCamera, setDevicesCamera] = React.useState([]);
  const [devicesMic, setDevicesMic] = React.useState([]);

  const updateMediaDevices = async () => {
    try {
      window.navigator.mediaDevices
        .enumerateDevices()
        .then((devices) => {
          const devicesCameraList = (devices || []).filter(
            (x) => x.kind === 'videoinput'
          );
          setDevicesCamera(devicesCameraList);

          const devicesMicList = (devices || []).filter(
            (x) => x.kind === 'audioinput'
          );
          setDevicesMic(devicesMicList);
        })
        .catch((e) => {
          console.dir(e);
        });
    } catch (error) {
      console.error('Error updating media devices:', error);
    }
  };

  React.useEffect(() => {
    // 최초 렌더링 시 미디어 디바이스 목록 업데이트
    updateMediaDevices();

    // 미디어 디바이스 변경 감지 이벤트 리스너 등록
    const handleDeviceChange = () => {
      updateMediaDevices();
    };

    navigator.mediaDevices.addEventListener('devicechange', handleDeviceChange);

    // 컴포넌트 언마운트 시 이벤트 리스너 해제
    return () => {
      navigator.mediaDevices.removeEventListener(
        'devicechange',
        handleDeviceChange
      );
    };
  }, []);

  React.useEffect(() => {
    if (Array.isArray(devicesCamera) && devicesCamera.length > 0) {
      if (
        selectedDeviceCamera !== null &&
        Object.keys(selectedDeviceCamera)?.length > 0
      ) {
        setSelectedDeviceCamera(selectedDeviceCamera);
      } else {
        setSelectedDeviceCamera(devicesCamera[0]);
      }
    }

    if (Array.isArray(devicesMic) && devicesMic.length > 0) {
      if (
        selectedDeviceMic !== null &&
        Object.keys(selectedDeviceMic)?.length > 0
      ) {
        setSelectedDeviceMic(selectedDeviceMic);
      } else {
        setSelectedDeviceMic(devicesMic[0]);
      }
    }
  }, [devicesMic, devicesCamera]);

  React.useEffect(() => {
    const selectedCamera = {
      camera: {
        deviceId:
          selectedDeviceCamera?.deviceId ||
          selectedDeviceCamera?.camera?.deviceId,
        label:
          selectedDeviceCamera?.label || selectedDeviceCamera?.camera?.label,
      },
    };
    const selectedMic = {
      mic: {
        deviceId:
          selectedDeviceMic?.deviceId || selectedDeviceMic?.mic?.deviceId,
        label: selectedDeviceMic?.label || selectedDeviceMic?.mic?.label,
      },
    };

    sessionStorage.setItem('camera', JSON.stringify(selectedCamera));
    sessionStorage.setItem('mic', JSON.stringify(selectedMic));
  }, [selectedDeviceMic, selectedDeviceCamera]);

  return [
    selectedDeviceCamera,
    setSelectedDeviceCamera,
    selectedDeviceMic,
    setSelectedDeviceMic,
    devicesCamera,
    devicesMic,
  ];
};

/** ===========================
 * @function useRecognitionFace
 * 얼굴 인식 API
 * @param {*} videoRef (object) : useRef() 비디오
 * @param {*} canvasRef (object) : useRef() 캔버스
 * @returns 
 * setIsPaused(boolean) : stop 버튼 클릭 시 detections 기능 정지
 * successedRecognitionFace(boolean) : 얼굴 인식이 되면 true, 아닐 시 false
=============================== */
export const useRecognitionFace = (videoRef, canvasRef) => {
  const [successedRecognitionFace, setSuccessedRecognitionFace] =
    React.useState(false);
  const [isPaused, setIsPaused] = React.useState(true);

  React.useEffect(() => {
    const loadModels = async () => {
      Promise.all([
        faceapi?.nets?.tinyFaceDetector.loadFromUri('/models'),
        faceapi?.nets?.faceLandmark68Net.loadFromUri('/models'),
        faceapi?.nets?.faceRecognitionNet.loadFromUri('/models'),
        faceapi?.nets?.faceExpressionNet.loadFromUri('/models'),
      ]);
    };
    loadModels();
  }, []);

  React.useEffect(() => {
    const video = videoRef?.current;
    const canvas = canvasRef?.current;
    const displaySize = {
      width: video.offsetWidth,
      height: video.offsetHeight,
    };
    faceapi?.matchDimensions(canvas, displaySize);

    const intervalId = setInterval(async () => {
      if (!isPaused) {
        const detections = await faceapi
          ?.detectAllFaces(video, new faceapi.TinyFaceDetectorOptions())
          ?.withFaceLandmarks()
          ?.withFaceExpressions();

        if (detections.length > 0) {
          setSuccessedRecognitionFace(true);
        } else {
          setSuccessedRecognitionFace(false);
        }
        // 캔버스에 얼굴 위치, 감정 등을 표시해주는 로직 (현재 미사용)
        // const resizedDetections = faceapi.resizeResults(detections, displaySize);
        // canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height);
        // faceapi.draw.drawDetections(canvas, resizedDetections);
        // faceapi.draw.drawFaceLandmarks(canvas, resizedDetections);
        // faceapi.draw.drawFaceExpressions(canvas, resizedDetections);
      }
    }, 300);

    return () => clearInterval(intervalId);
  }, [isPaused]);

  return [setIsPaused, successedRecognitionFace];
};

/** ===========================
 * @function useResize
 * 화면의 너비와 높이 값 구하는 hooks
 * @returns 
 * windowSize(object): width, height를 담은 객체 
=============================== */
export const useResize = () => {
  const [windowSize, setWindowSize] = React.useState({
    width: undefined,
    height: undefined,
  });

  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  React.useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};
