import React from 'react';
import { Outlet } from 'react-router-dom';
import { useResult } from 'utils/result';
import TesttakerHeader from '../header/TesttakerHeader';
import Footer from '../footer/Footer';

function TesttakerLayout() {
  const {
    recordResult,
    isFullscreen,
    setIsFullscreen,
    isMouseLeave,
    setIsMouseLeave,
  } = useResult();

  React.useEffect(() => {
    const detectFullscreen = (event) => {
      if (isFullscreen) {
        setIsFullscreen(window.innerHeight === window.screen.height);
        recordResult({
          type: 'detect',
          data: {
            onFullscreen: window.innerHeight === window.screen.height,
          },
        });
      }
    };
    const detectMouseLeave = (event) => {
      if (!isMouseLeave) {
        setIsMouseLeave(true);
        recordResult({
          type: 'detect',
          data: {
            isMouseLeave: true,
          },
        });
      }
    };

    window.addEventListener('resize', detectFullscreen);
    window.document.addEventListener('mouseleave', detectMouseLeave);

    return () => {
      window.removeEventListener('resize', detectFullscreen);
      window.document.removeEventListener('mouseleave', detectMouseLeave);
    };
  }, [isFullscreen, isMouseLeave]);

  return (
    <>
      <TesttakerHeader />
      <Outlet />
      <Footer />
    </>
  );
}

export default TesttakerLayout;
