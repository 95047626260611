import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useResult } from 'utils/result';
import { toast } from 'react-toastify';
import { updateCondition } from 'store/slices/testtakerSlice';
import { getAssessmentInfo } from 'store/slices/testtakerSlice';
import { executeAction } from 'utils/redux';
import { useStatus } from 'utils/status';
import { useProgress } from 'utils/progress';
import { Modal } from '@mui/material';
import { DropdownMenu } from 'components/dropdown/DropdownMenu';
import { ReactComponent as CameraIcon } from 'assets/images/icon/new-camera-icon.svg';
import { ReactComponent as MicIcon } from 'assets/images/icon/new-mic-Icon.svg';
import { useGetMediaDevices } from 'utils/hooks';

const Setup = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { uid, aid, email: cid, logoURL, userPlan } = useStatus();
  const { stream, setStream, recordResult, recordIpAddress } = useResult();
  const { setIsEntireLoading } = useProgress();
  const [canSubmit, setCanSubmit] = React.useState(false);
  const [streamLoading, setStreamLoading] = React.useState(false);
  // const [devicesCamera, setDevicesCamera] = React.useState([]);
  // const [devicesMic, setDevicesMic] = React.useState([]);
  // const [selectedDeviceCamera, setSelectedDeviceCamera] = React.useState(null);
  // const [selectedDeviceMic, setSelectedDeviceMic] = React.useState(null);
  const [dropdownCamera, setDropdownCamera] = React.useState(false);
  const [dropdownMic, setDropdownMic] = React.useState(false);
  const [assessment, setAssessment] = React.useState(null);
  const [mediaSetting, setMediaSetting] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [animationId, setAnimationId] = React.useState();
  const [terminated, setTerminated] = React.useState(false);
  // const [deviceListRender, setDeviceListRender] = React.useState(false);
  const [
    selectedDeviceCamera,
    setSelectedDeviceCamera,
    selectedDeviceMic,
    setSelectedDeviceMic,
    devicesCamera,
    devicesMic,
    setDeviceListRender,
  ] = useGetMediaDevices();
  const [isVideoTests, setIsVideoTests] = React.useState(false);
  const canvasRef = React.useRef(null);

  const buttonRefs = {
    camera: React.useRef(null),
    mic: React.useRef(null),
  };
  const dropdownRefs = {
    camera: React.useRef(null),
    mic: React.useRef(null),
  };
  const videoTagRef = React.useRef(null);
  const videoCanvasRef = React.useRef(null);

  React.useEffect(() => {
    if (!Boolean(uid) || !Boolean(aid)) return;

    setIsEntireLoading(true);
    executeAction(
      getAssessmentInfo({
        uid,
        aid,
      })
    )
      .unwrap()
      .then(({ doc }) => {
        setIsEntireLoading(false);
        setAssessment(doc);
      })
      .catch((e) => {
        setIsEntireLoading(false);
        setAssessment(null);
      });
  }, [uid, aid]);

  // React.useEffect(() => {
  //   setIsEntireLoading(true);
  //   window.navigator.mediaDevices
  //     .enumerateDevices()
  //     .then((devices) => {
  //       const devicesCameraList = (devices || []).filter(
  //         (x) => x.kind === "videoinput"
  //       );
  //       if (Array.isArray(devicesCameraList) && devicesCameraList.length > 0) {
  //         setSelectedDeviceCamera(devicesCameraList[0]);
  //       }
  //       setDevicesCamera(devicesCameraList);
  //       const devicesMicList = (devices || []).filter(
  //         (x) => x.kind === "audioinput"
  //       );
  //       if (Array.isArray(devicesMicList) && devicesMicList.length > 0) {
  //         setSelectedDeviceMic(devicesMicList[0]);
  //       }
  //       setDevicesMic(devicesMicList);
  //       setIsEntireLoading(false);
  //     })
  //     .catch((e) => {
  //       console.dir(e);
  //       setIsEntireLoading(false);
  //     });
  // }, [deviceListRender]);

  React.useEffect(() => {
    if (!Boolean(selectedDeviceCamera)) {
      return setCanSubmit(false);
    }

    return setCanSubmit(true);
  }, [selectedDeviceCamera]);

  React.useEffect(() => {
    if (videoTagRef.current) {
      if (!Boolean(selectedDeviceCamera)) return;

      setStreamLoading(true);
      (async () => {
        // const _stream = await window.navigator.mediaDevices.getUserMedia({
        await window.navigator.mediaDevices
          .getUserMedia({
            video: {
              deviceId: selectedDeviceCamera?.deviceId,
              width: 640,
              height: 480,
            },
            audio: {
              echoCancellation: true,
              deviceId: selectedDeviceMic?.deviceId,
            },
          })
          .then(function (stream) {
            videoTagRef.current.srcObject = stream;
            setStream(stream);
            setStreamLoading(false);
            setDeviceListRender(true);

            const audioContext = new AudioContext();

            const sourceNode = audioContext.createMediaStreamSource(stream);
            const analyserNode = audioContext.createAnalyser();
            analyserNode.fftSize = 2048;
            sourceNode.connect(analyserNode);
            // analyserNode.connect(audioContext.destination);

            const canvas = canvasRef.current;
            const canvasContext = canvas.getContext('2d');

            function draw() {
              setAnimationId(requestAnimationFrame(draw));

              const bufferLength = analyserNode.frequencyBinCount;
              const dataArray = new Uint8Array(bufferLength);
              analyserNode.getByteFrequencyData(dataArray);
              canvasContext.clearRect(0, 0, canvas.width, canvas.height);

              let sum = 0;
              for (let i = 0; i < bufferLength; i++) {
                sum += dataArray[i];
              }

              const average = sum / bufferLength;
              canvasContext.fillStyle = 'rgb(50, 148, 238)';
              canvasContext.fillRect(0, 0, average * 10, canvas.height);
            }

            draw();
          })
          .catch(function (err) {
            setStream();
            setStreamLoading(false);
            console.error(err);
          });
        // videoTagRef.current.srcObject = _stream;
      })();
    }
  }, [videoTagRef.current, selectedDeviceCamera, selectedDeviceMic]);

  React.useEffect(() => {
    if (terminated) {
      cancelAnimationFrame(animationId);
    }
  }, [animationId, terminated]);

  React.useEffect(() => {
    const cameraEventHandler = (event) => {
      if (
        (dropdownRefs.camera.current &&
          dropdownRefs.camera.current.contains(event.target)) ||
        (buttonRefs.camera.current &&
          buttonRefs.camera.current.contains(event.target))
      ) {
        return;
      }

      setDropdownCamera(false);
    };

    const micEventHandler = (event) => {
      if (
        (dropdownRefs.mic.current &&
          dropdownRefs.mic.current.contains(event.target)) ||
        (buttonRefs.mic.current &&
          buttonRefs.mic.current.contains(event.target))
      ) {
        return;
      }

      setDropdownMic(false);
    };

    window.document.addEventListener('click', cameraEventHandler);
    window.document.addEventListener('click', micEventHandler);
    return () => {
      window.document.removeEventListener('click', cameraEventHandler);
      window.document.removeEventListener('click', micEventHandler);
    };
  }, []);

  React.useEffect(() => {
    if (!assessment) return;

    assessment?.tests?.find((x) => {
      if (
        x.section === 'video' ||
        x.question_type === 'tag.video' ||
        x.question_type === 'tag.language'
      ) {
        setIsVideoTests(true);
      }
    });
  }, [assessment]);

  const onCameraClickHandler = (device) => {
    setSelectedDeviceCamera(device);
    setDropdownCamera(false);
  };

  const onMicClickHandler = (device) => {
    setSelectedDeviceMic(device);
    setDropdownMic(false);
  };

  // const onCameraSettingClickHandler = async () => {
  //   if(stream === undefined || stream === null) {
  //     setMediaSetting(true);
  //     setShowModal(true);
  //   } else {
  //     setDropdownCamera(!dropdownCamera);
  //     setMediaSetting(false);
  //   }

  // };

  // const onMicSettingClickHandler = async () => {
  //   if(stream === undefined || stream === null) {
  //     setMediaSetting(true);
  //     setShowModal(true);
  //   } else {
  //     setDropdownMic(!dropdownMic);
  //     setMediaSetting(false);
  //   }

  // };
  const onSubmitHandler = () => {
    if (selectedDeviceCamera?.label === '' || selectedDeviceMic?.label === '') {
      toast(t('toast.desc.camera-setting-error'));
      return;
    }

    setTerminated(true);

    const canvas = videoCanvasRef.current;
    canvas
      .getContext('2d')
      .drawImage(videoTagRef.current, 0, 0, canvas.width, canvas.height);
    canvas.toBlob((blob) => {
      recordResult({
        type: 'detect',
        data: {
          profile: blob,
        },
      }).then(() => {
        recordIpAddress('before').then(() => {
          executeAction(
            updateCondition({
              uid,
              aid,
              cid,
              condition: 1,
              progress: 0,
            })
          )
            .unwrap()
            .then(() => {
              setIsEntireLoading(false);
              if (isVideoTests) {
                navigate('/testtaker/progress/video-information', {
                  state: { logoURL, assessment, userPlan },
                  replace: true,
                });
              } else {
                if (
                  assessment?.introVideoUrl !== '' &&
                  assessment?.introVideoUrl !== undefined
                ) {
                  navigate('/testtaker/progress/intro', {
                    state: { logoURL, userPlan },
                    replace: true,
                  });
                } else {
                  if (
                    assessment?.groupTests &&
                    assessment?.groupTests?.testcnt > 0
                  ) {
                    navigate('/testtaker/progress/selecttest', {
                      state: { logoURL },
                      replace: true,
                    });
                    return;
                  } else {
                    navigate('/testtaker/evaluation', {
                      state: { logoURL, userPlan },
                      replace: true,
                    });
                  }
                }
              }
            })
            .catch((e) => {
              console.dir(e);
              setIsEntireLoading(false);
            });
        });
      });
    });
  };

  return (
    <main className="pages-public-setup">
      <div className="container-container">
        <div className="container">
          <div className="container-left">
            <header className="header">
              <h1 className="header-title fs28to24">
                {t('title.camera-setting')}
              </h1>
              <div className="header-description">
                {t('desc.camera-setting')}
              </div>
            </header>
            <article className="camera">
              <div className="camera-left">
                <h2 className="camera-left-title">{t('title.camera')}</h2>
                <DropdownMenu
                  width={'100%'}
                  frontIcon={<CameraIcon />}
                  selectedLabel={selectedDeviceCamera?.label}
                  menuList={devicesCamera}
                  onClick={onCameraClickHandler}
                />
                {/* <div className="camera-left-selected">
                  {selectedDeviceCamera?.label}
                </div> */}
              </div>
              {/* <div className="camera-right">
                <button
                  ref={buttonRefs.camera}
                  disabled={streamLoading ? true : false}
                  className={`camera-right-setting common-button fsbtn16 gray ${streamLoading ? "disabled" : "active"}`}
                  onClick={onCameraSettingClickHandler}
                ></button>
                <div
                  ref={dropdownRefs.camera}
                  className="camera-right-dropdown"
                >
                  {dropdownCamera && (
                    devicesCamera.length > 0 &&
                      <ul className="camera-right-dropdown-list">
                        {(devicesCamera || []).map((device, idx) => (
                          <li
                            key={idx}
                            className="camera-right-dropdown-list-item"
                            onClick={() => onCameraClickHandler(device)}
                          >
                            {device?.label}
                          </li>
                        ))}
                      </ul>
                    )}
                </div>
              </div> */}
            </article>
            <article className="mic">
              <div className="mic-left">
                <h2 className="mic-left-title">{t('title.microphone')}</h2>
                <DropdownMenu
                  width={'100%'}
                  frontIcon={<MicIcon />}
                  selectedLabel={selectedDeviceMic?.label}
                  menuList={devicesMic}
                  onClick={onMicClickHandler}
                />
                <canvas className="mic-canvas" id={'mic-can'} ref={canvasRef} />
                {/* <div className="mic-left-selected">
                  {selectedDeviceMic?.label}
                </div> */}
              </div>
              {/* <div className="mic-right">
                <button
                  ref={buttonRefs.mic}
                  disabled={streamLoading ? true : false}
                  className={`mic-right-setting common-button fsbtn16 gray ${streamLoading ? "disabled" : "active"}`}
                  onClick={onMicSettingClickHandler}
                ></button>
                <div ref={dropdownRefs.mic} className="mic-right-dropdown">
                  {dropdownMic && (
                    devicesMic.length > 0 &&
                    <ul className="mic-right-dropdown-list">
                      {(devicesMic || []).map((device, idx) => (
                        <li
                          key={idx}
                          className="mic-right-dropdown-list-item"
                          onClick={() => onMicClickHandler(device)}
                        >
                          {device?.label}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div> */}
            </article>
          </div>
          <div className="container-right">
            <section className="video">
              <video
                ref={videoTagRef}
                className="video-display"
                autoPlay={true}
                // controls={true}
              ></video>
            </section>
            <canvas
              ref={videoCanvasRef}
              width="400"
              height="300"
              style={{ display: 'none' }}
            />
            <section className="alert">
              <h2 className="alert-title fs18">{t('title.camera-tips')}</h2>
              <ul className="alert-list">
                {[
                  'desc.tips-permission-browser',
                  'desc.tips-supported-browser',
                  'desc.tips-permission-device',
                  'desc.tips-private-window',
                  'desc.tips-update-browser',
                  'desc.tips-restart-device',
                ].map((i18nKey, idx) => (
                  <li key={idx} className="alert-list-item">
                    {t(i18nKey)}
                  </li>
                ))}
              </ul>
            </section>
          </div>
        </div>
        <button
          className={`submit common-button fsbtn16 primary ${
            selectedDeviceCamera &&
            (selectedDeviceCamera?.label === '' ||
              selectedDeviceMic?.label === '')
              ? 'disabled'
              : 'active'
            // process.env.NODE_ENV === "development"
            //   ? "active"
            //   : canSubmit && "active"
          }`}
          onClick={
            onSubmitHandler
            // process.env.NODE_ENV === "development"
            //   ? onSubmitHandler
            //   : canSubmit
            //   ? onSubmitHandler
            //   : null
          }
        >
          {t('btn.start')}
        </button>
      </div>

      {mediaSetting && (
        <Modal open={showModal} onClose={() => setShowModal(false)}>
          <div className="modal media-authority-modal">
            <section className="content">
              <section className="title">
                <h2 className="title-main">
                  {t('dialog.title.camera-mic-permission')}
                </h2>
                <div className="title-sub">
                  {t('dialog.desc.camera-mic-permission')}
                </div>
              </section>
            </section>
            <section className="buttons">
              <button
                className={`confirm common-button fsbtn16 primary active`}
                onClick={() => setShowModal(false)}
              >
                {t('btn.ok')}
              </button>
            </section>
          </div>
        </Modal>
      )}
    </main>
  );
};

export default Setup;
