import React from 'react';
import { Modal } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseButton } from 'assets/images/icon/close_24px.svg';
import { ReactComponent as CameraIcon } from 'assets/images/icon/new-camera-icon.svg';
import { ReactComponent as MicIcon } from 'assets/images/icon/new-mic-Icon.svg';
import { ReactComponent as MainLogo } from 'assets/images/logo/main_logo.svg';
import { DropdownMenu } from 'components/dropdown/DropdownMenu';
import * as Sentry from '@sentry/react';

function MediaSettingModal({
  open,
  close,
  selectedDeviceCamera,
  devicesCamera,
  onCameraClickHandler,
  selectedDeviceMic,
  devicesMic,
  onMicClickHandler,
}) {
  const { t } = useTranslation();
  const [streamLoading, setStreamLoading] = React.useState(false);
  const [animationId, setAnimationId] = React.useState();
  const canvasRef = React.useRef(null);
  const videoTagRef = React.useRef(null);

  React.useEffect(() => {
    if (videoTagRef.current) {
      if (!selectedDeviceCamera) return;

      setStreamLoading(true);
      (async () => {
        await window.navigator.mediaDevices
          .getUserMedia({
            video: { deviceId: selectedDeviceCamera?.deviceId },
            audio: {
              echoCancellation: true,
              deviceId: selectedDeviceMic?.deviceId,
            },
          })
          .then((stream) => {
            videoTagRef.current.srcObject = stream;
            setStreamLoading(false);

            const audioContext = new AudioContext();

            const sourceNode = audioContext.createMediaStreamSource(stream);
            const analyserNode = audioContext.createAnalyser();
            analyserNode.fftSize = 2048;
            sourceNode.connect(analyserNode);

            const canvas = canvasRef.current;
            const canvasContext = canvas.getContext('2d');

            function draw() {
              setAnimationId(requestAnimationFrame(draw));

              const bufferLength = analyserNode.frequencyBinCount;
              const dataArray = new Uint8Array(bufferLength);
              analyserNode.getByteFrequencyData(dataArray);
              canvasContext.clearRect(0, 0, canvas.width, canvas.height);

              let sum = 0;
              for (let i = 0; i < bufferLength; i += 1) {
                sum += dataArray[i];
              }

              const average = sum / bufferLength;
              canvasContext.fillStyle = 'rgb(50, 148, 238)';
              canvasContext.fillRect(0, 0, average * 10, canvas.height);
            }

            draw();
          })
          .catch((err) => {
            Sentry.captureException(err);
            setStreamLoading(false);
            console.error(err);
          });
      })();
    }
  }, [videoTagRef.current, selectedDeviceCamera, selectedDeviceMic]);

  const onClickHandler = (boolean) => {
    close(boolean);
  };

  return (
    <Modal open={open} onClose={() => close(false)}>
      <div className="modal demo-media-setting-modal">
        <section className="content">
          <section className="header-title">
            <MainLogo />
            <button className="title-close-button" onClick={() => close(false)}>
              <CloseButton />
            </button>
          </section>
          <section className="body-contents">
            <div>
              <h2 className="setting-title">
                {t('voicetest.modal.device_setting')}
              </h2>
              <div className="setting-desc">
                {t('voicetest.modal.device_setting_desc')}
              </div>
            </div>
            <article className="body-contents-camera">
              <h2 className="camera-title fs16">
                {t('voicetest.modal.camera_tittle')}
              </h2>
              <DropdownMenu
                width="100%"
                frontIcon={<CameraIcon />}
                selectedLabel={selectedDeviceCamera?.label}
                menuList={devicesCamera}
                onClick={onCameraClickHandler}
              />
            </article>
            <article className="body-contents-mic">
              <h2 className="mic-title fs16">
                {t('voicetest.modal.mic_tittle')}
              </h2>
              <DropdownMenu
                width="100%"
                frontIcon={<MicIcon />}
                selectedLabel={selectedDeviceMic?.label}
                menuList={devicesMic}
                onClick={onMicClickHandler}
              />
              <canvas className="mic-canvas" ref={canvasRef} />
            </article>
            <article className="alert">
              <h2 className="alert-title fs18">
                {t('voicetest.modal.trouble_title')}
              </h2>
              <ul className="alert-list">
                {[
                  t('voicetest.modal.alert1'),
                  t('voicetest.modal.alert2'),
                  t('voicetest.modal.alert3'),
                  t('voicetest.modal.alert4'),
                ].map((i18nKey, idx) => (
                  <li key={idx} className="alert-list-item fs14">
                    {t(i18nKey)}
                  </li>
                ))}
              </ul>
            </article>

            {/* 저장하기 버튼 */}
            <article className="buttons">
              <button
                className="confirm fsbtn16 primary active"
                onClick={() => onClickHandler(false)}
              >
                {t('voicetest.modal.btn_save')}
              </button>
            </article>
          </section>
          <div className="footer">
            <div>© Promenade AI Inc.</div>
          </div>
        </section>
      </div>
    </Modal>
  );
}

export default MediaSettingModal;
