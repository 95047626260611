import React, { useEffect, useState } from 'react';
import { useResult } from 'utils/result';
import LanguageCommon from './LanguageCommon';

const LanguageDistinction = ({ test, setCurrentState }) => {
  const { results } = useResult();
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [currentStateIndex, setCurrentStateIndex] = useState(0);

  useEffect(() => {
    const selectedLang = [];

    for (const key in results?.selected_lang) {
      if (results?.selected_lang[key]) {
        selectedLang.push(key);
      }
    }

    setSelectedLanguages(selectedLang);
  }, []);

  return (
    <>
      {(selectedLanguages || []).map(
        (lang, idx) =>
          currentStateIndex === idx && (
            <LanguageCommon
              key={idx}
              test={test}
              language={lang}
              setCurrentState={setCurrentState}
              selectedLanguages={selectedLanguages}
              currentStateIndex={currentStateIndex}
              setCurrentStateIndex={setCurrentStateIndex}
            />
          )
      )}
    </>
  );
};

export default LanguageDistinction;
