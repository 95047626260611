import { nextQuestion } from './question';

export async function chineseListeningQuestion(previous) {
  const baseUrl = `${process.env.REACT_APP_IMATE_URL}/images/audio/chineseListening`;
  /** ==========
 * 문제
============== */
  const questionDifficulty = {
    0: [
      '男的意思是有什么？',
      5,
      {
        audio: [
          [
            `${baseUrl}/Level-3/Level-3-Desc-1~5.mp3`,
            `${baseUrl}/Level-3/Level-3-Dialog-1-1.mp3`,
            `${baseUrl}/Level-3/Level-3-Dialog-1-2.mp3`,
            `${baseUrl}/Common-Voice/Chinese-问题.mp3`,
            `${baseUrl}/Common-Voice/Chinese-1.mp3`,
            `${baseUrl}/Level-3/Level-3-Q-1.mp3`,
            `${baseUrl}/Common-Voice/Chinese-A.mp3`,
            `${baseUrl}/Level-3/Level-3-Q-1-1.mp3`,
            `${baseUrl}/Common-Voice/Chinese-B.mp3`,
            `${baseUrl}/Level-3/Level-3-Q-1-2.mp3`,
            `${baseUrl}/Common-Voice/Chinese-C.mp3`,
            `${baseUrl}/Level-3/Level-3-Q-1-3.mp3`,
          ],
        ],
      },
    ],
    1: [
      '他们最可能是什么关系？',
      5,
      {
        audio: [
          [
            `${baseUrl}/Level-3/Level-3-Desc-1~5.mp3`,
            `${baseUrl}/Level-3/Level-3-Dialog-2-1.mp3`,
            `${baseUrl}/Level-3/Level-3-Dialog-2-2.mp3`,
            `${baseUrl}/Common-Voice/Chinese-问题.mp3`,
            `${baseUrl}/Common-Voice/Chinese-1.mp3`,
            `${baseUrl}/Level-3/Level-3-Q-2.mp3`,
            `${baseUrl}/Common-Voice/Chinese-A.mp3`,
            `${baseUrl}/Level-3/Level-3-Q-2-1.mp3`,
            `${baseUrl}/Common-Voice/Chinese-B.mp3`,
            `${baseUrl}/Level-3/Level-3-Q-2-2.mp3`,
            `${baseUrl}/Common-Voice/Chinese-C.mp3`,
            `${baseUrl}/Level-3/Level-3-Q-2-3.mp3`,
          ],
        ],
      },
    ],
    2: [
      '根据对话内容，选出正确答案。',
      5,
      {
        audio: [
          [
            `${baseUrl}/Level-3/Level-3-Desc-1~5.mp3`,
            `${baseUrl}/Level-3/Level-3-Dialog-3-1.mp3`,
            `${baseUrl}/Level-3/Level-3-Dialog-3-2.mp3`,
            `${baseUrl}/Common-Voice/Chinese-问题.mp3`,
            `${baseUrl}/Common-Voice/Chinese-1.mp3`,
            `${baseUrl}/Level-3/Level-3-Q-3.mp3`,
            `${baseUrl}/Common-Voice/Chinese-A.mp3`,
            `${baseUrl}/Level-3/Level-3-Q-3-1.mp3`,
            `${baseUrl}/Common-Voice/Chinese-B.mp3`,
            `${baseUrl}/Level-3/Level-3-Q-3-2.mp3`,
            `${baseUrl}/Common-Voice/Chinese-C.mp3`,
            `${baseUrl}/Level-3/Level-3-Q-3-3.mp3`,
          ],
        ],
      },
    ],
    3: [
      '女的打算坐什么去上海？',
      5,
      {
        audio: [
          [
            `${baseUrl}/Level-3/Level-3-Desc-1~5.mp3`,
            `${baseUrl}/Level-3/Level-3-Dialog-4-1.mp3`,
            `${baseUrl}/Level-3/Level-3-Dialog-4-2.mp3`,
            `${baseUrl}/Level-3/Level-3-Dialog-4-3.mp3`,
            `${baseUrl}/Level-3/Level-3-Dialog-4-4.mp3`,
            `${baseUrl}/Common-Voice/Chinese-问题.mp3`,
            `${baseUrl}/Common-Voice/Chinese-1.mp3`,
            `${baseUrl}/Level-3/Level-3-Q-4.mp3`,
            `${baseUrl}/Common-Voice/Chinese-A.mp3`,
            `${baseUrl}/Level-3/Level-3-Q-4-1.mp3`,
            `${baseUrl}/Common-Voice/Chinese-B.mp3`,
            `${baseUrl}/Level-3/Level-3-Q-4-2.mp3`,
            `${baseUrl}/Common-Voice/Chinese-C.mp3`,
            `${baseUrl}/Level-3/Level-3-Q-4-3.mp3`,
          ],
        ],
      },
    ],
    4: [
      '男的明天打算做什么？',
      5,
      {
        audio: [
          [
            `${baseUrl}/Level-3/Level-3-Desc-1~5.mp3`,
            `${baseUrl}/Level-3/Level-3-Dialog-5-1.mp3`,
            `${baseUrl}/Level-3/Level-3-Dialog-5-2.mp3`,
            `${baseUrl}/Level-3/Level-3-Dialog-5-3.mp3`,
            `${baseUrl}/Level-3/Level-3-Dialog-5-4.mp3`,
            `${baseUrl}/Common-Voice/Chinese-问题.mp3`,
            `${baseUrl}/Common-Voice/Chinese-1.mp3`,
            `${baseUrl}/Level-3/Level-3-Q-5.mp3`,
            `${baseUrl}/Common-Voice/Chinese-A.mp3`,
            `${baseUrl}/Level-3/Level-3-Q-5-1.mp3`,
            `${baseUrl}/Common-Voice/Chinese-B.mp3`,
            `${baseUrl}/Level-3/Level-3-Q-5-2.mp3`,
            `${baseUrl}/Common-Voice/Chinese-C.mp3`,
            `${baseUrl}/Level-3/Level-3-Q-5-3.mp3`,
          ],
        ],
      },
    ],
    5: [
      '他们最有可能在哪儿？',
      10,
      {
        audio: [
          [
            `${baseUrl}/Level-4/Level-4-Desc-1~5.mp3`,
            `${baseUrl}/Level-4/Level-4-Dialog-1-1.mp3`,
            `${baseUrl}/Level-4/Level-4-Dialog-1-2.mp3`,
            `${baseUrl}/Common-Voice/Chinese-问题.mp3`,
            `${baseUrl}/Common-Voice/Chinese-1.mp3`,
            `${baseUrl}/Level-4/Level-4-Q-1.mp3`,
            `${baseUrl}/Common-Voice/Chinese-A.mp3`,
            `${baseUrl}/Level-4/Level-4-Q-1-1.mp3`,
            `${baseUrl}/Common-Voice/Chinese-B.mp3`,
            `${baseUrl}/Level-4/Level-4-Q-1-2.mp3`,
            `${baseUrl}/Common-Voice/Chinese-C.mp3`,
            `${baseUrl}/Level-4/Level-4-Q-1-3.mp3`,
            `${baseUrl}/Common-Voice/Chinese-D.mp3`,
            `${baseUrl}/Level-4/Level-4-Q-1-4.mp3`,
          ],
        ],
      },
    ],
    6: [
      '女的让男的做什么？',
      10,
      {
        audio: [
          [
            `${baseUrl}/Level-4/Level-4-Desc-1~5.mp3`,
            `${baseUrl}/Level-4/Level-4-Dialog-2-1.mp3`,
            `${baseUrl}/Level-4/Level-4-Dialog-2-2.mp3`,
            `${baseUrl}/Common-Voice/Chinese-问题.mp3`,
            `${baseUrl}/Common-Voice/Chinese-1.mp3`,
            `${baseUrl}/Level-4/Level-4-Q-2.mp3`,
            `${baseUrl}/Common-Voice/Chinese-A.mp3`,
            `${baseUrl}/Level-4/Level-4-Q-2-1.mp3`,
            `${baseUrl}/Common-Voice/Chinese-B.mp3`,
            `${baseUrl}/Level-4/Level-4-Q-2-2.mp3`,
            `${baseUrl}/Common-Voice/Chinese-C.mp3`,
            `${baseUrl}/Level-4/Level-4-Q-2-3.mp3`,
            `${baseUrl}/Common-Voice/Chinese-D.mp3`,
            `${baseUrl}/Level-4/Level-4-Q-2-4.mp3`,
          ],
        ],
      },
    ],
    7: [
      '父母希望女的怎样做？',
      10,
      {
        audio: [
          [
            `${baseUrl}/Level-4/Level-4-Desc-1~5.mp3`,
            `${baseUrl}/Level-4/Level-4-Dialog-3-1.mp3`,
            `${baseUrl}/Level-4/Level-4-Dialog-3-2.mp3`,
            `${baseUrl}/Common-Voice/Chinese-问题.mp3`,
            `${baseUrl}/Common-Voice/Chinese-1.mp3`,
            `${baseUrl}/Level-4/Level-4-Q-3.mp3`,
            `${baseUrl}/Common-Voice/Chinese-A.mp3`,
            `${baseUrl}/Level-4/Level-4-Q-3-1.mp3`,
            `${baseUrl}/Common-Voice/Chinese-B.mp3`,
            `${baseUrl}/Level-4/Level-4-Q-3-2.mp3`,
            `${baseUrl}/Common-Voice/Chinese-C.mp3`,
            `${baseUrl}/Level-4/Level-4-Q-3-3.mp3`,
            `${baseUrl}/Common-Voice/Chinese-D.mp3`,
            `${baseUrl}/Level-4/Level-4-Q-3-4.mp3`,
          ],
        ],
      },
    ],
    8: [
      '关于女的，下面哪个是正确的？',
      10,
      {
        audio: [
          [
            `${baseUrl}/Level-4/Level-4-Desc-1~5.mp3`,
            `${baseUrl}/Level-4/Level-4-Dialog-4-1.mp3`,
            `${baseUrl}/Level-4/Level-4-Dialog-4-2.mp3`,
            `${baseUrl}/Common-Voice/Chinese-问题.mp3`,
            `${baseUrl}/Common-Voice/Chinese-1.mp3`,
            `${baseUrl}/Level-4/Level-4-Q-4.mp3`,
            `${baseUrl}/Common-Voice/Chinese-A.mp3`,
            `${baseUrl}/Level-4/Level-4-Q-4-1.mp3`,
            `${baseUrl}/Common-Voice/Chinese-B.mp3`,
            `${baseUrl}/Level-4/Level-4-Q-4-2.mp3`,
            `${baseUrl}/Common-Voice/Chinese-C.mp3`,
            `${baseUrl}/Level-4/Level-4-Q-4-3.mp3`,
            `${baseUrl}/Common-Voice/Chinese-D.mp3`,
            `${baseUrl}/Level-4/Level-4-Q-4-4.mp3`,
          ],
        ],
      },
    ],
    9: [
      '女的对自己的职业是什么态度？',
      10,
      {
        audio: [
          [
            `${baseUrl}/Level-4/Level-4-Desc-1~5.mp3`,
            `${baseUrl}/Level-4/Level-4-Dialog-5-1.mp3`,
            `${baseUrl}/Level-4/Level-4-Dialog-5-2.mp3`,
            `${baseUrl}/Level-4/Level-4-Dialog-5-3.mp3`,
            `${baseUrl}/Level-4/Level-4-Dialog-5-4.mp3`,
            `${baseUrl}/Common-Voice/Chinese-问题.mp3`,
            `${baseUrl}/Common-Voice/Chinese-1.mp3`,
            `${baseUrl}/Level-4/Level-4-Q-5.mp3`,
            `${baseUrl}/Common-Voice/Chinese-A.mp3`,
            `${baseUrl}/Level-4/Level-4-Q-5-1.mp3`,
            `${baseUrl}/Common-Voice/Chinese-B.mp3`,
            `${baseUrl}/Level-4/Level-4-Q-5-2.mp3`,
            `${baseUrl}/Common-Voice/Chinese-C.mp3`,
            `${baseUrl}/Level-4/Level-4-Q-5-3.mp3`,
            `${baseUrl}/Common-Voice/Chinese-D.mp3`,
            `${baseUrl}/Level-4/Level-4-Q-5-4.mp3`,
          ],
        ],
      },
    ],
    10: [
      '他们最有可能在哪儿？',
      10,
      {
        audio: [
          [
            `${baseUrl}/Level-5/Level-5-Desc-1~4.mp3`,
            `${baseUrl}/Level-5/Level-5-Dialog-1-1.mp3`,
            `${baseUrl}/Level-5/Level-5-Dialog-1-2.mp3`,
            `${baseUrl}/Common-Voice/Chinese-问题.mp3`,
            `${baseUrl}/Common-Voice/Chinese-1.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-1.mp3`,
            `${baseUrl}/Common-Voice/Chinese-A.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-1-1.mp3`,
            `${baseUrl}/Common-Voice/Chinese-B.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-1-2.mp3`,
            `${baseUrl}/Common-Voice/Chinese-C.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-1-3.mp3`,
            `${baseUrl}/Common-Voice/Chinese-D.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-1-4.mp3`,
          ],
        ],
      },
    ],
    11: [
      '男的是什么意思？',
      15,
      {
        audio: [
          [
            `${baseUrl}/Level-5/Level-5-Desc-1~4.mp3`,
            `${baseUrl}/Level-5/Level-5-Dialog-2-1.mp3`,
            `${baseUrl}/Level-5/Level-5-Dialog-2-2.mp3`,
            `${baseUrl}/Common-Voice/Chinese-问题.mp3`,
            `${baseUrl}/Common-Voice/Chinese-1.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-2.mp3`,
            `${baseUrl}/Common-Voice/Chinese-A.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-2-1.mp3`,
            `${baseUrl}/Common-Voice/Chinese-B.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-2-2.mp3`,
            `${baseUrl}/Common-Voice/Chinese-C.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-2-3.mp3`,
            `${baseUrl}/Common-Voice/Chinese-D.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-2-4.mp3`,
          ],
        ],
      },
    ],
    12: [
      '男的是什么意思？',
      15,
      {
        audio: [
          [
            `${baseUrl}/Level-5/Level-5-Desc-1~4.mp3`,
            `${baseUrl}/Level-5/Level-5-Dialog-3-1.mp3`,
            `${baseUrl}/Level-5/Level-5-Dialog-3-2.mp3`,
            `${baseUrl}/Common-Voice/Chinese-问题.mp3`,
            `${baseUrl}/Common-Voice/Chinese-1.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-3.mp3`,
            `${baseUrl}/Common-Voice/Chinese-A.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-3-1.mp3`,
            `${baseUrl}/Common-Voice/Chinese-B.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-3-2.mp3`,
            `${baseUrl}/Common-Voice/Chinese-C.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-3-3.mp3`,
            `${baseUrl}/Common-Voice/Chinese-D.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-3-4.mp3`,
          ],
        ],
      },
    ],
    13: [
      '女的为什么让男的早出发？',
      15,
      {
        audio: [
          [
            `${baseUrl}/Level-5/Level-5-Desc-1~4.mp3`,
            `${baseUrl}/Level-5/Level-5-Dialog-4-1.mp3`,
            `${baseUrl}/Level-5/Level-5-Dialog-4-2.mp3`,
            `${baseUrl}/Level-5/Level-5-Dialog-4-3.mp3`,
            `${baseUrl}/Level-5/Level-5-Dialog-4-4.mp3`,
            `${baseUrl}/Common-Voice/Chinese-问题.mp3`,
            `${baseUrl}/Common-Voice/Chinese-1.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-4.mp3`,
            `${baseUrl}/Common-Voice/Chinese-A.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-4-1.mp3`,
            `${baseUrl}/Common-Voice/Chinese-B.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-4-2.mp3`,
            `${baseUrl}/Common-Voice/Chinese-C.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-4-3.mp3`,
            `${baseUrl}/Common-Voice/Chinese-D.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-4-4.mp3`,
          ],
        ],
      },
    ],
    14: [
      '本文认为父母和孩子交流的时候用哪种方式更好？',
      15,
      {
        audio: [
          [
            `${baseUrl}/Level-5/Level-5-Desc-5~6.mp3`,
            `${baseUrl}/Level-5/Level-5-Dialog-5~6.mp3`,
            `${baseUrl}/Common-Voice/Chinese-问题.mp3`,
            `${baseUrl}/Common-Voice/Chinese-1.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-5.mp3`,
            `${baseUrl}/Common-Voice/Chinese-A.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-5-1.mp3`,
            `${baseUrl}/Common-Voice/Chinese-B.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-5-2.mp3`,
            `${baseUrl}/Common-Voice/Chinese-C.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-5-3.mp3`,
            `${baseUrl}/Common-Voice/Chinese-D.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-5-4.mp3`,
          ],
        ],
      },
    ],
    15: [
      '根据这段话，孩子给父母回信的时候会怎样？',
      15,
      {
        audio: [
          [
            `${baseUrl}/Common-Voice/Chinese-问题.mp3`,
            `${baseUrl}/Common-Voice/Chinese-2.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-6.mp3`,
            `${baseUrl}/Common-Voice/Chinese-A.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-6-1.mp3`,
            `${baseUrl}/Common-Voice/Chinese-B.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-6-2.mp3`,
            `${baseUrl}/Common-Voice/Chinese-C.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-6-3.mp3`,
            `${baseUrl}/Common-Voice/Chinese-D.mp3`,
            `${baseUrl}/Level-5/Level-5-Q-6-4.mp3`,
          ],
        ],
      },
    ],
    16: [
      '女的是什么意思？',
      20,
      {
        audio: [
          [
            `${baseUrl}/Level-6/Level-6-Desc-1~5.mp3`,
            `${baseUrl}/Level-6/Level-6-Dialog-1-1.mp3`,
            `${baseUrl}/Level-6/Level-6-Dialog-1-2.mp3`,
            `${baseUrl}/Common-Voice/Chinese-问题.mp3`,
            `${baseUrl}/Common-Voice/Chinese-1.mp3`,
            `${baseUrl}/Level-6/Level-6-Q-1.mp3`,
            `${baseUrl}/Common-Voice/Chinese-A.mp3`,
            `${baseUrl}/Level-6/Level-6-Q-1-1.mp3`,
            `${baseUrl}/Common-Voice/Chinese-B.mp3`,
            `${baseUrl}/Level-6/Level-6-Q-1-2.mp3`,
            `${baseUrl}/Common-Voice/Chinese-C.mp3`,
            `${baseUrl}/Level-6/Level-6-Q-1-3.mp3`,
            `${baseUrl}/Common-Voice/Chinese-D.mp3`,
            `${baseUrl}/Level-6/Level-6-Q-1-4.mp3`,
          ],
        ],
      },
    ],
    17: [
      '男的是什么意思？',
      20,
      {
        audio: [
          [
            `${baseUrl}/Level-6/Level-6-Desc-1~5.mp3`,
            `${baseUrl}/Level-6/Level-6-Dialog-2-1.mp3`,
            `${baseUrl}/Level-6/Level-6-Dialog-2-2.mp3`,
            `${baseUrl}/Level-6/Level-6-Dialog-2-3.mp3`,
            `${baseUrl}/Level-6/Level-6-Dialog-2-4.mp3`,
            `${baseUrl}/Common-Voice/Chinese-问题.mp3`,
            `${baseUrl}/Common-Voice/Chinese-1.mp3`,
            `${baseUrl}/Level-6/Level-6-Q-2.mp3`,
            `${baseUrl}/Common-Voice/Chinese-A.mp3`,
            `${baseUrl}/Level-6/Level-6-Q-2-1.mp3`,
            `${baseUrl}/Common-Voice/Chinese-B.mp3`,
            `${baseUrl}/Level-6/Level-6-Q-2-2.mp3`,
            `${baseUrl}/Common-Voice/Chinese-C.mp3`,
            `${baseUrl}/Level-6/Level-6-Q-2-3.mp3`,
            `${baseUrl}/Common-Voice/Chinese-D.mp3`,
            `${baseUrl}/Level-6/Level-6-Q-2-4.mp3`,
          ],
        ],
      },
    ],
    18: [
      '女的为什么很兴奋？',
      20,
      {
        audio: [
          [
            `${baseUrl}/Level-6/Level-6-Desc-1~5.mp3`,
            `${baseUrl}/Level-6/Level-6-Dialog-3-1.mp3`,
            `${baseUrl}/Level-6/Level-6-Dialog-3-2.mp3`,
            `${baseUrl}/Level-6/Level-6-Dialog-3-3.mp3`,
            `${baseUrl}/Level-6/Level-6-Dialog-3-4_.mp3`,
            `${baseUrl}/Common-Voice/Chinese-问题.mp3`,
            `${baseUrl}/Common-Voice/Chinese-1.mp3`,
            `${baseUrl}/Level-6/Level-6-Q-3.mp3`,
            `${baseUrl}/Common-Voice/Chinese-A.mp3`,
            `${baseUrl}/Level-6/Level-6-Q-3-1.mp3`,
            `${baseUrl}/Common-Voice/Chinese-B.mp3`,
            `${baseUrl}/Level-6/Level-6-Q-3-2.mp3`,
            `${baseUrl}/Common-Voice/Chinese-C.mp3`,
            `${baseUrl}/Level-6/Level-6-Q-3-3.mp3`,
            `${baseUrl}/Common-Voice/Chinese-D.mp3`,
            `${baseUrl}/Level-6/Level-6-Q-3-4.mp3`,
          ],
        ],
      },
    ],
    19: [
      '下列哪一项和通知的内容不相符：',
      20,
      {
        audio: [
          [
            `${baseUrl}/Level-6/Level-6-Desc-1~5.mp3`,
            `${baseUrl}/Level-6/Level-6-Dialog-4.mp3`,
            `${baseUrl}/Common-Voice/Chinese-问题.mp3`,
            `${baseUrl}/Common-Voice/Chinese-1.mp3`,
            `${baseUrl}/Level-6/Level-6-Q-4.mp3`,
            `${baseUrl}/Common-Voice/Chinese-A.mp3`,
            `${baseUrl}/Level-6/Level-6-Q-4-1.mp3`,
            `${baseUrl}/Common-Voice/Chinese-B.mp3`,
            `${baseUrl}/Level-6/Level-6-Q-4-2.mp3`,
            `${baseUrl}/Common-Voice/Chinese-C.mp3`,
            `${baseUrl}/Level-6/Level-6-Q-4-3.mp3`,
            `${baseUrl}/Common-Voice/Chinese-D.mp3`,
            `${baseUrl}/Level-6/Level-6-Q-4-4.mp3`,
          ],
        ],
      },
    ],
    20: [
      '根据这段话，下列哪项可以使人获得职业幸福感？',
      25,
      {
        audio: [
          [
            `${baseUrl}/Level-6/Level-6-Desc-1~5.mp3`,
            `${baseUrl}/Level-6/Level-6-Dialog-5.mp3`,
            `${baseUrl}/Common-Voice/Chinese-问题.mp3`,
            `${baseUrl}/Common-Voice/Chinese-1.mp3`,
            `${baseUrl}/Level-6/Level-6-Q-5.mp3`,
            `${baseUrl}/Common-Voice/Chinese-A.mp3`,
            `${baseUrl}/Level-6/Level-6-Q-5-1.mp3`,
            `${baseUrl}/Common-Voice/Chinese-B.mp3`,
            `${baseUrl}/Level-6/Level-6-Q-5-2.mp3`,
            `${baseUrl}/Common-Voice/Chinese-C.mp3`,
            `${baseUrl}/Level-6/Level-6-Q-5-3.mp3`,
            `${baseUrl}/Common-Voice/Chinese-D.mp3`,
            `${baseUrl}/Level-6/Level-6-Q-5-4.mp3`,
          ],
        ],
      },
    ],
    21: [
      '根据听到的内容，选出正确答案：',
      25,
      {
        audio: [
          [
            `${baseUrl}/Level-7/Level-7-Desc-1~5.mp3`,
            `${baseUrl}/Level-7/Level-7-Dialog-1-1.mp3`,
            `${baseUrl}/Level-7/Level-7-Dialog-1-2.mp3`,
            `${baseUrl}/Common-Voice/Chinese-问题.mp3`,
            `${baseUrl}/Common-Voice/Chinese-1.mp3`,
            `${baseUrl}/Level-7/Level-7-Q-1.mp3`,
            `${baseUrl}/Common-Voice/Chinese-A.mp3`,
            `${baseUrl}/Level-7/Level-7-Q-1-1.mp3`,
            `${baseUrl}/Common-Voice/Chinese-B.mp3`,
            `${baseUrl}/Level-7/Level-7-Q-1-2.mp3`,
            `${baseUrl}/Common-Voice/Chinese-C.mp3`,
            `${baseUrl}/Level-7/Level-7-Q-1-3.mp3`,
            `${baseUrl}/Common-Voice/Chinese-D.mp3`,
            `${baseUrl}/Level-7/Level-7-Q-1-4.mp3`,
          ],
        ],
      },
    ],
    22: [
      '关于男的可以知道什么？',
      25,
      {
        audio: [
          [
            `${baseUrl}/Level-7/Level-7-Desc-1~5.mp3`,
            `${baseUrl}/Level-7/Level-7-Dialog-2-1.mp3`,
            `${baseUrl}/Level-7/Level-7-Dialog-2-2.mp3`,
            `${baseUrl}/Common-Voice/Chinese-问题.mp3`,
            `${baseUrl}/Common-Voice/Chinese-1.mp3`,
            `${baseUrl}/Level-7/Level-7-Q-2.mp3`,
            `${baseUrl}/Common-Voice/Chinese-A.mp3`,
            `${baseUrl}/Level-7/Level-7-Q-2-1.mp3`,
            `${baseUrl}/Common-Voice/Chinese-B.mp3`,
            `${baseUrl}/Level-7/Level-7-Q-2-2.mp3`,
            `${baseUrl}/Common-Voice/Chinese-C.mp3`,
            `${baseUrl}/Level-7/Level-7-Q-2-3.mp3`,
            `${baseUrl}/Common-Voice/Chinese-D.mp3`,
            `${baseUrl}/Level-7/Level-7-Q-2-4.mp3`,
          ],
        ],
      },
    ],
    23: [
      '女的认为从事自由职业最大的挑战是什么？',
      25,
      {
        audio: [
          [
            `${baseUrl}/Level-7/Level-7-Desc-1~5.mp3`,
            `${baseUrl}/Level-7/Level-7-Dialog-3-1.mp3`,
            `${baseUrl}/Level-7/Level-7-Dialog-3-2.mp3`,
            `${baseUrl}/Level-7/Level-7-Dialog-3-3.mp3`,
            `${baseUrl}/Level-7/Level-7-Dialog-3-4.mp3`,
            `${baseUrl}/Level-7/Level-7-Dialog-3-5.mp3`,
            `${baseUrl}/Level-7/Level-7-Dialog-3-6.mp3`,
            `${baseUrl}/Common-Voice/Chinese-问题.mp3`,
            `${baseUrl}/Common-Voice/Chinese-1.mp3`,
            `${baseUrl}/Level-7/Level-7-Q-3.mp3`,
            `${baseUrl}/Common-Voice/Chinese-A.mp3`,
            `${baseUrl}/Level-7/Level-7-Q-3-1.mp3`,
            `${baseUrl}/Common-Voice/Chinese-B.mp3`,
            `${baseUrl}/Level-7/Level-7-Q-3-2.mp3`,
            `${baseUrl}/Common-Voice/Chinese-C.mp3`,
            `${baseUrl}/Level-7/Level-7-Q-3-3.mp3`,
            `${baseUrl}/Common-Voice/Chinese-D.mp3`,
            `${baseUrl}/Level-7/Level-7-Q-3-4.mp3`,
          ],
        ],
      },
    ],
    24: [
      '关于手机的光，可以知道什么？',
      25,
      {
        audio: [
          [
            `${baseUrl}/Level-7/Level-7-Desc-1~5_根据听到的内容选出正确答案.mp3`,
            `${baseUrl}/Level-7/Level-7-Dialog-4_频繁地使用手机容易导致皮肤受损而引起早衰这一种说法让每天离不开手机的人感到十分担忧专家指出高能.mp3`,
            `${baseUrl}/Common-Voice/Chinese-问题.mp3`,
            `${baseUrl}/Common-Voice/Chinese-1.mp3`,
            `${baseUrl}/Level-7/Level-7-Q-4_关于手机的光可以知道什么.mp3`,
            `${baseUrl}/Common-Voice/Chinese-A.mp3`,
            `${baseUrl}/Level-7/Level-7-Q-4-1_会伤害皮肤.mp3`,
            `${baseUrl}/Common-Voice/Chinese-B.mp3`,
            `${baseUrl}/Level-7/Level-7-Q-4-2_会使人心情不好.mp3`,
            `${baseUrl}/Common-Voice/Chinese-C.mp3`,
            `${baseUrl}/Level-7/Level-7-Q-4-3_含有红外线.mp3`,
            `${baseUrl}/Common-Voice/Chinese-D.mp3`,
            `${baseUrl}/Level-7/Level-7-Q-4-4_导致发育不良.mp3`,
          ],
        ],
      },
    ],
    25: [
      '收到通知的人可能需要提前安排什么工作？',
      25,
      {
        audio: [
          [
            `${baseUrl}/Level-7/Level-7-Desc-1~5.mp3`,
            `${baseUrl}/Level-7/Level-7-Dialog-5.mp3`,
            `${baseUrl}/Common-Voice/Chinese-问题.mp3`,
            `${baseUrl}/Common-Voice/Chinese-1.mp3`,
            `${baseUrl}/Level-7/Level-7-Q-5.mp3`,
            `${baseUrl}/Common-Voice/Chinese-A.mp3`,
            `${baseUrl}/Level-7/Level-7-Q-5-1.mp3`,
            `${baseUrl}/Common-Voice/Chinese-B.mp3`,
            `${baseUrl}/Level-7/Level-7-Q-5-2.mp3`,
            `${baseUrl}/Common-Voice/Chinese-C.mp3`,
            `${baseUrl}/Level-7/Level-7-Q-5-3.mp3`,
            `${baseUrl}/Common-Voice/Chinese-D.mp3`,
            `${baseUrl}/Level-7/Level-7-Q-5-4.mp3`,
          ],
        ],
      },
    ],
  };

  /** ==========
 * 문제 선택지
============== */
  const choices = {
    0: [
      ['A', 0],
      ['B', 0],
      ['C', 1],
      ['D', 0],
    ],
    1: [
      ['A', 0],
      ['B', 1],
      ['C', 0],
      ['D', 0],
    ],
    2: [
      ['A', 0],
      ['B', 1],
      ['C', 0],
      ['D', 0],
    ],
    3: [
      ['A', 0],
      ['B', 0],
      ['C', 1],
      ['D', 0],
    ],
    4: [
      ['A', 1],
      ['B', 0],
      ['C', 0],
      ['D', 0],
    ],
    5: [
      ['A', 0],
      ['B', 0],
      ['C', 1],
      ['D', 0],
    ],
    6: [
      ['A', 0],
      ['B', 0],
      ['C', 0],
      ['D', 1],
    ],
    7: [
      ['A', 0],
      ['B', 1],
      ['C', 0],
      ['D', 0],
    ],
    8: [
      ['A', 0],
      ['B', 0],
      ['C', 0],
      ['D', 1],
    ],
    9: [
      ['A', 0],
      ['B', 1],
      ['C', 0],
      ['D', 0],
    ],
    10: [
      ['A', 0],
      ['B', 0],
      ['C', 1],
      ['D', 0],
    ],
    11: [
      ['A', 0],
      ['B', 0],
      ['C', 0],
      ['D', 1],
    ],
    12: [
      ['A', 0],
      ['B', 0],
      ['C', 0],
      ['D', 1],
    ],
    13: [
      ['A', 0],
      ['B', 0],
      ['C', 1],
      ['D', 0],
    ],
    14: [
      ['A', 0],
      ['B', 0],
      ['C', 0],
      ['D', 1],
    ],
    15: [
      ['A', 0],
      ['B', 0],
      ['C', 1],
      ['D', 0],
    ],
    16: [
      ['A', 0],
      ['B', 1],
      ['C', 0],
      ['D', 0],
    ],
    17: [
      ['A', 1],
      ['B', 0],
      ['C', 0],
      ['D', 0],
    ],
    18: [
      ['A', 0],
      ['B', 0],
      ['C', 0],
      ['D', 1],
    ],
    19: [
      ['A', 0],
      ['B', 1],
      ['C', 0],
      ['D', 0],
    ],
    20: [
      ['A', 0],
      ['B', 1],
      ['C', 0],
      ['D', 0],
    ],
    21: [
      ['A', 0],
      ['B', 1],
      ['C', 0],
      ['D', 0],
    ],
    22: [
      ['A', 0],
      ['B', 0],
      ['C', 1],
      ['D', 0],
    ],
    23: [
      ['A', 0],
      ['B', 0],
      ['C', 1],
      ['D', 0],
    ],
    24: [
      ['A', 1],
      ['B', 0],
      ['C', 0],
      ['D', 0],
    ],
    25: [
      ['A', 0],
      ['B', 1],
      ['C', 0],
      ['D', 0],
    ],
  };

  /** ==========
 * 난이도
============== */
  const difficultyLevels = {
    5: 'Beginner',
    10: 'Intermediate',
    15: 'Intermediate',
    20: 'Advanced',
    25: 'Advanced',
  };

  /** ==========
 * 지문 그룹
============== */
  const passageQuestions = {
    0: [
      [
        '根据对话内容，选出正确答案。',
        '女：我有点儿困了，现在想喝咖啡，去北京机场的路上有吗？',
        '男: 有，别担心。',
      ],
      [0],
    ],
    1: [
      [
        '根据对话内容，选出正确答案。',
        '男：浴室的灯怎么开着啊？',
        '女: 女儿说她要洗澡，她去拿衣服了。',
      ],
      [1],
    ],
    2: [
      [
        '根据对话内容，选出正确答案。',
        '女：你的那张照片是什么时候照的？ 不太像你。',
        '男：那是我初中的时候照的，那时比较胖，而且头发也很长。',
      ],
      [2],
    ],
    3: [
      [
        '根据对话内容，选出正确答案。',
        '女：请问有明天早上去上海的火车票吗？',
        '男：有，早上七点的。行吗？',
        '女：好的，请给我两张票。',
        '男：两张六百八十块。',
      ],
      [3],
    ],
    4: [
      [
        '根据对话内容，选出正确答案。',
        '男：妈，明天早上六点半叫我起床。',
        '女：明天是周六不上课，你要去见朋友吗？',
        '男：我跟朋友约好一起去练习打网球。',
        '女：好的，你早点儿睡吧。',
      ],
      [4],
    ],
    5: [
      [
        '根据对话内容，选出问题的正确答案。',
        '男：女士，您坐的位置好像是我的。您看，这是我的登机牌。',
        '女：真对不起，我坐错了。我应该坐在旁边的位置。',
      ],
      [5],
    ],
    6: [
      [
        '根据对话内容，选出问题的正确答案。',
        '女：这里的景色太漂亮了，你帮我照张相吧。',
        '男：好的，你再往后站一点儿，我帮你把后面的山也照进去。',
      ],
      [6],
    ],
    7: [
      [
        '根据对话内容，选出问题的正确答案。',
        '男：这么好的机会你错过太可惜了。你父母不反对吗？',
        '女：我们商量过了，父母让我自己拿主意。',
      ],
      [7],
    ],
    8: [
      [
        '根据对话内容，选出问题的正确答案。',
        '女：走楼梯太累了，我们还是坐电梯上去吧。',
        '男：你不是要减肥吗？走楼梯对减肥有帮助。',
      ],
      [8],
    ],
    9: [
      [
        '根据对话内容，选出问题的正确答案。',
        '男：你为什么选择当老师？',
        '女：因为我父母都是老师，所以他们都希望我成为老师。',
        '男：当老师感觉怎么样？',
        '女：当老师确实很辛苦，但是我觉得再辛苦再累也值得，而且我从来没后悔过。',
      ],
      [9],
    ],
    10: [
      [
        '根据对话内容，选出问题的正确答案。',
        '男：出去玩儿，能不能少带点儿东西？你带的东西太多了。',
        '女：衣服得带，照相机也得带。',
      ],
      [10],
    ],
    11: [
      [
        '根据对话内容，选出问题的正确答案。',
        '女：周末我们一起去学习街舞吧。',
        '男：我这把年纪了还学什么街舞呀？我怕别人笑话我。',
      ],
      [11],
    ],
    12: [
      [
        '男的是什么意思？',
        '女：我们来打个赌吧，怎么样？ 谁输了谁请客。',
        '男：那你就准备好钱吧。',
      ],
      [12],
    ],
    13: [
      [
        '根据对话内容，选出问题的正确答案。',
        '女：我们明天一早就得出发。',
        '男：用得着那么早吗？',
        '女：我们得坐长途汽车去，而且明天是星期天很可能堵车。',
        '男：原来是这样啊，知道了，我明天早点起。',
      ],
      [13],
    ],
    14: [
      [
        '听录音，选择问题的正确答案',
        '随着科学技术和通讯技术的不断发展，人们写信的机会越来越少了 ，打电话，发短信的时间代替了书信。但教育孩子的时候，写信常常是一个不错的方法。你觉得和孩子进行口头交流效果不太好的时 候，当你希望自己的话能够引起孩子重视的时候，就可以用这种方法。因为父母写信的时候大多心平气和、思路清晰、条理完整；而孩子回信的时候，也能够充分的表达自己心中的喜怒哀乐，并且在写信的时候能锻炼文字表达能力。',
      ],
      [14, 15],
    ],
    15: [
      [
        '根据听到的内容，选出正确答案：',
        '男：听说您打算再开一家餐厅？',
        '女：对，目前正在装修，等餐厅装修好了再请你去参观。',
      ],
      [16],
    ],
    16: [
      [
        '根据听到的内容，选出正确答案：',
        '女：王山，你以前学过汽车维修吗？',
        '男：学过啊，而且学了4年呢',
        '女：太好了，我的车坏了，可以帮我看一下吗？',
        '男：好的，但是现在我没有工具，没有办法给你修',
      ],
      [17],
    ],
    17: [
      [
        '根据听到的内容，选出正确答案：',
        '女：快来看啊！这可是我最喜欢的演员的签名照。',
        '男：看你兴奋的。你是怎么拿到的？',
        '女：我去电影节做志愿者了！她是开幕式的嘉宾，刚好我负责接待她。',
        '男：你的运气真好啊！',
      ],
      [18],
    ],
    18: [
      [
        '根据听到的内容，选出正确答案：',
        '通知',
        '各位员工：',
        '为更好的展示公司整体形象，增强员工团队意识，集团公司决定统一着装，具体要求如下：',
        '1.	在公司里工作时间内，所有员工统一穿工作服上班',
        '2.	重要的会议、上级领导来公司检查指导工作、团体参观等特定时间和场合必须穿工作服。',
        '3.	因接待、出差、外勤等特殊原因不方便穿工作服时，可以临时穿便装。',
        '4.	暂时没有工作服的员工，可以穿白色正装衬衫、黑色裤子。',
        '公司人事部',
        '2023年5月5日',
      ],
      [19],
    ],
    19: [
      [
        '根据听到的内容，选出正确答案：',
        '根据最新影响职业幸福感的五大因素排行榜，可以知道：足够的休闲时间和客观的收入都是获得职业幸福感的重要因素。那么，怎样的工作既有很多空闲时间又有满意的收入呢？最近，作为职业幸福感榜首的自由职业，就满足了上面两个要求。在时间方面，自由职业者不仅免去了平时上班的奔波，还少了很多人际交往上的矛盾。在收入方面，76%的自由职业者月收入都高于全国平均工资水平。因此，很多有一定职业技能的人都已经放弃了原有的固定工作，而做起了自由职业者。',
      ],
      [20],
    ],
    20: [
      [
        '根据听到的内容，选出正确答案：',
        '女：公司这个月的业绩有明显的提高，多亏了你们部门的广告方案做得好。',
        '男：太过奖了，其实各个部门的合作也非常重要。',
      ],
      [21],
    ],
    21: [
      [
        '根据听到的内容，选出正确答案：',
        '女：你的汉语说的太地道了，我都没听出来你是外国人。',
        '男：我的父母在中国工作，我从小就跟他们来到中国，在北京生活',
      ],
      [22],
    ],
    22: [
      [
        '根据听到的内容，选出正确答案：',
        '男：听说你是一位自由职业者，请问您具体是做什么工作的呢？感觉如何？',
        '女：我主要做网站设计工作，感觉挺好的。最大的好处就是能自己安排时间，只要按时完成工作就可以。',
        '男：听起来不错，但是需要很强的自制力吧，我可能会浪费很多时间。',
        '女：是的。自己如何管理自己确实是最大的挑战，要有效合理地安排和利用时间。',
        '男：没有工作的时候您怎么办？',
        '女：我已经可以在淡季和旺季之间找到一个平衡点，有工作的时候努力工作，没有工作的时候也不担心，去旅行或者自我开发。',
      ],
      [23],
    ],
    23: [
      [
        '根据听到的内容，选出正确答案：',
        '频繁地使用手机容易导致皮肤受损而引起早衰，这一种说法让每天离不开手机的人感到十分担忧。专家指出，高能可见光、太阳和空气污染是对皮肤产生不良影响的三种主要外部原因。这三种原因都有可能引起皮肤发热发炎，使皮肤的自我防御能力下降。手机发出的光属于高能可见光，对皮肤确实具有一定程度的损伤。可是只要能合理地控制使用时间，并不会对皮肤造成特别严重的伤害。',
      ],
      [24],
    ],
    24: [
      [
        '根据听到的内容，选出正确答案：',
        '通知',
        '尊敬的各位客户：',
        '你们好！中秋佳节将至，首先对于贵司对本公司的支持和通力合作表示衷心的感谢。在您的支持和信赖下，我们才得以在激烈的市场环境下不断大步挺进！在此我代表公司的全体同仁，恭祝诸位：中秋节快乐，阖家团圆！现我公司综合实际情况定于2022年9月8日至2022年9月15日为中秋放假日，2022年9月16日正式上班。为确保不耽误贵公司的正常运作，请贵公司提前做好所需库存的计划安排，并将订单传真到我公司营业部，以便我公司尽早安排生产、送货。服务好贵司。',
        '以上造成的诸多不便，还望得到贵司的谅解与支持！谢谢！',
        '韩中商贸客户服务部',
        '2020年8月1日',
      ],
      [25],
    ],
  };

  return nextQuestion(
    previous,
    questionDifficulty,
    choices,
    difficultyLevels,
    passageQuestions
  );
}
