import React from 'react';
import { useStateWithCallback } from 'utils/hooks';
import { executeAction } from 'utils/redux';
import {
  setResults as setResultsThunk,
  uploadBytes,
  updateCondition,
  setVoiceTest,
} from 'store/slices/testtakerSlice';
import axios from 'axios';
import { useStatus } from 'utils/status';

const ResultContext = React.createContext(null);

export function ResultProvider({ children }) {
  const { uid, aid, email } = useStatus();
  const [results, setResults] = useStateWithCallback({});
  const [stream, setStream] = React.useState(null);
  const [audioStream, setAudioStream] = React.useState(null);
  const [blob, setBlob] = React.useState(null);
  const [audioBlob, setAudioBlob] = React.useState(null);
  const [beforeIpAddress, setBeforeIpAddress] = React.useState('');
  const [afterIpAddress, setAfterIpAddress] = React.useState('');
  const [isFullscreen, setIsFullscreen] = React.useState(true);
  const [isMouseLeave, setIsMouseLeave] = React.useState(false);
  const curResults = React.useRef(results);

  React.useEffect(() => {
    curResults.current = results;
  }, [results]);

  React.useEffect(() => {
    if (stream) {
      recordResult({
        type: 'detect',
        data: {
          onWebcam: true,
        },
      });
    } else {
      recordResult({
        type: 'detect',
        data: {
          onWebcam: false,
        },
      });
    }
  }, [stream]);

  const getResult = () => ({
    results,
  });

  const submitResults = (_results) =>
    new Promise(async (resolve, reject) => {
      const tasks = [];
      const convertToFirestore = async (obj, prevKey) => {
        const newObj = {};

        for (const [key, value] of Object.entries(obj || {})) {
          if (typeof value === 'object') {
            if (value instanceof File) {
              newObj[key] = (
                await executeAction(
                  uploadBytes({
                    uid,
                    aid,
                    cid: email,
                    sid: prevKey,
                    fileName: value.name,
                    bytes: value,
                  })
                ).unwrap()
              )?.downloadURL;
            } else if (value instanceof Blob) {
              if (
                key === 'english' ||
                key === 'chinese' ||
                key === 'japanese' ||
                key === 'korean'
              ) {
                newObj[key] = (
                  await executeAction(
                    uploadBytes({
                      uid,
                      aid,
                      cid: email,
                      sid: key,
                      fileName: value.name,
                      bytes: value,
                    })
                  ).unwrap()
                )?.downloadURL;
              } else {
                newObj[key] = (
                  await executeAction(
                    uploadBytes({
                      uid,
                      aid,
                      cid: email,
                      sid: prevKey,
                      fileName: value.name,
                      bytes: value,
                    })
                  ).unwrap()
                )?.downloadURL;
              }
            } else {
              newObj[key] = await convertToFirestore(value, key);
            }
          } else if (typeof value === 'undefined') {
            newObj[key] = null;
          } else {
            newObj[key] = value;
          }
        }

        return newObj;
      };

      const newResults = await convertToFirestore(_results, null);

      executeAction(
        setResultsThunk({
          uid,
          aid,
          cid: email,
          results: newResults,
        })
      )
        .unwrap()
        .then(() => {
          executeAction(
            updateCondition({
              uid,
              aid,
              cid: email,
              condition: 2,
              progress: 1,
            })
          )
            .unwrap()
            .then(() => resolve());
        })
        .catch((e) => reject(e));
    });

  const recordResult = ({
    type,
    section,
    sid,
    tid,
    checks,
    attributes,
    cultureAttributes,
    langTest,
    selected,
    data,
  }) =>
    new Promise(async (resolve) => {
      if (Boolean(sid) && Boolean(tid) && checks?.length > 0) {
        setResults(
          {
            ...curResults.current,
            [sid]: {
              ...curResults.current?.[sid],
              [tid]: checks || [],
            },
          },
          (result) => resolve(result)
        );
      }

      if (Boolean(sid) && Boolean(selected)) {
        setResults({
          ...curResults.current,
          [sid]: {
            ...curResults.current?.[sid],
            ...selected,
          },
        });
      }

      if (Boolean(sid) && Boolean(langTest)) {
        setResults({
          ...curResults.current,
          [sid]: {
            ...curResults.current?.[sid],
            ...langTest,
          },
        });
      }

      if (
        Boolean(sid) &&
        Array.isArray(attributes) &&
        Array.isArray(cultureAttributes)
      ) {
        setResults(
          {
            ...curResults.current,
            [sid]: {
              ...curResults.current?.[sid],
              attributes,
              cultureAttributes,
            },
          },
          (result) => resolve(result)
        );
      }

      if (Boolean(sid) && Boolean(data)) {
        setResults(
          {
            ...curResults.current,
            [sid]: data,
          },
          (result) => resolve(result)
        );
      }

      if (type === 'survey') {
        setResults(
          {
            ...curResults.current,
            survey: data,
          },
          (result) => resolve(result)
        );
      }

      if (type === 'detect') {
        setResults(
          {
            ...curResults.current,
            detect: {
              ...(curResults.current?.detect || {}),
              ...data,
            },
          },
          (result) => resolve(result)
        );
      }
    });

  const recordIpAddress = (period) =>
    new Promise(async (resolve, reject) => {
      try {
        const ipAddress = await getIpAddress();
        if (period === 'before') {
          setBeforeIpAddress(ipAddress);
        } else if (period === 'after') {
          setAfterIpAddress(ipAddress);
        }
        return resolve(ipAddress);
      } catch (e) {
        return reject(e);
      }
    });

  const value = {
    results,
    setResults,
    recordResult,
    submitResults,
    stream,
    setStream,
    audioStream,
    setAudioStream,
    blob,
    setBlob,
    audioBlob,
    setAudioBlob,
    recordIpAddress,
    beforeIpAddress,
    isFullscreen,
    setIsFullscreen,
    isMouseLeave,
    setIsMouseLeave,
    getResult,
  };

  return (
    <ResultContext.Provider value={value}>{children}</ResultContext.Provider>
  );
}

const getIpAddress = () =>
  new Promise(async (resolve) => {
    axios
      .get('https://jsonip.com')
      .then(({ data }) => {
        const { ip } = data || {};
        return resolve(ip);
      })
      .catch(() => resolve('0000'));
  });

export const useResult = () => React.useContext(ResultContext);
