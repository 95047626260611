import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useProgress } from 'utils/progress';
import { ReactComponent as TriangleIcon } from 'assets/images/icon/testtaker/triangle-icon.svg';
import { useStatus } from 'utils/status';
import { englishListeningQuestion } from 'utils/tests/listening/english';
import { chineseListeningQuestion } from 'utils/tests/listening/chinese';
import { japaneseListeningQuestion } from 'utils/tests/listening/japanese';
import { DemoListeningAnswerItem } from 'components/languageTest/DemoAnswerListItem';
import { koreanListeningQuestion } from 'utils/tests/listening/korean';
import { useResize } from 'utils/hooks';
import * as Sentry from '@sentry/react';

const DemoLanguageListening = ({
  language,
  playAudio,
  setPlayAudio,
  setExampleStep,
  listeningData,
  setListeningData,
  selectedListening,
  setSelectedListening,
}) => {
  const { t } = useTranslation();
  const audioRef = useRef(null);
  const windowSize = useResize();
  const { setTestInfo, getTestInfo } = useStatus();
  const { doneCnt, maxDoneCnt } = getTestInfo();
  const { setIsEntireLoading } = useProgress();
  const [currentAudioIndex, setCurrentAudioIndex] = useState(0);
  const [postQuestionData, setPostQuestionData] = useState({});
  const [audioUrls, setAudioUrls] = useState();
  const [isCheckedImage, setIsCheckedImage] = useState();

  /** ==========
   * @function postQuestionHandler
   * 문제 클릭 시 선택한 답안을 문제 정보 state에 저장
   * @param {*} choice (string)
  ============== */
  const postQuestionHandler = (choice) => {
    setIsEntireLoading(true);
    const postSelectedValue = listeningData;
    postSelectedValue.inputAnswer = choice;
    postSelectedValue.questionHistory = [...listeningData.questionHistory];

    setPostQuestionData({ ...postSelectedValue });
  };

  /** ==========
   * @function postQuestion
   * 실제 함수로 데이터 전송
   * @param {*} lang (string)
   * @returns 
  ============== */
  const postQuestion = (lang) => {
    switch (lang) {
      case 'english':
        return englishListeningQuestion({ ...postQuestionData });
      case 'chinese':
        return chineseListeningQuestion({ ...postQuestionData });
      case 'japanese':
        return japaneseListeningQuestion({ ...postQuestionData });
      case 'korean':
        return koreanListeningQuestion({ ...postQuestionData });
    }
  };

  /** ==========
   * @function onClickSelectAnswerHandler
   * 선택지 클릭 시 동작하는 이벤트 핸들러
   * setTestInfo: 테스트 정보 수정
   * postQuestionHandler: 비동기로 보낼 데이터 저장
   * setSelectedListening: 선택한 답안 저장
   * setCurrentAudioIndex: audio파일 reset
   * @param {*} choice (string)
   * @param {*} idx (number)
  ============== */
  const onClickSelectAnswerHandler = (choice, idx) => {
    setTestInfo({
      duration: 900,
      doneCnt: doneCnt + 1,
      maxDoneCnt,
      canSubmit: false,
      buttonName: t('btn.next'),
    });

    postQuestionHandler(choice);
    setSelectedListening([...selectedListening, idx]);
    setCurrentAudioIndex(0);
  };

  /** ==========
   * 테스트 완료 시 실행 함수
   * setExampleStep: 다음 단계로 이동
   * setTestInfo: 테스트 정보 초기화
  ============== */
  const onSubmit = () => {
    setExampleStep(4);
    setTestInfo({
      duration: null,
      doneCnt: null,
      maxDoneCnt: null,
      canSubmit: false,
    });
  };

  /** ==========
   * 오디오 재생 이벤트 핸들러
   * 한번 클릭하면 오디오 계속 재생
  ============== */
  const playAudioHandler = () => {
    setPlayAudio(true);
  };

  /** ==========
   * 리스닝 문제 비동기 통신
  ============== */
  useEffect(() => {
    postQuestion(language)
      .then((res) => {
        setListeningData(res);
      })
      .catch((e) => {
        Sentry.captureException(e);
        console.error(e);
      });
  }, [postQuestionData]);

  useEffect(() => {
    setAudioUrls(listeningData?.passageAudio);
  }, [listeningData]);

  /** ==========
   * @summary
   * 컴포넌트가 렌더링 될 때 첫 번째 오디오 로드
   * @return
   * 컴포넌트가 언마운트 될 때 모든 오디오 정리
  ========== */
  useEffect(() => {
    if (playAudio) {
      audioRef.current = new Audio(audioUrls[0][currentAudioIndex]);
      setCurrentAudioIndex(currentAudioIndex + 1);
      setTimeout(() => {
        setIsEntireLoading(false);
        audioRef.current.play();
      }, 700);

      return () => {
        if (audioRef.current) {
          audioRef.current.pause();
          audioRef.current = null;
        }
      };
    }
  }, [playAudio, audioUrls]);

  /** ==========
   * 현재 재생 중인 오디오가 종료될 때 다음 오디오를 재생
  ============== */
  useEffect(() => {
    if (playAudio) {
      const handleEnded = () => {
        if (currentAudioIndex <= audioUrls[0].length - 1) {
          audioRef.current.pause();
          audioRef.current = new Audio(audioUrls[0][currentAudioIndex]);
          audioRef.current.play();
          setCurrentAudioIndex(currentAudioIndex + 1);
        }
      };
      if (audioRef.current) {
        audioRef.current.addEventListener('ended', handleEnded);
      }
      return () => {
        if (audioRef.current) {
          audioRef.current.removeEventListener('ended', handleEnded);
        }
      };
    }
  }, [currentAudioIndex]);

  useEffect(() => {
    setIsCheckedImage(listeningData?.question?.includes('.jpg'));

    /** ---------------
     * 테스트를 완료 했을 때 modal창을 오픈
     * level 화면에 표시
    ------------------- */
    if (listeningData?.result) {
      setIsEntireLoading(false);
      onSubmit();
    }

    /** ---------------
     * 시간 15분이 초과 됐을 시
     * 현재 완료된 부분만 재요청 => result값 받은 후
     * db에 저장
    ------------------- */
    const skillEndEventListener = () => {
      postQuestion(language)
        .then((res) => {
          setListeningData(res);
        })
        .catch((e) => {
          Sentry.captureException(e);
          console.error(e);
        });
    };

    window.document.addEventListener('skill_end', skillEndEventListener);
    return () => {
      window.document.removeEventListener('skill_end', skillEndEventListener);
    };
  }, [listeningData]);

  return (
    Boolean(Object.keys(listeningData).length !== 0) && (
      <>
        {windowSize?.width > 1023 && (
          <div className="objectives-title-box">
            <h3>{t('evaluation.language_test.title')}</h3>
          </div>
        )}
        <div className="separater pages-protected-evaluation-objectives-common-listening">
          <section className="separater-left">
            <div className="question fs18">
              <p className="multiple-question">{listeningData?.passage[0]}</p>
            </div>
            {isCheckedImage && (
              <div className="question-image">
                <img src={`${listeningData?.question}`} alt="question_image" />
              </div>
            )}
            <div className="button-wrapper">
              <button
                className={`common-button black fsbtn16 ${
                  playAudio ? 'disabled' : 'active'
                }`}
                disabled={!!playAudio}
                onClick={playAudioHandler}
              >
                {t('btn.start-listening')}
              </button>
            </div>
          </section>
          <section className="separater-right">
            {(listeningData?.passageGroup || []).map((question, idx) => (
              <DemoListeningAnswerItem
                key={idx}
                onClick={onClickSelectAnswerHandler}
                playAudio={playAudio}
                question={listeningData?.question}
                isCheckedImage={isCheckedImage}
                choices={listeningData?.choices}
                question_index={idx} // 0,1,2
                current_question={question} // 15,16
                question_id={listeningData?.questionId} // 3
                selectedListening={selectedListening}
              />
              // <ListeningAnswerItem
              //   key={idx}
              //   onClick={onClickSelectAnswerHandler}
              //   playAudio={playAudio}
              //   question={listeningData?.question}
              //   isCheckedImage={isCheckedImage}
              //   choices={listeningData?.choices}
              //   question_index={idx} // 0,1,2
              //   current_question={question} // 15,16
              //   question_id={listeningData?.questionId} // 3
              //   selectedListening={selectedListening}
              // />
            ))}
          </section>
        </div>
      </>
    )
  );
};

export default DemoLanguageListening;
