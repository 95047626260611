import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputEditor from 'components/codeEditor/InputEditor';
import OutputEditor from 'components/codeEditor/OutputEditor';
import { ReactComponent as TriangleIcon } from 'assets/images/icon/testtaker/triangle-icon.svg';
import { languageOptions } from 'components/codeEditor/languageOptions';
import { useCallbackPrompt } from 'utils/hooks';
import ExitModal from 'components/modal/ExitModal';
import LanguagesDropdown from 'components/codeEditor/LanguagesDropdown';
import { useResult } from 'utils/result';
import { divideSkillScope, divideSkillSection, useStatus } from 'utils/status';
import { toast } from 'react-toastify';
import {
  formatTime,
  convertTextToSec,
  convertHtmlForDisplay,
} from 'utils/string';
import axios from 'axios';

function Code({ test, setCurrentState, assessment, objectiveTests }) {
  const { t } = useTranslation();
  const javascriptDefault = `/* ${t('code.editor-placeholder')} */`;
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(true);
  const { recordResult } = useResult();
  const { setTestInfo, getTestInfo } = useStatus();
  const editorRef = React.useRef(null);
  const [cansubmit, SetCanSubmit] = React.useState(false);
  const { duration, doneCnt, maxDoneCnt } = getTestInfo();
  const [outputResult, setOutputResult] = React.useState();
  const [code, setCode] = useState(javascriptDefault);
  const [processing, setProcessing] = useState(null);
  const [languageName, setLanguageName] = useState();
  const [languageValue, setLanguageValue] = useState();
  const [languageID, setLanguageID] = useState();
  const [throttle, setThrottle] = useState(false);

  /**
   * onSubmitHandler() : 데이터 저장
   * type: string,
   * section: string,
   * sid: string,(사용자가 직접 만든 문제는 uuid가 존재하고 테스트에 기본으로 제공하는 문제는 id가 존재함)
   * data: {
   *  sourceCode : string,
   *  result : string,
   *  languageCode : string.
   * }
   *  */
  const onSubmitHandler = (code, outputResult, languageID) => {
    recordResult({
      type: divideSkillScope(test),
      section: divideSkillSection(test),
      sid: test?.uuid || test?.id,
      data: {
        sourceCode: code,
        result: outputResult,
        codeLanguage: languageID,
      },
    });
    SetCanSubmit(false);
  };

  React.useEffect(() => {
    const skillEndEventListener = (event) => {
      onSubmitHandler(code, outputResult, languageID);
    };

    window.scrollTo(0, 0);

    window.document.addEventListener('skill_end', skillEndEventListener);
    return () =>
      window.document.removeEventListener('skill_end', skillEndEventListener);
  }, []);

  React.useEffect(() => {
    const testDoneEventListener = (event) => {
      onSubmitHandler(code, outputResult, languageID);
    };

    let btnName = '';
    if (doneCnt === maxDoneCnt) {
      btnName = t('btn.submit');
    } else {
      btnName = t('btn.next');
    }

    setTestInfo({
      duration,
      doneCnt,
      maxDoneCnt,
      canSubmit: cansubmit,
      buttonName: btnName,
    });

    window.document.addEventListener('test_done', testDoneEventListener);
    return () => {
      window.document.removeEventListener('test_done', testDoneEventListener);
    };
  }, [cansubmit, code, outputResult, languageID]);

  useEffect(() => {
    setOutputResult('');
  }, [test]);

  /**
   * @param {*} str : encoding 시킬 string 값
   * @param {*} bytes : decoding 시킬 string 값
   * @returns
   */
  function encode(str) {
    return window.btoa(unescape(encodeURIComponent(str || '')));
  }

  function decode(bytes) {
    const escaped = escape(window.atob(bytes || ''));
    try {
      return decodeURIComponent(escaped);
    } catch {
      return unescape(escaped);
    }
  }

  // React.useEffect(() => {
  //   const temp = languageOptions.find((perLang) =>
  //     perLang.id === objectiveTests.codeLanguage
  //   );

  //   setLanguageName(temp.name);
  //   setLanguageValue(temp.value);
  // }, [languageName, languageValue]);

  const checkStatus = async (token) => {
    const options = {
      method: 'GET',
      url: `${process.env.REACT_APP_RAPID_API_URL}/${token}`,
      params: { base64_encoded: 'true', fields: '*' },
      headers: {
        'X-RapidAPI-Host': process.env.REACT_APP_RAPID_API_HOST,
        'X-RapidAPI-Key': process.env.REACT_APP_RAPID_API_KEY,
      },
    };
    try {
      const response = await axios.request(options);
      const statusId = response.data.status?.id;

      // Processed - we have a result
      if (statusId === 1 || statusId === 2) {
        // still processing
        setTimeout(() => {
          checkStatus(token);
        }, 2000);
      } else if (statusId === 6) {
        // compilation error
        // setOutputResult(window.atob(response.data?.compile_output));
        setOutputResult(decode(response.data?.compile_output));
        setProcessing(false);
        SetCanSubmit(true);
      } else if (statusId === 3 || statusId === 11) {
        setOutputResult(
          decode(response.data?.stdout) !== null
            ? `${decode(response.data?.stdout)}`
            : null
        );
        // setOutputResult(window.atob(response.data?.stdout) !== null
        //     ? `${window.atob(response.data?.stdout)}`
        //     : null);
        setProcessing(false);
        SetCanSubmit(true);
      } else if (statusId === 5) {
        setOutputResult('Time Limit Exceeded');
        setProcessing(false);
        SetCanSubmit(true);
      } else {
        SetCanSubmit(true);
        setProcessing(false);
        setOutputResult(decode(response.data?.stderr));
      }
      return;
    } catch (err) {
      console.log('err', err);
      setProcessing(false);
    }
  };

  const handleCompile = () => {
    setProcessing(true);
    const formData = {
      // language_id: objectiveTests.codeLanguage,
      language_id: languageID,
      source_code: encode(code),
    };
    const options = {
      method: 'POST',
      url: process.env.REACT_APP_RAPID_API_URL,
      params: { base64_encoded: 'true', fields: '*' },
      headers: {
        'content-type': 'application/json',
        'Content-Type': 'application/json',
        'X-RapidAPI-Host': process.env.REACT_APP_RAPID_API_HOST,
        'X-RapidAPI-Key': process.env.REACT_APP_RAPID_API_KEY,
      },
      data: formData,
    };
    axios
      .request(options)
      .then((response) => {
        const { token } = response.data;
        checkStatus(token);
      })
      .catch((err) => {
        const error = err.response ? err.response.data : err;
        const { status } = err.response;
        console.log('status', status);
        if (status === 429) {
          console.log('too many requests', status);
        }
        setProcessing(false);
        console.log('catch block...', error);
      });
  };

  const onChange = (action, data) => {
    switch (action) {
      case 'code': {
        setCode(data);
        break;
      }
      default: {
        console.warn('case not handled!', action, data);
      }
    }
  };

  const isDisable = () => {
    // 개발 언어를 선택하지 않았을 때 토스트로 알려준다.
    if (languageID === undefined) {
      toast(t('title.select-lang'));
      return;
    }
    setThrottle(true);
    setTimeout(() => {
      setThrottle(false);
    }, 6000);

    if (!throttle) {
      return handleCompile();
    }
    return null;
  };

  const onSelectChange = (sl, idx) => {
    const temp = languageOptions.find((perLang) => perLang.id === sl);

    setLanguageName(temp.name);
    setLanguageValue(temp.value);
    setLanguageID(temp.id);
  };

  return (
    <main className="pages-protected-evaluation-subjectives-code">
      <ExitModal
        showPrompt={showPrompt}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
      />
      <div className="container">
        <div className="spliter">
          <section className="spliter-left">
            <div className="question">
              <pre className="fs18">{test.question}</pre>
            </div>

            {/* <div className="timer">
              {formatTime(convertTextToSec(test?.limit))}
            </div> */}
          </section>
          <section className="spliter-right">
            <div className="editor-select">
              {/* <span className="fs14">{languageName}</span> */}
              <div className="question-language-select-box">
                <div className="question-language-select-caution">
                  <span className="fs16">{t('title.select-lang')}</span>
                </div>
                <div className="question-language-selected">
                  <LanguagesDropdown
                    onSelectChange={onSelectChange}
                    // selectedCategory={selectedCategory}
                    // objectiveTests={objectiveTests}
                  />
                </div>
              </div>
            </div>
            <aside className="editor-input">
              <InputEditor
                code={code}
                language={languageValue}
                onChange={onChange}
                isLeadonly={false}
                isLine="on"
                doneCnt={doneCnt}
              />
            </aside>

            <aside className="editor-result-button-box">
              <span className="fs16">{t('code.result-title')}</span>
              <button
                className="common-button fsbtn16 primary active"
                disabled={throttle}
                onClick={isDisable}
              >
                {processing ? t('btn.code-runing') : t('btn.code-run')}
                <TriangleIcon />
              </button>
            </aside>

            <aside className="editor-output">
              <OutputEditor outputResult={outputResult} />
            </aside>
          </section>
        </div>
      </div>
    </main>
  );
}

export default Code;
