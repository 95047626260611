import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { executeAction } from 'utils/redux';
import { useProgress } from 'utils/progress';
import { getAssessmentInfo } from 'store/slices/testtakerSlice';
import {
  getAssessmentsOfCurrentUser,
  getCandidatesOfAssessments,
  getUserInfo,
} from 'store/slices/authSlice';
import * as Sentry from '@sentry/react';
import Reject from './publicInvitation/Reject';
import DemoAccept from './publicInvitation/DemoAccept';

const PublicInvitation = () => {
  const location = useLocation();
  const [assessment, setAssessment] = React.useState({});
  const [_assessment, _setAssessment] = React.useState([]);
  const [candidate, setCandidate] = React.useState(null);
  const [candidateCount, setCandidateCount] = React.useState([]);
  const [user, setUser] = React.useState();
  const [isLoading, setIsLoading] = React.useState(false);
  const { setIsEntireLoading } = useProgress();
  const navigate = useNavigate();

  const { uid, aid, logoURL, userPlan, isPersonality } = location.state || {};

  React.useEffect(() => {
      if (!uid || !aid) return;

      executeAction(
      getUserInfo({
        uid,
        aid,
      })
    )
      .unwrap()
      .then(({ doc }) => {
        setUser(doc);
      })
      .catch((e) => {
          Sentry.captureException(e);
          console.dir(e);
      });
  }, [uid, aid]);

  React.useEffect(() => {
      if (!uid || !aid) return;

      const skilllist = {};
    executeAction(
      getAssessmentsOfCurrentUser({
        uid,
        aid,
      })
    )
      .unwrap()
      .then(({ docs }) => {
        setCandidateCount(docs);
        executeAction(
          getCandidatesOfAssessments({
            assessments: docs,
            uid,
          })
        )
          .unwrap()
          .then(({ candidates }) => {
            (docs || []).forEach((doc, idx) => {
              const skills = new Array();
              doc.candidates = candidates?.[idx] || [];
              (doc?.tests || []).forEach((test, idx) => {
                skills.push(
                  test?.title_en ||
                    test?.category_skill ||
                    doc?.groupTests?.group
                );
              });
              skilllist[doc.id] = skills;
            });
            _setAssessment([...docs] || []);
          });
      })
      .catch((e) => {
          Sentry.captureException(e);
          console.dir(e);
      });
  }, [uid, aid]);

  React.useEffect(() => {
    const _candidateCount = new Array();

    candidateCount?.forEach((_assessment, idx) => {
      _assessment.candidates.length >= 0
        ? _assessment.candidates.map((val, _idx) => {
            _candidateCount.push(val);
          })
        : _candidateCount.push([]);
    });
    setCandidate([..._candidateCount]);
  }, [uid, aid, _assessment]);

  React.useEffect(() => {
    if (!uid || !aid) return;

    executeAction(
      getAssessmentInfo({
        uid,
        aid,
      })
    )
      .unwrap()
      .then(({ doc }) => {
        setAssessment(doc);
      })
      .catch((e) => {
          Sentry.captureException(e);
          console.dir(e);
      });
  }, [uid, aid]);

  if (Boolean(uid) && Boolean(aid)) {
    if (assessment?.archived === true) {
      navigate('/testtaker/auth/invalidinvitation');
      return;
    }
    return (
      <div className="relative">
        {/* {!isPersonality && <Landing />} */}
        <DemoAccept
          uid={uid}
          aid={aid}
          assessment={assessment}
          logoURL={logoURL}
          userPlan={userPlan}
          user={user}
          isPersonality={isPersonality}
        />
      </div>
    );
  }

  return <Reject />;
};

export default PublicInvitation;
