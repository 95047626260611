import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStatus } from 'utils/status';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ThankYou } from 'assets/images/illustration/ThankyouText.svg';
import { executeAction } from 'utils/redux';
import { getCandidateInAssessment } from 'store/slices/authSlice';
import { getAssessmentInfo } from 'store/slices/testtakerSlice';
import * as Sentry from '@sentry/react';

const OBPI_TESTS = [
  {
    category: 'fellowship',
    title: 'title.fellowship',
    left: 'desc.fellowship-left',
    right: 'desc.fellowship-right',
  },
  {
    category: 'authority',
    title: 'title.authority',
    left: 'desc.authority-left',
    right: 'desc.authority-right',
  },
  {
    category: 'conformity',
    title: 'title.conformity',
    left: 'desc.conformity-left',
    right: 'desc.conformity-right',
  },
  {
    category: 'emotion',
    title: 'title.emotion',
    left: 'desc.emotion-left',
    right: 'desc.emotion-right',
  },
  {
    category: 'detail',
    title: 'title.detail',
    left: 'desc.detail-left',
    right: 'desc.detail-right',
  },
  {
    category: 'proficiency',
    title: 'title.proficiency',
    left: 'desc.proficiency-left',
    right: 'desc.proficiency-right',
  },
  {
    category: 'work-orientation',
    title: 'title.work-orientation',
    left: 'desc.work-orientation-left',
    right: 'desc.work-orientation-right',
  },
  {
    category: 'patience',
    title: 'title.patience',
    left: 'desc.patience-left',
    right: 'desc.patience-right',
  },
  {
    category: 'fair-mindedness',
    title: 'title.fair-mindedness',
    left: 'desc.fair-mindedness-left',
    right: 'desc.fair-mindedness-right',
  },
  {
    category: 'loyalty',
    title: 'title.loyalty',
    left: 'desc.loyalty-left',
    right: 'desc.loyalty-right',
  },
  {
    category: 'disclosure',
    title: 'title.disclosure',
    left: 'desc.disclosure-left',
    right: 'desc.disclosure-right',
  },
  {
    category: 'initiative',
    title: 'title.initiative',
    left: 'desc.initiative-left',
    right: 'desc.initiative-right',
  },
];

function Done() {
  const { t } = useTranslation();
  const { uid, aid, email: cid, logoURL } = useStatus();
  const navigate = useNavigate();
  const [assessment, setAssessment] = React.useState({});
  const [objectiveTests, setObjectiveTests] = React.useState([]);
  const [obpiTest, setOBPITest] = React.useState(null);
  const [candidate, setCandidate] = React.useState({});
  const [objectiveScores, setObjectiveScores] = React.useState({});
  const [refreshing, setRefreshing] = React.useState(true);
  const [obpiStanine, setOBPIStanine] = React.useState([]);

  React.useEffect(() => {
    const docElem = window.document.documentElement;
    if (docElem.exitFullscreen) {
      docElem.exitFullscreen();
    } else if (docElem.webkitExitFullscreen) {
      docElem.webkitExitFullscreen();
    } else if (docElem.msExitFullscreen) {
      docElem.msExitFullscreen();
    } else if (docElem.cancelFullScreen) {
      docElem.cancelFullScreen();
    }
  }, []);

  React.useEffect(() => {
    const obpistanine = new Array();
    for (const obpis of OBPI_TESTS) {
      const score = obpiTest?.traits?.find((x) => x.scale === obpis.category);
      obpistanine.push(score?.stanine);
    }
    setOBPIStanine(obpistanine);
  }, [obpiTest]);

  React.useEffect(() => {
    if (!uid || !aid) {
      navigate('/testtaker/auth/invalidinvitation', {
        state: { uid, aid, logoURL },
        replace: true,
      });
      return;
    }

    executeAction(
      getAssessmentInfo({
        uid,
        aid,
      })
    )
      .unwrap()
      .then(({ doc }) => {
        setAssessment(doc || {});
      })
      .catch((e) => {
        Sentry.captureException(e);
        console.dir(e);
        setAssessment({});
      });
  }, [aid]);

  React.useEffect(() => {
    const results = candidate?.results;

    const _objectiveScores = {};
    const tasks = new Array();
    for (const skill of objectiveTests) {
      const suuid = skill?.uuid;
      const result = results?.[suuid];
      const lang2 = assessment?.language;
      if (!result) continue;
      const temp = new Array();
      const category = {};
      const array = new Array();
      const answers = skill.question;

      for (const [key, val] of Object.entries(result || {})) {
        const tid = key;
        const answer = answers.find((x) => x.tid === tid);
        // console.log(answer);
        if (!category[answer.testInfo.skill_en])
          category[answer.testInfo.skill_en] = {};
        if (!category[answer.testInfo.skill_en].cnt)
          category[answer.testInfo.skill_en].cnt = 0;
        if (!category[answer.testInfo.skill_en].correct)
          category[answer.testInfo.skill_en].correct = 0;
        category[answer.testInfo.skill_en].cnt += 1;
        if (answer?.testInfo?.correct.length > 1) {
          let isCorrect = true;
          answer?.testInfo?.correct.forEach((cor, idx) => {
            isCorrect = isCorrect && cor === val?.[0][idx];
          });
          temp.push(true);
          category[answer.testInfo.skill_en].correct += 1;
        } else {
          if (answer?.testInfo?.correct?.[0] === val?.[0]) {
            category[answer.testInfo.skill_en].correct += 1;
          }
          temp.push(answer?.testInfo?.correct?.[0] === val?.[0]);
        }
      }
      let _score = 0;
      if (temp.length) _score = temp.filter((x) => x).length / temp.length;
      const correct = temp.filter((x) => x).length;
      _objectiveScores[suuid] = new Array();
      _objectiveScores[suuid].push(_score);
      _objectiveScores[suuid].push(correct);
      _objectiveScores[suuid].push(temp.length);
    }

    setObjectiveScores(_objectiveScores);
  }, [objectiveTests]);

  React.useEffect(() => {
    if (!assessment || !Object.keys(assessment || {}).length) {
      return;
    }

    const { tests } = assessment || {};
    const newObjectiveTests = new Array();
    const newSubjectiveTests = new Array();
    const newLibraryTests = new Array();
    (tests || []).forEach((test, idx) => {
      if (test?.testcnt > 0 || test?.skills?.length > 0) {
        (test?.skills || []).forEach((skill) => {
          if (candidate?.results?.[skill.uuid]) {
            newObjectiveTests.push(skill);
          }
        });
        return;
      }

      const { section } = test;
      if (
        section === 'video' ||
        section === 'essay' ||
        section === 'multiple-choice' ||
        section === 'file-upload' ||
        section === 'spreadsheets' ||
        section === 'code'
      ) {
        newSubjectiveTests.push(test);
      } else if (
        test?.title_en?.indexOf('인성/심리검사') >= 0 ||
        test?.title_en?.toUpperCase().indexOf('KHAI') >= 0
      ) {
        // if(test?.title_en?.indexOf('OBPI') >= 0) {
        setOBPITest(candidate?.results?.obpi);
      } else if (
        test?.question_type?.indexOf('tag.subjective') >= 0 ||
        test?.question_type?.indexOf('tag.code') >= 0 ||
        test?.question_type?.indexOf('tag.video') >= 0
      ) {
        newLibraryTests.push(test);
      } else {
        newObjectiveTests.push(test);
      }
      // console.log(test);
    });
    setObjectiveTests(newObjectiveTests || []);
  }, [assessment, candidate]);

  React.useEffect(() => {
    if(!aid || !cid || !uid) return;
    if (refreshing) {
      executeAction(
        getCandidateInAssessment({
          uid,
          aid,
          email: cid,
        })
      )
        .unwrap()
        .then(({ doc }) => {
          // console.log(doc);
          setCandidate(doc || {});
          setRefreshing(false);
        })
        .catch((e) => {
          Sentry.captureMessage('getCandidateInAssessment', {extra: {uid, aid, cid}});
          console.dir(e);
          setRefreshing(false);
        });
    }
  }, [aid, cid, refreshing]);

  return (
    <main className="demo-pages-protected-evaluation-done">
      <div className="container">
        <div>
          <ThankYou />
        </div>
        <div>
          <div
            dangerouslySetInnerHTML={{ __html: t('evaluation.done.title') }}
          />
          <div>{t('evaluation.done.close_text')}</div>
        </div>
      </div>
    </main>
  );
}

export default Done;
