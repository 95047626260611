import {
  getFirestore,
  collection,
  doc,
  getDoc,
  getDocs,
  addDoc,
  setDoc,
  updateDoc,
  deleteDoc,
  query,
  where,
  Timestamp,
  orderBy,
} from 'firebase/firestore';
import * as Sentry from '@sentry/react';
import { app } from './index';

const db = getFirestore(app);

export const _getAssessmentsOfCurrentUser = ({ uid }) =>
  new Promise(async (resolve, reject) => {
    try {
      const assessmentsRef = collection(db, 'users', uid, 'assessments');
      const q = query(assessmentsRef, orderBy('createdAt', 'desc'));
      const querySnapshot = await getDocs(q);
      const newDocs = new Array();
      querySnapshot.forEach((doc) => {
        newDocs.push({
          id: doc.id,
          ...doc.data(),
        });
      });

      return resolve(newDocs);
    } catch (e) {
      return reject(e);
    }
  });

export const _getCandidatesOfAssessment = ({ uid, aid }) =>
  new Promise(async (resolve, reject) => {
    try {
      const collectionRef = collection(
        db,
        'users',
        uid,
        'assessments',
        aid,
        'candidates'
      );
      const q = query(collectionRef, orderBy('createdAt', 'desc'));
      const querySnapshot = await getDocs(q);
      const docs = new Array();
      querySnapshot.forEach((doc) => {
        docs.push({ id: doc.id, ...doc.data() });
      });
      return resolve(docs);
    } catch (e) {
      return reject(e);
    }
  });

export const _getCandidateInAssessment = ({ uid, aid, email }) =>
  new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(
        db,
        'users',
        uid,
        'assessments',
        aid,
        'candidates',
        email
      );
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        return resolve({
          id: docSnap.id,
          ...docSnap.data(),
        });
      }
        Sentry.captureMessage('_getCandidateInAssessment', {extra: {uid, aid, email}});
        throw new Error('empty_doc');
    } catch (e) {
      return reject(e);
    }
  });

export const _updateCandidateInfo = ({
  uid,
  aid,
  email,
  firstName,
  lastName,
    birth,
    testNumber
}) =>
  new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(
        db,
        'users',
        uid,
        'assessments',
        aid,
        'candidates',
        email
      );
      await setDoc(
        docRef,
        {
          firstName,
          lastName,
          email,
            birth: birth || '',
            testNumber: testNumber || '',
          invitedAt: Timestamp.now(),
          createdAt: Timestamp.now(),
          progress: -1,
          condition: 0,
        },
        { merge: true }
      );
      return resolve(true);
    } catch (e) {
      return reject(e);
    }
  });

export const _getUserInfo = ({ uid }) =>
  new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(db, 'users', uid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        return resolve({
          id: docSnap.id,
          ...docSnap.data(),
        });
      }
        Sentry.captureMessage('_getUserInfo', {extra: {uid}});
        throw new Error('empty_doc');
    } catch (e) {
      return reject(e);
    }
  });

export const _updateCandidate = ({ uid, aid, cid, phoneNumber, countryCode }) =>
  new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(
        db,
        'users',
        uid,
        'assessments',
        aid,
        'candidates',
        cid
      );
      await setDoc(
        docRef,
        {
          email: cid,
          firstName: '',
          lastName: '',
          phoneNumber,
          countryCode,
          invitedAt: Timestamp.now(),
          createdAt: Timestamp.now(),
          progress: -1,
          condition: 0,
        },
        {
          merge: true,
        }
      );
      return resolve(true);
    } catch (e) {
      return reject(e);
    }
  });

export const _updateCandidatePhoneNumber = ({
  uid,
  aid,
  cid,
  phoneNumber,
  countryCode,
}) =>
  new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(
        db,
        'users',
        uid,
        'assessments',
        aid,
        'candidates',
        cid
      );
      await setDoc(
        docRef,
        {
          email: cid,
          // firstName: '',
          // lastName: '',
          phoneNumber,
          countryCode,
        },
        {
          merge: true,
        }
      );
      return resolve(true);
    } catch (e) {
      return reject(e);
    }
  });

export const _updateCandidateWithoutPhoneNumber = ({ uid, aid, cid }) =>
  new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(
        db,
        'users',
        uid,
        'assessments',
        aid,
        'candidates',
        cid
      );
      await setDoc(
        docRef,
        {
          email: cid,
          firstName: '',
          lastName: '',
          invitedAt: Timestamp.now(),
          createdAt: Timestamp.now(),
          progress: -1,
          condition: 0,
        },
        {
          merge: true,
        }
      );
      return resolve(true);
    } catch (e) {
      return reject(e);
    }
  });

export const _leaveUpdateCandidate = ({ uid, aid, cid, data }) =>
  new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(
        db,
        'users',
        uid,
        'assessments',
        aid,
        'candidates',
        cid
      );
      await setDoc(
        docRef,
        {
          ...data,
          updatedAt: Timestamp.now(),
        },
        {
          merge: true,
        }
      );
      return resolve(true);
    } catch (e) {
      return reject(e);
    }
  });

export const _getEditText = ({ uid, aid, key, lang }) =>
  new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(db, 'users', uid, 'assessments', aid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const { edits } = docSnap.data() || {};
        if (edits && Boolean(edits?.[key])) {
          return resolve(edits?.[key]);
        }
        const template = await _getDefaultTemplate({
          tid: key,
        });
        lang = (lang || '').slice(0, 2);
        const editText = template?.[lang];
        await setDoc(
          doc(db, 'users', uid, 'assessments', aid),
          {
            edits: {
              [key]: editText || '',
            },
          },
          {
            merge: true,
          }
        );

        return resolve(editText);
      }
        Sentry.captureMessage('_getEditText', {extra: {uid, aid, key, lang}});
        throw new Error('empty_doc');
    } catch (e) {
      return reject(e);
    }
  });

const _getDefaultTemplate = ({ tid }) =>
  new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(db, 'templates', tid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        return resolve({
          id: docSnap.id,
          ...docSnap.data(),
        });
      }
        Sentry.captureMessage('_getDefaultTemplate', {extra: {tid}});
        throw new Error('empty_doc');
    } catch (e) {
      return reject(e);
    }
  });

export const _setCandidateDoc = ({
  uid,
  email,
  // firstName,
  // lastName,
  aid,
  phoneNumber,
  countryCode,
}) =>
  new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(db, 'users', uid, 'candidates', email);

      await setDoc(
        docRef,
        {
          email: email || '',
          // firstName: firstName || '',
          // lastName: lastName || '',
          phoneNumber: phoneNumber || '',
          countryCode: countryCode || '',
          assessments: {
            [aid]: null,
          },
          updatedAt: Timestamp.now(),
        },
        {
          merge: true,
        }
      );
      return resolve(true);
    } catch (e) {
      return reject(e);
    }
  });

export const _setCandidateDocWithoutPhoneNumber = ({
  uid,
  email,
  firstName,
  lastName,
    birth,
    testNumber,
  aid,
}) =>
  new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(db, 'users', uid, 'candidates', email);

      await setDoc(
        docRef,
        {
          email: email || '',
          firstName: firstName || '',
          lastName: lastName || '',
            birth: birth || '',
            testNumber: testNumber || '',
          assessments: {
            [aid]: null,
          },
          updatedAt: Timestamp.now(),
        },
        {
          merge: true,
        }
      );
      return resolve(true);
    } catch (e) {
      return reject(e);
    }
  });
