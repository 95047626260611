import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import TinyEditor from 'components/editor/TinyEditor';
import { useCallbackPrompt } from 'utils/hooks';
import ExitModal from 'components/modal/ExitModal';
import { useResult } from 'utils/result';
import { divideSkillScope, divideSkillSection, useStatus } from 'utils/status';
import {
  spaceRegex,
  formatTime,
  convertTextToSec,
  convertHtmlForDisplay,
  convertHtmlToText,
  boldRegex,
} from 'utils/string';

function DemoStylingEssay({ test, setCurrentState, totalTest }) {
  const { t } = useTranslation();
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(true);
  const { recordResult, getResult } = useResult();
  const { results } = getResult();
  const { setTestInfo, getTestInfo } = useStatus();
  const editorRef = React.useRef(null);
  const [cansubmit, SetCanSubmit] = React.useState(false);
  const { duration, doneCnt, maxDoneCnt } = getTestInfo();

  const onSubmitHandler = () => {
    recordResult({
      type: divideSkillScope(test),
      section: divideSkillSection(test),
      sid: test?.uuid || test?.id, // 사용자가 직접 만든 문제는 uuid가 존재하고 테스트에 기본으로 제공하는 문제는 id가 존재함
      data: {
        text: editorRef.current?.getContent(),
        question: convertHtmlToText(test?.question),
      },
    });
    editorRef.current?.setContent('');
    SetCanSubmit(false);
  };

  React.useEffect(() => {
    const skillEndEventListener = (event) => {
      onSubmitHandler();
    };

    window.scrollTo(0, 0);

    window.document.addEventListener('skill_end', skillEndEventListener);
    return () =>
      window.document.removeEventListener('skill_end', skillEndEventListener);
  }, []);

  React.useEffect(() => {
    SetCanSubmit(false);
    editorRef.current?.setContent('');
  }, [doneCnt]);

  React.useEffect(() => {
    const testDoneEventListener = (event) => {
      onSubmitHandler();
    };

    let btnName = '';
    if (doneCnt === maxDoneCnt) {
      btnName = t('btn.submit');
    } else {
      btnName = t('btn.next');
    }
    // if(cansubmit) {
    setTestInfo({
      duration,
      doneCnt,
      maxDoneCnt,
      canSubmit: cansubmit,
      buttonName: btnName,
    });
    // } else {
    //   setTestInfo({
    //     duration: duration,
    //     doneCnt: doneCnt,
    //     maxDoneCnt: maxDoneCnt,
    //     canSubmit: false,
    //     buttonName : btnName
    //   });
    // }

    window.document.addEventListener('test_done', testDoneEventListener);
    return () => {
      window.document.removeEventListener('test_done', testDoneEventListener);
    };
  }, [cansubmit, doneCnt]);

  const canSubmit = () => {
    if (editorRef.current.getContent().length > 0) SetCanSubmit(true);
    else SetCanSubmit(false);
  };

  return (
    <main className="demo-styling-pages-protected-evaluation-subjectives-essay">
      <ExitModal
        showPrompt={showPrompt}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
      />
      <div className="container">
        <div className="spliter">
          <section className="spliter-left">
            <div className="question">
              <div className="styling-title-wrapper">
                <h3>{t('evaluation.styling.title')}</h3>
              </div>
              <pre
                dangerouslySetInnerHTML={{
                  __html: convertHtmlForDisplay(test?.question),
                }}
              />

              {totalTest?.id === '990001' &&
                results['99000100000'] !== undefined && (
                  <div className="style-image-box">
                    <img
                      alt=""
                      className="style-image"
                      src={`${results['99000100000'].img.createUrl}`}
                    />
                  </div>
                )}
            </div>
          </section>
          <section className="spliter-right">
            <TinyEditor
              ref={editorRef}
              className="answer"
              onEditorChange={() => {
                canSubmit();
              }}
            />
          </section>
        </div>
      </div>
    </main>
  );
}

export default DemoStylingEssay;
